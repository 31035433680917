import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
  userdetails: any;
  user_role: string;
  user_id: string;
  isUploadMeasures: boolean;
  isCollapsed: boolean;
  activeMenu: string = 'viewingMeasures';

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.userdetails = JSON.parse(localStorage.getItem('auth.user'));
      if (!_.isNull(this.userdetails)) {
        this.user_role = this.userdetails.role;
        this.user_id = this.userdetails.id;
      }
    });
  }
}
