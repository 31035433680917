import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {HighchartsChartModule} from 'highcharts-angular';
import {MatSortModule} from '@angular/material/sort';

import {SharedModule} from '../../../shared/shared.module';

// import {AudienceComponent} from './audience/audience.component';
// import {UserflowsComponent} from './userflows/userflows.component';
// import {TechnologyComponent} from './technology/technology.component';

// import {BrowserComponent} from './technology/browser/browser.component';
// import {OsComponent} from './technology/os/os.component';
// import {BehaviorsComponent} from './behaviors/behaviors.component';
// import {GeodataComponent} from './geodata/geodata.component';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatAutocompleteModule,
  MatButtonModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  NativeDateModule
} from '@angular/material';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {GA4UsersComponent} from './users/users.component';
import { PagesComponent } from './pages/pages.component';
import {GA4TrafficChannelsComponent} from './traffic/channels/traffic-channels.component';
import {GA4TrafficCountriesComponent} from './traffic/countries/traffic-countries.component';
import {GA4TrafficLanguagesComponent} from './traffic/languages/traffic-languages.component';
import {GA4TrafficSourcesWebsitesComponent} from './traffic/sources-websites/traffic-sources-websites.component';
import {GA4TrafficSourcesLinksComponent} from './traffic/sources-links/traffic-sources-links.component';

// import {PageViewsComponent} from './behavior-overview/page-views/page-views.component';
// import {BehaviorSummaryComponent} from './behavior-overview/behavior-summary/behavior-summary.component';
// import {ChannelsComponent} from './channels/channels.component';
// import {DirectComponent} from './channels/direct/direct.component';
// import {ReferralsComponent} from './channels/referrals/referrals.component';
// import {OrganicSearchComponent} from './channels/organic-search/organic-search.component';
// import {SocialComponent} from './channels/social/social.component';
// import {LanguageComponent} from './geodata/language/language.component';
// import {DemographicsComponent} from './demographics/demographics.component';
// import {GenderComponent} from './demographics/gender/gender.component';
// import {AgeComponent} from './demographics/age/age.component';


const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
    HighchartsChartModule,
    MatInputModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelectModule,
    MatSortModule,
    NativeDateModule,
  ],
  declarations: [
    GA4UsersComponent,
    PagesComponent,
    GA4TrafficChannelsComponent,
    GA4TrafficCountriesComponent,
    GA4TrafficLanguagesComponent,
    GA4TrafficSourcesWebsitesComponent,
    GA4TrafficSourcesLinksComponent,
  ], providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}

  ]


})
export class GoogleAnalyticsV4Module {
}
