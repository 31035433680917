import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class FileService {

  constructor() {
  }


  public downloadFile(url: any) {
    const filename = url.substring(url.lastIndexOf('/') + 1).split('?')[0];
    const a = document.createElement('a');
    a.href = url;
    a.download = filename; // Set the file name.
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
}
