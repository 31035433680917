import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {DynamicFormComponent} from './containers/dynamic-form/dynamic-form.component';
import {FormInputComponent} from './components/form-input/form-input.component';
import {FormSelectComponent} from './components/form-select/form-select.component';
import {FormButtonComponent} from './components/form-button/form-button.component';
import {FormCheckboxComponent} from './components/form-checkbox/form-checkbox.component';
import {FormRadioComponent} from './components/form-radio/form-radio.component';
import {DynamicFieldDirective} from './dynamic-field.directive';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [
    DynamicFieldDirective,
    DynamicFormComponent,
    FormInputComponent,
    FormSelectComponent,
    FormButtonComponent,
    FormCheckboxComponent,
    FormRadioComponent
  ],
  exports: [DynamicFormComponent,],
  entryComponents: [
    FormButtonComponent,
    FormInputComponent,
    FormSelectComponent,
    FormCheckboxComponent,
    FormRadioComponent,
  ],
})
export class DynamicFormModule {
}
