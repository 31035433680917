import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';

import {ApirequestService} from '../../../shared/services/apirequest.service';
import {ApirequestdetailService} from '../../../shared/services/apirequestdetail.service';

@Component({
  selector: 'app-experimentsfilters',
  templateUrl: './experimentsfilters.component.html',
  styleUrls: ['../experiments.component.scss']
})
export class ExperimentsfiltersComponent implements OnInit {
  @Input() pagefilters: any;


  domainsFiltersList = [];
  domainsFilters = [];
  typeFilters = [];
  durationFilters = [];
  targetPopulationFilters = [];
  stageFilters = [];
  measureSourceFilters = [];
  thefilter;
  btnclicked = false;
  url_measurefilters = 'measures/domains_list/';
  isType = false;
  isHypothesizedDomain = false;
  isStage = false;
  isAdvanced = false;
  isDuration = false;
  isTargetPopulation = false;
  isSource = false;
  showSource = false;
  showType = false;
  showHypothesizedDomain = false;
  showStage = false;
  showDuration = false;
  showTargetPopulation = false;


  constructor(
    private apirequestService: ApirequestService,
    private apirequestdetailService: ApirequestdetailService,
  ) {
  }

  ngOnInit() {

    _.each(['Experiment', 'Game', 'Survey'], (value) => {
      if (this.pagefilters.measure_source.includes(value)) {
        this.measureSourceFilters.push(value);
        this.showSource = true;
      }
    });

  }

  // _.remove(v, function(n){ return n!=8;});
  arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele !== value;
    });
  }

  toggleEditable(event, type, val) {
    console.log(val);
    if (type === 'measure_source' || type === 'type') {
      if (event.target.checked === true) {
        this.measureSourceFilters.push(val);
      } else if (event.target.checked === false) {
        this.measureSourceFilters = this.arrayRemove(this.measureSourceFilters, val);
      }
      console.log('measureSourceFilters=' + this.measureSourceFilters.join());
    }


    this.thefilter = {
      // 'domains': this.domainsFilters.join(',') || 'All',
      // 'type': this.typeFilters.join(',') || 'All',
      // 'duration': this.durationFilters.join(',') || 'All',
      // 'target_population': this.targetPopulationFilters.join(',') || 'All',
      // 'stage': this.stageFilters.join(',') || 'All',
      // 'type': this.measureSourceFilters.join() || 'All',
      'type': this.measureSourceFilters.join(),
      'title': 'all'
    };

    console.log('this.thefilter=' + JSON.stringify(this.thefilter));
    this.apirequestdetailService.setFilteredData(this.thefilter);
    console.log('getFilteredData=' + this.apirequestdetailService.getFilteredData());
  }
}
