import {Component, OnInit} from '@angular/core';

import {ApirequestService} from '../../../../shared/services/apirequest.service';
import {GoogleStatsAudienceModel} from '../../../../shared/models/google_stats_audience.model';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-ga4-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class GA4UsersComponent implements OnInit {
  dashboard_details: GoogleStatsAudienceModel;
  start_date: string = '';
  end_date: string = '';
  loading: boolean;

  constructor(
    private apirequestService: ApirequestService,
  ) {
  }


  ngOnInit() {
    // this.start_date = '2022-01-01';
    // this.end_date = '2022-01-31';
    // this.get_stats_from_google(this.start_date, this.end_date);
    let today = new Date();
    var lastMonth = new Date(today);
    lastMonth.setDate(lastMonth.getDate() - 30);

    this.start_date = lastMonth.toISOString().slice(0, 10);
    this.end_date = today.toISOString().slice(0, 10);

    this.getData(this.start_date, this.end_date);
  }

  // filter_stats(){
  //   this.loading = true;
  //   this.start_date = (<HTMLInputElement>document.getElementById('start_date')).value;
  //   this.end_date = (<HTMLInputElement>document.getElementById('end_date')).value;
  //   console.log(this.start_date, ':' ,this.end_date);
  //   this.get_stats_from_google(this.start_date, this.end_date);
  // }

  filter_by_date() {
    this.loading = true;
    this.getData(this.start_date, this.end_date);
  }

  // get_stats_from_google(start_date, end_date){
  getData(start_date, end_date) {
    this.loading = true;
    var url = '';

    if (start_date != '' && end_date != '') {
      var formatedstartDate = moment(start_date).format('YYYY-MM-DD');
      var formatedendDate = moment(end_date).format('YYYY-MM-DD');

    } else {
      // let today = new Date().toISOString().slice(0, 10);
      let today = new Date();
      var lastMonth = new Date(today);
      lastMonth.setDate(lastMonth.getDate() - 30);

      var formatedstartDate = lastMonth.toISOString().slice(0, 10);
      var formatedendDate = today.toISOString().slice(0, 10);
    }
    url = 'analytics/users/?start_date=' + formatedstartDate + '&end_date=' + formatedendDate;
    this.start_date = formatedstartDate;
    this.end_date = formatedendDate;

    this.apirequestService.get(url)
      .subscribe(
        data => {
          this.dashboard_details = {
            ...data
            // ...data[0],
            // pageviewsPerSession: parseFloat(data[0].pageviewsPerSession).toFixed(2),
            // bounceRate: parseFloat(data[0].bounceRate).toFixed(2),

            // avgSessionDuration: moment().startOf('day')
            //   .seconds(Number(data[0].avgSessionDuration))
            //   // .format('H:mm:ss'),
            //   .format('HH:mm:ss'),
          };
          this.loading = false;

        },
        error => {
          console.log('ERROR::' + error);
          this.loading = false;
        });
  }


  get_session_duration(secondsSessionDuration) {
    let unix_timestamp = parseFloat(secondsSessionDuration);
    // Create a new JavaScript Date object based on the timestamp
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    var date = new Date(unix_timestamp * 1000);
    // Hours part from the timestamp
    var hours = date.getHours();
    // Minutes part from the timestamp
    var minutes = '0' + date.getMinutes();
    // Seconds part from the timestamp
    var seconds = '0' + date.getSeconds();

    // Will display time in 10:30:23 format
    var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

    return formattedTime;
  }


}


