import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import * as _ from 'lodash';

import {ApirequestService} from '../../shared/services/apirequest.service';
import {ApirequestdetailService} from '../../shared/services/apirequestdetail.service';
import {CustomSnackBarComponent} from '../../shared/common/custom-snack-bar/custom-snack-bar.component';
import {ConfirmationDialogService} from '../../shared/confirmation-dialog/confirmation-dialog.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {AlertService} from '../../shared/services/alert.service';

@Component({
  selector: 'app-all-measures',
  templateUrl: './measures.component.html',
  styleUrls: ['./measures.component.scss'],
})
export class AllMeasuresComponent implements OnDestroy, OnInit {
  measures = [];
  page: number;
  total = 0;
  total_pages = 0;
  thefilters = {};
  page_size = 50;
  sort_fields: any;
  search_data: any;
  route_stage: string;
  url_params;
  access_measure: boolean;
  loading = true;
  subscription: Subscription;

  collapse = 'closed';
  btncollapse = 'not_clicked';
  show = false;
  showText = false;
  isMainmeasuredetails = false;
  search_input: string;
  user_id_: string;
  user_role: string;
  loading_submit: boolean;

  constructor(
    private apirequestService: ApirequestService,
    private apirequestdetailService: ApirequestdetailService,
    private route: ActivatedRoute,
    private router: Router,
    public snackBar: MatSnackBar,
    private confirmationDialogService: ConfirmationDialogService,
    public alertService: AlertService,
  ) {
    this.subscription = this.apirequestdetailService.getFilteredData()
      .subscribe(
        data => {
          this.thefilters = data;
          this.page = 1;
          this.getDataByPage();
        },
        error => {
          console.log(error);
        });
  }

  ngOnInit() {
    let u = JSON.parse(localStorage.getItem('auth.user'));
    this.user_id_ = u.id;
    this.user_role = u.role;
    this.route.queryParams.subscribe(params => {
      this.page = params['page'] || '1';
      this.thefilters = {
        'domains': params['domains'] || 'All',
        'type': params['type'] || 'All',
        'duration': params['duration'] || 'All',
        'target_population': params['target_population'] || 'All',
        'stage': params['stage'] || 'All',
        'measure_source': 'SOBC',
      };
      this.search_data = {'data': params['data'] || ''};
      this.sort_fields = {'sort_by': params['sort_by'] || 'popular'};
    });
    this.getDataByPage();
  }

  getDataByPage() {
    this.url_params = this.thefilters;
    _.merge(this.url_params, {'page': this.page.toString(), 'page_size': this.page_size});
    _.merge(this.url_params, this.search_data);
    _.merge(this.url_params, this.sort_fields);

    const params = new HttpParams({
      fromObject: this.url_params
    });
    this.apirequestService.get('measures/search2/', params)
      .subscribe(
        res => {
          this.total = res.count;
          this.measures = res.results;
          this.page_size = res.page_size;
          this.page = res.current_page;
          this.total_pages = res.total_pages;
          this.loading = false;
          this.router.navigate([], {relativeTo: this.route, queryParams: this.url_params});
        },
        error => {
          console.log(error);
        });
    this.search_input = this.search_data.data;
  }

  loadPageSize(n: number): void {
    this.page = 1;
    this.page_size = n;
    this.getDataByPage();
  }

  goToPage(n: number): void {
    this.page = n;
    this.getDataByPage();
  }

  onNext(): void {
    this.page++;
    this.getDataByPage();
  }

  onPrev(): void {
    this.page--;
    this.getDataByPage();
  }

  toggleCollapse() {
    this.show = !this.show;
    this.btncollapse = this.btncollapse === 'clicked' ? 'not_clicked' : 'clicked';
    this.collapse = this.collapse === 'closed' ? 'open' : 'closed';
  }

  sortMeasures(sort) {
    this.sort_fields = {'sort_by': sort};
    this.getDataByPage();
  }

  searchMeasure(search) {
    this.search_data = {'data': search};
    this.getDataByPage();
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  saveAndPublish(destination, action='APPROVE', id) {
    this.loading_submit = true;
    // this.submitData('Submit');
    this.loading_submit = false;
    // this.router.navigate(['/dashboard/measure/' + this.id]);
    let msg1 = 'Confirm pulling down measure';
    let msg2 = 'Are you sure you want to pull down this measure?';
    let msg3 = 'Yes pull down';

    if(action == 'PUBLISH'){
      msg1 = 'Confirm publishing measure';
      msg2 = 'Are you sure you want to publish this measure?';
      msg3 = 'Yes publish';

    }
    this.confirmationDialogService.confirm(msg1, msg2, msg3)
      .then((confirmed) => {
        if (confirmed == true) {
          let data = {
            'measure_id': id,
          };
          if(action == 'PULL_DOWN'){
            data['action'] = 'PULL_DOWN';
          }
          else if(action == 'PUBLISH'){
            data['action'] = 'PUBLISH';
          }
          const url = 'measures/submit_for_approval/';
          this.loading_submit = true;
          this.apirequestService.post(url, data)
            // .pipe(first())

            .subscribe(
              data => {
                // this.id = data.id;
                this.snackBar.openFromComponent(CustomSnackBarComponent, {
                  data: {
                    message: data == 'Measure successfully pulled down.'? 'Measure successfully pulled down.': 'Measure successfully published',
                    action: 'Success'
                  },
                  duration: 3 * 1000,
                  panelClass: ['transparent-snack-bar']
                });
                this.loading_submit = false;
                this.getDataByPage();
                // this.router.navigate(['/dashboard/measure/' + this.id]);
              },
              error => {
                this.alertService.error(error, '');
                this.loading_submit = false;
              });
        }
      });
    // this.router.navigate(['/dashboard/measure/' + this.id]);
  }
}
