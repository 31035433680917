import {Component, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';
import * as moment from 'moment/moment';
import {ApirequestService} from '../../../../../shared/services/apirequest.service';
import {formatNumber} from '@angular/common';
import {GoogleStatsAudienceModel} from '../../../../../shared/models/google_stats_audience.model';
import {DatesService} from '../../../../../shared/services/dates.service';


@Component({
  selector: 'app-behavior-summary',
  templateUrl: './behavior-summary.component.html',
  styleUrls: ['./behavior-summary.component.css']
})
export class BehaviorSummaryComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  updateFlag = false;
  start_date: string = '';
  end_date: string = '';
  loading: boolean;
  url: string;
  yValue: any;
  Metric: string = 'Page Views';
  selectedMetric: string = 'pageViews';
  behavior_details: GoogleStatsAudienceModel;
  maxDate = new Date();
  chartOptions: Highcharts.Options = {
    title: {
      text: ''
    },
    colors: ['#0091d5'],
    chart: {
      type: 'area',
      width: 900,
      zoomType: 'xy'

    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: 'category',
      labels: {
        padding: 0,

      }
    },
    legend: {
      itemStyle: {
        color: '#0091d5',
      },
      enabled: true,
      borderColor: '#0091d5',
      borderWidth: 2,
      backgroundColor: 'rgba(34, 102, 255, 0.05)',
      borderRadius: 10
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y}</b>'
    },
    yAxis: {
      min: 0,
      title: {
        // text: this.Metric
      },
    },
    plotOptions: {
      area: {
        // pointPadding: 0.2,
        borderWidth: 0,
        dataLabels: {
          enabled: true
        }
      }

    },
    series: [
      {
        type: 'area',
        name: 'Overview',
        data: [],
      }

    ],

  };

  constructor(private apiService: ApirequestService, private datesService: DatesService) {
  }

  ngOnInit() {

    // let today = new Date();
    const dateString = "2023-02-01T12:30:45.678Z";
    let today = new Date(dateString);
    var lastMonth = new Date(today);
    lastMonth.setDate(lastMonth.getDate() - 30);
    this.start_date = lastMonth.toISOString().slice(0, 10);
    this.end_date = today.toISOString().slice(0, 10);

    this.getData(this.start_date, this.end_date);
    this.getSummarisedData(this.start_date, this.end_date);
  }

  filter_by_date() {
    this.loading = true;
    this.getData(this.start_date, this.end_date);
    this.getSummarisedData(this.start_date, this.end_date);
  }

  onChange(event) {
    this.Metric = event.source.triggerValue;
    this.selectedMetric = event.value;
    this.getData(this.start_date, this.end_date);

  }

  getSummarisedData(start_date, end_date) {

    this.loading = true;
    if (start_date != '' && end_date != '') {
      var formatedstartDate = this.datesService.formatDate(start_date);
      var formatedendDate = this.datesService.formatDate(end_date);
      this.url = 'analytics/behavior_summary/?&start_date=' + formatedstartDate + '&end_date=' + formatedendDate;
    } else {
      this.start_date = this.datesService.getStartDate();
      this.end_date = this.datesService.getEndDate();

      this.url = 'analytics/behavior_summary/';
    }

    this.apiService.get(this.url).subscribe(res => {
      this.loading = false;

      this.behavior_details = {
        ...res[0],
        pageViews: res[0].pageViews,
        bounceRate: parseFloat(res[0].bounceRate).toFixed(2) + '%',
        uniquePageviews: res[0].uniquePageviews,
        avgTimeOnPage: moment().startOf('day')
          .seconds(res[0].avgTimeOnPage)
          .format('H:mm:ss'),
        exitRate: parseFloat(res[0].exitRate).toFixed(2) + '%'
      };


      console.log(this.behavior_details);
    }, error => {
      console.log('ERROR::' + error);
      this.loading = false;
    });

  }


  getData(start_date, end_date) {
    var formatedstartDate = moment(start_date).format('YYYY-MM-DD');
    var formatedendDate = moment(end_date).format('YYYY-MM-DD');
    this.loading = true;
    if (start_date != '' && end_date != '') {
      this.url = 'analytics/behavior_summary/?dimension=true&start_date=' + formatedstartDate + '&end_date=' + formatedendDate;
    } else {
      this.url = 'analytics/behavior_summary/?dimension=true';
    }

    this.apiService.get(this.url).subscribe(res => {
      this.loading = false;
      const entries = Object.entries(res);
      let dataSet = [];
      for (let i = 0; i < entries.length; i++) {

        this.yValue = Number(entries[i][1][this.selectedMetric]);
        if (this.selectedMetric == 'bounceRate' || this.selectedMetric == 'avgTimeOnPage' || this.selectedMetric == 'exitRate') {
          this.yValue = Number(formatNumber(this.yValue, 'en-US', '1.2-3'));
        }
        dataSet.push({'name': moment(entries[i][1]['date']).format('DD-MMMM-YYYY'), y: this.yValue});
      }
      this.chartOptions.series[0]['data'] = dataSet;
      this.updateFlag = true;

    }, error => {
      console.log('ERROR::' + error);
      this.loading = false;
    });

  }

}
