import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {ApirequestService} from '../../../shared/services/apirequest.service';
import {AlertService} from '../../../shared/services/alert.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  loading = false;
  submitted = false;
  error: string;
  id: string;
  sign_up_token: string;
  url_reset_password = 'users/reset_password/';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apirequestService: ApirequestService,
    public alertService: AlertService,
  ) {
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.resetPasswordForm.controls;
  }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.id = params['id'];
        this.sign_up_token = params['sign_up_token'];
      },
      error => {
        console.log(error);
      });
    this.createForm();
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
      return;
    }
    // this.loading = true;
    this.apirequestService.post(this.url_reset_password, this.resetPasswordForm.value)
      .subscribe(
        data => {
          this.router.navigate(['/login']);
        },
        error => {
          this.alertService.error(error, error.error);
          this.loading = false;
        });
  }

  private createForm() {
    this.resetPasswordForm = this.formBuilder.group({
      id: this.id,
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmpassword: ['', [Validators.required, Validators.minLength(6)]],
    });
  }
}


@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ConfirmEmailComponent implements OnInit {
  confirmEmailForm: FormGroup;
  loading = false;
  submitted = false;
  error: string;
  url_confirm_email = 'users/confirm_email/';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apirequestService: ApirequestService,
    public alertService: AlertService,) {
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.confirmEmailForm.controls;
  }

  ngOnInit() {
    this.createForm();
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.confirmEmailForm.invalid) {
      return;
    }
    // this.loading = true;
    this.apirequestService.post(this.url_confirm_email, this.confirmEmailForm.value)
      .subscribe(
        data => {
          this.alertService.success('Email sent');
          this.router.navigate(['/confirm_email']);
        },
        error => {
          this.alertService.error(error, error.error);
          this.loading = false;
        });
  }

  private createForm() {
    this.confirmEmailForm = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }
}
