import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpParams} from '@angular/common/http';

import {ApirequestService} from '../../../shared/services/apirequest.service';
import {AlertService} from '../../../shared/services/alert.service';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss']
})
export class ListUsersComponent implements OnInit {

  searchForm: FormGroup;
  loading = false;
  users = [];
  page = 1;
  total = 0;
  limit = 10;
  total_pages = 0;
  page_size = 0;
  request_type: string;
  url = 'users/users/';
  url_search = 'users/search-user/';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apirequestService: ApirequestService,
    public alertService: AlertService,
  ) {
  }

  ngOnInit() {
    this.request_type = 'init';
    this.getData();
    this.initSearchForm();
  }

  onSubmit() {
    this.getData();
    this.request_type = 'search';
  }

  getData() {
    if (this.request_type === 'init') {
      this.loading = true;
      this.apirequestService.get(this.url)
        .subscribe(
          res => {
            this.total = res.count;
            this.page_size = res.page_size;
            this.total_pages = res.total_pages;
            this.loading = false;
            this.users = res.results;
          },
          error => {
            console.log(error);
          });
    } else if (this.request_type === 'search') {
      this.apirequestService.post(this.url_search, this.searchForm.value)
        .subscribe(
          res => {
            this.total = res.count;
            this.users = res.results;
            this.page_size = res.page_size;
            this.page = res.current_page;
            this.total_pages = res.total_pages;
            this.loading = false;
          },
          error => {
            console.log(error);
          });
    } else {
      const params = new HttpParams()
        .set('page', this.page.toString());
      this.apirequestService.get(this.url, params)
        .subscribe(
          res => {
            this.total = res.count;
            this.users = res.results;
            this.page_size = res.page_size;
            this.page = res.current_page;
            this.total_pages = res.total_pages;
            this.loading = false;
          },
          error => {
            console.log(error);
          });
    }
  }

  goToPage(n: number): void {
    this.page = n;
    this.getData();
  }

  onNext(): void {
    this.page++;
    this.getData();
  }

  onPrev(): void {
    this.page--;
    this.getData();
  }

  private initSearchForm() {
    this.searchForm = this.formBuilder.group({
      email: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
    });
  }
}


@Component({
  selector: 'app-pending-institution-approval',
  templateUrl: './pending_institution_approval.component.html',
  styleUrls: ['./list-users.component.scss']
})
export class PendingInstitutionApprovalComponent implements OnInit {
  loading = false;
  users = [];
  page = 1;
  total = 0;
  limit = 10;
  total_pages = 0;
  page_size = 0;
  url = 'users/request-institution-change/';
  url_approve_institution = 'users/approve_institution_change/';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apirequestService: ApirequestService,
    public alertService: AlertService,
  ) {
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.loading = true;
    this.apirequestService.get(this.url)
      .subscribe(
        res => {
          this.total = res.count;
          this.page_size = res.page_size;
          this.total_pages = res.total_pages;
          this.loading = false;
          this.users = res.results;
        },
        error => {
          console.log(error);
        });
  }

  approveInstitution(requester_id) {
    this.apirequestService.get(this.url_approve_institution + requester_id + '/')
      .subscribe(
        res => {
          this.alertService.success('Institution approved');
        },
        error => {
          console.log(error);
        });
  }

  goToPage(n: number): void {
    this.page = n;
    this.getData();
  }

  onNext(): void {
    this.page++;
    this.getData();
  }

  onPrev(): void {
    this.page--;
    this.getData();
  }
}
