import {Component, Input, OnInit, ViewChildren, ElementRef, QueryList} from '@angular/core';
import * as _ from 'lodash';

import {ApirequestService} from '../../../shared/services/apirequest.service';
import {ApirequestdetailService} from '../../../shared/services/apirequestdetail.service';
import {HttpParams} from '@angular/common/http';

@Component({
  selector: 'app-measuresfilters',
  templateUrl: './measuresfilters.component.html',
  styleUrls: ['../measures.component.scss']
})
export class MeasuresfiltersComponent implements OnInit {
  @Input() pagefilters: any;
  @Input() isOnSidebar: boolean = true;
  @ViewChildren('chkboxes') chkboxes: QueryList<ElementRef>;

  domainsFiltersList = [];
  behaviorsFiltersList = [];
  languagesFiltersList = [];
  populationFiltersList = [];
  domainsFilters = [];
  behaviorsFilters = [];
  languageFilters = [];
  populationFilters = [];
  measurementModeFilters = [];
  durationFilters = [];
  validityStudiesUploaded = []
  measureSourceFilters = [];
  databaseFilters = []
  specificLinksFilters = [];
  thefilter;
  btnclicked = false;
  url_measurefilters = 'measures/domains_list/';
  behavior_url_measurefilters = 'measures/behaviors_list/';
  language_url_measurefilters = 'measures/languages_list/';
  population_url_measurefilters = 'measures/population_list/';
  isType = false;
  isHypothesizedDomain = false;
  isStage = false;
  isAdvanced = false;
  isDuration = true;
  isTargetPopulation = true;
  isSource = false;
  showSource = false;
  showSpecificLinks = false;
  showType = false;
  showStage = false;
  showDuration = false;
  showTargetPopulation = false;
  measure_of_the_day = [];
  showDomains = false;
  showBehaviors = false;
  showLanguages = false;
  showPopulation = false;
  showMeasurementMode = false;
  showValidityStudiesUploaded = false;
  measureNumbers: any;
  itemCounts: any;



  constructor(
    private apirequestService: ApirequestService,
    private apirequestdetailService: ApirequestdetailService,
  ) {
  }

  ngOnInit() {
    const url_ = 'measures/measure-of-the-day/';
    var params = new HttpParams();
    this.apirequestService.get(url_, params)
      .subscribe(
        res => {
          this.measure_of_the_day = res.results;

        },
        error => {
          console.log(error);
        });

    this.apirequestService.get(this.url_measurefilters)
      .subscribe(
        res => {
          this.domainsFiltersList = res.results;
          _.each(this.domainsFiltersList, (value) => {
            if (this.pagefilters.domains.includes(value.id)) {
              this.domainsFilters.push(value.id);
            }
          });
        },
        error => {
          console.log(error);
        });

        this.apirequestService.get('measures/measure-numbers/')
        .subscribe(
          res => {
            this.measureNumbers = res.measure_source;
            this.itemCounts = res;

          },
          error => {
            console.log(error);
          });

        

    this.apirequestService.get(this.behavior_url_measurefilters)
      .subscribe(
        res => {
          this.behaviorsFiltersList = res.results;
          _.each(this.behaviorsFiltersList, (value) => {
            if (this.pagefilters.behaviors.includes(value.id)) {
              this.behaviorsFilters.push(value.id);
            }
          });
        },
        error => {
          console.log(error);
        });

    this.apirequestService.get(this.language_url_measurefilters)
      .subscribe(
        res => {
          this.languagesFiltersList = res.results;
          _.each(this.languagesFiltersList, (value) => {
            if (this.pagefilters.language.includes(value.name)) {
              this.languageFilters.push(value.name);
            }
          });
        },
        error => {
          console.log(error);
        });

      this.apirequestService.get(this.population_url_measurefilters)
      .subscribe(
        res => {
          this.populationFiltersList = res.results;
          _.each(this.populationFiltersList, (value) => {
            if (this.pagefilters.target_population.includes(value.name)) {
              this.populationFilters.push(value.name);
            }
          });
        },
        error => {
          console.log(error);
        });

    _.each(['Self-report', 'Task', 'Observational', 'Physiological', 'Other', 'Not specified'], (value) => {
      if (this.pagefilters.measurement_mode.includes(value)) {
        this.measurementModeFilters.push(value);
        this.showType = true;
      }
    });

    _.each(['Science of Behavior Change (SOBC)', 'Group Evaluated Measures (GEM)', 'Stress Measurement Network (SMN)', 'User upload', 'Other'], (value) => {
      if (this.pagefilters.measure_source.includes(value)) {
        this.measureSourceFilters.push(value);
        this.showSource = true;
      }
    });
    _.each(['None', 'Influence', 'Behavior change validation'], (value) => {
      if (this.pagefilters.validity_studies_uploaded.includes(value)) {
        this.validityStudiesUploaded.push(value);
        this.showStage = true;
      }
    });
    _.each(['0-5 minutes', '6-10 minutes', '11-15 minutes', '16-20 minutes',  '20+ minutes', 'Not specified'], (value) => {
      if (this.pagefilters.duration.includes(value)) {
        this.durationFilters.push(value);
        this.showDuration = true;
      }
    });

    _.each(['Behavior change techniques', 'Experiment Factory', 'Cognitive Atlas'], (value) => {
      if (this.pagefilters.specific_links.includes(value)) {
        this.specificLinksFilters.push(value);
        this.showSpecificLinks = true;
      }
    });
  }

  // _.remove(v, function(n){ return n!=8;});
  arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele !== value;
    });
  }

  toggleEditable(event, type, val) {
    if (type === 'domains') {
      if (event.target.checked === true) {
        if (val == 'All') {
          this.domainsFiltersList.forEach(item => this.domainsFilters.push(item.id));
        } else {
          this.domainsFilters.push(val);
        }

      } else if (event.target.checked === false) {
        if (val == 'All') {
          this.domainsFilters = [];
        } else {
          this.domainsFilters = this.arrayRemove(this.domainsFilters, val);
        }
      }
    }else if (type === 'behaviors') {
      if (event.target.checked === true) {
        if (val == 'All') {
          this.behaviorsFiltersList.forEach(item => this.behaviorsFilters.push(item.id));
        } else {
          this.behaviorsFilters.push(val);
        }

      } else if (event.target.checked === false) {
        if (val == 'All') {
          this.behaviorsFilters = [];
        } else {
          this.behaviorsFilters = this.arrayRemove(this.behaviorsFilters, val);
        }
      }
    }else if (type === 'language') {
      if (event.target.checked === true) {
        if (val == 'All') {
          this.languagesFiltersList.forEach(item => this.languageFilters.push(item.name));
        } else {
          this.languageFilters.push(val);
        }

      } else if (event.target.checked === false) {
        if (val == 'All') {
          this.languageFilters = [];
        } else {
          this.languageFilters = this.arrayRemove(this.languageFilters, val);
        }
      }
    }else if (type === 'target_population') {
      if (event.target.checked === true) {
        if (val == 'All') {
          this.populationFiltersList.forEach(item => this.populationFilters.push(item.name));
        } else {
          this.populationFilters.push(val);
        }

      } else if (event.target.checked === false) {
        if (val == 'All') {
          this.populationFilters = [];
        } else {
          this.populationFilters = this.arrayRemove(this.populationFilters, val);
        }
      }
    }else if (type === 'measurement_mode') {
      if (event.target.checked === true) {
        this.measurementModeFilters.push(val);
      } else if (event.target.checked === false) {
        this.measurementModeFilters = this.arrayRemove(this.measurementModeFilters, val);
      }
    } else if (type === 'duration') {
      if (event.target.checked === true) {
        this.durationFilters.push(val);
      } else if (event.target.checked === false) {
        this.durationFilters = this.arrayRemove(this.durationFilters, val);
      }
    } else if (type === 'validity_studies_uploaded') {
      if (event.target.checked === true) {
        this.validityStudiesUploaded.push(val);
      } else if (event.target.checked === false) {
        this.validityStudiesUploaded = this.arrayRemove(this.validityStudiesUploaded, val);
      }
    } else if (type === 'measure_source') {
      if (event.target.checked === true) {
        this.measureSourceFilters.push(val);
      } else if (event.target.checked === false) {
        this.measureSourceFilters = this.arrayRemove(this.measureSourceFilters, val);
      }
    }
    else if (type === 'specific_links') {
      if (event.target.checked === true) {
        this.specificLinksFilters.push(val);
      } else if (event.target.checked === false) {
        this.specificLinksFilters = this.arrayRemove(this.specificLinksFilters, val);
      }
    }

    this.thefilter = {
      'domains': this.domainsFilters.join(',') || 'All',
      'behaviors': this.behaviorsFilters.join(',') || 'All',
      'language': this.languageFilters.join(',') || 'All',
      'target_population': this.populationFilters.join(',') || 'All',
      'measurement_mode': this.measurementModeFilters.join(',') || 'All',
      'duration': this.durationFilters.join(',') || 'All',
      'measure_source': this.measureSourceFilters.join(',') || 'All',
      'validity_studies_uploaded': this.validityStudiesUploaded.join(',') || 'All',
      'database': this.databaseFilters.join(',') || 'All',
      'specific_links': this.specificLinksFilters.join(',') || 'All',
    };
    this.apirequestdetailService.setFilteredData(this.thefilter);
  }
    toggleDomain(show: boolean): void {
    this.showDomains = !show;
  }

    toggleBehavior(show: boolean): void {
    this.showBehaviors = !show;
  }

  toggleLanguage(show: boolean): void {
    this.showLanguages = !show;
  }

  togglePopulation(show: boolean): void {
    this.showPopulation = !show;
  }

  toggleMeasurementMode(show: boolean): void {
    this.showMeasurementMode = !show;
  }

  toggleValidityStudiesUploaded(show: boolean): void {
    this.showValidityStudiesUploaded = !show;
  }

  toggleDuration(show: boolean): void {
    this.showDuration = !show;
  }

  toggleSource(show: boolean): void {
    this.showSource = !show;
  }

  toggleSpecificLinks(showSpecificLinks: boolean): void {
    this.showSpecificLinks = !showSpecificLinks;
  }

  

  uncheckAllFilters(): void {
    this.chkboxes.forEach(x => x.nativeElement.checked = false)
    this.thefilter = {
      'domains':  'All',
      'behaviors': 'All',
      'language': 'All',
      'target_population': 'All',
      'measurement_mode': 'All',
      'duration': 'All',
      'measure_source': 'All',
      'validity_studies_uploaded': 'All',
      'database': 'All',
      'specific_links': 'All'
    };

    this.apirequestdetailService.setFilteredData(this.thefilter);
  }
}
