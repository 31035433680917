import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApirequestdetailService {
  private getSubjectSearchSource = new Subject<any>();
  private getSubjectFilteredSource = new Subject<any>();
  private getSubjectSortBySource = new Subject<any>();

  setSearchData(filters) {
    this.getSubjectSearchSource.next(filters);
  }

  clearSearchData() {
    this.getSubjectSearchSource.next();
  }

  // Returns an Observable string streams
  getSearchData(): Observable<any> {
    return this.getSubjectSearchSource.asObservable();
  }

  setFilteredData(filters) {
    this.getSubjectFilteredSource.next(filters);
  }

  clearFilteredData() {
    this.getSubjectFilteredSource.next();
  }

  // Returns an Observable string streams
  getFilteredData(): Observable<any> {
    return this.getSubjectFilteredSource.asObservable();
  }

  setSortByData(filters) {
    this.getSubjectSortBySource.next(filters);
  }

  clearSortByData() {
    this.getSubjectSortBySource.next();
  }

  // Returns an Observable string streams
  getSortByData(): Observable<any> {
    return this.getSubjectSortBySource.asObservable();
  }
}
