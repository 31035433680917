import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {Field} from '../../models/field.interface';
import {FieldConfig} from '../../models/field-config.interface';

@Component({
  selector: 'app-form-checkbox',
  styleUrls: ['form-checkbox.component.scss'],
  template: `
    <div
      class="dynamic-field form-checkbox"
      [formGroup]="group">
      <label class="mt-3">{{ config.label }}</label>
      <br>
      <div class="form-check" [formArrayName]="config.name" *ngFor="let option of config.options; let i = index">
      <span [formGroupName]="i">
        <input class="form-check-input" type="checkbox" [value]="option.id" id="option.id" [formControlName]="option.id" >
        <label class="form-check-label" for="option.label">
          {{option.label}}
        </label>
        </span>
      </div>
    </div>
  `
})
export class FormCheckboxComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
  submitted: boolean;
}
