import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  MatButtonModule,
  MatDialogModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatToolbarModule, MatNativeDateModule, MatIconModule, MatSidenavModule, MatListModule,
    MatTableModule, MatPaginatorModule, MatSortModule, MatSnackBarModule, MatDialogModule, MatMenuModule,
    MatTooltipModule, MatProgressSpinnerModule],
  exports: [CommonModule, MatButtonModule, MatToolbarModule, MatNativeDateModule, MatIconModule, MatSidenavModule, MatListModule,
    MatTableModule, MatPaginatorModule, MatSortModule, MatSnackBarModule, MatDialogModule, MatMenuModule,
    MatTooltipModule, MatProgressSpinnerModule],

  declarations: []
})
export class CustomMaterialModule {
}
