import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {AlertService} from '../../shared/services/alert.service';
import {AuthenticationService} from '../../shared/services/authentication.service';

export class User {
  constructor(
    public email: string,
    public password: string) {
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error: string;
  siguptoken: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    public alertService: AlertService,
  ) {
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.siguptoken = params['siguptoken'] || '';
      },
      error => {
        console.log(error);
      });

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
    if (this.route.snapshot.queryParams['returnUrl'] === '/dashboard') {
      this.alertService.success('Login to access the dashboard');
    }

  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.authenticationService.login(this.f.email.value, this.f.password.value)
      .subscribe(
        data => {
          if (data) {
            this.authenticationService.currentUser()
              .subscribe(() => {
                this.router.navigate([this.returnUrl]);
              });
          } else {
            this.error = 'Username or password not set';
            this.alertService.error('Error:: ', this.error);
          }
        },
        error => {
          this.alertService.error(error, error.error);
          this.loading = false;
        });
  }
}



