import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {MatTooltipModule} from '@angular/material/tooltip';

import {SharedModule} from '../../shared/shared.module';

import {CitationsDialogComponent, MeasuredetailsComponent} from './measuredetails.component';
import {GemMeasuredetailsComponent} from './gemmeasuredetails/gemmeasuredetails.component';
import {MIDSSMeasuredetailsComponent} from './midssmeasuredetails/midssmeasuredetails.component';
// import {VisualizationTaxonomyComponent} from './visualization-taxonomy/visualization-taxonomy.component';
// import { ContextMenuComponent } from './visualization-taxonomy/context-menu/context-menu.component';
import {ContextMenuComponent} from './visualization-taxonomy/context-menu/context-menu.component';
import { NetworkDiagramComponent } from './network-diagram/network-diagram.component';
import { NetworkDiagramDiagramComponent } from './network-diagram/diagram/diagram.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatTooltipModule,
    SharedModule,
  ],
  declarations: [
    CitationsDialogComponent,
    MeasuredetailsComponent,
    GemMeasuredetailsComponent,
    MIDSSMeasuredetailsComponent,
    // VisualizationTaxonomyComponent,
    ContextMenuComponent,
    NetworkDiagramComponent,
    NetworkDiagramDiagramComponent,
  ],
  entryComponents: [
    CitationsDialogComponent
  ]
})
export class MeasureDetailsModule {
}
