import {Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, ElementRef, AfterViewInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {environment} from '../../../../environments/environment';
import {AuthenticationService} from '../../../shared/services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit, OnChanges {
  @Input() _status: boolean;
  changeNav: boolean;
  is_authenticated: boolean;
  host_url: string;

  constructor(private route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private router: Router,
  ) {
  }

  ngOnInit() {
    this.host_url = environment.host_url;
    this.is_authenticated = this.authenticationService.isAuthenticated();
    // this.loadExternalScript('assets/js/dropdown-menu.js').then(() => {
    //   // External script loaded successfully
    //   // Now you can call the callK function
    //   console.log(`Loaded script`);
      
    // }).catch(error => {
    //   console.error('Error loading external script:', error);
    // });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.is_authenticated = changes._status.currentValue;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number > 20) {
      this.changeNav = true;
    } else {
      this.changeNav = false;
    }
  }

  loadExternalScript(url: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = url;
      scriptElement.onload = () => {
        resolve();
      };
      scriptElement.onerror = (error) => {
        reject(error);
      };
      document.body.appendChild(scriptElement);
    });
  }
}
