import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';

import {AppConfig} from './app-config';
import {GoogleAnalyticsService} from './shared/services/google-analytics.service';

import {AppComponent} from './app.component';

import {AppRoutingModule} from './app-routing.module';
import {PagesModule} from './pages/pages.module';
import {SharedModule} from './shared/shared.module';
import {HighchartsChartModule} from 'highcharts-angular';

// import {ResourcesComponent} from './pages/resources/resources.component';



@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    PagesModule,
    SharedModule,
    HighchartsChartModule,
    // ResourcesComponent,

  ],
  declarations: [AppComponent],
  providers: [
    AppConfig,
    GoogleAnalyticsService,
    Location,
    {provide: LocationStrategy, useClass: PathLocationStrategy},
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {
}
