import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import {MatDialog} from '@angular/material';

import {ApirequestService} from '../../../../shared/services/apirequest.service';


@Component({
  selector: 'app-list-notifications',
  templateUrl: './list-notifications.component.html',
  styleUrls: ['./list-notifications.component.scss']
})
export class ListNotificationsComponent{
  measures: Array<any>;
  id: string;
  folder_name: string;
  page = 1;
  total = 0;
  limit = 10;
  total_pages = 0;
  url_params;
  page_size = 0;
  @Output() messageEvent = new EventEmitter<boolean>();
  @Input() notificationsList: any[];

  // notificationsList: any[];  //= [1,2,3,4,5,6,7,8,9,10];

  constructor(
    private apirequestService: ApirequestService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'] || '';
      this.folder_name = params['name'] || '';
      this.page = params['page'] || '1';
    });
    // this.getNotifications();
  }

  private getNotifications() {
    this.url_params = {'page': this.page.toString()};
    // const params = new HttpParams()
    //   .set('page', this.page.toString());
    const url = 'measures/dashboard/';
    // this.apirequestService.get(url, params)
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.notificationsList = res.notifications;
        },
        error => {
          console.log(error);
        });
  }

  transform(value: string): string {
    const maxLength = 15;
    if (value.length <= maxLength) {
      return value;
    } else {
      return value.substring(0, maxLength) + '...';
    }
  }

  transform2(value: string): string {
    const maxLength = 25;
    if (value.length <= maxLength) {
      return value;
    } else {
      return value.substring(0, maxLength) + '...';
    }
  }

  isOdd(input: number){
    return input % 2 == 0? true: false;
  }

  closeNotifications(){
    this.messageEvent.emit(false);
  }


}
