import {Component} from '@angular/core';

import {LogoutComponent} from '../../logout/logout.component';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css']
})
export class AppLayoutComponent {
  is_authenticated: boolean;

  constructor() {
  }

  onActivate(event: any) {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 50); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 0);

    if (event instanceof LogoutComponent) {
      //  //Below will subscribe to the _logStatus emitter
      event._logStatus.subscribe((data) => {
        // Will receive the data from child here
        setTimeout(() => this.is_authenticated = data, 0);
      });
    }
  }

}
