import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material';

import {ApirequestService} from '../../shared/services/apirequest.service';

import {Experiment} from '../../shared/models/experiment';


@Component({
  selector: 'app-experimentdetails',
  templateUrl: './experimentdetails.component.html',
  styleUrls: ['./experimentdetails.component.scss']
})
export class ExperimentdetailsComponent implements OnInit {
  experiment: Experiment;
  osfDetails = [];
  hasOsf = false;
  studies = [];
  identifieddata = [];
  measureddata = [];
  influenceddata = [];
  validateddata = [];
  relatedMeasures = [];
  creditDetails = [];
  identified = false;
  measured = false;
  influenced = false;
  validated = false;
  loading_related_articles = true;
  loading_osf_details = true;
  loading_credit_details = true;
  loading_comments = true;
  theStars = [];
  load_reviewers = false;
  id: string;
  comments;
  isLoggedIn: boolean;
  page = 1;
  comments_total = 0;
  limit = 10;
  total_pages = 0;
  page_size = 0;
  survey_count = true;
  entries: string;
  toLoadGoogleScholar = false;
  title: string;
  identifiedClicked: false;

  constructor(
    private apirequestService: ApirequestService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'] || '';
    });
    this.loadData();
  }

  private loadData() {
    console.log('GOT HERE HERE');
    const url = 'experiments/' + this.id;
    console.log('URL=' + url);
    this.apirequestService.getExperiments(url)
      .subscribe(
        res => {
          this.experiment = res;
        },
        error => {
          console.log(error);
        });
  }
}
