import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';

import {ApirequestService} from '../../../../shared/services/apirequest.service';
import {AlertService} from '../../../../shared/services/alert.service';


@Component({
  selector: 'app-share-collection-dialog',
  template: `
  <span (click)="onNoClick()" class="float-right">x</span>
  <h1 mat-dialog-title class="text-capitalize"> Share {{title}}</h1>
  <div mat-dialog-content>
   <ng-select
          [items]="users"
          [multiple]="true"
          [closeOnSelect]="false"
          bindLabel="full_name"
          bindValue="id"
          [(ngModel)]="shared_users"
          placeholder="Select who to share with">
        </ng-select>
  </div>
  <div mat-dialog-action class="m-4">
  <button class="btn btn-sm" (click)="onNoClick()">Cancel</button>
  <button class="btn btn-primary btn-sm ml-2" (click)="shareFolder(shared_users)">share</button>
  </div>
  `,
  styleUrls: ['./my-collection.component.scss']
})

export class ShareCollectionComponent implements OnInit {
  openCollection = false;
  shared_users;
  title: string;
  users = [];
  payload;


  constructor(
    public dialogRef: MatDialogRef<ShareCollectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public alertService: AlertService,
    private apirequestService: ApirequestService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.getUsers();
    this.data.title = this.data.type === 'folder' ? this.data.collectionFolder.name : this.data.measure.title;
  }

  getUsers() {
    const url = 'users/share_with/';
    this.apirequestService.get(url)
      .subscribe(
        res => {
          let ress: any[];
          ress = res.results;
          ress.map((i) => {
            i.full_name = i.first_name + ' ' + i.last_name;
            return i;
          });
          this.users = ress;
        },
        error => {
          console.log(error);
        });
  }

  shareFolder(users) {
    const users_data = [];
    const url = 'my_collection/share/';
    if (users) {
      users.forEach(function (element) {
        const obj = {user_id: element};
        users_data.push(obj);
      });
      if (this.data.type === 'folder') {
        this.payload = {
          'item_type': 'folder',
          'folder_id': this.data.collectionFolder.id,
          'users': users_data,
        };
      } else {
        this.payload = {
          'item_type': 'measure',
          'measure_id': this.data.measure.id,
          'users': users_data,
        };
      }
      this.apirequestService.post(url, this.payload)
        .subscribe(
          res => {
            this.openSnackBar(res.message, 'success');
            this.dialogRef.close();
          },
          error => {
            this.openSnackBar(error.message, 'Error');
            console.log(error);
          });
    } else {
      this.openSnackBar('No user selected to share ' + this.title, 'Error');
      this.dialogRef.close();
    }
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  shareMeasure(data, type) {
    const dialogRef = this.dialog.open(ShareCollectionComponent, {
      panelClass: 'customPanelClass',
      data: {
        measure: data,
        type: type,
      },
    });
  }
}
