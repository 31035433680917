import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable()
export class CheckClickService {

  edit_measure_clicked = false;

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  toggle() {
    this.edit_measure_clicked = true;
    this.change.emit(this.edit_measure_clicked);
  }

}
