import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import {MatDialog} from '@angular/material';

import {ApirequestService} from '../../../../../shared/services/apirequest.service';


@Component({
  selector: 'app-reviseddashboard-list-notifications',
  templateUrl: './list-notifications.component.html',
  styleUrls: ['./list-notifications.component.scss']
})
export class RevisedDashboardListNotificationsComponent implements OnInit {
  measures: Array<any>;
  id: string;
  folder_name: string;
  page = 1;
  total = 0;
  limit = 10;
  total_pages = 0;
  url_params;
  page_size = 0;

  // notificationsList = [1,2,3,4,5,6,7,8,9,10];
  notificationsList: any[];

  constructor(
    private apirequestService: ApirequestService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'] || '';
      this.folder_name = params['name'] || '';
      this.page = params['page'] || '1';
    });
    this.getNotifications();
  }

  private getNotifications() {
    console.log('Getting notifications first time.');
    const url = 'measures/dashboard/';
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.notificationsList = res.notifications;
          this.total = this.notificationsList.length;
          this.markNotificationsSeen();
        },
        error => {
          console.log(error);
        });
  }

  private markNotificationsSeen() {
    const url = 'notifications/mark-seen/';
    this.apirequestService.get(url)
      .subscribe(
        res => {
        },
        error => {
          console.log(error);
        });
  }



  transform(value: string): string {
    const maxLength = 15;
    if (value.length <= maxLength) {
      return value;
    } else {
      return value.substring(0, maxLength) + '...';
    }
  }

  isOdd(input: number){
    return input % 2 == 0? true: false;
  }

  getCustomLink(curr: any){
    // <div style="cursor: pointer;" [routerLink]="[]" *ngIf="(curr.item_category == 'measures') && (curr.type != 'ITEM_PULL_DOWN_REQUEST')">
    //   <div style="cursor: pointer;" [routerLink]="['/dashboard/measure/details', curr.measure_id]" *ngIf="(curr.item_category == 'measures') && (curr.type == 'ITEM_PULL_DOWN_REQUEST')">
    //   <div style="cursor: pointer;" [routerLink]="['/dashboard/study-revision/details', curr.study_id, 'get-revision']" *ngIf="(curr.item_category == 'studies') && (curr.type != 'ITEM_PULL_DOWN_REQUEST')">
    //   <div style="cursor: pointer;" [routerLink]="['/dashboard/study/details', curr.study_id]" *ngIf="(curr.item_category == 'studies') && (curr.type == 'ITEM_PULL_DOWN_REQUEST')">

      if((curr.item_category == 'measures') && (curr.type != 'ITEM_PULL_DOWN_REQUEST')){
        // url = `[/dashboard/measure-revision/details/${curr.measure_id}/get-revision]`;
        return ['/dashboard/measure-revision/details', curr.measure_id, 'get-revision'];
      }
      else if((curr.item_category == 'measures') && (curr.type == 'ITEM_PULL_DOWN_REQUEST')){
        // url = `[/dashboard/measure/details/${curr.measure_id}]`;
        return ['/dashboard/measure/details', curr.measure_id];
      }
      else if((curr.item_category == 'studies') && (curr.type != 'ITEM_PULL_DOWN_REQUEST')){
        // url = `[/dashboard/study-revision/details/${curr.measure_id}/get-revision]`;
        return ['/dashboard/study-revision/details', curr.study_id, 'get-revision'];
      }
      else if((curr.item_category == 'studies') && (curr.type == 'ITEM_PULL_DOWN_REQUEST')){
        // url = `[/dashboard/study/details/${curr.measure_id}]`;
        return ['/dashboard/study-evidence/details', curr.study_id];
      }
  }


}
