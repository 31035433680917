import {Component, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";

import {GoogleAnalyticsService} from './shared/services/google-analytics.service';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  title = 'Measures | Science of Behavior Change';

  constructor(private analytics: GoogleAnalyticsService, private titleService:Title, ) {
    this.titleService.setTitle(this.title);
  }

  ngOnInit() {
    this.analytics.init();
  }
}
