/**
 * This directive is used as an anchor to get access
 * to the ViewContainerRef which here is exposed via
 * the public member `viewContainer`
 *
 */

import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[dynamic-tabs]'
})
export class DynamicTabsDirective {
  constructor(public viewContainer: ViewContainerRef) {
  }
}
