import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {ApirequestService} from '../../shared/services/apirequest.service';
import {AlertService} from '../../shared/services/alert.service';

@Component({
  selector: 'app-register',
  templateUrl: './create_user.component.html',
  styleUrls: ['./create_user.component.scss']
})
export class CreateUserComponent implements OnInit {
  registrationForm: FormGroup;
  loading = false;
  submitted = false;
  error: string;
  id: string;
  url_add = 'users/create/';
  url = 'users/details/';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apirequestService: ApirequestService,
    public alertService: AlertService,
  ) {
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registrationForm.controls;
  }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.id = params['id'];
      },
      error => {
        console.log(error);
      });
    this.createForm();
    this.getFormData();
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registrationForm.invalid) {
      return;
    }
    // this.loading = true;
    this.apirequestService.post(this.url_add, this.registrationForm.value)
      .subscribe(
        data => {
          localStorage.setItem('email', data.email);
          this.router.navigate(['/verify_email']);
        },
        error => {
          this.alertService.error(error, error.error);
          this.loading = false;
        });
  }

  private createForm() {
    this.registrationForm = this.formBuilder.group({
      id: [''],
      email: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmpassword: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  private getFormData() {
    if (this.id) {
      this.apirequestService.get(this.url + this.id + '/')
        .subscribe(
          res => {
            res.confirmpassword = res.password;
            this.registrationForm.patchValue(res, {onlySelf: true});
          },
          error => {
            console.log(error);
          });
    }
  }
}


@Component({
  selector: 'app-registration-redirect',
  templateUrl: './registration_redirect.component.html',
  styleUrls: ['./create_user.component.scss']
})
export class RegistrationRedirectComponent implements OnInit {
  url_confirm_email = 'users/confirm_email/';
  user_email = '';
  url_resend_sign_up_token = 'users/resend_sign_up_token/';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apirequestService: ApirequestService,
    public alertService: AlertService,
  ) {
  }

  ngOnInit() {
    this.user_email = localStorage.getItem('email');
  }

  ResendEmail() {
    const data = {'email': this.user_email};
    this.apirequestService.post(this.url_resend_sign_up_token, data)
      .subscribe(
        res => {
          this.router.navigate(['/verify_email']);
        },
        error => {
        });
  }
}
