import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AppConfig} from 'src/app/app-config';
// import * as moment from 'moment';
// import moment from "moment";
import * as moment from 'moment/moment';
// import 'moment-timezone';
import * as _ from 'lodash';

@Injectable()
export class ApirequestService {
  auth_token_key: string;

  constructor(
    private appConfig: AppConfig,
    private http: HttpClient,
    private router: Router
  ) {
    this.auth_token_key = appConfig.AUTH_TOKEN_KEY;
  }

  /**
   * This is a Global place to add all the request headers for every REST calls
   */
  getToken = function () {
    const token = JSON.parse(localStorage.getItem(this.auth_token_key));
    if (!_.isNull(token)) {
      if (moment(token.expires_at) > moment().add(token.expires_in, 'ms')) {
        return token.access_token;
      } else {
        localStorage.clear();
        console.log('Bearer token not found:');
      }
    }
  };

  getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    const token = this.getToken();
    headers = headers.append('Content-Type', 'application/json');
    if (token !== null) {
      headers = headers.append('Authorization', 'Bearer ' + token);
    }
    return headers;
  }

  get(urlEnd: string, urlParams?: HttpParams): Observable<any> {
    return this.http.get(this.appConfig.baseApiPath + urlEnd, {headers: this.getHeaders(), params: urlParams});
  }

  getCustom(urlEnd: string, urlParams?: HttpParams): Observable<any> {
    return this.http.get(urlEnd);
  }

  getFile(urlEnd: string, urlParams?: HttpParams): Observable<any> {
    return this.http.get(this.appConfig.baseApiPath + urlEnd, {headers: this.getHeaders(), responseType: 'blob', params: urlParams});
  }

  getAppend(urlEnd: string, urlParams?: String): Observable<any> {
    return this.http.get(this.appConfig.baseApiPath + urlEnd + urlParams, {headers: this.getHeaders()});
  }

  post(urlEnd: string, body: Object): Observable<any> {
    return this.http.post(this.appConfig.baseApiPath + urlEnd, body, {headers: this.getHeaders()});
  }

  put(urlEnd: string, body: Object): Observable<any> {
    return this.http.put(this.appConfig.baseApiPath + urlEnd, JSON.stringify(body), {headers: this.getHeaders()});
  }

  delete(urlEnd: string): Observable<any> {
    return this.http.delete(this.appConfig.baseApiPath + urlEnd, {headers: this.getHeaders()});
  }

  /* Experiments */
  getExperiments(urlEnd: string, urlParams?: HttpParams): Observable<any> {
    // var experimentsApiBaseApiPath = 'http://127.0.0.1:5000/api/v1/';
    var experimentsApiBaseApiPath = 'http://oip-staging.busaracenterhub.org:5000/api/v1/';
    return this.http.get(experimentsApiBaseApiPath + urlEnd, {headers: this.getHeaders(), params: urlParams});
  }

  /* End of experiments */

  /* Experiment Factory Links  */
  getExperimentFactoryLinks(urlEnd: string, urlParams?: HttpParams): Observable<any> {
    var experimentsApiBaseApiPath = 'http://127.0.0.1:5000/api/v1/';
    return this.http.get(experimentsApiBaseApiPath + urlEnd, {headers: this.getHeaders(), params: urlParams});
  }


}
