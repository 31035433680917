import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {FormBuilder, FormGroup} from '@angular/forms';

import {ApirequestService} from '../../shared/services/apirequest.service';
import {AlertService} from '../../shared/services/alert.service';
import {ShareCollectionComponent} from './share-collection.component';
import {PromptDialogComponent} from '../../shared/common/prompt-dialog/prompt-dialog.component';
import {CustomSnackBarComponent} from '../../shared/common/custom-snack-bar/custom-snack-bar.component';


@Component({
  selector: 'app-my-collection-dialog',
  template: `
  <span (click)="onNoClick()" class="float-right">x</span>
  <h1 mat-dialog-title class="text-capitalize"> {{title}}</h1>
  <div mat-dialog-content>
    <form [formGroup]="folderForm">
      <input type="text" formControlName="id" id="id" class="form-control" hidden="true">
      <input type="text" formControlName="name" id="name" class="form-control">
    </form>
  </div>
  <div mat-dialog-action class="m-4">
  <button class="btn btn-sm" (click)="onNoClick()">Cancel</button>
  <button class="btn btn-primary btn-sm ml-2" (click)="createMyCollectionFolder()" *ngIf="button_name != 'Delete'">{{button_name}}</button>
  <button class="btn btn-danger btn-sm ml-2" (click)="createMyCollectionFolder()" *ngIf="button_name == 'Delete'">{{button_name}}</button>
  </div>
  `,
})

export class MyCollectionDialogComponent implements OnInit {
  collectionFolders;
  openCollection = false;
  folderForm: FormGroup;
  title: string;
  button_name: string;

  constructor(
    public dialogRef: MatDialogRef<MyCollectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public alertService: AlertService,
    private apirequestService: ApirequestService,
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.createForm();
    switch (this.data.action) {
      case 'create':
        this.title = 'New Folder';
        this.button_name = 'Create';
        break;
      case 'edit':
        this.title = 'Update Folder';
        this.button_name = 'Update';
        break;
      case 'delete':
        this.title = 'Delete Folder';
        this.button_name = 'Delete';
        break;
    }
    this.folderForm.patchValue(this.data.collectionFolder, {onlySelf: true});
  }

  createForm() {
    this.folderForm = this.formBuilder.group({
      id: '',
      name: '',
      owner: '',
    });
  }

  createMyCollectionFolder() {
    if (this.folderForm.invalid) {
      return;
    }

    if (this.folderForm.get('id').value === '') {
      const payload = {
        'folder_name': this.folderForm.get('name').value,
      };
      const url = 'my_collection/folders/create/';
      this.apirequestService.post(url, payload)
        .subscribe(
          res => {
            this.alertService.success(res.message);
            this.dialogRef.close();
          },
          error => {
            console.log(error);
          });
    } else {
      const url = 'my_collection/folders/manage/' + this.folderForm.get('id').value + '/';
      const userdetails = JSON.parse(localStorage.getItem('auth.user'));
      this.folderForm.get('owner').setValue(userdetails.id);
      if (this.data.action === 'edit') {
        this.apirequestService.put(url, this.folderForm.value)
          .subscribe(
            res => {
              this.alertService.success('Folder successfully update');
              this.dialogRef.close();
            },
            error => {
              console.log(error);
            });
      } else if (this.data.action === 'delete') {
        this.apirequestService.delete(url)
          .subscribe(
            res => {
              this.alertService.success('Folder successfully deleted');
              this.dialogRef.close();
            },
            error => {
              console.log(error);
            });
      } else {
        return;
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}


@Component({
  selector: 'app-my-collection',
  templateUrl: './my-collection.component.html',
  styleUrls: ['./my-collection.component.scss']
})

export class MyCollectionComponent implements OnInit {
  collectionFolders;
  measure_id: string;
  toOpen = false;
  isInstructionCollapse: boolean;

  constructor(
    private apirequestService: ApirequestService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public alertService: AlertService,
  ) {
  }

  ngOnInit() {
    this.getMyCollectionFolders();
    this.route.params.subscribe(params => {
      this.measure_id = params['id'] || '';
    });
  }

  openMyCollectionDialogue(folder, action) {
    const dialogRef = this.dialog.open(MyCollectionDialogComponent, {
      data: {
        collectionFolder: folder,
        action: action,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getMyCollectionFolders();
    });
  }

  viewMeasures(collectionFolder) {
    if (this.measure_id != '') {
      const dialogRef = this.dialog.open(PromptDialogComponent, {
        panelClass: 'customPanelClass',
        data: {
          title: '',
          body: 'Do you want to add measure to folder?',
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.addMeasureToFolder(collectionFolder);
          this.router.navigate(['/dashboard/mycollection/']);
        }
      });
    } else {
      if (collectionFolder.measure_count > 0) {
        this.router.navigate(['/dashboard/mycollection/' + collectionFolder.name, collectionFolder.id]);
      }
    }

  }

  shareFolder(collectionFolder) {
    // pop up to add users
    const dialogRef = this.dialog.open(ShareCollectionComponent, {
      panelClass: 'customPanelClass',
      data: {
        collectionFolder: collectionFolder,
        type: 'folder',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getMyCollectionFolders();
    });
  }

  shareMeasure(measure) {
    // pop up to add users
    const dialogRef = this.dialog.open(ShareCollectionComponent, {
      panelClass: 'customPanelClass',
      data: {
        measure: measure,
        type: 'measure',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getMyCollectionFolders();
    });
  }

  addMeasureToFolder(collectionFolder) {
    const url = 'my_collection/add_to_folder/';
    const payload = {
      'folder_id': collectionFolder.id,
      'measure_id': this.measure_id,
    };
    this.apirequestService.post(url, payload)
      .subscribe(
        res => {
          this.getMyCollectionFolders();
          this.alertService.success(res.message);
          this.snackBar.openFromComponent(CustomSnackBarComponent, {
            data: {
              message: res.message,
              action: 'Success'
            },
            duration: 3 * 1000,
            panelClass: ['transparent-snack-bar']
          });
        },
        error => {
          console.log(error);
        });
  }

  private getMyCollectionFolders() {
    const url = 'my_collection/dashboard/';
    // Get all folders
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.collectionFolders = res;
        },
        error => {
          console.log(error);
        });
  }
}


