import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent {
  @ViewChild('dropdownContent') dropdownContent!: ElementRef;
  isDropdownVisible = false;

//   toggleDropdown() {
//     this.isDropdownVisible = !this.isDropdownVisible;
//   }
toggleDropdown(status: boolean) {
    this.isDropdownVisible = status;
  }
}
