import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceText'
})
export class ReplaceTextPipe implements PipeTransform {
  transform(value: string, ...args: any[]) {
    return value.replace(/_x000D_\n/g, '<br>');
  }
}
