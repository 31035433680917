import {Component, OnInit} from '@angular/core';
import {ApirequestService} from '../../../../../shared/services/apirequest.service';
import * as Highcharts from 'highcharts';
import {DatesService} from '../../../../../shared/services/dates.service';

@Component({
  selector: 'app-browser',
  templateUrl: './browser.component.html',
  styleUrls: ['./browser.component.css']
})
export class BrowserComponent implements OnInit {

  loading: boolean;
  start_date: string = '';
  end_date: string = '';
  url: string;
  maxDate = new Date();
  Highcharts: typeof Highcharts = Highcharts;
  updateFlag = false;
  chartOptions: Highcharts.Options = {
    title: {
      text: ''
    },
    colors: ['#0091d5'],
    chart: {
      type: 'bar',

    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: 'category'
    },
    legend: {
      itemStyle: {
        color: '#0091d5',
      },
      enabled: true,
      borderColor: '#0091d5',
      borderWidth: 2,
      backgroundColor: 'rgba(34, 102, 255, 0.05)',
      borderRadius: 10
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y}</b>'
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Users'
      },
    },

    plotOptions: {
      bar: {
        pointPadding: 0.2,
        borderWidth: 0,
        dataLabels: {
          enabled: true
        }
      }
    },
    series: [
      {
        type: 'column',
        name: 'Browsers',
        data: [],
      }

    ]
  };

  constructor(private apiService: ApirequestService, private dateService: DatesService) {
  }

  ngOnInit() {

     // let today = new Date();
     const dateString = "2023-02-01T12:30:45.678Z";
     let today = new Date(dateString);
     var lastMonth = new Date(today);
     lastMonth.setDate(lastMonth.getDate() - 30);
     this.start_date = lastMonth.toISOString().slice(0, 10);
     this.end_date = today.toISOString().slice(0, 10);
    this.getData(this.start_date, this.end_date);
  }

  filter_by_date() {
    this.loading = true;
    this.getData(this.start_date, this.end_date);
  }

  getData(start_date, end_date) {

    if (start_date != '' && end_date != '') {
      this.start_date = this.dateService.formatDate(start_date);
      this.end_date = this.dateService.formatDate(end_date);

      this.url = 'analytics/browser/?start_date=' + this.start_date + '&end_date=' + this.end_date;
    } else {
      this.start_date = this.dateService.getStartDate();
      this.end_date = this.dateService.getEndDate();

      this.url = 'analytics/browser/';
    }
    this.loading = true;
    this.apiService.get(this.url).subscribe(res => {
        this.loading = false;
        const entries = Object.entries(res);
        console.log(Object.keys(res));
        let dataSet = [];
        for (let i = 0; i < entries.length; i++) {
          dataSet.push({'name': entries[i][1]['browser'], y: Number(entries[i][1]['users'])});

        }
        this.chartOptions.series[0]['data'] = dataSet;

        this.updateFlag = true;

      }, error => {
        console.error(error.message);
        this.loading = false;
      }
    );

  }


}
