import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
// import * as moment from 'moment';
// import moment from "moment";
import * as moment from 'moment/moment';
import * as _ from 'lodash';

import {AppConfig, Token} from 'src/app/app-config';
import {ApirequestService} from './apirequest.service';

@Injectable()
export class AuthenticationService {
  server: String;
  auth_token_key: string;

  constructor(
    private http: HttpClient,
    private apiRequest: ApirequestService,
    private appConfig: AppConfig) {
    this.server = appConfig.baseApiPath;
    this.auth_token_key = appConfig.AUTH_TOKEN_KEY;
  }

  login(username: string, password: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const params =
      'grant_type=' + 'password' +
      '&client_id=' + this.appConfig.app_client_id +
      '&client_secret=' + this.appConfig.app_client_secret +
      '&username=' + username +
      '&password=' + password;

    const options = {
      'headers': headers
    };

    const url = `${this.server}auth/token/`;
    return this.http.post(url, params, options)
      .pipe(map(
        (token: Token) => {
          // login successful if there's a jwt token in the response
          if (token && token.access_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            const till = moment().add(token.expires_in, 'seconds');
            token.expires_at = till;
            localStorage.setItem(this.auth_token_key, JSON.stringify(token));
            return true;
          }
        }));
  }

  currentUser() {
    const url = 'users/details/';
    return this.apiRequest.get(url)
      .pipe(map((user: any) => {
        localStorage.setItem('auth.user', JSON.stringify(user[0]));
      }));
  }

  getUser() {
    try {
      return JSON.parse(localStorage.getItem('auth.user'));
    } catch {
      console.log('has error');
    }
  }

  getToken() {
    const token_timeout = 10 * 1000;
    const token = JSON.parse(localStorage.getItem('auth.token'));

    if (!_.isNull(token)) {
      if (moment(token.expires_at) > moment().add(token_timeout, 'ms')) {
        return token;
      }
      localStorage.clear();
    }
    return null;
  }

  isAuthenticated() {
    return (!_.isNull(this.getUser()) || _.isEmpty(this.getUser())) && (!_.isNull(this.getToken()));
  }

  logout(token) {
    // implement revoke token function
    this.revokeToken(token.access_token);
  }

  revokeToken(token: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const options = {headers: headers};
    const param =
      'token=' +
      token +
      '&client_id=' +
      this.appConfig.app_client_id +
      '&client_secret=' +
      this.appConfig.app_client_secret;

    const url = `${this.server}auth/revoke_token/`;
    this.http.post(url, param, options).subscribe(
      data => {
        localStorage.clear();
      },
      err => {
        console.log('error');
      });
  }
}
