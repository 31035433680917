import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {RouterModule} from '@angular/router';

import {SharedModule} from '../../shared/shared.module';

import {MeasuresformComponent} from './measuresform.component';
import {DetailsComponent} from './details/details.component';
import {StudiesComponent} from './studies/studies.component';
import {MaterialsComponent, SendForReviewComponent} from './materials/materials.component';
import {MeasuresAllModule} from './../allmeasures/measuresall.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MeasuresAllModule,

    NgSelectModule,
    SharedModule,
  ],
  declarations: [
    MeasuresformComponent,
    DetailsComponent,
    StudiesComponent,
    MaterialsComponent,
    SendForReviewComponent,

  ],
  entryComponents: [
    SendForReviewComponent,
  ]
})
export class MeasuresFormModule {
}
