import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {FormBuilder, FormGroup} from '@angular/forms';

import {ApirequestService} from './../../../../shared/services/apirequest.service';
import {AlertService} from './../../../../shared/services/alert.service';
import {PromptDialogComponent} from '../../../../shared/common/prompt-dialog/prompt-dialog.component';
import {CustomSnackBarComponent} from './../../../../shared/common/custom-snack-bar/custom-snack-bar.component';


@Component({
  selector: 'app-revised-dashboard-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})

export class RevisedDashboardNotificationsComponent implements OnInit {
  collectionFolders;
  measure_id: string;
  toOpen = false;
  isInstructionCollapse: boolean;
  foldersList: any[];
  tooltip: string;

  constructor(
    private apirequestService: ApirequestService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public alertService: AlertService,
  ) {
  }

  ngOnInit() {
    this.tooltip = 'Click to view';
    this.getMyCollectionFolders();
    this.route.params.subscribe(params => {
      this.measure_id = params['id'] || '';
      if(this.measure_id != '' && this.measure_id != undefined){
        this.tooltip = 'Click to add measure';
      }
    });
  }

  viewMeasures(collectionFolder) {
    if (this.measure_id != '') {
      const dialogRef = this.dialog.open(PromptDialogComponent, {
        panelClass: 'customPanelClass',
        data: {
          title: '',
          body: 'Do you want to add measure to folder?',
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.addMeasureToFolder(collectionFolder);
          this.router.navigate(['/dashboard/my-collection/']);
        }
      });
    } else {
      // if (collectionFolder.measure_count > 0) {
        this.router.navigate(['/dashboard/mycollection/' + collectionFolder.name, collectionFolder.id]);
      // }
    }

  }

  addMeasureToFolder(collectionFolder) {
    const url = 'my_collection/add_to_folder/';
    const payload = {
      'folder_id': collectionFolder.id,
      'measure_id': this.measure_id,
    };
    this.apirequestService.post(url, payload)
      .subscribe(
        res => {
          this.getMyCollectionFolders();
          this.alertService.success(res.message);
          this.snackBar.openFromComponent(CustomSnackBarComponent, {
            data: {
              message: res.message,
              action: 'Success'
            },
            duration: 3 * 1000,
            panelClass: ['transparent-snack-bar']
          });
        },
        error => {
          console.log(error);
        });
  }

  private getMyCollectionFolders() {
    const url = 'my_collection/dashboard/';
    // Get all folders
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.collectionFolders = res;
          this.foldersList = [...this.collectionFolders.my_folders, ...this.collectionFolders.folders_shared_with_me];
        },
        error => {
          console.log(error);
        });
  }

  transform(value: string): string {
    const maxLength = 15;
    if (value.length <= maxLength) {
      return value;
    } else {
      return value.substring(0, maxLength) + '...';
    }
  }
}


