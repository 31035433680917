import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import * as d3 from 'd3';
import {ApirequestService} from '../../../shared/services/apirequest.service';
import {ContextMenuModel} from './context-menu/interfaces/context-menu-model';
import {VisualizationtaxonomyDialogService} from './visualization-taxonomy-dialog/visualization-taxonomy-dialog.service';
// import * as test from './custom-context-menu/test.js';
// import * as custom from './custom-context-menu/context-menu.js';


@Component({
  selector: 'app-visualization-taxonomy',
  templateUrl: './visualization-taxonomy.component.html',
  styleUrls: ['./visualization-taxonomy.component.css']
})
export class VisualizationTaxonomyComponent implements OnInit {
  @Input() measureTitle: string;
  @Input() childMeasureTitle: any;


  loading: boolean;
  caurl: string;
  efurl: string;
  searchTerm: string;
  data: Array<any> = new Array<any>();
  links: any = [];
  errormsg: string;
  menu: any;
  thiss: any;

  // Context menu
  isDisplayContextMenu: boolean;
  rightClickMenuItems: Array<ContextMenuModel> = [];
  rightClickMenuPositionX: number;
  rightClickMenuPositionY: number;
  @ViewChild('graphContainer') graphContainer: ElementRef;
  width = 800;
  height = 600;
  colors = d3.scaleOrdinal(d3.schemeCategory10);
  svg: any;
  force: any;
  path: any;
  circle: any;
  drag: any;
  dragLine: any;
  // mouse event vars
  selectedNode = null;
  selectedLink = null;

  constructor(
    private apiService: ApirequestService,
    private visualizationtaxonomyDialogService: VisualizationtaxonomyDialogService
  ) {
  }

  ngOnInit() {
    this.measureTitle = 'Adaptive N-Back Task';
    this.thiss = this;
    // this.getCAData(this.measureTitle);
    this.processResults([]);
  }

  ngOnChanges() {
    console.log('measureTitle=', this.measureTitle);
    // this.searchTerm = 'articulation'; //this.measureTitle; //'articulation';//this.measureTitle;
    this.searchTerm = this.measureTitle;
    if (this.measureTitle != null) {
      this.getCAData(this.searchTerm);
    }
    this.thiss = this;


  }

  getCAData(searchTerm: string) {
    console.log('Called');
    this.caurl = 'vis_tax/searchca/?search=' + searchTerm;
    this.loading = true;
    this.apiService.get(this.caurl).subscribe(res => {
      this.loading = false;
      console.log(`res=${res}`);
      this.errormsg = '';


      this.data = res;
      this.processResults(this.data);

    }, error => {
      console.log(error);
      console.log(`res=${error}`);
      if (error.status == 404) {
        this.errormsg = 'No links found';
        // this.MyDOMElement.nativeElement.innerHTML = 'No links found';
      }
      console.error(error.message);
      this.loading = false;
    });


  }

  public getEFData(efsearchTerm) {
    console.log(efsearchTerm);

    // http://127.0.0.1:5000/api/v1/searchexperiment/test
    this.efurl = 'searchexperiment/' + efsearchTerm;
    this.loading = true;
    this.apiService.getExperimentFactoryLinks(this.efurl).subscribe(res => {
      this.loading = false;
      console.log(res);
      this.data = res;
      console.log(this.data);
      var item = this.data;


    }, error => {
      console.log(error);
      if (error.status == 404) {
        this.errormsg = 'No results found';
      }
      console.error(error.message);
      this.loading = false;
    });

  }

  processResults(nodes) {
    nodes = [
      {id: 1, name: 'Test 1', count: 1},
      {id: 2, name: 'Test 2', count: 2},
      {id: 3, name: 'Test 3', count: 3},
    ];
    console.log(`nodes=${JSON.stringify(nodes)}`);
    console.log(`nodes_raw=${nodes}`);

    nodes.unshift({'id': 0, 'name': this.childMeasureTitle, 'count': 0});
    // [{ 'id': 0, 'name': this.measureTitle }].concat(nodes);
    for (let i = 0; i < nodes.length; i++) {
      this.links.push({source: nodes[0], target: nodes[i], left: false, right: true});
    }

    const rect = this.graphContainer.nativeElement.getBoundingClientRect();
    this.width = rect.width;
    this.svg = d3.select('#graphContainer')
      .attr('oncontextmenu', 'return false;')
      .attr('width', this.width)
      .attr('height', this.height);


    this.force = d3.forceSimulation()
      .force('link', d3.forceLink().id((d: any) => d.id).distance(240))
      .force('charge', d3.forceManyBody().strength(-1650))
      .force('x', d3.forceX(this.width / 2))
      .force('y', d3.forceY(this.height / 2))
      .force('center', d3.forceCenter(this.width / 2, this.height / 2))
      .on('tick', () => this.tick());

    // define arrow markers for graph links
    this.svg.append('svg:defs').append('svg:marker')
      .attr('id', 'end-arrow')
      .attr('viewBox', '0 -5 10 10')
      .attr('refX', 6)
      .attr('markerWidth', 3)
      .attr('markerHeight', 3)
      .attr('orient', 'auto')
      .append('svg:path')
      .attr('d', 'M0,-5L10,0L0,5')
      .attr('fill', '#000');

    this.svg.append('svg:defs').append('svg:marker')
      .attr('id', 'start-arrow')
      .attr('viewBox', '0 -5 10 10')
      .attr('refX', 4)
      .attr('markerWidth', 3)
      .attr('markerHeight', 3)
      .attr('orient', 'auto')
      .append('svg:path')
      .attr('d', 'M10,-5L0,0L10,5')
      .attr('fill', '#000');


    // line displayed when dragging new nodes
    this.dragLine = this.svg.append('svg:path')
      .attr('class', 'link dragline hidden')
      .attr('d', 'M0,0L0,0');

    // handles to link and node element groups
    this.path = this.svg.append('svg:g').selectAll('path');
    this.circle = this.svg.append('svg:g').selectAll('g');

    this.restart(nodes);

  }


  // update force layout (called automatically each iteration)
  tick() {
    // draw directed edges with proper padding from node centers
    this.path.attr('d', (d: any) => {
      const deltaX = d.target.x - d.source.x;
      const deltaY = d.target.y - d.source.y;
      const dist = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
      const normX = deltaX / dist;
      const normY = deltaY / dist;
      const sourcePadding = d.left ? 35 : 30;
      const targetPadding = d.right ? 35 : 30;
      const sourceX = d.source.x + (sourcePadding * normX);
      const sourceY = d.source.y + (sourcePadding * normY);
      const targetX = d.target.x - (targetPadding * normX);
      const targetY = d.target.y - (targetPadding * normY);

      return `M${sourceX},${sourceY}L${targetX},${targetY}`;
    });

    this.circle.attr('transform', (d) => `translate(${d.x},${d.y})`);
  }


  // // update graph (called when needed)
  restart(nodes) {
    // path (link) group
    this.path = this.path.data(this.links);

    // add new links
    this.path = this.path.enter().append('svg:path')
      .attr('class', 'link')
      .classed('selected', (d) => d === this.selectedLink)
      .style('marker-start', (d) => d.left ? 'url(#start-arrow)' : '')
      .style('marker-end', (d) => d.right ? 'url(#end-arrow)' : '')
      .merge(this.path);

    // circle (node) group
    // NB: the function arg is crucial here! nodes are known by id, not by index!
    this.circle = this.circle.data(nodes, (d) => d.id);


    // update existing nodes (selected visual states)
    this.circle.selectAll('circle')
      .style('fill', 'transparent')
      .classed('reflexive', (d) => d.reflexive);
    const g = this.circle.enter().append('svg:g');
////


// Add the path using this helper function
    g.append('svg:rect')
      .attr('class', 'node')
      .attr('x', -120)
      .attr('y', -11)
      .attr('width', 235)    //.attr('width', 235)
      .attr('height', 18)
      .attr('stroke', '#FFFFFF')
      .attr('fill', '#FFFFFF') //#69a3b2
      .style('cursor', 'pointer')
      .on('mousedown', (d) => {
        let link = '';
        if (d.id == 0) {
          let w = this.childMeasureTitle.toLowerCase().replace(/ /g, '_');
          // link = `http://expfactory.org:5000/api/v1/experiments/${w}`;
          link = this.measureTitle;// `https://expfactory.org/experiments/${w}/preview`;
        } else {
          link = d.ca_link;
        }
        window.open(link, '_blank');

      });
// .on('mouseover', (d) => {
// }).on('mouseout', (d) => {
// });


    g.append('svg:rect')
      .attr('class', 'node')
      // .attr('x', 50)
      // .attr('y', 10)
      .attr('x', (d) => d.id == 0 ? 80 : 50)
      .attr('y', (d) => d.id == 0 ? -11 : 10)
      // .attr('width', 100)    //.attr('width', 235)
      // .attr('width', (d) => d.id == 0 ?  0: 100)
      // .attr('width', (d) => d.id == 0 ?  100: d.ef_items.count == 0? 0 : 80)    //.attr('width', 235)
      // .attr('width', (d) => d.id == 0 ? 0 : d.ef_items.count == 0 ? 0 : 80)
      .attr('width', (d) => d.id == 0 ? 0 : 80)
      .attr('height', 20)
      // .attr('stroke', '#FFFFFF')
      // .attr('fill', '#FFFFFF')
      .attr('stroke', (d) => d.id == 0 ? '#69a3b2' : '#FFFFFF')
      .attr('fill', (d) => d.id == 0 ? '#69a3b2' : '#FFFFFF')
      .attr('color', (d) => d.id == 0 ? '#FFFFFF' : '#000000')
      .style('cursor', 'pointer')
      .on('click', (d) => {
        if (d.id == 0) {
          let link = '';
          let w = this.measureTitle.toLowerCase().replace(/ /g, '_');
          // link = `http://expfactory.org:5000/api/v1/experiments/${w}`;
          link = this.measureTitle;
          window.open(link, '_blank');

        } else {
          this.loadTheoryTechniquesToolDialog(d.name, d.ef_items.items);
        }
      })
      // .append('svg:text');
      .append('svg:text');


    g.append('svg:text')
      // .attr('x', 80)
      // .attr('y', 24)
      .attr('x', (d) => d.id == 0 ? 125 : 80)
      .attr('y', (d) => d.id == 0 ? 4 : 24)
      .attr('class', 'id')
      .text((d) => d.count > 0 ? d.count : '');


    // // show node IDs
    g.append('svg:text')
      .attr('x', 0)
      .attr('y', 4)
      .attr('class', 'id')
      .text((d) => d.id == 0 ? d.name : d.name);


    this.circle = g.merge(this.circle);

    // set the graph in motion
    this.force
      .nodes(nodes)
      .force('link')
      .links(this.links);


  }

  checkEF(id) {
    console.log(`concept id=${id}`);
    return 5;
  }


  loadTheoryTechniquesToolDialog(title, data) {
    let data_ = [];
    data.forEach(element => {
      data_.push(
        {'title': element.name, 'value': element.link}
      );
    });
    // [{ 'title': 'test title', 'value': 'test value' }];
    this.visualizationtaxonomyDialogService.confirm(this.toTitleCase(title), data_, 'Yes Submit');
  }

  toTitleCase(string) {
    let strLen = string.length;
    let k = string.split('');
    let fullString = k[0].toUpperCase();
    let i = 0;
    k.forEach(item => {
      if (i != 0) {
        fullString = `${fullString}${k[i]}`;
      }
      i++;
    });

    return fullString;
  }
}








