import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {RouterModule} from '@angular/router';

import {SharedModule} from '../../shared/shared.module';

import {MyCollectionComponent, MyCollectionDialogComponent} from './my-collection.component';
import {ShareCollectionComponent} from './share-collection.component';
import {ListFolderMeasuresComponent} from './list-folder-measures/list-folder-measures.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    RouterModule,

    SharedModule,
  ],
  declarations: [
    MyCollectionComponent,
    MyCollectionDialogComponent,
    ListFolderMeasuresComponent,
    ShareCollectionComponent,
  ],
  entryComponents: [
    MyCollectionDialogComponent,
    ShareCollectionComponent,
  ],
})
export class MyCollectionModule {
}
