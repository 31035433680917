import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import {AppLayoutComponent} from './layout/app-layout/app-layout.component';
import {AppHeaderComponent} from './layout/app-header/app-header.component';
import {DashboardNavigationComponent} from './layout/dashboard-navigation/dashboard-navigation.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {PendingReviewComponent} from './pending_review/pending_review.component';
import {MymeasuresComponent} from './mymeasures/mymeasures.component';
import {HelpComponent} from './help/help.component';
import {LoginComponent} from './login/login.component';

/*** App modules ***/
import {SharedModule} from '../shared/shared.module';
import {SurveysModule} from './surveys/surveys.module';
import {UsersModule} from './users/users.module';
import {MyCollectionModule} from './my-collection/my-collection.module';
import {MeasuresFormModule} from './measuresform/measuresform.module';
import {MeasuresModule} from './measures/measures.module';
import {SobcResourcesModule} from './resources-layout/sobc-resources/sobcresources.module';

import {MeasureDetailsModule} from './measuredetails/measuredetails.module';

// import {MeasureApprovalComponent} from './measureapproval/measureapproval.component';
import {MeasureApprovalModule} from './measureapproval/measureapproval.module';

import {PagesRoutingModule} from './pages.routing';
import {LogoutComponent} from './logout/logout.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationDialogComponent} from '../shared/confirmation-dialog/confirmation-dialog.component';
import {ConfirmationDialogService} from '../shared/confirmation-dialog/confirmation-dialog.service';
import {CheckClickService} from '../shared/services/check_is_cliked.service';
import {MeasuresAllModule} from './allmeasures/measuresall.module';
import {MeasuresStatisticsComponent} from './measures-statistics/measures-statistics.component';
import {BulkMeasureUploadComponent} from './bulk-measure-upload/bulk-measure-upload.component';
import {ExperimentdetailsModule} from './experimentdetails/experimentdetails.module';
import {ExperimentsModule} from './experiments/experiments.module';
import {TheorytechniquestoolDialogService} from '../shared/theorytechniquestool-dialog/theorytechniquestool-dialog.service';
import {TheorytechniquestoolDialogComponent} from '../shared/theorytechniquestool-dialog/theorytechniquestool-dialog.component';
import {GoogleAnalyticsModule} from './statistics/google-analytics/googleanalytics.module';
import {HighchartsChartModule} from 'highcharts-angular';
import {VisualizationtaxonomyDialogService} from './measuredetails/visualization-taxonomy/visualization-taxonomy-dialog/visualization-taxonomy-dialog.service';
import {VisualizationtaxonomyDialogComponent} from './measuredetails/visualization-taxonomy/visualization-taxonomy-dialog/visualization-taxonomy-dialog.component';
import {HeaderSearchComponent} from './layout/header-search/header-search.component';
import {AppFooterComponent} from './layout/app-footer/app-footer.component';
import {ResourcesComponent} from './resources/resources.component';
import {SobcResourceDetailsComponent} from './sobc-resources-single/sobcresourcesingle.component';
import {SobcResourceDetails2Component} from './sobc-resources-single2/sobcresourcesingle2.component';
import {SobcResources2Component} from './sobc-resources2/sobcresources2.component';
import {ResourcesLayoutComponent} from './resources-layout/resources-layout.component';
import {SobcResourcesComponent} from './resources-layout/sobc-resources/sobcresources.component';
import {SobcSingleResourceDetailsComponent} from './resources-layout/sobc-single-resource-details/sobcresourcesingle2.component';
import {AppResourcesHeaderComponent} from './resources-layout/app-resources-header/app-resources-header.component';
import {AppResourcesFooterComponent} from './resources-layout/app-resources-footer/app-resources-footer.component';
// VisualizationtaxonomyDialogComponent;
import {DashboardNavigationRevisedComponent} from './layout/dashboard-navigation-revised/dashboard-navigation.component';

// GA4
import {GoogleAnalyticsV4Module} from './statistics/google-analytics-ga4/googleanalyticsv4.module';
import {StatisticsGA4UsersComponent} from './dashboard-revised/dashboard/google-analytics-ga4/audience/users.component';
import {StatisticsGA4PagesComponent} from './dashboard-revised/dashboard/google-analytics-ga4/pages/pages.component';
import {StatisticsGA4TrafficChannelsComponent} from './dashboard-revised/dashboard/google-analytics-ga4/traffic/channels/traffic-channels.component';
import {StatisticsGA4TrafficCountriesComponent} from './dashboard-revised/dashboard/google-analytics-ga4/traffic/countries/traffic-countries.component';
import {StatisticsGA4TrafficLanguagesComponent} from './dashboard-revised/dashboard/google-analytics-ga4/traffic/languages/traffic-languages.component';
import {StatisticsGA4WebsiteComponent} from './dashboard-revised/dashboard/google-analytics-ga4/traffic/websites/websites.component';
import {StatisticsGA4LinksComponent} from './dashboard-revised/dashboard/google-analytics-ga4/traffic/links/links.component';
// Revised dashboard
import {DashboardRevisedComponent} from './dashboard-revised/dashboard/dashboard-revised.component';
import {RevisedDashboardMeasuresComponent} from './dashboard-revised/dashboard/measures/measures.component';
import {RevisedDashboardAllMeasuresComponent} from './dashboard-revised/dashboard/measures/all/dashboard-all-measures.component';
import {RevisedDashboardMeasureFormComponent} from './dashboard-revised/dashboard/measures/measure-form/measure-form.component';
import {RevisedDashboardMeasureFormDetailsComponent} from './dashboard-revised/dashboard/measures/measure-form/details/details.component';
import {RevisedDashboardStudyEvidenceComponent} from './dashboard-revised/dashboard/study-evidence/study-evidence.component';
import {RevisedDashboardAllStudiesComponent} from './dashboard-revised/dashboard/study-evidence/all/dashboard-all-studies.component';
import {RevisedDashboardStudyEvidenceFormComponent} from './dashboard-revised/dashboard/study-evidence/form/study-evidence.component';
import {RevisedDashboardMeasuredetailsComponent} from './dashboard-revised/dashboard/measures/details/details.component';
import {RevisedDashboardBulkMeasureUploadComponent} from './dashboard-revised/dashboard/measures/measure-form/bulk-upload/bulk-upload.component';
import {StudyEvidenceDetailsComponent} from './dashboard-revised/dashboard/study-evidence/details/details.component';
import {RevisedDashboardMyCollectionComponent} from './dashboard-revised/dashboard/my-collection/my-collection.component';
import {MyCollectionDialogComponent} from './dashboard-revised/dashboard/my-collection/my-collection-dialog.component';

import { MatTabsModule } from '@angular/material/tabs';
import {StatisticsGoogleAnalyticsV4Module} from './dashboard-revised/dashboard/google-analytics-ga4/googleanalyticsv4.module';
// import {StatisticsGA4UsersComponent} from '././dashboard-revised/dashboard/google-analytics-ga4/users/users.component';
import {StatsGATrafficComponent} from  './dashboard-revised/dashboard/google-analytics-ga4/traffic/traffic.component';
import {CardComponent} from  './dashboard-revised/card/card.component';

import { StudiesDialogComponent } from './dashboard-revised/dashboard/measures/measure-form/studies-dialog/dialog.component';
import { MeasuresDialogComponent } from './dashboard-revised/dashboard/study-evidence/form/measures-dialog/dialog.component';
import {RevisedDashboardListFolderMeasuresComponent} from './dashboard-revised/dashboard/my-collection/list-folder-measures/list-folder-measures.component';
import {RevisedDashboardNotificationsComponent} from './dashboard-revised/dashboard/notifications/notifications.component';
import {RevisedDashboardListNotificationsComponent} from './dashboard-revised/dashboard/notifications/list-notifications/list-notifications.component';
import {ListNotificationsComponent} from './layout/dashboard-navigation-revised/list-notifications/list-notifications.component';
import { MatDialogModule } from '@angular/material/dialog';
// import { MatDialogModule } from '@angular/material';

import {RevisedDashboardUsersComponent} from './dashboard-revised/dashboard/users/users.component';
import { RevisedDashboardInstitutionsComponent } from './dashboard-revised/dashboard/institutions/institutions.component';
import {RevisedDashboardAllUsersComponent} from './dashboard-revised/dashboard/users/all/all-users.component';
import {RevisedDashboardUserFormComponent} from './dashboard-revised/dashboard/users/form/user-form.component';
import {RevisedDashboardInstitutionFormComponent} from './dashboard-revised/dashboard/institutions/form/institution-form.component';
import {RevisedDashboardAllInstitutionsComponent} from './dashboard-revised/dashboard/institutions/all/all-institutions.component';
import { AuthorDesignComponent } from './dashboard-revised/dashboard/study-evidence/form/author-design/author-design.component';
// import { RevisedDashboardMeasureFormDetailsComponent } from './dashboard-revised/dashboard/measures/measure-form/details/details.component';

import { AngularEditorModule } from '@kolkov/angular-editor';

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatAutocompleteModule,
  MatButtonModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  NativeDateModule
} from '@angular/material';
import {MatSortModule} from '@angular/material/sort';
import { DropdownComponent } from './layout/app-header/dropdown-munu/dropdown.component';

import { VisualizationTaxonomyComponent } from './../../app/pages/measuredetails/visualization-taxonomy/visualization-taxonomy.component';
import { CustomSelectComponent } from './../../app/pages/dashboard-revised/dashboard/study-evidence/form/custom-select/custom-select.component';

// import { NetworkDiagramComponent } from './../../app/pages/measuredetails/network-diagram/network-diagram.component';
// import { DynamicWidthDirective } from './../../app/pages/measuredetails/network-diagram/dynamicwirdth.directive';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule.forRoot(),

    /*** App modules ***/
    PagesRoutingModule,
    SharedModule,
    UsersModule,
    SurveysModule,
    MyCollectionModule,
    MeasuresFormModule,
    MeasuresModule,
    SobcResourcesModule,
    MeasuresAllModule,
    MeasureDetailsModule,
    MeasureApprovalModule,
    ExperimentdetailsModule,
    ExperimentsModule,
    GoogleAnalyticsModule,
    HighchartsChartModule,
    GoogleAnalyticsV4Module,
    MatTabsModule,
    // StatisticsGoogleAnalyticsV4Module,
    MatInputModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelectModule,
    MatSortModule,
    MatDialogModule,
    AngularEditorModule
    


  ],
  declarations: [
    DashboardComponent,
    PendingReviewComponent,
    MymeasuresComponent,
    HelpComponent,
    LoginComponent,
    AppLayoutComponent,
    AppHeaderComponent,
    DashboardNavigationComponent,
    LogoutComponent,
    // MeasureApprovalComponent,
    ConfirmationDialogComponent,
    TheorytechniquestoolDialogComponent,
    VisualizationtaxonomyDialogComponent,
    MeasuresStatisticsComponent,
    BulkMeasureUploadComponent,
    HeaderSearchComponent,
    AppFooterComponent,
    ResourcesComponent,
    SobcResourceDetailsComponent,
    SobcResourceDetails2Component,
    SobcResources2Component,
    ResourcesLayoutComponent,
    SobcResourcesComponent,
    SobcSingleResourceDetailsComponent,
    AppResourcesHeaderComponent,
    AppResourcesFooterComponent,
    DashboardNavigationRevisedComponent,
    DashboardRevisedComponent,
    CardComponent,
    StatisticsGA4UsersComponent,
    StatisticsGA4PagesComponent,
    StatsGATrafficComponent,
    StatisticsGA4TrafficChannelsComponent,
    StatisticsGA4TrafficCountriesComponent,
    StatisticsGA4TrafficLanguagesComponent,
    StatisticsGA4WebsiteComponent,
    StatisticsGA4LinksComponent,
    RevisedDashboardMeasuresComponent,
    RevisedDashboardAllMeasuresComponent,
    RevisedDashboardMeasureFormComponent,
    RevisedDashboardMeasureFormDetailsComponent,
    RevisedDashboardStudyEvidenceFormComponent,
    RevisedDashboardStudyEvidenceComponent,
    RevisedDashboardAllStudiesComponent,
    StudiesDialogComponent,
    MeasuresDialogComponent,
    RevisedDashboardMeasuredetailsComponent,
    RevisedDashboardBulkMeasureUploadComponent,
    StudyEvidenceDetailsComponent,
    RevisedDashboardMyCollectionComponent,
    MyCollectionDialogComponent,
    RevisedDashboardListFolderMeasuresComponent,
    RevisedDashboardNotificationsComponent,
    RevisedDashboardListNotificationsComponent,
    ListNotificationsComponent,
    RevisedDashboardUsersComponent,
    RevisedDashboardAllUsersComponent,
    RevisedDashboardUserFormComponent,
    AuthorDesignComponent,
    RevisedDashboardInstitutionsComponent,
    RevisedDashboardInstitutionFormComponent,
    RevisedDashboardAllInstitutionsComponent,
    DropdownComponent,
    VisualizationTaxonomyComponent,
    CustomSelectComponent,
    // DynamicWidthDirective,
    // NetworkDiagramLeftButtonsComponent,
    // NetworkDiagramComponent


    
  ],
  providers: [ConfirmationDialogService, CheckClickService, TheorytechniquestoolDialogService, VisualizationtaxonomyDialogService],
  entryComponents: [ConfirmationDialogComponent, TheorytechniquestoolDialogComponent, VisualizationtaxonomyDialogComponent, StudiesDialogComponent,MyCollectionDialogComponent, MeasuresDialogComponent],
})
export class PagesModule {
}