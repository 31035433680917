import {Component, OnInit} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';

import {ApirequestService} from '../../shared/services/apirequest.service';

@Component({
  selector: 'app-mymeasures',
  templateUrl: './mymeasures.component.html',
  styleUrls: ['./mymeasures.component.scss']
})

export class MymeasuresComponent implements OnInit {
  loading = true;
  myMeasures = [];
  page = 1;
  total = 0;
  limit = 10;
  total_pages = 0;
  page_size = 0;
  tab_title = '';
  allow_create_new = false;
  tab: string;

  constructor(
    private apirequestService: ApirequestService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.getMyMeasures();
    this.route.params.subscribe(params => {
      this.tab = params['tab'] || '';
      if (this.tab != '') {
        this.getMyMeasures('draft', 'BULK_UPLOAD');
      }
    });

  }

  getMyMeasures(status?: string, is_bulk_upload?: string) {
    const url = 'measures/my_measures/';
    var params;
    var tab_title;
    var allow_create_new;
    if (status) {
      params = new HttpParams()
        .set('page', this.page.toString())
        .set('owner', 'Me')
        .set('status', status)
        .set('is_bulk_upload', is_bulk_upload);
      tab_title = status;
      allow_create_new = true;
      if (status === 'pending_approval') {
        tab_title = 'pending approval';
      }
    } else {
      params = new HttpParams()
        .set('page', this.page.toString())
        .set('owner', 'Me');
      tab_title = '';
      allow_create_new = false;
    }
    this.apirequestService.get(url, params)
      .subscribe(
        res => {
          this.total = res.count;
          this.page_size = res.page_size;
          this.myMeasures = res.results;
          this.total_pages = res.total_pages;
          this.loading = false;
          this.tab_title = tab_title;
          this.allow_create_new = allow_create_new;

        },
        error => {
          console.log(error);
        });
  }

  goToPage(n: number): void {
    this.page = n;
    this.getMyMeasures();
  }

  onNext(): void {
    this.page++;
    this.getMyMeasures();
  }

  onPrev(): void {
    this.page--;
    this.getMyMeasures();
  }

  confirmDelete(mesureId){
   
    let conf = confirm(`Are you sure you want to delete this measure?`);  
    if (conf == true) {  
        // result = "You have selected OK!";
        // alert(`DELETING: ${mesureId}`);
        const url = 'measures/my_measures/';
        // let params = new HttpParams()
        // .set('id', mesureId);
        let params = {
          id: mesureId
        };
        this.apirequestService.post(url, params)
      .subscribe(
        res => {
          alert(res.message);
          this.getMyMeasures();
        },
        error => {
          console.log(error);
        });
        
    } else {  
        // result = "You have selected Cancelled!";  
    }  
  }

}
