import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import * as _ from 'lodash';

import {ApirequestService} from '../../shared/services/apirequest.service';
import {ApirequestdetailService} from '../../shared/services/apirequestdetail.service';
import {GoogleAnalyticsService} from '../../shared/services/google-analytics.service';

@Component({
  selector: 'app-experiments',
  templateUrl: './experiments.component.html',
  styleUrls: ['./experiments.component.scss'],
})
export class ExperimentsComponent implements OnDestroy, OnInit {
  measures = [];
  experiments = [];
  page: number;
  total = 0;
  total_pages = 0;
  thefilters = {};
  page_size = 50;
  sort_fields: any;
  search_data: any;
  route_stage: string;
  url_params;
  access_measure: boolean;
  loading = true;
  subscription: Subscription;

  collapse = 'closed';
  btncollapse = 'not_clicked';
  show = false;
  showText = false;
  isMainmeasuredetails = false;
  search_input: string;
  showMeasures = true;
  showExperiments = false;
  // Experiments pagination
  page_experiments: number;
  total_experiments = 0;
  total_pages_experiments = 0;
  thefilters_experiments = {};
  page_size_experiments = 50;
  loading_experiments = true;

  constructor(
    private apirequestService: ApirequestService,
    private apirequestdetailService: ApirequestdetailService,
    private route: ActivatedRoute,
    private router: Router,
    public googleAnalyticsService: GoogleAnalyticsService,
  ) {

    console.log('HERE this.apirequestdetailService.getFilteredData()=' + JSON.stringify(this.apirequestdetailService.getFilteredData()));
    console.log('TYPE=' + typeof (this.apirequestdetailService.getFilteredData()));

    this.subscription = this.apirequestdetailService.getFilteredData()
      .subscribe(
        data => {
          this.thefilters = data;
          this.page = 1;
          this.page_experiments = 1;
          this.getDataByPage();
          console.log('this.thefilters=' + JSON.stringify(this.thefilters));
        },
        error => {
          console.log(error);
        });
  }


  toogelDisplay(item) {
    if (item == 'measures') {
      this.showMeasures = false;
      this.showExperiments = true;
    } else {
      this.showMeasures = false;
      this.showExperiments = true;
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      console.log('params=' + JSON.stringify(params));
      this.page = params['page'] || '1';
      this.thefilters = {
        // 'domains': params['domains']  || 'All',
        // 'type':  params['type'] || 'All',
        // 'duration': params['duration']|| 'All',
        // 'target_population': params['target_population'] || 'All',
        // 'stage': params['stage'] || 'All',
        'measure_source': params['type'] || 'experiment',
      };
      // params['type'] = 'All';
      // this.search_data =  { 'data': params['data'] || '', 'type': params['type'] || '' };
      // this.search_data =  { 'data': params['data'] || '', 'type': 'All' || '' };
      this.search_data = {'data': params['data'] || '', 'type': this.thefilters['measure_source']};
      // this.sort_fields  = { 'sort_by': params['sort_by'] || 'popular' };
    });
    this.getExperimentsDataByPage();

    // this.getDataByPage();

    this.showMeasures = false;
    this.showExperiments = true;

  }

  /* Get experiments data */
  getExperimentsDataByPage() {
    this.url_params = this.thefilters;
    _.merge(this.url_params, {'page': this.page.toString(), 'page_size': this.page_size});
    _.merge(this.url_params, this.search_data);
    _.merge(this.url_params, this.sort_fields);

    const params = new HttpParams({
      fromObject: this.url_params
    });
    this.apirequestService.getExperiments('experiments', params)
      .subscribe(
        res => {
          this.total_experiments = res.total;
          this.experiments = res.results;
          this.page_size_experiments = res.per_page;
          this.page_experiments = res.current_page;
          this.total_pages_experiments = res.pages;
          this.loading_experiments = false;
          this.router.navigate([], {relativeTo: this.route, queryParams: this.url_params});
        },
        error => {
          console.log(error);
        });
    // this.search_input = this.search_data.data;
  }

  /* End of get experiments data */

  getDataByPage() {
    //////
    console.log('this.thefilters_2=' + JSON.stringify(this.thefilters));
    var type_ = this.thefilters['type'];
    // var arr_ = type_.split(',');
    // var type_last = arr_[colors][arr_.length - 1];
    this.search_data = {'data': this.search_data['data'] || this.thefilters['title'], 'type': type_};

    /////


    // _.merge(this.url_params, { 'page': this.page.toString(), 'page_size': this.page_size });
    console.log('page_experiments=' + this.page_experiments.toString());
    this.url_params = this.thefilters;
    _.merge(this.url_params, {'page': this.page_experiments.toString(), 'page_size': this.page_size});
    _.merge(this.url_params, this.search_data);
    _.merge(this.url_params, this.sort_fields);

    const params = new HttpParams({
      fromObject: this.url_params
    });
    // if(type == 'measures'){
    // this.apirequestService.get('measures/search/', params)
    // .subscribe(
    //   res => {
    //     this.total = res.count;
    //     this.measures = res.results;
    //     this.page_size = res.page_size;
    //     this.page = res.current_page;
    //     this.total_pages = res.total_pages;
    //     this.loading = false;
    //     this.router.navigate([], { relativeTo: this.route, queryParams: this.url_params });
    //   },
    //   error => {
    //     console.log(error);
    //   });
    // }
    // else

    {


      //   this.url_params = this.thefilters;
      // _.merge(this.url_params, { 'page': this.page.toString()});


      const params_ = new HttpParams({
        fromObject: {'page': this.page_experiments.toString()}
      });

      console.log('page_experiments2=' + this.page_experiments.toString());

      // this.url_params = this.thefilters;
      // _.merge(this.url_params, { 'page_experiments': this.page.toString(), 'page_size_experiments': this.page_size_experiments });
      this.url_params = this.thefilters;
      _.merge(this.url_params, {
        'page_experiments': this.page.toString(),
        'page_size_experiments': this.page_size_experiments,
        'total_pages_experiments': this.total_pages_experiments
      });
      // this.apirequestService.getExperiments('experiments', params_)
      if (this.search_data['type'] == undefined || this.search_data['type'] == '') {
        this.search_data['type'] = 'experiment';
        console.log('GOT HERE. this.search_data[\'type\']=' + this.search_data['type']);
      }
      this.apirequestService.getExperiments('searchexperimentbytype/' + this.search_data['type'] + '/' + this.search_data['data'], params_)
        .subscribe(
          res => {
            this.total_experiments = res.count;
            this.experiments = res.results;
            this.page_size_experiments = res.per_page;
            this.page_experiments = res.current_page;
            this.total_pages_experiments = res.pages;
            this.loading_experiments = false;
            this.router.navigate([], {relativeTo: this.route, queryParams: this.url_params});
          },
          error => {
            console.log(error);
          });
    }
    // this.search_input = this.search_data.data;
  }


  getSearchDataByPage() {
    this.url_params = this.thefilters;
    _.merge(this.url_params, {'page': this.page.toString(), 'page_size': this.page_size});
    _.merge(this.url_params, this.search_data);
    _.merge(this.url_params, this.sort_fields);

    const params = new HttpParams({
      fromObject: this.url_params
    });
    // if(type == 'measures'){
    // this.apirequestService.get('measures/search/', params)
    // .subscribe(
    //   res => {
    //     this.total = res.count;
    //     this.measures = res.results;
    //     this.page_size = res.page_size;
    //     this.page = res.current_page;
    //     this.total_pages = res.total_pages;
    //     this.loading = false;
    //     this.router.navigate([], { relativeTo: this.route, queryParams: this.url_params });
    //   },
    //   error => {
    //     console.log(error);
    //   });
    // }
    // else

    {


      //   this.url_params = this.thefilters;
      // _.merge(this.url_params, { 'page': this.page.toString()});

      const params_ = new HttpParams({
        fromObject: {'page': this.page_experiments.toString()}
      });

      // this.url_params = this.thefilters;
      // _.merge(this.url_params, { 'page_experiments': this.page.toString(), 'page_size_experiments': this.page_size_experiments });
      this.url_params = this.thefilters;
      _.merge(this.url_params, {
        'page_experiments': this.page.toString(),
        'page_size_experiments': this.page_size_experiments,
        'total_pages_experiments': this.total_pages_experiments
      });
      // this.apirequestService.getExperiments('searchexperiment/?', params_)
      // this.apirequestService.getExperiments('searchexperiment/'+this.search_data['data'],new HttpParams({}))
      if (this.search_data['type'] == undefined || this.search_data['type'] == '') {
        this.search_data['data'] = 'experiment';
        console.log('GOT HERE. this.search_data[\'data\']=' + this.search_data['data']);
      }
      this.apirequestService.getExperiments('searchexperimentbytype/' + this.search_data['data'] + '/' + this.search_data['type'], new HttpParams({})).subscribe(
        res => {
          this.total_experiments = res.count;
          this.experiments = res.results;
          this.page_size_experiments = res.per_page;
          this.page_experiments = res.current_page;
          this.total_pages_experiments = res.pages;
          this.loading_experiments = false;
          this.router.navigate([], {relativeTo: this.route, queryParams: this.url_params});
        },
        error => {
          console.log(error);
        });
    }
    // this.search_input = this.search_data.data;
  }

  // loadPageSize1(n: number): void {
  //   this.page = 1;
  //   this.page_size = n;
  //   this.getDataByPage();
  // }


  loadPageSize(n: number): void {
    this.page_experiments = 1;
    this.page_size_experiments = n;
    this.getDataByPage();
  }


  // goToPage(n: number): void {
  //   this.page = n;
  //   this.getDataByPage();
  // }

  // onNext(): void {
  //   this.page++;
  //   // this.getDataByPage();
  //   alert("HERE1");
  // }

  // onPrev(): void {
  //   alert("HERE2");
  //   this.page--;
  //   // this.getDataByPage();
  // }

  // goToPageExperiments(n: number): void {
  //   this.page_experiments = n;
  //   this.getDataByPage();
  // }

  goToPage(n: number): void {
    this.page_experiments = n;
    this.getDataByPage();
  }


  onNext(): void {
    // this.page_experiments = this.page_experiments+1;
    this.page_experiments++;
    console.log('page_experiments=' + this.page_experiments);
    this.getDataByPage();
  }

  onPrev(): void {
    this.page_experiments--;
    // this.page_experiments = this.page_experiments+1;
    // this.getDataByPage();
    console.log('page_experiments=' + this.page_experiments);
  }

  toggleCollapse() {
    this.show = !this.show;
    this.btncollapse = this.btncollapse === 'clicked' ? 'not_clicked' : 'clicked';
    this.collapse = this.collapse === 'closed' ? 'open' : 'closed';
  }

  sortMeasures(sort) {
    this.sort_fields = {'sort_by': sort};
    this.getDataByPage();
  }

  searchMeasure(search) {

    this.search_data = {'data': search};
    this.getDataByPage();
    // this.getSearchDataByPage();
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  downloadMeasure(id, title) {
    const data = {
      'measure_id': id,
    };
    const url = 'measures/download_count/';
    this.apirequestService.post(url, data)
      .subscribe(
        error => {
          console.log(error);
        });

    this.googleAnalyticsService.eventEmitter('download_measure_detail', 'measure', 'click', title, 1);
  }


}
