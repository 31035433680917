import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';
import * as _ from 'lodash';

export interface Token {
  access_token: string;
  token_refresh: string;
  expires_in: number;
  expires_at: object;
}

/**
 * This is a singleton class
 */
@Injectable()
export class AppConfig {
  // Provide all the Application Configs here
  public version = '1.0.0';
  public currencyFormat = {style: 'currency', currency: 'USD'};
  public dateFormat = {year: 'numeric', month: 'short', day: 'numeric'};
  public AUTH_TOKEN_KEY = 'auth.token';
  public AUTH_USER_KEY = 'user.details';

  // API Related configs
  public baseApiPath: string;
  public baseApiRoot = 'v1';
  public apiProtocol: string;
  // public apiProtocol = 'https://';
  public apiPort = environment.apiPort;
  public apiHostName = environment.apiHostName;
  public app_client_id = environment.client_id;
  public app_client_secret = environment.client_secret;


  constructor() {
    if (_.isUndefined(this.apiProtocol)) {
      this.apiProtocol = window.location.protocol;
    }
    if (_.isUndefined(this.apiHostName)) {
      this.apiHostName = window.location.hostname;
    }
    if (_.isUndefined(this.apiPort)) {
      this.apiPort = window.location.port;
    }
    if (this.apiHostName.includes('infomud') || this.apiHostName.includes('heroku') || this.apiHostName.includes('ngrok')) {
      this.baseApiPath = this.apiProtocol + '//' + this.apiHostName + '/' + this.baseApiRoot + '/';
    } else {
      this.baseApiPath = this.apiProtocol + '//' + this.apiHostName + ':' + this.apiPort + '/' + this.baseApiRoot + '/';
    }
  }
}
