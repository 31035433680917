import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UploadFileService} from '../../shared/services/uploadfile.service';
import {ApirequestService} from '../../shared/services/apirequest.service';
import {environment} from '../../../environments/environment';
import * as _ from 'lodash';


@Component({
  selector: 'app-bulk-measure-upload',
  templateUrl: './bulk-measure-upload.component.html',
  styleUrls: ['./bulk-measure-upload.component.scss']
})
export class BulkMeasureUploadComponent implements OnInit {

  @Output() uploadedFile = new EventEmitter<boolean>();
  measures = [];
  page = 1;
  total = 0;
  total_pages = 0;
  page_size = 10;
  search_data: any;
  search_input: string;
  measureForm: FormGroup;
  supporting_document_file = 'Supporting Document File';
  submitted: boolean;
  loading: boolean;
  file_path: string;
  file: any;
  upload_response: any;
  tab: string;
  api_host_url: string;
  bulk_upload_template_file_url: string
  success_msg : string;


  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apirequestService: ApirequestService,
    private uploadFile: UploadFileService,) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      //   this.page =  params['page'] || '1';
      //   this.search_data =  { 'data': params['data'] || '' };
      this.createForm();
      this.submitted = false;
      this.loading = false;
      this.api_host_url = environment.host_url;
      this.bulk_upload_template_file_url = 'https://measures.scienceofbehaviorchange.org:8003/media/Measures_Bulk_upload_template.xlsx';
      this.success_msg = "Measures uploaded successfully. An email has been sent to reviewers to review and publish the measures. Users can visit the 'My Measures Page' to review or edit their measures.";
    });
    // this.getMeasures();
  }

  initStudy(): FormGroup {
    return this.formBuilder.group({
      //   id: [''],
      supporting_document: this.formBuilder.array([this.initSupportingDocument()])
    });
  }

  initSupportingDocument(): FormGroup {
    return this.formBuilder.group({
      supporting_document_file: ['', Validators.required],
      //   type: ['', Validators.required],
    });
  }

  deleteMeasure(measure) {
    console.log(measure);
  }

  onUploadedFile(form, i, data) {
    form.controls.supporting_document.controls[i].controls.link.setValue(data.file_path);
  }

  getMeasures() {
    const url = 'measures/search/';
    let url_params = {'page': this.page.toString(), 'page_size': this.page_size.toString()};
    _.merge(url_params, this.search_data);

    const params = new HttpParams({
      fromObject: url_params
    });

    this.apirequestService.get(url, params)
      .subscribe(
        res => {
          this.total = res.count;
          this.page_size = res.page_size;
          this.measures = res.results;
          this.total_pages = res.total_pages;
          this.router.navigate([], {relativeTo: this.route, queryParams: url_params});
        },
        error => {
          console.log(error);
        });
    this.search_input = this.search_data.data;
  }

  searchMeasure(search) {
    this.search_data = {'data': search};
    this.getMeasures();
  }

  goToPage(n: number): void {
    this.page = n;
    this.getMeasures();
  }

  onNext(): void {
    this.page++;
    this.getMeasures();
  }

  onPrev(): void {
    this.page--;
    this.getMeasures();
  }


	  submitData() {
		this.loading = true;
		const reader = new FileReader();
    	const formData = new FormData();
		formData.append('file', this.file);
		formData.append('file_type', 'bulk_measure_upload_file');
		console.log(`file2=${this.file}`);
		const url = 'uploads/upload/';
		this.uploadFile.uploadfile(url, formData)
			.subscribe(
			data => {
				this.loading = false;
				this.file_path = data.file_path;
				this.upload_response = data;
				this.uploadedFile.emit(data);
			},
			error => {
				this.loading = false;
				this.upload_response = error; //error.error;
				console.log(error);
				console.log(`error=${this.upload_response}`);
			});
	  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      this.file = event.target.files[0];
      console.log(`file=${this.file}`);
    }
  }

  private createForm() {
    this.measureForm = this.formBuilder.group({
      //   id: this.id,
      //   studiesid: '',
      supporting_document: this.formBuilder.array([this.initSupportingDocument()])//this.formBuilder.array([this.initStudy()]),
    });
  }
  isObjectEmpty = (objectName) => {
    return (Object.keys(objectName).length === 0);
  };

}
