import { Component, OnInit, ViewChild, ElementRef, Renderer2, Input, HostListener } from '@angular/core';
import * as d3 from 'd3';
import { ApirequestService } from '../../../../shared/services/apirequest.service';
import { DiagramSharedService } from '../diagram-shared.service';
import { WindowSizeService } from './window-size.service';
// import { Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

interface Node extends d3.SimulationNodeDatum {
  id: string;
  fx?: number;
  fy?: number;
  radius?: number;
  ca_link?: string;
  order?: number;
}

interface Link extends d3.SimulationLinkDatum<Node> {
  source: string | Node;
  target: string | Node;
}

@Component({
  selector: 'app-network-diagram-diagram',
  templateUrl: './left-buttons.component.html',
  styleUrls: ['./left-buttons.component.scss']
})
export class NetworkDiagramDiagramComponent implements OnInit {
  // measure_ca_id: string = 'trm_56674133b666c';
  concepts = [];
  img1: string = '../../../../../assets/images//cognitive_atlas/Group_760.png';
  img2: string = '../../../../../assets/images//cognitive_atlas/Group_760@2x.png';
  img_url: string;
  allNodes: any[];
  links: any[];
  selectedNode: string;
  @Input() measure_title: string;
  @Input() measure_ca_id: string;

  @ViewChild('networkDiagram') networkDiagram: ElementRef;
  windoScreenWidth: number;

  // screenWidth: number;
  // screenHeight: number;
  // private resizeSubscription: Subscription;

    private screenWidth = new BehaviorSubject<number>(window.innerWidth);
    private screenHeight = new BehaviorSubject<number>(window.innerHeight);

    screenWidth$ = this.screenWidth.asObservable();
    screenHeight$ = this.screenHeight.asObservable();


  constructor(
    private apirequestService: ApirequestService,
    private renderer: Renderer2,
    private diagramSharedService: DiagramSharedService,
    // private windowSizeService: WindowSizeService
  ) {
    this.onResize();
  }

  // ngOnDestroy(): void {
  //   if (this.resizeSubscription) {
  //     this.resizeSubscription.unsubscribe();
  //   }
  // }

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    this.screenWidth.next(window.innerWidth);
    this.screenHeight.next(window.innerHeight);
  }

  ngOnInit() {
    // this.resizeSubscription = this.windowSizeService.screenWidth$.subscribe(width => {
    //   this.screenWidth = width;
    //   console.log(`screenWidth=${this.screenWidth}`);
    // });
    // this.windowSizeService.screenHeight$.subscribe(height => {
    //   this.screenHeight = height;
    // });
    
    

    this.img_url = `${this.img1} 1x, ${this.img2} 2x`;
    this.allNodes = [];
    this.links = [];
    const url = 'visualization-taxonomy/get-concepts/' + this.measure_ca_id + '/';

    this.diagramSharedService.data$.subscribe(data => {
      if(data != undefined){
        if(data.source != 'diagram'){
          this.selectedNode = data.selectedNode;
          this.allNodes = data.allNodes;
          this.selectedNode = data.selectedNode;
          this.concepts = data.concepts;
          this.links = data.links;
          this.createNetworkDiagram(this.selectedNode);
        }
      }
    });

    this.screenWidth.subscribe(width => {
      console.log(`width:=${JSON.stringify(width)}`);
        this.windoScreenWidth = width;
        this.createNetworkDiagram(this.selectedNode);
    });
  }


  private createNetworkDiagram(selectedNodeName): void {
    if (selectedNodeName != undefined) {
        this.selectedNode = selectedNodeName;
    }

    console.log(`this.links=${JSON.stringify(this.links)}`);
    
    // const mainNodeRadius = 90;
    const mainNodeRadius = 90;
    const nodeRadius = 50;
    let nodes = [];
    const links = [];
    let randomIndex = Math.floor(Math.random() * this.concepts.length);

    let i = 0;
    let mainNodeUrl = `https://www.cognitiveatlas.org/task/id/${this.measure_ca_id}/`;
    nodes.push({ id: this.measure_title, radius: mainNodeRadius, ca_link: mainNodeUrl, order: 0 });

    this.allNodes.forEach(currConcept => {
        let is_selected = false;
        let order = this.allNodes.length + 1;

        if (this.measure_title == 'Stop Signal Task') {
            if (currConcept.name == 'Proactive Control') {
                is_selected = true;
                this.selectedNode = currConcept.name;
                if (selectedNodeName == undefined) {
                    selectedNodeName = currConcept.name;
                }
            }
        }
      //   else if (this.measure_title == 'Adaptive N-Back Task') {
      //     if (currConcept.name == 'Proactive Interference') {
      //         is_selected = true;
      //         this.selectedNode = currConcept.name;
      //         if (selectedNodeName == undefined) {
      //             selectedNodeName = currConcept.name;
      //         }
      //     }
      // }
      else {
            // if (i == randomIndex) {
            //     is_selected = true;
            //     this.selectedNode = currConcept.name;
            //     if (selectedNodeName == undefined) {
            //         selectedNodeName = currConcept.name;
            //     }
            // }
            if (currConcept.is_selected == true) {
              is_selected = true;
              this.selectedNode = currConcept.name;
              if (selectedNodeName == undefined) {
                  selectedNodeName = currConcept.name;
              }
          }
        }
        // nodes.push({ id: currConcept.name, name: currConcept.name, radius: nodeRadius, ca_link: currConcept.ca_link, is_selected, order: order });
        // links.push({ source: this.measure_title, target: currConcept.name });
        i++;
    });
    
    // nodes = nodes.reverse();
    // let data = { nodes: nodes as Node[], links: links as Link[] };
    let mainNode = [];
    let mainNodeUrl1 = `https://www.cognitiveatlas.org/task/id/${this.measure_ca_id}/`;
    mainNode.push({ id: this.measure_title, radius: mainNodeRadius, ca_link: mainNodeUrl1, order: 0 });
    let allNodesNew = mainNode.concat(this.allNodes);
    // allNodesNew = allNodesNew.reverse();
    let data = { nodes: allNodesNew as Node[], links: this.links as Link[] };
    

    const width = 800;
    const height = 600;

    // let radius = 200; 
    // this.screenWidth.subscribe(width => {
    //   console.log(`width::=${JSON.stringify(width)}`);
    //   if(width <= 780){
    //     radius = 150;
    //   }
    // });
    let radius = this.windoScreenWidth <= 780? 170: 200; //100; // Reduced radius to bring nodes closer
    const angleStep = (2 * Math.PI) / (data.nodes.length - 1);


    console.log(`data.nodes|||| = ${JSON.stringify(data.nodes)}`);
    console.log(`allNodes|||| = ${JSON.stringify(this.allNodes)}`);
    data.nodes.forEach((node, index) => {
        if (node.id !== this.measure_title) {
            // const angle = angleStep * (index - 1);
            // const angle = angleStep * (index - 2);
            const angle = angleStep * (index - 2);
            node.fx = width / 2 + radius * Math.cos(angle);
            node.fy = height / 2 + radius * Math.sin(angle);
        } else {
            node.fx = width / 2;
            node.fy = height / 2;
        }
    });

    const svg = d3.select(this.networkDiagram.nativeElement)
        .html('')
        .append('svg')
        .attr('width', width)
        .attr('height', height);

    const simulation = d3.forceSimulation<Node>(data.nodes)
        .force('link', d3.forceLink<Node, Link>(data.links).id((d: any) => d.id).distance(50)) // Reduced distance for shorter links
        .force('charge', d3.forceManyBody().strength(-10))
        .force('center', d3.forceCenter(width / 2, height / 2));

    const link = svg.append('g')
        .attr('class', 'links')
        .selectAll('line')
        .data(data.links)
        .enter().append('line')
        .attr('stroke-width', 1)
        .attr('stroke', 'white');

    const node = svg.append('g')
        .attr('class', 'nodes')
        .selectAll('.node')
        .data(data.nodes)
        .enter().append('g')
        .attr('class', 'node')
        .attr('transform', (d: any) => `translate(${d.fx},${d.fy})`)
        .on('click', (d: any) => {
            this.onNodeClick(d.id, d.ca_link);
        })
        .call(d3.drag()
            .on('start', dragstarted)
            .on('drag', dragged)
            .on('end', dragended));

    node.append('circle')
        .attr('r', (d: any) => d.radius)
        .attr('stroke', 'white')
        .attr('fill', '#5cbdeb'); 

    node.append('foreignObject')
        .attr('class', 'text-container')
        // .attr('x', (d: any) => d.id == this.measure_title ? -95 : -40)
        // .attr('y', (d: any) => d.id == this.measure_title ? -100 : -40)
        .attr('x', (d: any) => d.id == this.measure_title ? -93.5 : -40)
        // .attr('y', (d: any) => d.id == this.measure_title ? -100 : -40)
        .attr('y', (d: any) => d.id == this.measure_title ? -100 : ((d.id.length > 21 && d.id === selectedNodeName)? -45: -40)) //45
        // .attr('width', (d: any) => d.id == this.measure_title ? 190 : 80)
        // .attr('height', (d: any) => d.id == this.measure_title ? 200 : 80)
        .attr('width', (d: any) => d.id == this.measure_title ? 185 : 80)
        .attr('height', (d: any) => d.id == this.measure_title ? 200 : 80)
        .html((d: any) => {
            if (d.id == this.measure_title) {
                return `
                <a style="text-decoration: none; color: white; cursor: pointer;" href="https://www.cognitiveatlas.org/task/id/${this.measure_ca_id}/" target="_blank">
                  <div style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; text-align: center; overflow-wrap: break-word; color: white; font-size: 17px; font-weight: normal; cursor: pointer;">
                   ${d.id}
                  </div>
                </a>`;
            }
            return `<a style="text-decoration: none; color: white; cursor: pointer;" href="${d.ca_link}" target="_blank">
            <div style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; text-align: center; overflow-wrap: break-word; color: white; cursor: pointer;">
              ${d.id}
            </div>
          </a>`;
        });

    node.append('foreignObject')
        .attr('class', 'text-container')
        .attr('x', -30)
        .attr('y', -10)
        .attr('width', 60)
        .attr('height', 80)
        .html((d: any) => {
            if (d.id !== this.measure_title && d.id === selectedNodeName) {
                return `
                  <div style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; text-align: center; overflow-wrap: break-word; cursor: pointer;">
                      <a style="text-decoration: none; background-color: white; width: 90%; height: 25%; border-radius: 20px; cursor: pointer;" href="${d.ca_link}" target="_blank">
                        <span style="color: #0091d5;">View</span><span style="color: #0091d5;"> > </span>
                      </a>
                  </div>
                `;
            }
            return ``;
        });

    simulation.on('tick', () => {
        link.attr('x1', (d: any) => d.source.x)
            .attr('y1', (d: any) => d.source.y)
            .attr('x2', (d: any) => d.target.x)
            .attr('y2', (d: any) => d.target.y);
    });

    function dragstarted(d: any) {
    }

    function dragged(d: any) {
    }

    function dragended(d: any) {
    }

    for (let i = 0; i < 300; i++) {
        simulation.tick();
    }
}


  setRectWidth(text) {
    const len = text.length;
    if (len <= 10) {
      return (len * 13);
    }
    else if (len >= 18) {
      return (len * 18);
    }
    return (len * 13);
  }

  onNodeClick(nodeId: string, ca_link: string) {
    this.diagramSharedService.sendData({
      source: 'diagram',
      selectedNode: nodeId,
      links: this.links
    });
    window.open(ca_link, '__self');
  }

  rearrangeNodesOrder(nodes: any[], selectedIndex: number): any[] {
    const targetIndex = nodes.findIndex(node => node.id === 'Auditory Tone Detection');
    if (targetIndex === -1) {
      console.warn('Target node not found');
      return nodes;
    }

    const selectedNode = nodes.splice(selectedIndex, 1)[0];
    nodes.splice(targetIndex, 0, selectedNode);

    for (let i = 1; i < nodes.length; i++) {
      nodes[i].order = i;
    }

    return nodes;

     // Set the order of the first item to 1
    //  nodes[selectedIndex].order = 1;
  
    //  // Re-arrange the other items
    //  for (let i = 1; i < nodes.length; i++) {
    //    if(nodes[i].id != this.selectedNode){
    //      nodes[i].order = i + 1;
    //    }
    //  }
    //  return nodes;
    
  }

  arrangeNodes(data: any): any {
    // return data;
        // Sort nodes by order
        data.nodes.sort((a, b) => a.order - b.order);

        // Calculate positions
        const width = 800;
        const height = 600;
        const radius = 200;
        const angleStep = (2 * Math.PI) / (data.nodes.length - 1);
    
        data.nodes.forEach((node, index) => {
          if (node.id !== this.measure_title) {
            const angle = angleStep * (index - 1);
            node.fx = width / 2 + radius * Math.cos(angle);
            node.fy = height / 2 + radius * Math.sin(angle);
          } else {
            node.fx = width / 2;
            node.fy = height / 2;
          }
        });
        console.log(`data.nodes=${JSON.stringify(data.nodes)}`);
        return data;

  }
}
