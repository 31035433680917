import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';

import {ApirequestService} from '../../../shared/services/apirequest.service';
import {AlertService} from '../../../shared/services/alert.service';

@Component({
  selector: 'app-surveys-create',
  templateUrl: './surveys-create.component.html',
  styleUrls: ['../surveys.component.scss']
})

export class SurveysCreateComponent implements OnInit {
  surveyForm: FormGroup;
  isInstructionCollapse: boolean;
  id: string;
  measure_id: string;
  submitted = false;
  loading = false;
  page_title = 'Create Survey';
  placeholder: string[];
  url = 'surveys/create/';
  form_sent = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apirequestService: ApirequestService,
    public alertService: AlertService,
  ) {
  }

  get f() {
    return this.surveyForm.controls;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.measure_id = params['measure_id'] || '';
    });
    if (!_.isEmpty(this.id)) {
      this.page_title = 'Update Survey';
      this.getFormData();
    }
    this.createForm();
  }

  initQuestion(): FormGroup {
    return this.formBuilder.group({
      label: '',
      type: 'text',
      options: this.formBuilder.array([this.initOption()]),
    });
  }

  addQuestion(): void {
    const control = <FormArray>this.surveyForm.get('questions');
    control.push(this.initQuestion());
  }

  getQuestions(form) {
    return form.controls.questions.controls;
  }

  removeQuestion(form, j) {
    const control = form.controls.questions;
    control.removeAt(j);
  }

  setQuestionsData(res) {
    const control = <FormArray>this.surveyForm.get('questions');
    control.removeAt(0);
    let i = 0;
    res.domains.forEach(x => {
      control.push(this.formBuilder.group({
        label: x.label,
        type: x.type,
      }));
      this.setOptionsData(this.surveyForm, i, res);
      i++;
    });
  }

  initOption(): FormGroup {
    return this.formBuilder.group({
      label: '',
    });
  }

  addOption(form, i): void {
    const control = form.controls.questions.controls[i].get('options');
    control.push(this.initOption());
  }

  getOptions(form) {
    return form.controls.options.controls;
  }

  removeOption(question, j) {
    const control = question.controls.options;
    control.removeAt(j);
  }

  setOptionsData(form, i, res) {
    const control = form.controls.questions.controls[i].get('options');
    control.removeAt(0);
    let thedata = [];
    thedata = res.studies[i].pmcid_pubmedid_or_citation;
    thedata.forEach(x => {
      control.push(this.formBuilder.group({
        label: x.label,
      }));
    });
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.surveyForm.invalid) {
      return;
    }
    this.loading = true;

    this.apirequestService.post(this.url, this.surveyForm.value)
      .subscribe(
        data => {
          this.alertService.success('Successfully created');
          this.router.navigate(['/dashboard/surveys/' + this.measure_id]);
          this.loading = false;
          this.form_sent = true;
        },
        error => {
          this.alertService.error(error, '');
          this.loading = false;
        });
  }

  private createForm() {
    this.surveyForm = this.formBuilder.group({
      id: '',
      measure_id: this.measure_id,
      name: ['', Validators.required],
      description: ['', Validators.required],
      questions: this.formBuilder.array([this.initQuestion()]),
    });
  }

  private getFormData() {
    const url = 'measures/details/details/' + this.id + '/';
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.surveyForm.patchValue(res, {onlySelf: true});
          this.setQuestionsData(res);
        },
        error => {
          console.log(error);
        });
  }

}
