import {Component, OnDestroy, OnInit, ElementRef, Renderer2, ViewChild, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import * as _ from 'lodash';

import {ApirequestService} from '../../../shared/services/apirequest.service';
import {ApirequestdetailService} from '../../../shared/services/apirequestdetail.service';
import {GoogleAnalyticsService} from '../../../shared/services/google-analytics.service';
import {debounceTime} from 'rxjs/operators';
import { DynamicScriptLoaderService } from '../../../shared/services/DynamicScriptLoaderService';
import { title } from 'process';
import {AppResourcesHeaderComponent} from './../app-resources-header/app-resources-header.component';
import { DataService } from '../services/data.service';
// my-component.component.ts

// Define a custom class
class  IndividualCategory{
  id: number;
  name: string;
  constructor(id, name, ) {
    this.id = id;
    this.name = name;
  }
}

@Component({
  selector: 'app-sobc-resources',
  templateUrl: './sobcresources.component.html',
  styleUrls: ['./sobcresources.component.scss', 'font-custom.css'],
})
export class SobcResourcesComponent implements OnDestroy, OnInit {
  // @ViewChild('myDiv') myDiv: ElementRef;
  @ViewChild('filters-selected', { read: ElementRef }) container: ElementRef;
  measures = [];
  resources = [];
  allresources = [];
  experiments = [];
  page: number;
  total = 0;
  total_pages = 0;
  thefilters = {};
  page_size = 9;
  sort_fields: any;
  search_data: any;
  route_stage: string;
  url_params;
  access_measure: boolean;
  loading = true;
  subscription: Subscription;

  collapse = 'closed';
  btncollapse = 'not_clicked';
  show = false;
  showText = false;
  isMainmeasuredetails = false;
  search_input: string;
  showMeasures = true;
  showExperiments = false;
  // Experiments pagination
  page_experiments: number;
  total_experiments = 0;
  total_pages_experiments = 0;
  thefilters_experiments = {};
  page_size_experiments = 10;
  loading_experiments = true;
  showFullMeasureHeader = false;
  resourcesFilters = [];
  filterCategories = [];
  searchedText = "";
  resources100 = [];
  is_authenticated = false;
  private searchTerms = new Subject<string>();
  private selectedResourcesFilter = new Subject<string>();

  constructor(
    private apirequestService: ApirequestService,
    private apirequestdetailService: ApirequestdetailService,
    private route: ActivatedRoute,
    private router: Router,
    public googleAnalyticsService: GoogleAnalyticsService,
    private renderer: Renderer2,
    private el: ElementRef,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private dataService: DataService
  ) {
    // this.subscription = this.apirequestdetailService.getFilteredData()
    //   .subscribe(
    //     data => {
    //       console.log(`this.thefilters=${JSON.stringify(data)}`);
    //       this.thefilters = data;
    //       this.page = 1;
    //       this.page_experiments = 1;
    //       this.getDataByPage();
    //     },
    //     error => {
    //       console.log(error);
    //     });
  }


  toogelDisplay(item) {
    if (item == 'measures') {
      this.showMeasures = true;
      this.showExperiments = false;
    } else {
      this.showMeasures = false;
      this.showExperiments = true;
    }
  }

  // ngAfterViewChecked(){
  //   this.loadScripts();
  // }
  // ngDoCheck(){
  //   this.loadScripts();
  // }

  ngOnInit() {
    // this.route.queryParams.subscribe(params => {
    //   this.page = params['page'] || '1';
    //   this.thefilters = {
    //     'measure_source': params['measure_source'] || 'All',
    //     'domains': params['domains'] || 'All',
    //     'behaviors': params['behaviors'] || 'All',
    //     'measurement_mode': params['measurement_mode'] || 'All',
    //     'validity_studies_uploaded': params['validity_studies_uploaded'] || 'All',
    //     'target_population': params['target_population'] || 'All',
    //     'language': params['language'] || 'All',
    //     'duration': params['duration'] || 'All',
    //     'database': params['database'] || 'All',
    //   };
    //   this.search_data = {'data': params['data'] || ''};
    //   this.sort_fields = {'sort_by': params['sort_by'] || 'popular'};
    //   if (params['experiments']) {
    //     // Disable experiments for now
    //     // this.getExperimentsDataByPage();
    //     // this.toogelDisplay('experiments');
    //   } else {
    //     this.getDataByPage();
    //     // Disable experiments for now
    //     // this.getExperimentsDataByPage();
    //   }
    // });
    this.loadScripts();
    this.getDataByPage();
    // Disable experiments for now
    // this.getExperimentsDataByPage();

    // this.getDataByPage();
    this.searchTerms.pipe(
      debounceTime(500)
    ).subscribe(searchTerm => {
      this.search_data = {'data': searchTerm};
      console.log(`this.search_data=${JSON.stringify(this.search_data)}`);
      ////////////////////////////////////////////////////////////////////////////
      if(searchTerm == ""){
        this.resources = this.measures;
        this.searchedText = "";
      }
      else{
        this.searchedText = searchTerm;
        // if(post.post_title.toUpperCase().indexOf(this.searchedText.toUpperCase()) !== -1){
        let filts = [];
  
        this.measures.map(post => {
          console.log(`post=${JSON.stringify(post)}`);
          if(post.post_title.toUpperCase().indexOf(searchTerm.toUpperCase()) !== -1){
            if(this.resourcesFilters.length > 0){
              const uppercaseArray = this.resourcesFilters; //allFilters_array; //.map(element => element.toUpperCase());
              let pcategory = post.post_category;
              if(uppercaseArray.includes(pcategory)){
                filts.push(post);
              }
            }
            else{
              filts.push(post);
            }
          }
        });
        this.resources = filts;
      }




      ////////////////////////////////////////////////////////////////////////////

      // this.getDataByPage('measures', 'keyup');
    });
    this.selectedResourcesFilter.subscribe(filter => {
        if(this.resourcesFilters.indexOf(filter) == -1){
            this.resourcesFilters.push(filter);
        }
    });
    // ps[0] = new IndividualCategory(0, "Special Issues & Sections");
    // ps[1] = new IndividualCategory(1, "Affiliated Organizations");
    // ps[2] = new IndividualCategory(2, "SOBC Youtube Page");
    // ps[3] = new IndividualCategory(3, "Others");
    // this.filterCategories = [
    //   "Special Issues & Sections",
    //   "Affiliated organisations",
    //   "SOBC Youtube page",
    //   "others"
    // ];
    this.filterCategories.push(
      new IndividualCategory(0, "Special Issues & Sections")
    );
    this.filterCategories.push(
      new IndividualCategory(1, "Affiliated Organizations")
    );
    this.filterCategories.push(
      new IndividualCategory(2, "SOBC Youtube Page")
    );
    this.filterCategories.push(
      new IndividualCategory(3, "Others")
    );
    document.getElementById('id01').style.display='none';
  }

  /* Get experiments data */
  getExperimentsDataByPage() {
    this.url_params = this.thefilters;
    _.merge(this.url_params, {'page': this.page.toString(), 'page_size': this.page_size});
    _.merge(this.url_params, this.search_data);
    _.merge(this.url_params, this.sort_fields);

    const params = new HttpParams({
      fromObject: this.url_params
    });
    this.apirequestService.getExperiments('experiments', params)
      .subscribe(
        res => {
          this.total_experiments = res.total;
          this.experiments = res.results;
          this.page_size_experiments = res.per_page;
          this.page_experiments = res.current_page;
          this.total_pages_experiments = res.pages;
          this.loading_experiments = false;
          this.router.navigate([], {relativeTo: this.route, queryParams: this.url_params});
        },
        error => {
          console.log(error);
        });
    // this.search_input = this.search_data.data;
  }

  /* End of get experiments data */

  getDataByPage(type = 'measures', source= 'pagination') {
    if(source == "keyup" && this.search_data.data !== ""){
      this.page = 1;
    }
    // this.url_params = this.thefilters;
    // _.merge(this.url_params, {'page': this.page.toString(), 'page_size': this.page_size});
    // _.merge(this.url_params, this.search_data);
    // _.merge(this.url_params, this.sort_fields);
    this.loading = true;
    const params = new HttpParams({
      fromObject: this.url_params
    });
    if (type == 'measures') {
      let url = 'https://measures.scienceofbehaviorchange.org:3001/external-api/?externalApiUrl=https://scienceofbehaviorchange.org/resources-categories-list/?pg=1';
      // let url = 'http://127.0.0.1:3001/external-api/?externalApiUrl=http://127.0.0.1/resources-categories-list/?pg=1';
      this.apirequestService.getCustom(url)
        .subscribe(
          res => {
            this.page = 1;
            // this.total = res.count;
            console.log(`total=${res.length}`);
            this.measures = res;
            this.allresources = res;
            // this.resources = res;
            /////
            let filts = [];
            for(let i=0; i<9; i++){
              filts.push(res[i]);
            }
            this.resources = filts;
            /////
            this.total = res.length;
            // this.page_size = res.page_size;
            // this.page = res.current_page;
            // this.total_pages = res.total_pages;
            this.loading = false;
            this.total_pages = Math.ceil(this.total/9);
            this.router.navigate([], {relativeTo: this.route, queryParams: this.url_params});
          },
          error => {
            console.log(error);
            this.loading = false;
          });
    } else {


      //   this.url_params = this.thefilters;
      // _.merge(this.url_params, { 'page': this.page.toString()});

      const params_ = new HttpParams({
        fromObject: {'page': this.page_experiments.toString()}
      });

      // this.url_params = this.thefilters;
      // _.merge(this.url_params, { 'page_experiments': this.page.toString(), 'page_size_experiments': this.page_size_experiments });
      this.url_params = this.thefilters;
      _.merge(this.url_params, {
        'page_experiments': this.page.toString(),
        'page_size_experiments': this.page_size_experiments,
        'total_pages_experiments': this.total_pages_experiments
      });

      this.apirequestService.getExperiments('experiments', params_)
        .subscribe(
          res => {
            this.total_experiments = res.count;
            this.experiments = res.results;
            this.page_size_experiments = res.per_page;
            this.page_experiments = res.current_page;
            this.total_pages_experiments = res.pages;
            this.loading_experiments = false;
            this.router.navigate([], {relativeTo: this.route, queryParams: this.url_params});
          },
          error => {
            console.log(error);
          });
    }


    // this.search_input = this.search_data.data;
  }

  loadPageSize(n: number): void {
    this.page = 1;
    this.page_size = n;
    this.getDataByPage();
  }


  loadExperimentsPageSize(n: number): void {
    this.page_experiments = 1;
    this.page_size_experiments = n;
    this.getDataByPage('experiments');
  }


  goToPage(n: number): void {
    this.page = n;
    console.log(`n=${n}`);
    let filts = [];
    let startIndex = (9 * this.page) - 9;
    if(n > 1){
      if(this.page > 1){startIndex = startIndex + 1;}
      for(let i=startIndex; i<startIndex + 8; i++){
        if(this.measures.length >= i){
          filts.push(this.measures[i-1]);
        }
      }
    }
    else{
      console.log(`got to else. this.page=${this.page}`);
      if(this.page > 1){startIndex =  0;}
      for(let i=startIndex; i<startIndex + 9; i++){
        if(this.measures.length >= i){
          filts.push(this.measures[i]);
        }
      }
    }
    this.resources = filts;
  }

  onNext(): void {
    console.log('onNext CALLED');
    console.log(`this.page_1=${this.page}`);
    this.page++;
    console.log(`this.page_2=${this.page}`);
    console.log(`this.measures.length=${this.measures.length}`);
    
    // this.getDataByPage();
    let startIndex = (9 * this.page) - 9;
    let filts = [];
    if(this.page > 1){startIndex = startIndex + 1;}
    for(let i=startIndex; i<startIndex + 8; i++){
      if(this.measures.length >= i){
        filts.push(this.measures[i-1]);
      }
    }
    this.resources = filts;
    
    
    console.log(`startIndex=${startIndex}`);
    console.log(`this.measures.length=${this.measures.length}`);
  }

  onPrev(): void {
    console.log(`this.page_1=${this.page}`);
    this.page--;
    
    console.log(`this.page_2=${this.page}`);
    // this.getDataByPage();
    let startIndex = (9 * this.page) - 9;
    // let adds = 8;
    if(this.page > 1){startIndex = startIndex + 1;}
    let filts = [];
    for(let i=startIndex; i<startIndex + 9; i++){
      if(this.measures.length >= i){
        filts.push(this.measures[i]);
      }
    }
    this.resources = filts;
    console.log('onPrev CALLED');
    console.log(`startIndex=${startIndex}`);
    console.log(`this.resources.length=${this.resources.length}`);
  }

  goToPageExperiments(n: number): void {
    this.page_experiments = n;
    this.getDataByPage('experiments');
  }

  onNextExperiment(): void {
    this.page_experiments++;
    this.getDataByPage('experiments');
  }

  onPrevExperiment(): void {
    this.page_experiments--;
    this.getDataByPage('experiments');
  }

  toggleCollapse() {
    this.show = !this.show;
    this.btncollapse = this.btncollapse === 'clicked' ? 'not_clicked' : 'clicked';
    this.collapse = this.collapse === 'closed' ? 'open' : 'closed';
  }

  sortMeasures(sort) {
    this.sort_fields = {'sort_by': sort};
    this.getDataByPage();
  }

  searchMeasure(search) {
    this.searchTerms.next(search);
  }

  toggleShowHeader(show: boolean): void {
    this.showFullMeasureHeader = !show;
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  downloadMeasure(id, title) {
    const data = {
      'measure_id': id,
    };
    const url = 'measures/download_count/';
    this.apirequestService.post(url, data)
      .subscribe(
        error => {
          console.log(error);
        });

    this.googleAnalyticsService.eventEmitter('download_measure_detail', 'measure', 'click', title, 1);
  }


   formatDate(inputDate) {
      // formatDate(post?.post_date.split(" ")[0])
      // Create a Date object from the input string
      inputDate = inputDate.split(" ")[0];
      const date = new Date(inputDate);
      
      // Define an array to get the month name
      const months = [
        "January", "February", "March", "April",
        "May", "June", "July", "August",
        "September", "October", "November", "December"
      ];
      
      // Extract the day, month, and year
      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();
      
      // Create the formatted date string
      const formattedDate = `${month} ${day}, ${year}`;
      
      return formattedDate;
    }


    handleKeyUp2 = (allFilters, page, inputValue) => {
    
      {
        let filts = [];
        this.measures.map(post => {
          console.log(this.resourcesFilters);
            console.log('HERE');
            if(this.resourcesFilters.length > 0){
              console.log('HERE_1');
              const uppercaseArray = this.resourcesFilters; //allFilters_array; //.map(element => element.toUpperCase());
              let pcategory = post.post_category;
              // if(uppercaseArray.includes(pcategory?.toUpperCase())){
              if(uppercaseArray.includes(pcategory)){
                console.log('HERE_1_1');
                // filts.push(post);
                if(this.resources.indexOf(post) == -1){
                  this.resources.push(post);
                }
              }
              else if(inputValue == ""){
                console.log('HERE_1_2');
                console.log(uppercaseArray);
                console.log(`pcategory=${pcategory}`);
                // filts.push(post);
              }
            }
            else{
              console.log('HERE_2');
              // filts.push(post);
              this.resources.push(post);
            }
        });
        // setCurrPagePosts(filts);
        // setCurrPagePosts(filts);
      }
    };


  itemClicked = (event, action, page) => {
      if(action == 'hide'){
       alert('clicked');
       let parentDiv = event.currentTarget.parentNode;
       // alert("You clicked a child div. Its parent has class: " + parentDiv.className);
       // let pDiv = `.${parentDiv.className}`;
 
 
 
       //////
       let parentsParentDiv = parentDiv.parentNode;
       alert(`parentsParentDiv className=${parentsParentDiv.className}`);
       /////////
       // Iterate through the child nodes of the parent div
      //  let textDiv = {};
      let textDiv = this.renderer.createElement('div');
       for (let i = 0; i < parentDiv.children.length; i++) {
         let child = parentDiv.children[i];
         
         // Check if the string exists in the child's text content
         // if (child.textContent.includes(searchString)) {
         if (child.className == "selected-tag-text") {
           textDiv = child;
           console.log("String found in child: " + child.textContent);
         }
       }
 
 
       ////////
 
 
       if (parentsParentDiv.contains(parentDiv)) {
         // Remove the child div from the parent
         parentsParentDiv.removeChild(parentDiv);
         ////////////////////////////////////////////////////////
 
         let allFilters = document.getElementById('all-filters').innerHTML;
         // let cancelledDivText = textDiv.innerHTML;
         let cancelledDivText = textDiv.innerHTML;
         if(allFilters.length > 0){
           // let s1 = `${cancelledDivText}`;
           let s2 = `${cancelledDivText}||`;
           if (allFilters.indexOf(s2) !== -1) {
             console.log("Substring found.");
             allFilters = allFilters.replace(s2, "");
           }
           // $('#all-filters').html(allFilters);
           // $('#all-filters').html(encodeURIComponent(allFilters));
           var dv = document.getElementById('all-filters');
           dv.innerHTML = encodeURIComponent(allFilters);
         }
         ////////////////////////////////////////////////////////
         
       }
     }
     else{
       let targetDivText = event.currentTarget.innerHTML;
       targetDivText = targetDivText.replace('&amp;', '&');
       if(this.resourcesFilters.indexOf(targetDivText) == -1){
        //  let ftls = this.resourcesFilters;
        //  ftls.push(targetDivText);
        //  setFilters(ftls);
        this.resourcesFilters.push(targetDivText);
       }
       let targetDivText2 = targetDivText;
       // var parentDiv = document.getElementById('filters-selected');
      //  let parentDiv = document.getElementsByClassName('filters-selected')[0];
      // let parentDiv = document.getElementsByClassName('filters-selected')[0];
      let parentDiv = this.el.nativeElement.querySelector('#filters-selected');
       // If the selected filter was not already selected
       // if(stringExistsInDivChildren('filters-selected', targetDivText) == false){
        //  targetDivText = targetDivText.replace('&amp;', '&');
        console.log(`this.resourcesFilters=${this.resourcesFilters}`);
        console.log(`targetDivText=${targetDivText}`);
         if(this.resourcesFilters.indexOf(targetDivText) != -1){
          console.log('got here');
         // let allFilters = document.getElementById('all-filters').innerHTML;
        //  let allFilters = document.getElementsByClassName('all-filters')[0].innerHTML;
        //  if(allFilters.length > 0){
        //    allFilters = `${allFilters}${targetDivText}||`;
        //  }
        //  else{
        //    allFilters = `${targetDivText}||`;
        //  }
         // $('#all-filters').html(allFilters);
         // $('#all-filters').html(encodeURIComponent(allFilters));
        //  var dv2 = document.getElementById('all-filters');
        //  dv2.innerHTML = encodeURIComponent(allFilters);
         
        //  const inputElement = document.getElementById('search-text');
        //  const inputValue = inputElement.value;
        // const inputValue = inputElement.innerText;
         
         // effectFilter(allFilters, page, inputValue);
         // effectFilter(allFilters, page, inputValue);
        //  this.handleKeyUp2(allFilters, page, inputValue);
        // this.handleKeyUp2(allFilters, page, inputValue);
       // if(true == true){
        //  let childDivs = parentDiv.querySelectorAll('.tags-selected');
        //  childDivs.forEach(function(div) {
        //    console.log(div.textContent); // Output the text content of each child div
        //  });
 
         // let childDivs = parentDiv.querySelectorAll('div');
         // // Get the count of divs
        //  let childDivsCount = childDivs.length + 1;
         // alert(`childDivsCount=${childDivsCount}`);
 
         ///////
         let targetDivTextLength = parseInt(targetDivText.length);
        //  let targetDivLength = parseInt((250*targetDivTextLength)/22);
        // let targetDivLength = 250*targetDivTextLength/22;
        
        let targetDivLength = targetDivTextLength; //* 10;
        // if(targetDivLength <= 10){targetDivLength *= 1.26;}
        // if(targetDivLength > 10 && targetDivLength <=20){targetDivLength *= 9;}
        // else if(targetDivLength > 20 && targetDivLength <= 30){targetDivLength *= 7;}
        // else if(targetDivLength > 30){targetDivLength *= 6;}

        // if(targetDivLength <= 10){targetDivLength = (targetDivLength * 1.26);}
        // if(targetDivLength > 10 && targetDivLength <=20){targetDivLength *= 9;}
        // else if(targetDivLength > 20 && targetDivLength <= 30){targetDivLength *= 7;}
        // else if(targetDivLength > 30){targetDivLength *= 6;}


        // targetDivLength = targetDivLength * 0.5;
        //  if(targetDivLength <= 50 && targetDivLength > 50){targetDivLength += 30;}
        //  else if(targetDivLength > 50 && targetDivLength < 69){targetDivLength += 50;}
        //  else if(targetDivLength >= 69 && targetDivLength < 100){targetDivLength = 100;}
        //  else{targetDivLength = targetDivLength;}

        // if(targetDivLength < 250){targetDivLength += 40;}
        // else if(targetDivLength > 250){targetDivLength = 250;}
        // if(targetDivLength < 250){targetDivLength += 20;}
        // else if(targetDivLength > 250){targetDivLength = 250;}

        if(targetDivLength <= 10){targetDivLength = 75.25;}
        else if(targetDivLength > 10 && targetDivLength <=20){targetDivLength = 148.9;}
        else if(targetDivLength > 20 && targetDivLength <= 30){targetDivLength = 174.7;}
        else if(targetDivLength > 30){targetDivLength = 174.4;}

       
        
        //  let itemId = `selected-tag-text-${childDivsCount}`;
         let itemWidth = `${targetDivLength}px`;
        //  if(targetDivText.indexOf("&amp;") !== -1){targetDivText = targetDivText.replace('&amp;', '');}
        //  let newFilterDiv = <div className="tags-selected" id={itemId} style={{width: {itemWidth}, display: 'inline-block'}} onClick={() => {alert('1')}}>
        //    <span className="selected-tag-text" id="selected-tag-text">{targetDivText}</span>
        //    <span className="selected-tag-cancel">&times;</span>
        //  </div>;
        //  newFilterDiv = ReactDOMServer.renderToStaticMarkup(newFilterDiv);


        //  ######################################################################
        let newFilterDiv = this.renderer.createElement('div');
        // Set properties or attributes if needed
        this.renderer.addClass(newFilterDiv, 'tags-selected');
        this.renderer.setStyle(newFilterDiv, 'width', itemWidth);
        this.renderer.setStyle(newFilterDiv, 'display', 'inline-block');

        let spacerDiv = this.renderer.createElement('div');
        this.renderer.setStyle(spacerDiv, 'height', '5px');
        this.renderer.appendChild(spacerDiv, this.renderer.createText('\u00a0\u00a0\u00a0'));
        this.renderer.appendChild(newFilterDiv, spacerDiv);


      //   <div class="tags-selected" id="k1" style="width: 100px; display: inline-block">
      //     <span class="selected-tag-text" id="selected-tag-text">Others1</span>
      //     <span class="selected-tag-cancel">&times;</span>
      //  </div>
      // <div style="height: 5px;">&nbsp;</div>
        // Create a new span element
        targetDivText = targetDivText.replace('&amp;', '&');
        let newSpan = this.renderer.createElement('span');
        // Set properties or attributes for the span
        this.renderer.addClass(newSpan, 'selected-tag-text');
        this.renderer.setAttribute(newSpan, 'id', 'selected-tag-text');
        this.renderer.appendChild(newSpan, this.renderer.createText(targetDivText));
        // Append the span to the div
        this.renderer.appendChild(newFilterDiv, newSpan);

        // Create a new span element
        let newSpan2 = this.renderer.createElement('span');
        // Set properties or attributes for the span
        this.renderer.addClass(newSpan2, 'selected-tag-cancel');
        // this.renderer.setAttribute(newSpan2, 'id', 'selected-tag-text');
        this.renderer.appendChild(newSpan2, this.renderer.createText('\u00a0\u00a0\u00a0x'));
        // Append the span to the div
        this.renderer.appendChild(newFilterDiv, newSpan2);

        // Append the new div to the container
        // this.renderer.appendChild(newFilterDiv, this.renderer.createText('Dynamically Created Div'));
        // this.renderer.appendChild(this.container.nativeElement.querySelector('#filters-selected'), newFilterDiv);
        // Append the div to the container
        this.renderer.appendChild(this.el.nativeElement.querySelector('#filters-selected'), newFilterDiv);

        //////////////////////////////////////////////////////////////////////
        // let w = this.resources;
        // w.forEach(resource => {
        //   // console.log(`resource=${JSON.stringify(resource)}`);
        //   let pcategory = resource.post_category;
        //   pcategory = pcategory.replace('&amp;', '&');
        //   console.log(`pcategory=${pcategory}`);
        //   if(this.resourcesFilters.indexOf(pcategory) != -1){
        //     // const uppercaseArray = this.resourcesFilters; //allFilters_array; //.map(element => element.toUpperCase());
        //   }
        //   else{
        //     this.resources.splice(resource, 1);
        //     console.log(`splice`);
        //   }
        // });





        // this.resources = [];
        // this.measures.forEach(resource => {
        //   let pcategory = resource.post_category;
        //   pcategory = pcategory.replace('&amp;', '&');
        //   console.log(`pcategory=${pcategory}`);
        //   console.log(`targetDivText=${targetDivText}`);
        //   if(pcategory == targetDivText){ //this.resources.indexOf(resource) == -1 &&
        //     // const uppercaseArray = this.resourcesFilters; //allFilters_array; //.map(element => element.toUpperCase());
        //     this.resources.push(resource);
        //   }
        // });


        //////////////////////////////////
          // if(post.post_title.toUpperCase().indexOf(this.searchedText.toUpperCase()) !== -1){
          let filts = [];
    
          this.measures.map(post => {
            console.log(`this.resourcesFilters=${JSON.stringify(this.resourcesFilters)}`);
            // console.log(`post=${JSON.stringify(post)}`);
            if(post.post_title.toUpperCase().indexOf(this.searchedText.toUpperCase()) !== -1){
              if(this.resourcesFilters.length > 0){
                // const uppercaseArray = this.resourcesFilters; //allFilters_array; //.map(element => element.toUpperCase());
                let uppercaseArray = [];
                this.resourcesFilters.forEach(c => {
                  uppercaseArray.push(c.trim());
                });
                console.log(`uppercaseArray=${JSON.stringify(uppercaseArray)}`);
                let pcategory = post.post_category;
                // pcategory = pcategory.replace('&amp;', '&');
                // console.log(`targetDivText=${targetDivText}`);
                // if(uppercaseArray.includes(pcategory)){
                if(uppercaseArray.indexOf(pcategory) != -1){
                  filts.push(post);
                }
                else if(uppercaseArray.indexOf('Others') != -1 && pcategory == ''){
                  filts.push(post);
                }
                else if(targetDivText.trim() == 'Others' && pcategory == ''){
                  filts.push(post);
                }
              }
              else{
                filts.push(post);
              }
            }
          });

          this.resources = filts;
          // this.measures = filts;
          // this.goToPage(1);
          this.total = filts.length;
        /////////////////////////////////
        
        






        //  ######################################################################
         // newFilterDiv = ReactDOMServer.renderToPipeableStream(newFilterDiv);
        //  var parser = new DOMParser();
         // Parse the HTML string
         // var doc = parser.parseFromString(newFilterDiv, 'text/html');
        //  var doc = parser.parseFromString(newFilterDiv, 'text/html');
         // Access the div element within the parsed document
        //  var divElement = doc.querySelector('div');
 
         //////////
         newFilterDiv.addEventListener('click', () => {
           // Handle the button click event here
           // this.setState({ message: 'Dynamically created button clicked!' });
           // alert('data3');
           newFilterDiv.style.display = 'none';
           // divElement.style.visibility = 'hidden';
           if(this.resourcesFilters.indexOf(targetDivText) != -1){
            //  let ftls = filters;
             // ftls.push(targetDivText);

             const index = this.resourcesFilters.indexOf(targetDivText);
 
             if (index !== -1) {
              this.resourcesFilters.splice(index, 1);
              let w = this.resources;
              let w2 = [];
              w.forEach(k => {
                let pcategory = k.post_category;
                pcategory = pcategory.replace('&amp;', '&');
                if(pcategory == targetDivText || pcategory == ''){
                  //this.resources.splice(k, 1);
                }
                else{
                  w2.push(k);
                }
              });
              this.resources = w2;
              console.log(`this.resourcesFilters=${this.resourcesFilters}`);
              
              if(this.resourcesFilters.length == 0){
                console.log(`searchedText=${this.searchedText}`);
                if(this.searchedText != ""){
                  let w3 = [];
                  let w4 = this.measures;
                  w4.forEach(k => {
                    
                  if(k.post_title.toUpperCase().indexOf(this.searchedText.toUpperCase()) !== -1){
                      w3.push(k);
                    }
                  });
                  this.resources = w3;
                  this.total = filts.length;
                }
                else{
                  for(let n=0; n<= 8; n++){
                    this.resources.push(this.measures[n]);
                  }
                }
             }

             }
            //  setFilters(ftls);
            //  setFilters(ftls);
             // const inputValue = e.target.value;
            //  let target = {}; //.value};
            //  target.target = {};
            //  target.target.value = "||"; // = {target: target.value};
            //  parentDiv.appendChild(divElement);
            //  handleKeyUp(target);
            //  console.log('removed');
             
           }
         });
         parentDiv.style.display = 'inline-block';
 
 
         /////////
        //  parentDiv.appendChild(divElement);
        //  parentDiv.style.marginTop = '-250px';
        //  parentDiv.style.display = 'inline-block';
       }
     }
   }

   closeClicked = () => {
    document.getElementById('id01').style.display='none';
    // var parentDiv = document.getElementById('filters-selected');
    // parentDiv.style.marginTop = '80px';
    // parentDiv.style.position = 'relative';
  }


  Group_541_ClassClicked = () => {
    document.getElementById('id01').style.display='block';
		// var parentDiv = document.getElementById('filters-selected');
		// var otherDiv = document.getElementById('all-filters');
		

    var parentDiv = this.el.nativeElement.querySelector('#filters-selected')
    var otherDiv = this.el.nativeElement.querySelector('#all-filters')
    parentDiv.innerHTML = '';
		// otherDiv.innerHTML = '';
  };


  clearAll = (action) => {
    const parentDiv = document.getElementsByClassName('filters-selected')[0];
      let filts = [];
      // parentDiv
      let i = 0;
      this.measures.map(post => {
        if(i <= 8){
          filts.push(post);
          i += 1;
        }
      });
      this.resources = filts;
      this.total = filts.length;

      if(action == 'clear'){
        parentDiv.innerHTML = '';
      }
      // else{
      //   parentDiv.innerHTML = '';
      //   categoriesList.map((category) => (
      //     // <div className="rounded-div" onClick={(e) => itemClicked(e, 'filter', page)} id={category.id}>{category.name}</div>
      //      <div>{setFilterItem(category.name)}</div>
         
      //    ));

      // }
      // setFilters([]);

  }

  openWindow = async (post_title, post_name, post_summary, post_image_url, post_category, resources, downloads, links,video) => {
    // props.updateData(
    //   post_title, post_name, post_image_url, post_category
    // );
    // setShowTargetComponent(true);
    let data =  {
      post_title: post_title,
      post_name: post_name,
      post_summary: post_summary,
      post_image_url: post_image_url,
      post_category: post_category,
      resources: resources,
      downloads: downloads,
      links: links,
      video: video
    };

    // localStorage.setItem('data', JSON.stringify(data));
    // window.open('/resource-details', '_self');
    // const jsonString = encodeURIComponent(JSON.stringify(data));
    // const {encrypted, iv} =  await this.encryptString('2486bd01-b806-42e2-b296-20122288b4b2', JSON.stringify(data));
    const key = await this.generateKey();
    const { ciphertext, iv } = await this.encryptString(key, JSON.stringify(data));
    // const jsonString = encodeURIComponent(`${ciphertext}`);
    const jsonString = encodeURIComponent(JSON.stringify(data));
    let url = `/resource-details/?d=${jsonString}&iv=${iv}`;
    window.open(url, '_self');

    
    // localStorage.setItem('data', JSON.stringify(data));
    // window.open('http://127.0.0.1:3000/details', '_self');
    // const jsonString = encodeURIComponent(JSON.stringify(data));
    // window.open(`http://127.0.0.1:3000/details/?json=${jsonString}`, '_self');

    // let url = `https://measures.scienceofbehaviorchange.org:3004/details/?json=${jsonString}`;
    // window.open(url, '_self');
  }

  async encryptString(key, plaintext) {
    const encoder = new TextEncoder();
    const encodedData = encoder.encode(plaintext);
  
    const iv = crypto.getRandomValues(new Uint8Array(12)); // Generate a random IV
    const ciphertext = await crypto.subtle.encrypt(
      { name: 'AES-GCM', iv },
      key,
      encodedData
    );
  
    return { ciphertext, iv };
  }

  // Function to generate a random 256-bit key
async generateKey() {
  const key = await crypto.subtle.generateKey(
    { name: 'AES-GCM', length: 256 },
    true,
    ['encrypt', 'decrypt']
  );
  return key;
}

private loadScripts() {
  // You can load multiple scripts by just providing the key as argument into load method of the service
  // this.dynamicScriptLoader.load('chartjs','random-num').then(data => {
  this.dynamicScriptLoader.load('js').then(data => {
    // Script Loaded Successfully
  }).catch(error => console.log(error));
}

toUpper = (text) => {
  return text.toUpperCase();
}

getTrimmedPostSummary = (text) => {
  // if(text.length > 288){
  //     return `${text.slice(0, 288)}...`;
  // }
  if(text.length > 286){
    return `${text.slice(0, 286)}...`;
}
  // if(text.length > 480){
  //   return `${text.slice(0, 480)}...`;
  // }
  else{
    return text;
  }
}

getMarginTop = (title) => {
  if(title.length <= 100){return '15px';}
  else{return '80px';}
}

getTitleLength = () => {
  return title.length;
}

getUrlEncoded = (post_title, post_name, post_summary, post_image_url, post_category, resources, downloads, links,video) => {
  post_summary = post_summary.replace('\n', '<br /><br />');
  post_summary = post_summary.replace("\n", '<br /><br />');
  // post_summary = post_summary.replace('\r\n\r\n', '<br /><br />');
  post_summary = post_summary.replace('Nielsen L, Riddle M,', '<br /><br />Nielsen L, Riddle M,');
  post_summary = post_summary.replace('\t', ' ');
  console.log(post_summary);
  let data =  {
    post_title: post_title,
    post_name: post_name,
    post_summary: post_summary,
    post_image_url: post_image_url,
    post_category: post_category,
    resources: resources,
    downloads: downloads,
    links: links,
    video: video
  };
  /////
  // const jsonString = encodeURIComponent(JSON.stringify(data));
  // let serverUrl = 'https://measures.scienceofbehaviorchange.org';
  let serverUrl = 'http://127.0.0.1:4200';
  // let url = `${serverUrl}/resource-details/?d=${jsonString}&iv=iv`;
  let url = `${serverUrl}/resource-details`;

  /////
  console.log(`data=${data}`);
  localStorage.setItem('details', JSON.stringify(data));
  // this.dataService.updateData(JSON.stringify(data));
  
  // return url;
  window.open('/resource-details', '_self');

}


}
