import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {UploadFileService} from '../../services/uploadfile.service';

@Component({
  selector: 'app-uploadfile',
  templateUrl: './uploadfile.component.html',
  styleUrls: ['./uploadfile.component.scss']
})
export class UploadfileComponent implements OnInit {
  @Input() inputName: string;
  @Output() uploadedFile = new EventEmitter<boolean>();
  file_path: string;

  constructor(
    private uploadFile: UploadFileService,
  ) {
  }

  ngOnInit() {
  }

  onFileChange(event) {
    const reader = new FileReader();
    const formData = new FormData();

    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      formData.append('file', file);
      formData.append('file_description', 'access_measure_file');
      formData.append('file_type', 'measure_file');
      const url = 'uploads/upload/';
      this.uploadFile.uploadfile(url, formData)
        .subscribe(
          data => {
            // this.file_path = data.file_path;
            this.file_path = data.url;
            this.uploadedFile.emit(data);
          },
          error => {
            console.log(error);
          });
    }
  }
}
