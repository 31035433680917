import {Component, OnInit, Input} from '@angular/core';
// import {ApirequestService} from '../../../../../../shared/services/apirequest.service';
import {MatTableDataSource} from '@angular/material';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment/moment';
import {Router, ActivatedRoute} from '@angular/router';
import {first} from 'rxjs/operators';
import {FileService} from 'src/app/shared/services/file.service';
import * as _ from 'lodash';
import {MatSnackBar} from '@angular/material';
import choice from '../../../../../shared/choices';

import { Measure } from '../../measures/measure-form/model/measure.model'; // Import the user model
import {ApirequestService} from '../../../../../shared/services/apirequest.service';
import {AlertService} from '../../../../../shared/services/alert.service';
import {CustomSnackBarComponent} from '../../../../../shared/common/custom-snack-bar/custom-snack-bar.component';
import { StudiesSharedService } from '../../measures/measure-form/studies-shared-service/studies-shared.service';


@Component({
  selector: 'app-dashboard-institution-form',
  templateUrl: './institution-form.component.html',
  styleUrls: ['./institution-form.component.css']
})
export class RevisedDashboardInstitutionFormComponent implements OnInit {


  loading: boolean;
  start_date: string = '';
  end_date: string = '';
  url: string;
  topHeaders: string[] = ['channelGrouping', 'acquisition', 'behavior'];
  displayedColumns: string[] = ['totalUsers', 'usersWhoEngagedWithPlatform', 'newUsers', 'sessions', 'avgSessionDuration', 'bounceRate'];
  alldisplayedColumns: string[] = ['channelGrouping', 'totalUsers', 'usersWhoEngagedWithPlatform', 'newUsers', 'sessions', 'avgSessionDuration', 'bounceRate'];
  dataSource: MatTableDataSource<any>;
  domainsFiltersList = [];
  institutionsFilterList = [];

  measurementModeFilters = [];

  languagesFiltersList = [];
  durationFilters = [];
  databaseFilters = [];
  choices = choice;

  domains_url_filters = 'measures/domains_list/';
  behavior_url_measurefilters = 'measures/behaviors_list/';
  population_url_measurefilters = 'measures/population_list/';
  // Measurement mode
  language_url_measurefilters = 'measures/languages_list/';
  // duration
  // measure_source

  selectedTitle: any;
  selectedInstitution: any;
  selectedDomain: any;
  selectedRole: any;
  
  filter_url = {};
  page: number;
  total: number;
  page_size: number;
  total_pages: number;


  measures: any[];

  items = ['All', 'Pending', 'Published', 'Draft'];
  // activeIndex: number | null = null;
  activeIndex: number = 0; // Set default to 0 (first item)
  measureForm: FormGroup;
  formSubmitted: boolean;
  loadedmeasure: any;
  isNewMeasure: boolean;
  submitted: boolean;
  @Input() id: string;
  @Input() sourceData: any;
  isSwitchOn: boolean = false; // Default state of the toggle switch

  url_institution = 'users/institution/create/';

  constructor(
    private apirequestService: ApirequestService,
    private router: Router,
    private fileService: FileService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public alertService: AlertService,
    private studiesSharedService: StudiesSharedService
  ) {
  }

  onSubmit() {
    this.formSubmitted = true;
    console.log('submit called');
    let measureData = this.measureForm.value;
    // if(this.id == '' || this.id == undefined){
    //   measureData['citations'] = 'citations';
    //   measureData['id'] = 'id';
    // }

    console.log(measureData);

    let theForm = this.measureForm.value;
      // theForm['domains'].value = [theForm['domains']];
      try{
        if(this.isSwitchOn == true){
          theForm.is_active = true;
        }
        else{
          theForm.is_active = false;
        }
        
      }
      catch(e){}
    
    if (this.measureForm.valid) { // Check if the form is valid
      // Process form submission
      this.formSubmitted = false;
      console.log(measureData);

      this.submitted = true;
      // stop here if form is invalid
      if (this.measureForm.invalid) {
        return;
      }
      this.loading = true;
      // let theForm = this.measureForm.value;
      // // theForm['domains'].value = [theForm['domains']];
      // try{
      //   if(this.isSwitchOn == true){
      //     theForm.is_active = true;
      //   }
      //   else{
      //     theForm.is_active = false;
      //   }
        
      // }
      // catch(e){}
      // try{
      //   theForm.behaviors = theForm.behaviors.split(',').map(behavior => ({ id: behavior.trim()}));
      // }
      // catch(e){}
      
      console.log(`theForm=${JSON.stringify(theForm)}`);
      if (this.measureForm.get('id').value === '') {
        console.log('Calling create');
        const url = 'users/institution/create/';
        this.apirequestService.post(url, theForm)
          .pipe(first())
          .subscribe(
            data => {
              this.id = data.id;
              this.snackBar.openFromComponent(CustomSnackBarComponent, {
                data: {
                  message: 'Institution successfully created.',
                  action: 'Success'
                },
                duration: 3 * 1000,
                panelClass: ['transparent-snack-bar']
              });
              if(this.sourceData != undefined){ // || this.sourceData.source_id != undefined
                this.studiesSharedService.sendData(data);
                let dialogRef = this.sourceData.dialogRef;
                dialogRef.close();
              }
              this.loading = false;
              // this.viewStudies.emit(this.id);
            },
            error => {
              this.alertService.error(error, '');
              this.loading = false;
            });
      } else {
        console.log('Calling update');
        const url = 'users/institution/update/'+this.id+'/';
        // theForm.patchValue({
        //   institution: this.selectedInstitution // Update the value of myField
        // });
        theForm['institution'] = this.selectedInstitution;
        this.apirequestService.post(url, theForm)
          .pipe(first())
          .subscribe(
            data => {
              this.snackBar.openFromComponent(CustomSnackBarComponent, {
                data: {
                  message: 'Institution successfully updated',
                  action: 'Success'
                },
                duration: 3 * 1000,
                panelClass: ['transparent-snack-bar']
              });
              this.loading = false;
            },
            error => {
              this.alertService.error(error, '');
              this.loading = false;
            });
      }
      
      // Proceed with your logic
      // console.log(measureData);
    }
    // else{
    //     // Form is invalid, handle errors
    //     // console.log('Form has errors');
    //     // const titleError = this.measureForm.get('title').errors;
    //     const controlErrors = this.getControlErrors();
    //     console.log('Form has errors:', controlErrors);
    //     return;
    // }
  }

  private getControlErrors(): { [key: string]: any } {
    const errors = {};
    Object.keys(this.measureForm.controls).forEach(key => {
      const control = this.measureForm.get(key);
      if (control.errors != null) {
        errors[key] = control.errors;
      }
    });
    return errors;
  }

  private getFormData() {
    if (this.id != '' || this.id != undefined) {
      this.isNewMeasure = false;
      const url = 'users/institution-details/' + this.id + '/';
      var can_edit_ = false;
      this.apirequestService.get(url)
        .subscribe(
          res => {
            // res.domains = res.domains[0];
            this.measureForm.patchValue(res, {onlySelf: true});
            if(res.is_active == true){
              this.isSwitchOn = true;
            }
            
            console.log(`this.measureForm.=${JSON.stringify(this.measureForm.value)}`);
            // this.setTagetpopulationData(res);
            this.loadedmeasure = res;
          },
          error => {
            console.log(error);
          });
    }
  }

  ngOnInit() {
     // let today = new Date();
     this.isNewMeasure = true;
     this.submitted = false;
     this.page = 1;
     this.total = 0;
     this.page_size = 20;

     this.route.params.subscribe(params => {
        this.id = params['id'] || '';
      });
     
    this.getFilters();
    this.createForm();
    if ((this.id != '' || this.id != undefined) && this.id != 'new') {
      this.getFormData();
    }

    // this.createForm();
    this.getFormData();

  }

  createForm() {
    this.measureForm = this.fb.group({
      id: '',
      name: ['', Validators.required],
      address: ['', Validators.required],
      website: ['', Validators.required],
      email: ['', Validators.required],
      is_active: '' //['', Validators.required],
      
    });

  }


  getFilters(){
    
    this.apirequestService.get(this.domains_url_filters)
    .subscribe(
      res => {
        this.domainsFiltersList = res.results;
      },
      error => {
        console.log(error);
      });

      this.apirequestService.get(this.url_institution)
      .subscribe(
        res => {
          this.institutionsFilterList = res.results;
        },
        error => {
          console.log(error);
        });

  
  }

  onSelectChange(item_name: string, event: any){
    const selectedValue = event.target.value;
    if(item_name == 'title'){
      this.selectedTitle = selectedValue;
    }
    else if(item_name == 'institution'){
      this.selectedInstitution = selectedValue;
    }
    else if(item_name == 'domains'){
      this.selectedDomain = selectedValue;
    }
    else if(item_name == 'role'){
      this.selectedRole = selectedValue;
    }

  }


  formatDate(inputDate) {
    // Create a new Date object from the input date string
    const date = new Date(inputDate);

    // Extract the day, month, and year from the date object
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month starts from 0
    const year = date.getFullYear();

    // Pad day and month with leading zeros if necessary
    const paddedDay = day < 10 ? '0' + day : day;
    const paddedMonth = month < 10 ? '0' + month : month;

    // Format the date as "DD/MM/YYYY"
    const formattedDate = `${paddedDay}/${paddedMonth}/${year}`;

    return formattedDate;
}

isOdd(num: number): boolean {
  return num % 2 !== 0;
}

isMultipleOf4(number) {
  return number % 4 === 0;
}

onKeyUp(event: any) {
  // You can access the value of the input field using event.target.value
  const enteredValue = event.target.value;
  console.log('Entered value:', enteredValue);
  
  // Here you can perform any actions based on the entered value
  // this.searchTerm = enteredValue;
}

filter_data(){
}

handleClick(index: number) {
  this.activeIndex = index;
  // this.selectedStatus = this.items[index];
}

toggleSwitch() {
  this.isSwitchOn = !this.isSwitchOn;
}


}

