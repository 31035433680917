import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import * as _ from 'lodash';

import {ApirequestService} from '../../shared/services/apirequest.service';
import {ApirequestdetailService} from '../../shared/services/apirequestdetail.service';
// import * as js from './css/resources.js';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  // styleUrls: ['./css/resources.component.scss'],
})
export class ResourcesComponent implements OnDestroy, OnInit {
  measures = [];
  page: number;
  total = 0;
  total_pages = 0;
  thefilters = {};
  page_size = 50;
  sort_fields: any;
  search_data: any;
  route_stage: string;
  url_params;
  access_measure: boolean;
  loading = true;
  subscription: Subscription;

  collapse = 'closed';
  btncollapse = 'not_clicked';
  show = false;
  showText = false;
  isMainmeasuredetails = false;
  search_input: string;
  imageUrl: string = 'assets/resources-css/Mask_Group_3.png';


  constructor(
    private apirequestService: ApirequestService,
    private apirequestdetailService: ApirequestdetailService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2
  ) {
    this.subscription = this.apirequestdetailService.getFilteredData()
      .subscribe(
        data => {
          this.thefilters = data;
          this.page = 1;
          this.getDataByPage();
        },
        error => {
          console.log(error);
        });
  }

  
  ngOnInit() {
    // this.route.queryParams.subscribe(params => {
    //   this.page = params['page'] || '1';
    //   this.thefilters = {
    //     'domains': params['domains'] || 'All',
    //     'type': params['type'] || 'All',
    //     'duration': params['duration'] || 'All',
    //     'target_population': params['target_population'] || 'All',
    //     'stage': params['stage'] || 'All',
    //     'measure_source': 'SOBC',
    //   };
    //   this.search_data = {'data': params['data'] || ''};
    //   this.sort_fields = {'sort_by': params['sort_by'] || 'popular'};
    // });
    // this.getDataByPage();

    this.loadScript('assets/resources-css/resources.js').then(() => {
      // Script has been loaded and executed
    }).catch((error) => {
      console.error('Error loading script:', error);
    });

    const linkElement = this.renderer.createElement('link');
    this.renderer.setAttribute(linkElement, 'rel', 'stylesheet');
    this.renderer.setAttribute(linkElement, 'type', 'text/css');
    this.renderer.setAttribute(linkElement, 'id', 'applicationStylesheet');
    this.renderer.setAttribute(linkElement, 'href', 'assets/resources-css/Resources.css'); // Replace with the actual path

    // Append the link element to the <head> of the document
    this.renderer.appendChild(document.head, linkElement);
  }

  getDataByPage() {
    this.url_params = this.thefilters;
    _.merge(this.url_params, {'page': this.page.toString(), 'page_size': this.page_size});
    _.merge(this.url_params, this.search_data);
    _.merge(this.url_params, this.sort_fields);

    const params = new HttpParams({
      fromObject: this.url_params
    });
    this.apirequestService.get('measures/search/', params)
      .subscribe(
        res => {
          this.total = res.count;
          this.measures = res.results;
          this.page_size = res.page_size;
          this.page = res.current_page;
          this.total_pages = res.total_pages;
          this.loading = false;
          this.router.navigate([], {relativeTo: this.route, queryParams: this.url_params});
        },
        error => {
          console.log(error);
        });
    this.search_input = this.search_data.data;
  }

  loadPageSize(n: number): void {
    this.page = 1;
    this.page_size = n;
    this.getDataByPage();
  }

  goToPage(n: number): void {
    this.page = n;
    this.getDataByPage();
  }

  onNext(): void {
    this.page++;
    this.getDataByPage();
  }

  onPrev(): void {
    this.page--;
    this.getDataByPage();
  }

  toggleCollapse() {
    this.show = !this.show;
    this.btncollapse = this.btncollapse === 'clicked' ? 'not_clicked' : 'clicked';
    this.collapse = this.collapse === 'closed' ? 'open' : 'closed';
  }

  sortMeasures(sort) {
    this.sort_fields = {'sort_by': sort};
    this.getDataByPage();
  }

  searchMeasure(search) {
    this.search_data = {'data': search};
    this.getDataByPage();
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }
  private loadScript(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      this.renderer.appendChild(document.body, script);
    });
  }
}
