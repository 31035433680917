import {Component, OnInit, Input} from '@angular/core';
import {ApirequestService} from '../../../../../shared/services/apirequest.service';
import {MatTableDataSource} from '@angular/material';
import * as moment from 'moment/moment';
import {Router} from '@angular/router';
import {FileService} from 'src/app/shared/services/file.service';
import * as _ from 'lodash';
import choice from '../../../../../shared/choices';
import { StudiesSharedService } from './../../measures/measure-form/studies-shared-service/studies-shared.service';

import {CustomSnackBarComponent} from './../../../../../shared/common/custom-snack-bar/custom-snack-bar.component';
import {ConfirmationDialogService} from './../../../../../shared/confirmation-dialog/confirmation-dialog.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {AlertService} from './../../../../../shared/services/alert.service';

@Component({
  selector: 'app-dashboard-all-studies',
  templateUrl: './dashboard-all-studies.component.html',
  styleUrls: ['./dashboard-all-studies.component.css']
})
export class RevisedDashboardAllStudiesComponent implements OnInit {


  loading: boolean;
  start_date: string = '';
  end_date: string = '';
  url: string;
  topHeaders: string[] = ['channelGrouping', 'acquisition', 'behavior'];
  displayedColumns: string[] = ['totalUsers', 'usersWhoEngagedWithPlatform', 'newUsers', 'sessions', 'avgSessionDuration', 'bounceRate'];
  alldisplayedColumns: string[] = ['channelGrouping', 'totalUsers', 'usersWhoEngagedWithPlatform', 'newUsers', 'sessions', 'avgSessionDuration', 'bounceRate'];
  dataSource: MatTableDataSource<any>;
  domainsFiltersList = [];
  behaviorsFiltersList = [];
  populationFiltersList = [];

  measurementModeFilters = [];

  languagesFiltersList = [];
  durationFilters = [];
  databaseFilters = [];
  choices = choice;

  domains_url_filters = 'measures/domains_list/';
  behavior_url_measurefilters = 'measures/behaviors_list/';
  population_url_measurefilters = 'measures/population_list/';
  // Measurement mode
  language_url_measurefilters = 'measures/languages_list/';
  // duration
  // measure_source

  selectedDomain: string;
  selectedBehaviour: string;
  selectedPopulation: string;
  selectedMeasurementMode: string;
  selectedLanguage: string;
  selectedDuration: string;
  selectedDatabase: string;
  selectedUploadedBy: string;
  
  selectedStatus: string;



  ///
  selectedTypeOfStudy: string;
  selectedSampleSize: string;
  searchTerm: string;


  
  filter_url = {};
  page: number;
  total: number;
  page_size: number;
  total_pages: number;

  loggedInUser: any;


  measures: any[];

  // items = ['All', 'Pending', 'Published', 'Draft']; //, 'Returned'];
  items = ['All', 'Published', 'Pending', 'Draft']; 
  // activeIndex: number | null = null;
  activeIndex: number = 0; // Set default to 0 (first item)
  @Input() sourceData: any;
  loading_submit: boolean;


  constructor(
    private apirequestService: ApirequestService,
    private router: Router,
    private fileService: FileService,
    private studiesSharedService: StudiesSharedService,
    public snackBar: MatSnackBar,
    private confirmationDialogService: ConfirmationDialogService,
    public alertService: AlertService,
  ) {
  }

  ngOnInit() {
     // let today = new Date();
     this.page = 1;
     this.total = 0;
     this.page_size = 20;
     this.searchTerm = '';
     this.selectedDomain = 'All';
     this.selectedBehaviour = 'All';
     this.selectedPopulation = 'All';
     this.selectedMeasurementMode = 'All';
     this.selectedLanguage = 'All';
     this.selectedDuration = 'All';
     this.selectedDatabase = 'All';
     this.selectedUploadedBy = 'All';
     this.selectedStatus = 'All';
     let user = JSON.parse(localStorage.getItem('auth.user'));
     this.loggedInUser = user;
     

    //  const currentDate = new Date();
    // const dateString = currentDate.toISOString();
    //  let today = new Date(dateString);
    //  var lastMonth = new Date(today);
    //  lastMonth.setDate(lastMonth.getDate() - 30);
    //  this.start_date = lastMonth.toISOString().slice(0, 10);
    //  this.end_date = today.toISOString().slice(0, 10);
    // this.dataSource = new MatTableDataSource();
    // this.getFilters();
    this.getData();

  }


  getData() {
    this.loading = true;
    if(this.selectedTypeOfStudy == undefined){
      this.selectedTypeOfStudy = 'All';
    }
    if(this.selectedSampleSize == undefined){
      this.selectedSampleSize = 'All';
    }
    
    let params = `type_of_study=${this.selectedTypeOfStudy}&sample_size=${this.selectedSampleSize}&page=${this.page}&page_size=${this.page_size}&data=${this.searchTerm}&uploaded_by=${this.selectedUploadedBy}&status=${this.selectedStatus}`;
      this.apirequestService.get('study_evidence/study_evidence/?'+params)
        .subscribe(
          res => {
            this.total = res.count;
            this.measures = res.results;
            // this.page_size = res.page_size;
            this.page = res.current_page;
            this.total_pages = res.total_pages;
            this.loading = false;
          },
          error => {
            console.log(error);
            this.loading = false;
          });
  }

  getData2() {
    if(this.selectedTypeOfStudy == undefined){
      this.selectedTypeOfStudy = 'All';
    }
    if(this.selectedSampleSize == undefined){
      this.selectedSampleSize = 'All';
    }
    let params = `type_of_study=${this.selectedTypeOfStudy}&sample_size=${this.selectedSampleSize}&page=${this.page}&page_size=${this.page_size}&data=${this.searchTerm}&uploaded_by=${this.selectedUploadedBy}&status=${this.selectedStatus}`;
      this.apirequestService.get('study_evidence/study_evidence/?'+params)
        .subscribe(
          res => {
            this.total = res.count;
            this.measures = res.results;
            // this.page_size = res.page_size;
            this.page = res.current_page;
            this.total_pages = res.total_pages;
          },
          error => {
            console.log(error);
          });
  }

  navigateTo(link: any) {
    console.log(link.toLowerCase().replace(/\s/g, ''));
    this.router.navigate(['/statistics/traffic/' + link.toLowerCase().replace(/\s/g, '')]);
  }

  exportData() {
    var pageSize = 10000;
    this.url = 'analytics/channels/?start_date=' + this.start_date + '&end_date=' + this.end_date + '&export=True';
    this.loading = true;
    this.apirequestService.get(this.url)
      .subscribe(
        data => {
          this.loading = false;
          this.fileService.downloadFile(data.file_url);
        },
        error => {
          console.log(error);
          this.loading = false;

        });
  }

  getFilters(){
    this.apirequestService.get(this.domains_url_filters)
    .subscribe(
      res => {
        this.domainsFiltersList = res.results;
      },
      error => {
        console.log(error);
      });

  this.apirequestService.get(this.behavior_url_measurefilters)
    .subscribe(
      res => {
        this.behaviorsFiltersList = res.results;
      },
      error => {
        console.log(error);
      });

  this.apirequestService.get(this.language_url_measurefilters)
    .subscribe(
      res => {
        this.languagesFiltersList = res.results;
      },
      error => {
        console.log(error);
      });

    this.apirequestService.get(this.population_url_measurefilters)
    .subscribe(
      res => {
        this.populationFiltersList = res.results;
      },
      error => {
        console.log(error);
      });
  }

  onSelectChange(item_name: string, event: any){
    const selectedValue = event.target.value;
    if(item_name == 'domains'){
      this.selectedDomain = selectedValue;
    }
    else if(item_name == 'behaviors'){
      this.selectedBehaviour = selectedValue;
    }
    else if(item_name == 'target_population'){
      this.selectedPopulation = selectedValue;
    }
    if(item_name == 'measurement_mode'){
      this.selectedMeasurementMode = selectedValue;
    }
    else if(item_name == 'language'){
      this.selectedLanguage = selectedValue;
    }
    else if(item_name == 'duration'){
      this.selectedDuration = selectedValue;
    }
    if(item_name == 'database'){
      this.selectedDatabase = selectedValue;
    }
    else if(item_name == 'uploaded_by'){
      this.selectedUploadedBy = selectedValue;
    }
    else if(item_name == 'type_of_study'){
      this.selectedTypeOfStudy = selectedValue;
    }

    this.getData2();
  }


  formatDate(inputDate) {
    // Create a new Date object from the input date string
    const date = new Date(inputDate);

    // Extract the day, month, and year from the date object
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month starts from 0
    const year = date.getFullYear();

    // Pad day and month with leading zeros if necessary
    const paddedDay = day < 10 ? '0' + day : day;
    const paddedMonth = month < 10 ? '0' + month : month;

    // Format the date as "DD/MM/YYYY"
    const formattedDate = `${paddedDay}/${paddedMonth}/${year}`;

    return formattedDate;
}

isOdd(num: number): boolean {
  return num % 2 !== 0;
}

isMultipleOf4(number) {
  return number % 4 === 0;
}

onKeyUp(source: string, event: any) {
  // You can access the value of the input field using event.target.value
  const enteredValue = event.target.value;
  console.log('Entered value:', enteredValue);
  
  // Here you can perform any actions based on the entered value
  if(source == 'sample_size'){
    this.selectedSampleSize = enteredValue;
  }
  else if(source == 'search_term'){
    this.searchTerm = enteredValue;
  }

  this.getData2();
}

filter_data(){
  this.getData2();
}

// handleClick(index: number) {
//   this.activeIndex = index;
//   this.selectedStatus = this.items[index];
//   this.getData2();
// }

handleClick(index: number) {
  this.activeIndex = index;
  this.selectedStatus = this.items[index];
  this.getData2();
}
getItem(item: string, i: number){
  if(i < this.items.length - 1){
    return ` |`;
  }
  return ``;
}

addStudyToMeasure(study: any){
  console.log('ADD CLICKED');
  let measureId = this.sourceData.source_id;
  this.studiesSharedService.sendData(study);
  let dialogRef = this.sourceData.dialogRef;
  dialogRef.close();
}

saveAndPublish(destination, action='APPROVE', id) {
  this.loading_submit = true;
  // this.submitData('Submit');
  this.loading_submit = false;
  // this.router.navigate(['/dashboard/measure/' + this.id]);
  let msg1 = 'Confirm pulling down study';
  let msg2 = 'Are you sure you want to pull down this study?';
  let msg3 = 'Yes pull down';

  if(action == 'PUBLISH'){
    msg1 = 'Confirm publishing study';
    msg2 = 'Are you sure you want to publish this study?';
    msg3 = 'Yes publish';

  }
  this.confirmationDialogService.confirm(msg1, msg2, msg3)
    .then((confirmed) => {
      if (confirmed == true) {
        let data = {
          'study_id': id,
        };
        if(action == 'PULL_DOWN'){
          data['action'] = 'PULL_DOWN';
        }
        else if(action == 'PUBLISH'){
          data['action'] = 'PUBLISH';
        }
        const url = 'study_evidence/submit_for_approval/';
        this.loading_submit = true;
        this.apirequestService.post(url, data)
          // .pipe(first())

          .subscribe(
            data => {
              // this.id = data.id;
              this.snackBar.openFromComponent(CustomSnackBarComponent, {
                data: {
                  message: data == 'Study successfully pulled down.'? 'Study successfully pulled down.': 'Study successfully published',
                  action: 'Success'
                },
                duration: 3 * 1000,
                panelClass: ['transparent-snack-bar']
              });
              this.loading_submit = false;
              this.getData();
              // this.router.navigate(['/dashboard/measure/' + this.id]);
            },
            error => {
              this.alertService.error(error, '');
              this.loading_submit = false;
            });
      }
    });
}


formatAuthors(authors: any){
  let allAuthors = ""
  try{
    if(authors != '' && authors != undefined){
      const validJsonString = authors.replace(/'/g, '"');
      // Parse the JSON string into an array of objects
      const authorsJson = JSON.parse(validJsonString);
      // allAuthors = authorsJson;
      authorsJson.forEach(curr => {
        if(allAuthors.length > 0){
          allAuthors = `${allAuthors}, ${curr.firstName} ${curr.lastName}`;
        }
        else{
          allAuthors = `${curr.firstName} ${curr.lastName}`;
        }
      });
      console.log(`Updated authors=${JSON.stringify(allAuthors)}`);
    }
  }catch(e){
    console.log(`Error updating authors=${e}`);
  }
  return allAuthors;
}


}

