import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {SharedModule} from '../../shared/shared.module';

import {CitationsDialogComponent, MeasureApprovalComponent,} from './measureapproval.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    SharedModule,
  ],
  declarations: [
    CitationsDialogComponent,
    MeasureApprovalComponent,
  ],
  entryComponents: [
    CitationsDialogComponent
  ]
})
export class MeasureApprovalModule {
}
