import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DynamicFormModule} from './common/dynamic-form/dynamic-form.module';
import {CustomMaterialModule} from './custom-material/custom-material.module';
import {AppCommonModule} from './common/common.module';

import {AuthGuard} from './_guards/auth.guard';

import {AuthenticationService} from './services/authentication.service';
import {ApirequestService} from './services/apirequest.service';
import {ApirequestdetailService} from './services/apirequestdetail.service';
import {UploadFileService} from './services/uploadfile.service';
import {GlobalService} from './services/global.service';


import {EmailDirective} from './directives/email.directive';
import {CompareDirective} from './directives/compare.directive';
import {FileDirective} from './directives/file.directive';
import {TrueFalseValueDirective} from './directives/true-false-value.directive';
import {EventEmitterService} from './services/event-emitter.service';
import { ReplaceTextPipe } from './pipes/replace-text.pipe';
import {DynamicScriptLoaderService} from './services/DynamicScriptLoaderService';


@NgModule({
  imports: [
    CommonModule,
    CustomMaterialModule,
    AppCommonModule,
    DynamicFormModule
  ],
  declarations: [
    EmailDirective,
    TrueFalseValueDirective,
    FileDirective,
    CompareDirective,
    ReplaceTextPipe,
  ],
  exports: [
    CustomMaterialModule,
    DynamicFormModule,
    AppCommonModule,
    ReplaceTextPipe,
  ],
  providers: [
    ApirequestService,
    UploadFileService,
    ApirequestdetailService,
    AuthenticationService,
    AuthGuard,
    GlobalService,
    EventEmitterService,
    DynamicScriptLoaderService,
  ],
})
export class SharedModule {
}
