import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

import {ApirequestService} from './apirequest.service';
import {AppConfig} from '../../app-config';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {
  constructor(
    private appConfig: AppConfig,
    private http: HttpClient,
    private apiRequest: ApirequestService
  ) {
  }

  getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    const token = this.apiRequest.getToken();
    if (token !== null) {
      headers = headers.append('Authorization', 'Bearer ' + token);
    }
    return headers;
  }

  uploadfile(urlEnd: string, body: FormData): Observable<any> {
    const uri = `${this.appConfig.baseApiPath}${urlEnd}`;
    return this.http.post(uri, body, {headers: this.getHeaders()});
  }
}
