import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';

import {SharedModule} from '../../shared/shared.module';

import {AllMeasuresComponent} from './allmeasures.component';
import {MeasuresfiltersComponent} from './measuresfilters/measuresfilters.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
  ],
  declarations: [
    AllMeasuresComponent,
    MeasuresfiltersComponent,
  ],
  exports: [
    AllMeasuresComponent
  ]
})
export class MeasuresAllModule {
}
