import {Component, OnInit, Input} from '@angular/core';

import {ApirequestService} from '../../../shared/services/apirequest.service';
import {ResearcherDashboardModel} from '../../../shared/models/researcher_dashboard';



@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  // dashboard_details: ResearcherDashboardModel;
  tabs = [
    { label: 'Tab 1', content: 'Content for Tab 1' },
    { label: 'Tab 2', content: 'Content for Tab 2' },
    // Add more tab objects as needed
  ];
  // @Input() dashboard_details: ResearcherDashboardModel[];
  @Input() dashboard_details: any[];
  @Input() title: string;

  constructor(
    private apirequestService: ApirequestService,
  ) {
  }

  ngOnInit() {
    // const url = 'measures/dashboard/';
    // this.apirequestService.get(url)
    //   .subscribe(
    //     data => {
    //       // this.dashboard_details = data;
    //       this.dashboard_details = {
    //         // my_draft_measures: '',
    //         my_published_measures: '',
    //         my_measures_pending_approval: '',
    //         validated_measures_count: '',
    //         influenced_measures_count: '',
    //         measured_measures_count: '',
    //         identified_measures_count: '',
    //         user_role: '',
    //         last_draft_measure_date: '',
    //         measure_pending_approal_days:'',
    //         last_publish_measure_date:'',
    //         my_collections_count:'',
    //         pending_review: ''
    //       };
    //     },
    //     error => {
    //       console.log(error);
    //     });
  }

}
