import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import {ApirequestService} from '../../../shared/services/apirequest.service';
import {ApirequestdetailService} from '../../../shared/services/apirequestdetail.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.css']
})
export class HeaderSearchComponent implements OnInit {
  page: number;
  sort_fields: any;
  search_data: any;
  thefilters = {};
  url_params;
  page_size = 50;
  measures = [];
  total = 0;
  total_pages = 0;
  loading = true;
  search_input: string;

  constructor(
    private route: ActivatedRoute,
    private apirequestService: ApirequestService,
    private apirequestdetailService: ApirequestdetailService,
    private router: Router,
  ) {
  }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.page = params['page'] || '1';
      this.thefilters = {
        'domains': params['domains'] || 'All',
        'type': params['type'] || 'All',
        'duration': params['duration'] || 'All',
        'target_population': params['target_population'] || 'All',
        'stage': params['stage'] || 'All',
        'measure_source': params['measure_source'] || 'All',
      };
      this.search_data = {'data': params['data'] || ''};
      this.sort_fields = {'sort_by': params['sort_by'] || 'popular'};
    });
  }

  getDataByPage(type = 'measures') {
    this.url_params = this.thefilters;
    _.merge(this.url_params, {'page': this.page.toString(), 'page_size': this.page_size});
    _.merge(this.url_params, this.search_data);
    _.merge(this.url_params, this.sort_fields);

    const params = new HttpParams({
      fromObject: this.url_params
    });
    if (type == 'measures') {
      this.apirequestService.get('measures/search/', params)
        .subscribe(
          res => {
            this.total = res.count;
            this.measures = res.results;
            this.page_size = res.page_size;
            this.page = res.current_page;
            this.total_pages = res.total_pages;
            this.loading = false;
            this.router.navigate([], {relativeTo: this.route, queryParams: this.url_params});
          },
          error => {
            console.log(error);
          });
    }
  }

  searchMeasure(search) {
    this.search_data = {'data': search};
    this.getDataByPage();
  }

}
