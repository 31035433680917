import {RouterModule, Routes} from '@angular/router';
// import {ResourcesComponent} from './pages/resources/resources.component';

const appRoutes: Routes = [
  {path: '**', redirectTo: ''},
  // {path: 'resources', component: ResourcesComponent},
];

export const AppRoutingModule = RouterModule.forRoot(appRoutes, {
  scrollPositionRestoration: 'disabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 150] // [x, y]
});
