import {Injectable} from '@angular/core';
import * as moment from 'moment/moment';

@Injectable({
  providedIn: 'root'
})
export class DatesService {

  constructor() {

  }

  public getStartDate() {
    var start_date = moment(moment().subtract(30, 'days')).format('YYYY-MM-DD');
    return start_date;

  }


  public getEndDate() {
    var end_date = moment(moment().subtract(1, 'days')).format('YYYY-MM-DD');
    return end_date;

  }

  public formatDate(date) {
    var formattedDate = moment(date).format('YYYY-MM-DD');
    return formattedDate;

  }
}
