import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../shared/services/authentication.service';

@Component({
  selector: 'app-app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.css']
})
export class AppFooterComponent implements OnInit {
  is_authenticated: boolean;

  constructor(
    private authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit() {
    this.is_authenticated = this.authenticationService.isAuthenticated();

  }

}
