import {Component, OnInit, Input, ViewChild, ElementRef} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'; // Import from platform-browser
// import {ApirequestService} from '../../../../../../shared/services/apirequest.service';
import {MatTableDataSource} from '@angular/material';
import {FormArray, FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import * as moment from 'moment/moment';
import {Router, ActivatedRoute} from '@angular/router';
import {first} from 'rxjs/operators';
import {FileService} from 'src/app/shared/services/file.service';
import * as _ from 'lodash';
import {MatSnackBar} from '@angular/material';
import choice from '../../../../../../shared/choices';

import { Measure } from './../model/measure.model'; // Import the user model
import {ApirequestService} from '../../../../../../shared/services/apirequest.service';
import {AlertService} from '../../../../../../shared/services/alert.service';
import {CustomSnackBarComponent} from '../../../../../../shared/common/custom-snack-bar/custom-snack-bar.component';

import { MatDialog } from '@angular/material/dialog';
import { StudiesDialogComponent } from './../studies-dialog/dialog.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { StudiesSharedService } from '../studies-shared-service/studies-shared.service';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MeasuresSharedService } from './../../../study-evidence/form/measures-shared-service/measures-shared.service';




@Component({
  selector: 'app-dashboard-measure-form-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class RevisedDashboardMeasureFormDetailsComponent implements OnInit {


  loading: boolean;
  start_date: string = '';
  end_date: string = '';
  url: string;
  topHeaders: string[] = ['channelGrouping', 'acquisition', 'behavior'];
  displayedColumns: string[] = ['totalUsers', 'usersWhoEngagedWithPlatform', 'newUsers', 'sessions', 'avgSessionDuration', 'bounceRate'];
  alldisplayedColumns: string[] = ['channelGrouping', 'totalUsers', 'usersWhoEngagedWithPlatform', 'newUsers', 'sessions', 'avgSessionDuration', 'bounceRate'];
  dataSource: MatTableDataSource<any>;
  domainsFiltersList = [];
  behaviorsFiltersList = [];
  populationFiltersList = [];

  measurementModeFilters = [];

  languagesFiltersList = [];
  durationFilters = [];
  databaseFilters = [];
  choices = choice;

  domains_url_filters = 'measures/domains_list/';
  behavior_url_measurefilters = 'measures/behaviors_list/';
  population_url_measurefilters = 'measures/population_list/';
  // Measurement mode
  language_url_measurefilters = 'measures/languages_list/';
  // duration
  // measure_source

  selectedDomain: any;
  selectedBehavior: any;
  selectedPopulation: string;
  selectedMeasurementMode: string;
  selectedLanguage: any;
  selectedDuration: string;
  selectedDatabase: string;
  selectedUploadedBy: string;
  searchTerm: string;
  selectedStatus: string;
  selectedStudies: any[];
  selectedShortDescription: any;
  
  filter_url = {};
  page: number;
  total: number;
  page_size: number;
  total_pages: number;
  measure_id: string;


  measures: any[];

  items = ['All', 'Pending', 'Published', 'Draft'];
  // activeIndex: number | null = null;
  activeIndex: number = 0; // Set default to 0 (first item)
  measureForm: FormGroup;
  formSubmitted: boolean;
  loadedmeasure: any;
  isNewMeasure: boolean;
  submitted: boolean;
  @Input() id: string;
  studyReceivedData: any;
  measureOwnerId: string;
  user_id: string;
  isMeasureOwner: boolean;
  isAdmin: boolean;
  loggedInUser: any;
  @Input() sourceData: any;



  /*WYSWYG from the links below:
  https://www.npmjs.com/package/@kolkov/angular-editor
    https://stackblitz.com/edit/angular-editor-wysiwyg?file=src%2Fapp%2Fapp.component.ts
  both refer to the same thing, second one is very clear
  */
  shortDescriptionHtmlContent = '';
  longDescriptionHtmlContent = '';

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Max 150 characters',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };


  config2: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '7.5rem',
    minHeight: '2.5rem',
    placeholder: 'Max 150 characters',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };


  constructor(
    private apirequestService: ApirequestService,
    private router: Router,
    private fileService: FileService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public alertService: AlertService,
    public dialog: MatDialog,
    private modalService: NgbModal,
    private studiesSharedService: StudiesSharedService,
    private measuresSharedService: MeasuresSharedService,
  ) {
  }
  
  onSubmit(publish: any) {
    // this.cdr.detectChanges(); // Trigger change detection manually
    this.formSubmitted = true;

    // console.log(`htmlContent value:=${this.shortDescriptionHtmlContent}`);

    let measureData = this.measureForm.value;

    console.log(measureData);
   
    if (this.measureForm.valid) { // Check if the form is valid
      // Process form submission
      this.formSubmitted = false;
      console.log(measureData);

      this.submitted = true;
      // stop here if form is invalid
      if (this.measureForm.invalid) {
        return;
      }
      this.loading = true;
      let theForm = this.measureForm.value;
      theForm.short_description = this.shortDescriptionHtmlContent;
      theForm.long_description = this.longDescriptionHtmlContent;

      
      // theForm['domains'].value = [theForm['domains']];
      try{
        theForm.domains = theForm.domains.split(',').map(domain => ({ id: domain.trim()}));
      }
      catch(e){}
      try{
        theForm.behaviors = theForm.behaviors.split(',').map(behavior => ({ id: behavior.trim()}));
      }
      catch(e){}

      try{
        let s = [];
        this.selectedStudies.forEach(curr => s.push(curr.id));
        theForm.study_evidences = s;
      }
      catch(e){}

      
      
      console.log(`theForm=${JSON.stringify(theForm)}`);
      if (this.measureForm.get('id').value === '') {
        console.log('Calling create');
        const url = 'measures/create/details/';
        this.apirequestService.post(url, theForm)
          .pipe(first())
          .subscribe(
            data => {
              // this.id = data.id;
              this.measure_id = data.id;
              this.snackBar.openFromComponent(CustomSnackBarComponent, {
                data: {
                  message: 'Measure Details saved',
                  action: 'Success'
                },
                duration: 3 * 1000,
                panelClass: ['transparent-snack-bar']
              });
              this.loading = false;
              this.formSubmitted = false;
              // this.viewStudies.emit(this.id);
              if(publish == true){
                theForm.publish = true;
                this.sendForReview('Reviewing Scientist');
              }
              else if(publish == false){
                theForm.publish = false;
              }
              // this.router.navigate(['/dashboard/measures/form/' +this.measure_id]);
              this.getFormData();

              if(this.sourceData != undefined){
                this.measuresSharedService.sendData(data);
                let dialogRef = this.sourceData.dialogRef;
                dialogRef.close();
              }
              this.attachStudyToMeasure(data);
              this.router.navigate(['/dashboard/measures/form/' +this.measure_id]);
            },
            error => {
              this.alertService.error(error, 'Measure with the details you provided exists.');
              this.loading = false;
            });
      } else {
        console.log('Calling update');
        const url = 'measures/update/details/';
        this.apirequestService.post(url, theForm)
          .pipe(first())
          .subscribe(
            data => {
              this.snackBar.openFromComponent(CustomSnackBarComponent, {
                data: {
                  message: 'Measure`s Details successfully updated',
                  action: 'Success'
                },
                duration: 3 * 1000,
                panelClass: ['transparent-snack-bar']
              });
              this.loading = false;
              this.formSubmitted = false;
              this.attachStudyToMeasure({id: this.measure_id, title: theForm.title});
            },
            error => {
              this.alertService.error(error, error.message);
              this.loading = false;
            });
      }
      
      // Proceed with your logic
      // console.log(measureData);
    }
    // else if (this.measureForm.invalid) {
    //   this.formSubmitted = false;
    //   return;
    // }
  }

  onSubmit2(publish: any) {
    // this.cdr.detectChanges(); // Trigger change detection manually
    this.formSubmitted = true;

    // console.log(`htmlContent value:=${this.shortDescriptionHtmlContent}`);

    let measureData = this.measureForm.value;

    console.log(measureData);
   
    if (this.measureForm.valid) { // Check if the form is valid
      // Process form submission
      this.formSubmitted = false;
      console.log(measureData);

      this.submitted = true;
      // stop here if form is invalid
      if (this.measureForm.invalid) {
        return;
      }
      this.loading = true;
      let theForm = this.measureForm.value;
      theForm.id = this.measure_id;
      theForm.short_description = this.shortDescriptionHtmlContent;
      theForm.long_description = this.longDescriptionHtmlContent;

      
      // theForm['domains'].value = [theForm['domains']];
      try{
        theForm.domains = theForm.domains.split(',').map(domain => ({ id: domain.trim()}));
      }
      catch(e){}
      try{
        theForm.behaviors = theForm.behaviors.split(',').map(behavior => ({ id: behavior.trim()}));
      }
      catch(e){}

      try{
        let s = [];
        this.selectedStudies.forEach(curr => s.push(curr.id));
        theForm.study_evidences = s;
      }
      catch(e){}

      
      
      console.log(`theForm=${JSON.stringify(theForm)}`);
       
        console.log('Calling update');
        const url = 'measures/update/details/';
        this.apirequestService.post(url, theForm)
          .pipe(first())
          .subscribe(
            data => {
              this.snackBar.openFromComponent(CustomSnackBarComponent, {
                data: {
                  message: 'Measure`s Details successfully updated',
                  action: 'Success'
                },
                duration: 3 * 1000,
                panelClass: ['transparent-snack-bar']
              });
              this.loading = false;
              this.formSubmitted = false;
              if(publish == true){
                theForm.publish = true;
                this.sendForReview('Reviewing Scientist');
              }
              else if(publish == false){
                theForm.publish = false;
              }
              if(this.sourceData != undefined){
                this.studiesSharedService.sendData(data);
                let dialogRef = this.sourceData.dialogRef;
                dialogRef.close();
              }
              this.attachStudyToMeasure({id: this.measure_id, title: theForm.title});
            },
            error => {
              this.alertService.error(error, error.message);
              this.loading = false;
            });
      
      
      // Proceed with your logic
      // console.log(measureData);
    }
    // else if (this.measureForm.invalid) {
    //   this.formSubmitted = false;
    //   return;
    // }
  }

  private getFormData() {
    if (this.id != '' || this.id != undefined) {
      this.isNewMeasure = false;
      const url = 'measures/details/details/' + this.id + '/';
      var can_edit_ = false;
      this.apirequestService.get(url)
        .subscribe(
          res => {
            // res.domains = res.domains[0];
            this.measure_id = this.id;
            this.measureForm.patchValue(res, {onlySelf: true});
            this.measureForm.patchValue({
              id: this.measure_id // Update the value of myField
            });
            // this.measureForm.get('detailsid').setValue('1');
            try{
              this.selectedDomain = res.domains[0].id;
            }catch(e){}
            try{
              this.selectedBehavior = res.behaviors[0].id;
            }catch(e){}
            try{
              this.selectedLanguage = res.language;
            }catch(e){}
            try{
              this.selectedStudies = res.study_evidences;
            }catch(e){}
            try{
              this.shortDescriptionHtmlContent = res.short_description;
            }catch(e){}
            try{
              this.longDescriptionHtmlContent = res.long_description;
            }catch(e){}
            try{
              this.measureOwnerId = res.researcher.id;
            }catch(e){}
            
            // this.selectedDomain = res.domains[0].id;
            // this.selectedBehavior = res.behaviors[0].id;
            // this.selectedLanguage = res.language;
            // this.selectedStudies = res.study_evidences;

            this.measureForm.patchValue({
              format: res.format // Update the value of myField
            });
            this.measureForm.patchValue({
              stage: 'Identified' // Update the value of stage filed
            });

            if(this.user_id == this.measureOwnerId){
              this.isMeasureOwner = true
             }

            
            
            console.log(`this.measureForm.=${JSON.stringify(this.measureForm.value)}`);
            // this.setTagetpopulationData(res);
            this.loadedmeasure = res;
            var user_ = JSON.parse(localStorage.getItem('auth.user'));
            if (res.researcher.id == user_['id'] || user_['role'] == 'Admin' || user_['role'] == 'System Admin') {
              can_edit_ = true;
            } else {
              res.co_owners.forEach(function (co_owner) {
                if (co_owner['id'] == user_['id']) {
                  can_edit_ = true;
                }
              });
            }
          },
          error => {
            console.log(error);
          });
    }
  }

  ngOnInit() {
     // let today = new Date();
     this.isNewMeasure = true;
     this.submitted = false;
     this.page = 1;
     this.total = 0;
     this.page_size = 20;
     this.searchTerm = '';
     this.selectedDomain = {};
    //  this.selectedBehaviour = {};
     this.selectedPopulation = 'All';
     this.selectedMeasurementMode = 'All';
    //  this.selectedLanguage = 'Not specified';
     this.selectedDuration = 'All';
     this.selectedDatabase = 'All';
     this.selectedUploadedBy = 'All';
     this.selectedStatus = 'All';
     this.selectedStudies = [];
     let user = JSON.parse(localStorage.getItem('auth.user'));
     this.loggedInUser = user;
     this.user_id = user.id;
     if(user.role == 'Admin'){this.isAdmin = true;}
     else {this.isAdmin = false;}
     

     
    
    //  setInterval(this.setShorDescription, 5000);



    //  this.loadExternalScript('https://cdn.ckeditor.com/ckeditor5/36.0.1/classic/ckeditor.js').then(() => {
    //   // External script loaded successfully
    //   // Now you can call the callK function
    //   this.loadExternalScript('assets/js/wyswyg/editor.js').then(() => {
    //       // External script loaded successfully
    //       // Now you can call the callK function
          
    //     }).catch(error => {
    //       console.error('Error loading external script:', error);
    //     });

    // }).catch(error => {
    //   console.error('Error loading external script:', error);
    // });

    //  let w = [
    //   {id: "1", title: "1"}, {id: "2", title: "2"}, {id: "3", title: "3"}
    //  ];
    //  w.forEach(curr => this.selectedStudies.push(curr));

    this.studiesSharedService.data$.subscribe(data => {
      if(data != undefined){
        console.log(`data=${JSON.stringify(data)}`);
        this.studyReceivedData = data;
        console.log(`studyReceivedData=${JSON.stringify(this.studyReceivedData)}`);

        this.selectedStudies.push(
          {
            id: this.studyReceivedData.id,
            title: this.studyReceivedData.title
          }
        );
      }
    });
    
     
    this.getFilters();
    this.createForm();
    if (this.id != '' || this.id != undefined) {
      this.getFormData();
    }

  }

  createForm() {
    this.measureForm = this.fb.group({
      id: '',
      // detailsid: '',
      title: ['', Validators.required],
      // stage: ['', Validators.required],
      stage: 'Identified',
      short_description: ['', Validators.required],
      format: ['', Validators.required],
      target_population: '',
      google_scholar_link: '', //this.formBuilder.array([this.initTargetPopulation()]),
      long_description: ['', Validators.required],
      type: '',
      duration: '',
      citations: '',
      study_evidences: [[]],
      domains: [[]],
      behaviors: [[]],
      language: '',
    });

  }


  getFilters(){
    this.apirequestService.get(this.domains_url_filters)
    .subscribe(
      res => {
        this.domainsFiltersList = res.results;
      },
      error => {
        console.log(error);
      });

  this.apirequestService.get(this.behavior_url_measurefilters)
    .subscribe(
      res => {
        this.behaviorsFiltersList = res.results;
      },
      error => {
        console.log(error);
      });

  this.apirequestService.get(this.language_url_measurefilters)
    .subscribe(
      res => {
        this.languagesFiltersList = res.results;
      },
      error => {
        console.log(error);
      });

    this.apirequestService.get(this.population_url_measurefilters)
    .subscribe(
      res => {
        this.populationFiltersList = res.results;
      },
      error => {
        console.log(error);
      });
  }

  onSelectChange(item_name: string, event: any){
    const selectedValue = event.target.value;
    if(item_name == 'domains'){
      this.selectedDomain = selectedValue;
    }
    else if(item_name == 'behaviors'){
      this.selectedBehavior = selectedValue;
    }
    else if(item_name == 'target_population'){
      this.selectedPopulation = selectedValue;
    }
    if(item_name == 'measurement_mode'){
      this.selectedMeasurementMode = selectedValue;
    }
    else if(item_name == 'language'){
      this.selectedLanguage = selectedValue;
    }
    else if(item_name == 'duration'){
      this.selectedDuration = selectedValue;
    }
    if(item_name == 'database'){
      this.selectedDatabase = selectedValue;
    }
    else if(item_name == 'uploaded_by'){
      this.selectedUploadedBy = selectedValue;
    }
  }


  formatDate(inputDate) {
    // Create a new Date object from the input date string
    const date = new Date(inputDate);

    // Extract the day, month, and year from the date object
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month starts from 0
    const year = date.getFullYear();

    // Pad day and month with leading zeros if necessary
    const paddedDay = day < 10 ? '0' + day : day;
    const paddedMonth = month < 10 ? '0' + month : month;

    // Format the date as "DD/MM/YYYY"
    const formattedDate = `${paddedDay}/${paddedMonth}/${year}`;

    return formattedDate;
}

isOdd(num: number): boolean {
  return num % 2 !== 0;
}

isMultipleOf4(number) {
  return number % 4 === 0;
}

// onKeyUp(event: any) {
//   // You can access the value of the input field using event.target.value
//   const enteredValue = event.target.value;
//   console.log('Entered value:', enteredValue);
  
//   // Here you can perform any actions based on the entered value
//   this.searchTerm = enteredValue;
// }

filter_data(){
}

handleClick(index: number) {
  this.activeIndex = index;
  this.selectedStatus = this.items[index];
}

openDialog(): void {
  const dialogRef = this.dialog.open(StudiesDialogComponent, {
    // width: '250px'
    width: '300%',
    height: '2500px',
    data: {sourceData: {source: 'measure_form', source_id: this.id}}
  });
  // After the dialog has been opened, let us update the data object
  dialogRef.componentInstance.data.sourceData.dialogRef = dialogRef;

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
  });
  
}

attachStudyToMeasure(measure: any){
  console.log('ADD CLICKED');
  // let measureId = this.sourceData.source_id;
  this.measuresSharedService.sendData(measure);
  let dialogRef = this.sourceData.dialogRef;
  dialogRef.close();
}

removeStudy(study: any){
  this.selectedStudies = this.selectedStudies.filter(item => item !== study);
}


loadExternalScript(url: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const scriptElement = document.createElement('script');
    scriptElement.src = url;
    scriptElement.onload = () => {
      resolve();
    };
    scriptElement.onerror = (error) => {
      reject(error);
    };
    document.body.appendChild(scriptElement);
  });
}


  
  
sendForReview(destination) {
  // this.onSubmit();
  if(this.measure_id != undefined){
  this.formSubmitted = true;
  // this.submitData('Submit');
  this.formSubmitted = false;
  // this.router.navigate(['/dashboard/measure/' + this.id]);
  // this.confirmationDialogService.confirm('Confirm submission for review', 'Are you sure you want to submit this measure for review?', 'Yes Submit')
    // .then((confirmed) => {
      // if (confirmed == true) {
        const data = {
          'measure_id': this.measure_id,
        };
        const url = 'measures/submit_for_approval/';
        this.formSubmitted = true;
        this.apirequestService.post(url, data)
          .pipe(first())
          .subscribe(
            data => {
              this.id = data.id;
              this.snackBar.openFromComponent(CustomSnackBarComponent, {
                data: {
                  message: data,
                  action: 'Success'
                },
                duration: 3 * 1000,
                panelClass: ['transparent-snack-bar']
              });
              this.formSubmitted = false;
              this.attachStudyToMeasure({id: this.measure_id});
              this.router.navigate(['/dashboard/measures/form/' + this.measure_id]);
            },
            error => {
              this.alertService.error(error, '');
              this.formSubmitted = false;
            });
      // }
    // });
      }
  // this.router.navigate(['/dashboard/measure/' + this.id]);
}





}

