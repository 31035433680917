import {Component, ElementRef, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import * as _ from 'lodash';
import {saveAs as importedSaveAs} from 'file-saver';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';

import {ApirequestService} from '../../shared/services/apirequest.service';
import {GoogleAnalyticsService} from '../../shared/services/google-analytics.service';
import {AuthenticationService} from '../../shared/services/authentication.service';

import {Measures} from '../../shared/models/measures';
import {GoogleScholar} from '../../shared/models/googlescholar';

import {ShareCollectionComponent} from '../my-collection/share-collection.component';
import {TheorytechniquestoolDialogService} from '../../shared/theorytechniquestool-dialog/theorytechniquestool-dialog.service';
import {Utility} from '../../shared/common/utility';

@Component({
  selector: 'app-citations-dialog',
  template: `
    <h1 mat-dialog-title class="text-capitalize">
      How to export
      <span class="text-capitalize">{{data.citation_name}} </span>references:</h1>
    <div mat-dialog-content>
      <ol>
        <li>
          {{citation_instruction}}
        </li>
        <li> Click the <b style="color: #0076ff">Export</b> button below.
          A file with the <b>{{data.extension}} </b>
          extension will be downloaded.
        </li>
        <li>
          Open Your favorite citation Software and import the file downloaded in 1) above.
        </li>
      </ol>
    </div>
    <div mat-dialog-action>
      <button class="btn btn-danger btn-sm" (click)="onNoClick()">Cancel</button>
      <button class="btn btn-primary btn-sm ml-2" (click)="downloadCitation(data.citation_type)">
        <i class="fa fa-circle-o-notch  fa-spin" *ngIf="loading"></i>
        Export
      </button>
    </div>
  `,
})
export class CitationsDialogComponent implements OnInit {
  citation_description: string;
  citation_instruction: string;
  loading = false;


  constructor(
    public dialogRef: MatDialogRef<CitationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private apirequestService: ApirequestService,
  ) {
  }

  ngOnInit() {

    this.citation_instruction = (this.data.citation_name === 'BibTex') ?
      'Ensure you have BibTex referencing software installed in your computer.' :
      'Ensure you have ' + this.data.citation_name + ' installed in your computer.';

  }


  downloadCitation(type) {
    this.loading = true;
    const url = 'measures/export_citations/' + this.data.measure_id + '/' + type + '/';
    this.apirequestService.getFile(url)
      .subscribe(
        response => {
          let extension = '.bib';
          if (type === 'bibtext') {
            extension = '.bib';
          } else if (type === 'endnote') {
            extension = '.xml';
          } else if (type === 'refworks' || type === 'zotero') {
            extension = '.enw';
          } else if (type === 'mendeley') {
            extension = '.irs';
          }
          importedSaveAs(response, this.data.measure_title + '_Citations' + extension);
          this.loading = false;
          this.dialogRef.close();
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}


@Component({
  selector: 'app-measuredetails',
  templateUrl: './measuredetails.component.html',
  styleUrls: ['./measuredetails.component.scss']
})
export class MeasuredetailsComponent implements OnInit {
  measures: Measures;

  osfDetails = [];
  hasOsf = false;
  studies = [];
  identifieddata: any;
  measureddata: any;
  influenceddata: any;
  validateddata: any;
  relatedMeasures = [];
  loadingRelatedMeasures = true;
  creditDetails = [];
  proposeMechanismData = [];
  proposedMechanismClicked = false;
  identified = false;
  measured = false;
  influenced = false;
  validated = false;
  citeMeasure = false;
  loading_related_articles = true;
  loading_osf_details = true;
  loading_credit_details = true;
  loading_comments = true;
  theStars = [];
  load_reviewers = false;
  id: string;
  comments;
  isLoggedIn: boolean;
  page = 1;
  comments_total = 0;
  limit = 10;
  total_pages = 0;
  page_size = 0;
  survey_count = false;
  entries: string;
  toLoadGoogleScholar = false;
  googleScholarDetails: GoogleScholar;
  title: string;
  mechanisms_and_technics_count = 0;
  mechanisms_and_technics = [];
  measureTitle: string;
  parentMeasureTitle: string;
  userdetails: any;
  user_role: string;
  identifiedCitation: boolean;
  measuredDemographics: boolean;
  measuredCitation: boolean;
  identifiedDemographics: boolean;
  validatedCitation: boolean;
  validatedDemographics: boolean;
  influencedDemographics: boolean;
  influencedCitation: boolean;
  loadingMeasure: boolean = true;
  allAuthors = [];
  clickedStudyItem: string;
  studyEvidenceList = [];

  @ViewChild('googleScholarCollapse', {read: ElementRef}) googleScholarCollapse: ElementRef;

  constructor(
    private apirequestService: ApirequestService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router,
    public dialog: MatDialog,
    public googleAnalyticsService: GoogleAnalyticsService,
    private theorytechniquestoolDialogService: TheorytechniquestoolDialogService,
    private elementRef: ElementRef,
    private utility: Utility
  ) {
  }

  ngOnInit() {
    this. clickedStudyItem = 'All';
    this.route.params.subscribe(params => {
      this.id = params['id'] || '';
      this.userdetails = JSON.parse(localStorage.getItem('auth.user'));
      if (!_.isNull(this.userdetails)) {
        this.user_role = this.userdetails.role;
      }
    });
    this.loadData();
    this.loadMechanismsAndTechnics();
    this.getMeasureDetailsOSF();
    this.getRelatedMeasures();
    this.getCreditDetails();
    this.getComments();
    this.getSurveys();
    this.utility.initSwiper(this.elementRef);
  }

  shareMeasure(measure) {
    // pop up to add users
    const dialogRef = this.dialog.open(ShareCollectionComponent, {
      panelClass: 'customPanelClass',
      data: {
        measure: this.measures,
        type: 'measure',
      },
    });
  }

  openCitationDialogue(type, ext, name) {
    this.dialog.open(CitationsDialogComponent, {
      data: {
        citation_type: type,
        measure_id: this.id,
        measure_title: this.measures.title,
        extension: ext,
        citation_name: name
      },
      position: {
        left: '20%'
      }
    });
  }

  loggedIn(type) {
    if (this.auth.isAuthenticated()) {
      this.isLoggedIn = true;
      if (type === 'collection') {
        // this.router.navigate(['/dashboard/mycollection/' + this.id + '/'], {queryParams: {returnUrl: 'measuredetails/' + this.id}});
        this.router.navigate(['/dashboard/mycollection/' + this.id + '/'], {queryParams: {returnUrl: 'measuredetails/' + this.id}});
      } else if (type === 'share') {
        this.shareMeasure(this.measures);
      }
    } else {
      if (type === 'comment' || type === 'share') {
        this.router.navigate(['/login'], {queryParams: {returnUrl: 'measuredetails/' + this.id}});
      } else {
        this.router.navigate(['/login'], {queryParams: {returnUrl: '/dashboard/mycollection/' + this.id}});
      }
    }
  }

  submitComment(e) {
    const data = {
      'measure_id': this.id,
      'comment': e
    };
    const url = 'measures/comment/';
    this.apirequestService.post(url, data)
      .subscribe(
        res => {
          this.isLoggedIn = false;
          this.getComments();
        },
        error => {
          console.log(error);
        });
  }

  googleScholarContainerClicked(event) {
    if (_.isEqual(this.googleScholarCollapse.nativeElement.id, 'scholar-trigger')) {
      this.getMeasureDetailsGoogleScholar();
    }
  }

  downloadMeasure(title) {
    const data = {
      'measure_id': this.id,
    };
    const url = 'measures/download_count/';
    this.apirequestService.post(url, data)
      .subscribe(
        error => {
          console.log(error);
        });
    this.googleAnalyticsService.eventEmitter('download_measure_detail', 'measure', 'click', title, 1);
  }

  goToPage(n: number): void {
    this.page = n;
    this.getComments();
  }

  onNext(): void {
    this.page++;
    this.getComments();
  }

  onPrev(): void {
    this.page--;
    this.getComments();
  }

  private loadTheoryTechniquesToolDialog() {
    // var data_ = [{'title': 'DATA 1', 'value': 'DATA1'}, {'title': 'DATA 2', 'value': 'DATA2'}, {'title': 'DATA 3', 'value': 'DATA3'}];
    // var data_ = [
    //   {'title': 'Mechanisms of Action', 'value': 'Behaviour Change Techniques'},
    //   {'title': 'Behavioral Regulation', 'value': 'Problem solving Self-monitoring of behavior Information about antecedents Behavior substitution Reduce negative emotions Conserving mental resources'},
    //   {'title': 'Beliefs about Capabilities', 'value': 'Problem solving Instruction on how to perform behavior Demonstration of the behavior Behavioral practice/rehearsal Graded tasks Verbal persuasion about capability Focus on past success Self-talk'},
    //   {'title': 'Beliefs about Consequences', 'value': 'Information about health consequences Salience of consequences Information about social and environmental consequences Anticipated regret Information about emotional consequences Pros and cons Comparative imagining of future outcomes Material incentive (behavior) Incentive (outcome) Reward (outcome)'}
    // ];
    var data_ = [];
    // var found = false;

    this.mechanisms_and_technics.forEach(function (mechanism_and_technic) {
      var found = false;
      data_.forEach(function (current) {
        if (current.title == mechanism_and_technic.mechanism) {
          var value = current.value;
          value += ', ' + mechanism_and_technic.technic;
          let index = data_.indexOf(current);
          data_[index] = {'title': current.title, 'value': value};
          found = true;
        }

      });
      if (found == false) {
        data_.push({'title': mechanism_and_technic.mechanism, 'value': mechanism_and_technic.technic});
      }

      // data_.push({'title': mechanism_and_technic.mechanism, 'value': mechanism_and_technic.technic});


    });
    this.proposeMechanismData = data_;
  }

  private loadMechanismsAndTechnics() {
    const url = 'theroryandtechnicstool/measure-mechanism-technics/' + this.id + '/';
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.mechanisms_and_technics_count = res.count;
          this.mechanisms_and_technics = res.results;
        },
        error => {
          console.log(error);
        }
      );

  }

  private loadData() {
    const url = 'measures/details/' + this.id + '/?source=public-measure-details-page';
    this.loadingMeasure = true;
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.loadingMeasure = false;
          this.title = res.title;
          this.measures = res;
          this.parentMeasureTitle = this.title;
          this.measureTitle = this.title;
          if (res.stage === 'Identified') {
            this.identified = true;
            this.measured = false;
            this.influenced = false;
            this.validated = false;
          } else if (res.stage === 'Measured') {
            this.identified = true;
            this.measured = true;
            this.influenced = false;
            this.validated = false;
          } else if (res.stage === 'Influenced') {
            this.identified = true;
            this.measured = true;
            this.influenced = true;
            this.validated = false;
          } else {
            this.identified = true;
            this.measured = true;
            this.influenced = true;
            this.validated = true;
          }

          this.studies = res.studies;
          for (let i = 0, len = this.studies.length; i < len; i++) {
            const studiesdata = this.studies[i];
            if (studiesdata.stage === 'Identified') {
              this.identifieddata = studiesdata;
            } else if (studiesdata.stage === 'Measured') {
              this.measureddata = studiesdata;
            } else if (studiesdata.stage === 'Influenced') {
              this.influenceddata = studiesdata;
            } else if (studiesdata.stage === 'Validated') {
              this.validateddata = studiesdata;
            }
          }
          this.studyEvidenceList = res.study_evidences;  // measures?.study_evidences
          if(this.studyEvidenceList.length > 1){
            this.studyEvidenceList = this.studyEvidenceList.sort((a, b) => b.type_of_study[0].localeCompare(a.type_of_study[0]));
          }
        },
        error => {
          console.log(error);
          this.loadingMeasure = false;
        });

    this.parentMeasureTitle = this.title;
    this.measureTitle = this.title;

  }

  private getMeasureDetailsOSF() {
    const url = 'measures/osf_details/' + this.id + '/';
    // GET OSF Details
    this.apirequestService.get(url)
      .subscribe(
        res => {
          if (_.isUndefined(res.message) || res.message === '') {
            this.hasOsf = true;
          }
          this.loading_osf_details = false;
          this.osfDetails = res;
        },
        error => {
          console.log(error);
        });
  }

  private getMeasureDetailsGoogleScholar() {
    const url = 'measures/related_google_scholar_articles/' + this.id + '/';
    // GET GOOGLE SCHOLAR Details
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.googleScholarDetails = res;
        },
        error => {
          console.log(error);
        });
  }

  private getRelatedMeasures() {
    this.loadingRelatedMeasures = true;
    const url = 'measures/related_measures/' + this.id + '/';
    // Get Related measures
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.relatedMeasures = res.results;
          this.loadingRelatedMeasures = false;
        },
        error => {
          console.log(error);
          this.loadingRelatedMeasures = false;
        });
  }

  private getCreditDetails() {
    const url = 'measures/credits_details/' + this.id + '/';
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.creditDetails = res;
          this.loading_credit_details = false;
          const data = [];
          for (let u = 0; parseInt(res.stars, 10) > u; u++) {
            data.push(u);
          }
          if (res.reviewers && res.reviewers.length > 0) {
            this.load_reviewers = true;
          }
          this.theStars = data;
        },
        error => {
          console.log(error);
        });
  }

  private getSurveys() {
    const url = 'surveys/surveys/' + this.id + '/';
    this.apirequestService.get(url)
      .subscribe(
        res => {
          if (_.isEmpty(res.results)) {
            this.survey_count = false;
          } else {
            this.survey_count = true;
          }
        },
        error => {
          console.log(error);
        });
  }

  private getComments() {
    const url = 'measures/comments/' + this.id + '/';
    const params = new HttpParams()
      .set('page', this.page.toString());
    this.apirequestService.get(url, params)
      .subscribe(
        res => {
          this.comments = res;
          this.comments_total = res.count;
          this.page_size = res.page_size;
          this.page = res.current_page;
          this.total_pages = res.total_pages;
          this.loading_comments = false;
          this.entries = (this.comments_total > 1) ? 'Comments' : 'Comment';
        },
        error => {
          console.log(error);
        });
  }

  getAuthors(study: any){
    try{
      // if(res.study_evidences.authors != '' && res.study_evidences.authors != undefined){
        const validJsonString = study.authors.replace(/'/g, '"');
        // Parse the JSON string into an array of objects
        const authorsJson = JSON.parse(validJsonString);
        // console.log(`authorsJson=${JSON.stringify(authorsJson)}`);
        if(authorsJson.length > 0){
          return authorsJson;
        }
        else{
          return [];
        }
      // }
    }catch(e){
      return [];
    }
  }

  getExtraFieldValue(field_name: string, study: any){
    try{

        let validJsonString = '[]';
        if(field_name == 'sex'){
          validJsonString = study.sex.replace(/'/g, '"');
        }
        else if(field_name == 'gender'){
          validJsonString = study.gender.replace(/'/g, '"');
        }
        else if(field_name == 'race'){
          validJsonString = study.race.replace(/'/g, '"');
        }
        else if(field_name == 'ethnicity'){
          validJsonString = study.ethnicity.replace(/'/g, '"');
        }
        else if(field_name == 'country'){
          validJsonString = study.country.replace(/'/g, '"');
        }
        
        
        // Parse the JSON string into an array of objects
        const authorsJson = JSON.parse(validJsonString);
        // console.log(`authorsJson=${JSON.stringify(authorsJson)}`);
        if(authorsJson.length > 0){
          return authorsJson;
        }
        else{
          return [];
        }
      // }
    }catch(e){
      return [];
    }
  }

  setClickedStudyItem(clickedStudyItem: string){
    this.clickedStudyItem = clickedStudyItem;
  }
}
