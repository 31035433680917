import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {ApirequestService} from '../../../../shared/services/apirequest.service';
import {MatTableDataSource} from '@angular/material';
import * as moment from 'moment/moment';
import {DatesService} from '../../../../shared/services/dates.service';
import {MatSort, Sort} from '@angular/material/sort';
import {LiveAnnouncer} from '@angular/cdk/a11y';

export interface Behaviors {

  usertype: string,
  users: number,
  sessions: number,
  bounceRate: number,
  avgSessionDuration: number,
  pageviewsPerSession: number,

}

@Component({
  selector: 'app-behaviors',
  templateUrl: './behaviors.component.html',
  styleUrls: ['./behaviors.component.css']
})
export class BehaviorsComponent implements AfterViewInit {
  loading: boolean;
  start_date: string = '';
  end_date: string = '';
  url: string;
  topHeaders: string[] = ['userType', 'acquisition', 'behavior'];
  displayedColumns: string[] = ['users', 'sessions', 'bounceRate', 'avgSessionDuration', 'pageviewsPerSession'];
  alldisplayedColumns: string[] = ['userType', 'users', 'sessions', 'bounceRate', 'avgSessionDuration', 'pageviewsPerSession'];
  dataSource: MatTableDataSource<any>;
  maxDate = new Date();
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private apirequestService: ApirequestService, private dateService: DatesService, private _liveAnnouncer: LiveAnnouncer) {
  }

  ngAfterViewInit() {
     // let today = new Date();
     const dateString = "2023-02-01T12:30:45.678Z";
     let today = new Date(dateString);
     var lastMonth = new Date(today);
     lastMonth.setDate(lastMonth.getDate() - 30);
     this.start_date = lastMonth.toISOString().slice(0, 10);
     this.end_date = today.toISOString().slice(0, 10);
     
    this.dataSource = new MatTableDataSource();
    this.dataSource.sort = this.sort;
    this.getData(this.start_date, this.end_date);
  }

  filter_by_date() {
    this.loading = true;
    this.getData(this.start_date, this.end_date);
  }

  getData(start_date, end_date) {

    if (start_date != '' && end_date != '') {
      var formatedstartDate = this.dateService.formatDate(start_date);
      var formatedendDate = this.dateService.formatDate(end_date);
      this.url = 'analytics/behaviors/?start_date=' + formatedstartDate + '&end_date=' + formatedendDate;
    } else {
      this.start_date = this.dateService.getStartDate();
      this.end_date = this.dateService.getEndDate();
      this.url = 'analytics/behaviors/';
    }
    this.loading = true;
    this.apirequestService.get(this.url)
      .subscribe(
        data => {
          let alldata = [];
          const entries = Object.entries(data);
          for (let i = 0; i < entries.length; i++) {
            var avgsession = entries[i][1]['avgSessionDuration'];
            entries[i][1]['avgSessionDuration'] = moment().startOf('day')
              .seconds(avgsession)
              .format('H:mm:ss');

            alldata.push(entries[i][1]);
          }
          this.dataSource.data = alldata;
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
        });
  }


  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }


}
