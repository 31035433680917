import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';

import {ApirequestService} from '../../../shared/services/apirequest.service';
import {AlertService} from '../../../shared/services/alert.service';
import {CustomSnackBarComponent} from '../../../shared/common/custom-snack-bar/custom-snack-bar.component';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  @Input() id: string;
  @Output() viewStudies = new EventEmitter<any>();
  measureForm: FormGroup;
  loading = false;
  submitted = false;
  isUpdate = false;
  thekeywords: string;
  thedomains: string[];
  thetarget_populations: string[];
  thelanguages: string[];
  thebehaviors: string[];
  edit_measure_id: string;
  // updateMeasureClicked = false;
  url_measurefilters = 'measures/domains_list/';
  url_target_populationlist = 'measures/population_list/';
  url_languageslist = 'measures/languages_list/';
  url_behaviorslist = 'measures/behaviors_list/';
  can_edit: boolean;
  loadedmeasure: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apirequestService: ApirequestService,
    public snackBar: MatSnackBar,
    public alertService: AlertService,
  ) {
  }



  ngOnInit() {
    this.can_edit = false;
    this.createForm();
    this.getDomainsList();
    this.getPopulationList();
    this.getLanguageList();
    this.getBehaviorsList();
    this.getFormData();
  }

  initDomain(): FormGroup {
    return this.formBuilder.group({
      id: ['', Validators.required],
      name: '',
    });
  }
  initBehaviors(): FormGroup {
    return this.formBuilder.group({
      id: ['', Validators.required],
      name: '',
    });
  }

  initLanguage(): FormGroup {
    return this.formBuilder.group({
      id: ['', Validators.required],
      name: '',
    });
  }

  initTargetPopulation(): FormGroup {
    return this.formBuilder.group({
      id: ['', Validators.required],
      name: '',
    });
  }

  get f() {
    return this.measureForm.controls;
  }

  addDomain(): void {
    const control = <FormArray>this.measureForm.get('domains');
    control.push(this.initDomain());
  }

  addBehavior(): void {
    const control = <FormArray>this.measureForm.get('behaviors');
    control.push(this.initBehaviors());
  }

  removeDomain(form, j) {
    const control = form.controls.domains;
    control.removeAt(j);
  }

  removeBehavior(form, j) {
    const control = form.controls.behaviors;
    control.removeAt(j);
  }

  getdomainsData(form) {
    return form.controls.domains.controls;
  }

  getbehaviorsData(form){
    return form.controls.behaviors.controls;
  }

  getDurationsData(form) {
    return form.controls.durations.controls;
  }

  setdomainsData(res) {
    const control = <FormArray>this.measureForm.controls.domains;
    control.removeAt(0);
    res.domains.forEach(x => {
      control.push(this.formBuilder.group({
        id: x.id,
        name: x.name
      }));
    });
  }

  setbehaviorsData(res) {
    const control = <FormArray>this.measureForm.controls.behaviors;
    control.removeAt(0);
    res.behaviors.forEach(x => {
      control.push(this.formBuilder.group({
        id: x.id,
        name: x.name
      }));
    });
  }

  setTagetpopulationData(res) {
    const control = <FormArray>this.measureForm.controls.target_population;
    control.removeAt(0);
    res.target_population.forEach(x => {
      control.push(this.formBuilder.group({
        id: x.id,
        name: x.name
      }));
    });
  }

  addKeyword(thekeyword) {
    const keys = this.measureForm.get('keywords').value;
    this.thekeywords = keys + ' ; ' + thekeyword;
    this.measureForm.get('keywords').setValue(this.thekeywords);
  }

  initCitation(): FormGroup {
    return this.formBuilder.group({
      title: ['', Validators.required],
      authors: ['', Validators.required],
      doi: ['', Validators.required],
      type: ['', Validators.required],
    });
  }

  addCitation(): void {
    const control = <FormArray>this.measureForm.get('citations');
    control.push(this.initCitation());
  }

  removeCitation(form, j) {
    const control = form.controls.citations;
    control.removeAt(j);
  }

  getcitationsData(form) {
    return form.controls.citations.controls;
  }

  setcitationsData(res) {
    const control = <FormArray>this.measureForm.controls.citations;
    control.removeAt(0);
    res.citations.forEach(x => {
      control.push(this.formBuilder.group({
        title: x.title,
        authors: x.authors,
        doi: x.doi,
        type: x.type,
      }));
    });
  }

  goNext() {
    if (this.measureForm.dirty === true) {
      this.submitData();
    }
    // route to studies
    if (!_.isEmpty(this.id)) {
      this.viewStudies.emit(this.id);
    } else {
      return;
    }
  }

  submitData() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.measureForm.invalid) {
      return;
    }
    this.loading = true;
    const theForm = this.measureForm.value;
    console.log(`theForm=${JSON.stringify(theForm)}`);
    if (this.measureForm.get('id').value === '' && this.measureForm.get('detailsid').value === '') {
      const url = 'measures/create/details/';
      this.apirequestService.post(url, theForm)
        .pipe(first())
        .subscribe(
          data => {
            this.id = data.id;
            this.snackBar.openFromComponent(CustomSnackBarComponent, {
              data: {
                message: 'Measure Details saved',
                action: 'Success'
              },
              duration: 3 * 1000,
              panelClass: ['transparent-snack-bar']
            });
            this.loading = false;
            this.viewStudies.emit(this.id);
          },
          error => {
            this.alertService.error(error, '');
            this.loading = false;
          });
    } else {
      const url = 'measures/update/details/';
      this.apirequestService.post(url, theForm)
        .pipe(first())
        .subscribe(
          data => {
            this.snackBar.openFromComponent(CustomSnackBarComponent, {
              data: {
                message: 'Measure`s Details successfully updated',
                action: 'Success'
              },
              duration: 3 * 1000,
              panelClass: ['transparent-snack-bar']
            });
            this.loading = false;
          },
          error => {
            this.alertService.error(error, '');
            this.loading = false;
          });
    }
  }

  private createForm() {
    this.route.params.subscribe(
      (params): void => {
        this.edit_measure_id = params['id'] || '';
        if (this.edit_measure_id == '') {
          this.can_edit = true;
        }
      });
    this.measureForm = this.formBuilder.group({
      id: '',
      detailsid: '',
      title: ['', Validators.required],
      stage: ['', Validators.required],
      short_description: ['', Validators.required],
      domains: this.formBuilder.array([this.initDomain()]),
      type: ['', Validators.required],
      format: ['', Validators.required],
      duration: ['', Validators.required],
      target_population: ['', Validators.required], //this.formBuilder.array([this.initTargetPopulation()]),
      keywords: ['', Validators.required],
      long_description: ['', Validators.required],
      language: ['', Validators.required], //this.formBuilder.array([this.initLanguage()]),
      behaviors: this.formBuilder.array([this.initBehaviors()]),
      citations: this.formBuilder.array([this.initCitation()]),
    });
  }

  private getFormData() {
    if (this.id) {
      this.isUpdate = true;
      const url = 'measures/details/details/' + this.id + '/';
      var can_edit_ = false;
      this.apirequestService.get(url)
        .subscribe(
          res => {
            this.measureForm.patchValue(res, {onlySelf: true});
            this.measureForm.get('detailsid').setValue('1');
            console.log(`this.measureForm.=${JSON.stringify(this.measureForm.value)}`);
            this.setdomainsData(res);
            this.setcitationsData(res);
            this.setbehaviorsData(res);
            // this.setTagetpopulationData(res);
            this.loadedmeasure = res;
            var user_ = JSON.parse(localStorage.getItem('auth.user'));
            if (res.researcher.id == user_['id'] || user_['role'] == 'Admin' || user_['role'] == 'System Admin') {
              can_edit_ = true;
            } else {
              res.co_owners.forEach(function (co_owner) {
                if (co_owner['id'] == user_['id']) {
                  can_edit_ = true;
                }
              });
            }

            if (can_edit_ == true) {
              this.can_edit = true;
            }
          },
          error => {
            console.log(error);
          });
    }
  }

  private getDomainsList() {
    this.apirequestService.get(this.url_measurefilters)
      .subscribe(
        res => {
          this.thedomains = res.results;
        },
        error => {
          console.log(error);
        });
  }

  private getBehaviorsList() {
    this.apirequestService.get(this.url_behaviorslist)
      .subscribe(
        res => {
          this.thebehaviors = res.results;
        },
        error => {
          console.log(error);
        });
  }

  private getPopulationList() {
    this.apirequestService.get(this.url_target_populationlist)
      .subscribe(
        res => {
          this.thetarget_populations = res.results;
        },
        error => {
          console.log(error);
        });
  }

  private getLanguageList() {
    this.apirequestService.get(this.url_languageslist)
      .subscribe(
        res => {
          this.thelanguages = res.results;
        },
        error => {
          console.log(error);
        });
  }

  

}
