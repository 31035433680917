import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import * as _ from 'lodash';

import {ApirequestService} from '../../../shared/services/apirequest.service';
import {GEMMeasures} from '../../../shared/models/gemmeasures';
import {GoogleScholar} from '../../../shared/models/googlescholar';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {ShareCollectionComponent} from '../../my-collection/share-collection.component';
import {CitationsDialogComponent} from '../measuredetails.component';
import {Utility} from '../../../shared/common/utility';
import {GoogleAnalyticsService} from '../../../shared/services/google-analytics.service';

@Component({
  selector: 'app-gemmeasuredetails',
  templateUrl: './gemmeasuredetails.component.html',
  styleUrls: ['../measuredetails.component.scss']
})
export class GemMeasuredetailsComponent implements OnInit {
  measures: GEMMeasures;
  osfDetails = [];
  googleScholarDetails: GoogleScholar;
  loading_gem_details = true;
  loading_gem_details_error = false;
  loading_related_articles = true;
  relatedMeasures = [];
  id: string;
  isLoggedIn: boolean;
  identifiedClicked: boolean;
  measuredClicked: boolean;
  creditDetails: any;
  loading_credit_details = true;
  load_reviewers = false;
  theStars: any;
  survey_count = false;

  @ViewChild('googleScholarCollapse', {read: ElementRef}) googleScholarCollapse: ElementRef;

  constructor(
    private apirequestService: ApirequestService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router,
    public dialog: MatDialog,
    private elementRef: ElementRef,
    private utility: Utility,
    public googleAnalyticsService: GoogleAnalyticsService,
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    this.loadData();
    this.getMeasureDetailsGoogleScholar();
    this.getRelatedMeasures();
    this.getCreditDetails();
    this.getSurveys();
    this.utility.initSwiper(this.elementRef);
  }

  loggedIn(type) {
    if (this.auth.isAuthenticated()) {
      this.isLoggedIn = true;
      if (type === 'collection') {
        this.router.navigate(['/dashboard/mycollection/' + this.id + '/'],
          {queryParams: {returnUrl: 'gemmeasuredetails/' + this.id}});
      } else {
        this.shareMeasure();
      }
    } else {
      if (type === 'comment') {
        this.router.navigate(['/login'], {queryParams: {returnUrl: 'gemmeasuredetails/' + this.id}});
      } else {
        this.router.navigate(['/login'], {queryParams: {returnUrl: '/dashboard/mycollection/' + this.id}});
      }
    }
  }

  shareMeasure() {
    // pop up to add users
    const dialogRef = this.dialog.open(ShareCollectionComponent, {
      panelClass: 'customPanelClass',
      data: {
        measure: this.measures,
        type: 'measure',
      },
    });
  }

  openCitationDialogue(type, ext, name) {
    this.dialog.open(CitationsDialogComponent, {
      data: {
        citation_type: type,
        measure_id: this.id,
        measure_title: this.measures.general_information.title,
        extension: ext,
        citation_name: name
      },
      position: {
        left: '20%'
      }
    });
  }

  private loadData() {
    const url = 'measures/gemdetails/' + this.id + '/';
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.measures = res;
          this.loading_gem_details = false;
        },
        error => {
          this.loading_gem_details_error = true;
          console.log(error);
        });
  }

  private getMeasureDetailsGoogleScholar() {
    const url = 'measures/related_google_scholar_articles/' + this.id + '/';
    // GET GOOGLE SCHOLAR Details
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.loading_related_articles = false;
          this.googleScholarDetails = res;
        },
        error => {
          console.log(error);
        });
  }

  private getRelatedMeasures() {
    const url = 'measures/related_measures/' + this.id + '/';
    // Get Related measures
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.relatedMeasures = res.results;
        },
        error => {
          console.log(error);
        });
  }

  googleScholarContainerClicked(event) {
    if (_.isEqual(this.googleScholarCollapse.nativeElement.id, 'scholar-trigger')) {
      this.getMeasureDetailsGoogleScholar();
    }
  }

  downloadMeasure(title) {
    const data = {
      'measure_id': this.id,
    };
    const url = 'measures/download_count/';
    this.apirequestService.post(url, data)
      .subscribe(
        error => {
          console.log(error);
        });
    this.googleAnalyticsService.eventEmitter('download_measure_detail', 'measure', 'click', title, 1);
  }

  private getCreditDetails() {
    const url = 'measures/credits_details/' + this.id + '/';
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.creditDetails = res;
          this.loading_credit_details = false;
          const data = [];
          for (let u = 0; parseInt(res.stars, 10) > u; u++) {
            data.push(u);
          }
          if (res.reviewers && res.reviewers.length > 0) {
            this.load_reviewers = true;
          }
          this.theStars = data;
        },
        error => {
          console.log(error);
        });
  }

  private getSurveys() {
    const url = 'surveys/surveys/' + this.id + '/';
    this.apirequestService.get(url)
      .subscribe(
        res => {
          if (_.isEmpty(res.results)) {
            this.survey_count = false;
          } else {
            this.survey_count = true;
          }
        },
        error => {
          console.log(error);
        });
  }
}
