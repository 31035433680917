import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';

import {ApirequestService} from '../../../shared/services/apirequest.service';
import {AlertService} from '../../../shared/services/alert.service';
import {CustomSnackBarComponent} from '../../../shared/common/custom-snack-bar/custom-snack-bar.component';
import {ConfirmationDialogService} from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import { inspect } from 'util';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-send-for-review',
  templateUrl: 'send-for-review.html',
})
export class SendForReviewComponent implements OnInit {
  @Input() id: string;
  commentsForm: FormGroup;
  comments: string;
  measure_id: string;
  destination: string;
  user_role: string;
  userdetails: any;
  can_edit = false;
  loaded_measure: string;

  constructor(
    public dialogRef: MatDialogRef<SendForReviewComponent>,
    private formBuilder: FormBuilder,
    private apirequestService: ApirequestService,
    public alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private activeRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.createCommentsForm();
    this.measure_id = this.id;
    console.log('this.measure_id=' + this.measure_id);

    // this.route.params.subscribe(params => {
    //   var id_ = params['id'] || '';
    //   if(id_ == ''){
    //       this.can_edit = true;
    //     }
    // });
    this.can_edit = true;


    this.measure_id = this.dialogRef.componentInstance.data['measure_id'];
    this.destination = this.dialogRef.componentInstance.data['destination'];
    this.userdetails = JSON.parse(localStorage.getItem('auth.user'));
    if (!_.isNull(this.userdetails)) {
      this.user_role = this.userdetails.role;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private createCommentsForm() {
    this.commentsForm = this.formBuilder.group({
      comments: ['', Validators.required],
    });
  }

  private sendForReview(user_role, action: string = 'APPROVE') {
    let senddata = {
      'destination': this.destination,
      'measure_id': this.measure_id,
      'comments': this.commentsForm.get('comments').value
    };
    if(action == 'APPROVE'){
      senddata['action'] = 'PUBLISH';
    }
    // 'action': 'PUBLISH'
    console.log(`senddata=${JSON.stringify(senddata)}`);
    const url_approval = 'measures/approve_reject_measure/';
    const url_review = 'measures/admin_send_to_user/';
    if (this.destination === 'Approve') {
      this.apirequestService.post(url_approval, senddata).subscribe(
        data => {
          this.alertService.success(data.message);
        },
        error => {
          console.log(error);
        });
    } else {
      this.apirequestService.post(url_review, senddata).subscribe(
        data => {
        },
        error => {
          console.log(error);
        });
    }
    this.dialogRef.close();
  }

  private saveAndPublish(user_role, action: string = 'APPROVE') {
    let senddata = {
      'destination': this.destination,
      'measure_id': this.measure_id,
      'comments': this.commentsForm.get('comments').value,
      'action': 'PUBLISH'
    };
    const url_approval = 'measures/approve_reject_measure/';
    const url_review = 'measures/admin_send_to_user/';
    if (this.destination === 'Approve') {
      this.apirequestService.post(url_approval, senddata).subscribe(
        data => {
          this.alertService.success(data.message);
        },
        error => {
          console.log(error);
        });
    } else {
      senddata['publish'] = true;
      this.apirequestService.post(url_review, senddata).subscribe(
        data => {
        },
        error => {
          console.log(error);
        });
    }
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.scss']
})
export class MaterialsComponent implements OnInit {
  @Input() id: string;
  @Output() viewStudies = new EventEmitter<any>();
  measureForm: FormGroup;
  submitted = false;
  loading = false;
  message = '';
  action = '';
  isUpdate = false;
  thecoowners: string[];
  animal: string;
  name: string;
  userdetails: any;
  user_role: string;
  user_id: string;
  access_measure_file = 'Access Measure File';
  is_measure_owner = false;
  loading_submit = false;
  can_edit = false;
  loadedmeasure: [];
  selectedValues: string[];
  materialsResp: any;
  isNewMeasure: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apirequestService: ApirequestService,
    public alertService: AlertService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private confirmationDialogService: ConfirmationDialogService
  ) {
  }

  get f() {
    return this.measureForm.controls;
  }

  ngOnInit() {
    this.isNewMeasure = true;
    this.route.params.subscribe(params => {
      this.id = params['id'] || '';
      this.userdetails = JSON.parse(localStorage.getItem('auth.user'));
      if (!_.isNull(this.userdetails)) {
        this.user_role = this.userdetails.role;
        this.user_id = this.userdetails.id;
      }
    });
    this.can_edit = true;
    this.createForm();
    this.getCoowners();
    this.getFormData();
    this.getMeasureDetails();
  }

  getMeasureDetails() {
    if (!_.isEmpty(this.id)) {
      const url = 'measures/details/details/' + this.id + '/';
      this.apirequestService.get(url)
        .subscribe(
          res => {
            if (_.isEqual(res.researcher.id, this.user_id)) {
              this.is_measure_owner = true;
              this.can_edit = true;
              this.loadedmeasure = res;
            } else {
              // this.can_edit = false;
              this.can_edit = true;
            }
            let coOwners = res.co_owners;
            let allCoOwners = [];
            coOwners.forEach(curr => {
              allCoOwners.push(
                {
                  "id": curr.id,
                  "email": curr.email,
                  "first_name": curr.first_name,
                  "last_name": curr.last_name,
                  "date_joined": curr.date_joined,
                  "institution": {
                    "id": curr.institution.id,
                    "name": curr.institution.name,
                  "role": curr.role,
                  "is_active": curr.is_active,
                  "qualifications": curr.qualifications,
                  "is_staff": curr.is_staff,
                  "is_superuser": curr.is_superuser,
                  "domains": curr.domains,
                  "link": curr.link,
                  "full_name": curr.full_name
                  }
                }
              )
            });
            this.selectedValues = allCoOwners;
          },
          error => {
            console.log(error);
          });
    }

  }

  onUploadedFile(data) {
    this.measureForm.get('access_measure_button').setValue(data.file_path);
  }

  goPrevious() {
    if (this.measureForm.dirty === true) {
      this.submitData('Draft');
    }
    // route to studies
    if (!_.isNil(this.id) || !_.isEmpty(this.id)) {
      this.viewStudies.emit(this.id);
    } else {
      return;
    }
  }

  saveData() {
    this.submitData('Draft');
    // this.router.navigate(['/dashboard/measure/' + this.id]);
  }

  submitData(save_as_value) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.measureForm.invalid) {
      return;
    }
    this.loading = true;
    this.measureForm.get('id').setValue(this.id);
    const theForm = this.measureForm.value;
    const coownersvalue = this.measureForm.get('co_owners').value;
    if (coownersvalue) {
      const coownersdata = [];
      coownersvalue.forEach(function (element) {
        const obj = {user_id: element};
        coownersdata.push(obj);
      });
      theForm.co_owners = coownersdata;
    }
    if (save_as_value) {
      theForm.save_as = save_as_value;
    }
    var msg;
    if (save_as_value === 'Submit') {
      msg = '';
    } else {
      msg = 'Measure saved as draft';
    }
    const url = 'measures/update/materials/';
    this.apirequestService.post(url, theForm)
      .pipe(first())
      .subscribe(
        data => {
          this.id = data.id;
          this.snackBar.openFromComponent(CustomSnackBarComponent, {
            data: {
              message: msg,
              action: 'Success'
            },
            duration: 3 * 1000,
            panelClass: ['transparent-snack-bar']
          });
          this.loading = false;
          this.router.navigate(['/dashboard/measure/' + this.id]);
        },
        error => {
          this.alertService.error(error, '');
          this.loading = false;
        });

  }

  sendForReview(destination) {
    this.loading_submit = true;
    this.submitData('Submit');
    this.loading_submit = false;
    this.router.navigate(['/dashboard/measure/' + this.id]);
    this.confirmationDialogService.confirm('Confirm submission for review', 'Are you sure you want to submit this measure for review?', 'Yes Submit')
      .then((confirmed) => {
        if (confirmed == true) {
          const data = {
            'measure_id': this.id,
          };
          const url = 'measures/submit_for_approval/';
          this.loading_submit = true;
          this.apirequestService.post(url, data)
            .pipe(first())
            .subscribe(
              data => {
                this.id = data.id;
                this.snackBar.openFromComponent(CustomSnackBarComponent, {
                  data: {
                    message: data,
                    action: 'Success'
                  },
                  duration: 3 * 1000,
                  panelClass: ['transparent-snack-bar']
                });
                this.loading_submit = false;
                // this.router.navigate(['/dashboard/measure/' + this.id]);
              },
              error => {
                this.alertService.error(error, '');
                this.loading_submit = false;
              });
        }
      });
    this.router.navigate(['/dashboard/measure/' + this.id]);
  }

  private createForm() {
    this.measureForm = this.formBuilder.group({
      id: this.id,
      materialsid: '',
      access_measure_button_is_task: ['', Validators.required],
      access_measure_button: [''],
      demo_link: [''],
      co_owners: [''],
      additional_comments_or_notes: [''],
      save_as: [''],
    });
  }

  private getFormData() {
    if (this.id) {
      this.isUpdate = true;
      const url = 'measures/details/materials/' + this.id + '/';
      var can_edit_ = false;
      this.apirequestService.get(url)
        .subscribe(
          res => {
            this.materialsResp = res;
            this.measureForm.patchValue(res, {onlySelf: true});
            this.measureForm.get('materialsid').setValue('1');
            console.log(`measureForm=${JSON.stringify(this.measureForm)}`);
            // this.isNewMeasure = false;
            var user_ = JSON.parse(localStorage.getItem('auth.user'));
            if (res.researcher.id == user_['id'] || user_['role'] == 'Admin' || user_['role'] == 'System Admin') {
              can_edit_ = true;
            } else {
              res.co_owners.forEach(function (co_owner) {
                if (co_owner['id'] == user_['id']) {
                  can_edit_ = true;
                }
              });
            }

            if (can_edit_ == true) {
              this.can_edit = true;
            }
          },
          error => {
            console.log(error);
          });
    }
  }

  private getCoowners() {
    const url = 'users/users/';
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.thecoowners = res.results;
        },
        error => {
          console.log(error);
        });
  }

  private sendForReviewByResearcher(destination) {
    const dialogRef = this.dialog.open(SendForReviewComponent, {
      data: {measure_id: this.id, destination: 'Researcher'}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.animal = result;
    });
  }


  // sendBackForReviewByResearcher
  private sendBackForReviewByResearcher(destination, status) {
    const dialogRef = this.dialog.open(SendForReviewComponent, {
      data: {measure_id: this.id, destination: 'Researcher', status: status, user_role: this.user_role}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.animal = result;
    });
  }

  // approveMeasure
  private approveMeasure(destination, status) {
    const url = 'measures/approve_reject_measure/';
    const conf_ = confirm('Are you sure you want to approve this measure?');
    if (conf_ === true) {
      const senddata = {
        'status': 'Approved',
        'measure_id': this.id,
        'comments': 'Approved'
      };
      this.apirequestService.post(url, senddata).subscribe(
        data => {
          alert('Measure successfully approved.');
        },
        error => {
          console.log(error);
        });
    } else {
      // alert('Y');
    }
  }
  private formatObject(obj: any) {
 
  }

  saveAndPublish(destination, action='APPROVE') {
    this.loading_submit = true;
    this.submitData('Submit');
    this.loading_submit = false;
    this.router.navigate(['/dashboard/measure/' + this.id]);
    this.confirmationDialogService.confirm('Confirm publishing measure', 'Are you sure you want to publish this measure?', 'Yes Publish')
      .then((confirmed) => {
        if (confirmed == true) {
          let data = {
            'measure_id': this.id,
          };
          if(action == 'PUBLISH'){
            data['action'] = 'PUBLISH';
          }
          const url = 'measures/submit_for_approval/';
          this.loading_submit = true;
          this.apirequestService.post(url, data)
            .pipe(first())
            .subscribe(
              data => {
                this.id = data.id;
                this.snackBar.openFromComponent(CustomSnackBarComponent, {
                  data: {
                    message: data == 'Measure successfully submitted for review'? 'Measure successfully published': data,
                    action: 'Success'
                  },
                  duration: 3 * 1000,
                  panelClass: ['transparent-snack-bar']
                });
                this.loading_submit = false;
                // this.router.navigate(['/dashboard/measure/' + this.id]);
              },
              error => {
                this.alertService.error(error, '');
                this.loading_submit = false;
              });
        }
      });
    // this.router.navigate(['/dashboard/measure/' + this.id]);
  }
}
