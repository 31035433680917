import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {ApirequestService} from '../../../shared/services/apirequest.service';
import {User} from '../../../shared/models/user';

@Component({
  selector: 'app-measuresdetails',
  templateUrl: './userdetails.component.html',
  styleUrls: ['./userdetails.component.scss']
})
export class UserDetailsComponent implements OnInit {
  user: User;
  studies = [];
  loading_user_details = true;

  constructor(
    private apirequestService: ApirequestService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(
      params => {
        this.loadData(params['id']);
      },
      error => {
        console.log('Error:: ' + error.error.message);
      });
  }

  private loadData(id) {
    const url = 'users/user-details/' + id + '/';
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.user = res;
          this.loading_user_details = false;
        },
        error => {
          console.log(error);
        });
  }
}
