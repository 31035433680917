import {Component, OnInit, Input} from '@angular/core';
import {ApirequestService} from '../../../../../shared/services/apirequest.service';
import {MatTableDataSource} from '@angular/material';
import * as moment from 'moment/moment';
import {Router} from '@angular/router';
import {FileService} from 'src/app/shared/services/file.service';
import * as _ from 'lodash';
import choice from '../../../../../shared/choices';

import {CustomSnackBarComponent} from '../../../../../shared/common/custom-snack-bar/custom-snack-bar.component';
import {ConfirmationDialogService} from '../../../../../shared/confirmation-dialog/confirmation-dialog.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {AlertService} from '../../../../../shared/services/alert.service';

@Component({
  selector: 'app-dashboard-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.css']
})
export class RevisedDashboardAllUsersComponent implements OnInit {


  loading: boolean;
  start_date: string = '';
  end_date: string = '';
  url: string;
  topHeaders: string[] = ['channelGrouping', 'acquisition', 'behavior'];
  displayedColumns: string[] = ['totalUsers', 'usersWhoEngagedWithPlatform', 'newUsers', 'sessions', 'avgSessionDuration', 'bounceRate'];
  alldisplayedColumns: string[] = ['channelGrouping', 'totalUsers', 'usersWhoEngagedWithPlatform', 'newUsers', 'sessions', 'avgSessionDuration', 'bounceRate'];
  dataSource: MatTableDataSource<any>;
  institutionFiltersList = [];
  roleFiltersList = [{id: 'Researcher', name: 'Researcher'}, {id: 'Reviewing Scientist', name: 'Reviewing Scientist'}, {id: 'Admin', name: 'Admin'}];
  statusFiltersList = [{id: 'Active', name: 'Active'}, {id: 'Inactive', name: 'Inactive'}];
  domainsFiltersList = [];

  choices = choice;

  domains_url_filters = 'measures/domains_list/';
  institutions_list_url = 'users/users/';
  population_url_measurefilters = 'measures/population_list/';
  // Measurement mode
  language_url_measurefilters = 'measures/languages_list/';
  // duration
  // measure_source

  selectedInstitution: string;
  selectedRole: string;
  selectedStatus: string;
  selectedDomain: string;
  searchTerm: string;
  
  
  filter_url = {};
  page: number;
  total: number;
  page_size: number;
  total_pages: number;


  measures: any[];

  items = ['All', 'Pending', 'Published', 'Draft']; //, 'Returned'];
  // activeIndex: number | null = null;
  activeIndex: number = 0; // Set default to 0 (first item)

  loading_submit: boolean;
  @Input() user_id: string;


  constructor(
    private apirequestService: ApirequestService,
    private router: Router,
    private fileService: FileService,
    public snackBar: MatSnackBar,
    private confirmationDialogService: ConfirmationDialogService,
    public alertService: AlertService,
  ) {
  }

  ngOnInit() {
     // let today = new Date();
     this.page = 1;
     this.total = 0;
     this.page_size = 20;
     this.searchTerm = '';
     this.selectedInstitution = 'All';
     this.selectedRole = 'All';
     this.selectedStatus = 'All';
     this.selectedDomain = 'All';
  
  
    this.getFilters();
    this.getData();

  }


  getData() {
    this.loading = true;
    let params = `institution=${this.selectedInstitution}&role=${this.selectedRole}&status=${this.selectedStatus}&domains=${this.selectedDomain}&data=${this.searchTerm}`;
    
    this.apirequestService.get('users/users/?'+params)
        .subscribe(
          res => {
            this.total = res.count;
            this.measures = res.results;
            // this.page_size = res.page_size;
            this.page = res.current_page;
            this.total_pages = res.total_pages;
            this.loading = false;
            if(this.user_id != undefined && this.user_id != ''){
              console.log(`user_id=${this.user_id}`);
              this.measures = this.measures.filter(curr => curr.institution.id === this.user_id);
            }
          },
          error => {
            console.log(error);
            this.loading = false;
          });
  }

  getData2() {
    let params = `institution=${this.selectedInstitution}&role=${this.selectedRole}&status=${this.selectedStatus}&domains=${this.selectedDomain}&data=${this.searchTerm}`;
      this.apirequestService.get('users/search/?'+params)
        .subscribe(
          res => {
            this.total = res.count;
            this.measures = res.results;
            // this.page_size = res.page_size;
            this.page = res.current_page;
            this.total_pages = res.total_pages;
          },
          error => {
            console.log(error);
          });
  }

  navigateTo(link: any) {
    console.log(link.toLowerCase().replace(/\s/g, ''));
    this.router.navigate(['/statistics/traffic/' + link.toLowerCase().replace(/\s/g, '')]);
  }

  exportData() {
    var pageSize = 10000;
    this.url = 'analytics/channels/?start_date=' + this.start_date + '&end_date=' + this.end_date + '&export=True';
    this.loading = true;
    this.apirequestService.get(this.url)
      .subscribe(
        data => {
          this.loading = false;
          this.fileService.downloadFile(data.file_url);
        },
        error => {
          console.log(error);
          this.loading = false;

        });
  }

  getFilters(){
    this.apirequestService.get(this.domains_url_filters)
    .subscribe(
      res => {
        this.domainsFiltersList = res.results;
      },
      error => {
        console.log(error);
      });

  this.apirequestService.get(this.institutions_list_url)
    .subscribe(
      res => {
        this.institutionFiltersList = res.results;
      },
      error => {
        console.log(error);
      });
  }

  onSelectChange(item_name: string, event: any){
    const selectedValue = event.target.value;
    if(item_name == 'domains'){
      this.selectedDomain = selectedValue;
    }
    else if(item_name == 'institution'){
      this.selectedInstitution = selectedValue;
    }
    else if(item_name == 'role'){
      this.selectedRole = selectedValue;
    }
    if(item_name == 'status'){
      this.selectedStatus = selectedValue;
    }

    this.getData2();
  }


  formatDate(inputDate) {
    // Create a new Date object from the input date string
    const date = new Date(inputDate);

    // Extract the day, month, and year from the date object
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month starts from 0
    const year = date.getFullYear();

    // Pad day and month with leading zeros if necessary
    const paddedDay = day < 10 ? '0' + day : day;
    const paddedMonth = month < 10 ? '0' + month : month;

    // Format the date as "DD/MM/YYYY"
    const formattedDate = `${paddedDay}/${paddedMonth}/${year}`;

    return formattedDate;
}

isOdd(num: number): boolean {
  return num % 2 !== 0;
}

isMultipleOf4(number) {
  return number % 4 === 0;
}

onKeyUp(event: any) {
  // You can access the value of the input field using event.target.value
  const enteredValue = event.target.value;
  console.log('Entered value:', enteredValue);
  
  // Here you can perform any actions based on the entered value
  this.searchTerm = enteredValue;

  this.getData2();
}

filter_data(){
  this.getData2();
}

handleClick(index: number) {
  this.activeIndex = index;
  this.selectedStatus = this.items[index];
  this.getData2();
}
getItem(item: string, i: number){
  if(i < this.items.length - 1){
    return ` |`;
  }
  return ``;
}

saveAndPublish(destination, action='APPROVE', id) {
  this.loading_submit = true;
  // this.submitData('Submit');
  this.loading_submit = false;
  // this.router.navigate(['/dashboard/measure/' + this.id]);
  let msg1 = 'Confirm deactivating user.';
  let msg2 = 'Are you sure you want to deactivate this user?';
  let msg3 = 'Yes deactivate';

  if(action == 'PUBLISH'){
    msg1 = 'Confirm activating user.';
    msg2 = 'Are you sure you want to activate this user?';
    msg3 = 'Yes activate';

  }
  this.confirmationDialogService.confirm(msg1, msg2, msg3)
    .then((confirmed) => {
      if (confirmed == true) {
        let data = {
          'id': id,
        };
        let url = '';
        if(action == 'PULL_DOWN'){
          data['action'] = 'PULL_DOWN';
          url = 'users/delete-user/?id='+id+'&action=deactivate';
        }
        else if(action == 'PUBLISH'){
          data['action'] = 'PUBLISH';
          url = 'users/delete-user/?id='+id+'&action=activate';
        }
        // const url = 'users/delete-user/?id='+id+'action=deactivate';
        this.loading_submit = true;
        this.apirequestService.get(url)
          // .pipe(first())

          .subscribe(
            data => {
              // this.id = data.id;
              this.snackBar.openFromComponent(CustomSnackBarComponent, {
                data: {
                  message: 'User successfully deactivated.',
                  action: 'Success'
                },
                duration: 3 * 1000,
                panelClass: ['transparent-snack-bar']
              });
              this.loading_submit = false;
              this.getData();
            },
            error => {
              this.alertService.error(error, '');
              this.loading_submit = false;
            });
      }
    });
}

chosenUser(first_name: string, last_name: string){
  localStorage.setItem('chosen_user', `${first_name} ${last_name}`);

}


}

