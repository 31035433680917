import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';

import {SharedModule} from '../../../shared/shared.module';
import {AudienceComponent} from './audience/audience.component';
import {UserflowsComponent} from './userflows/userflows.component';
import {TechnologyComponent} from './technology/technology.component';
import {HighchartsChartModule} from 'highcharts-angular';
import {BrowserComponent} from './technology/browser/browser.component';
import {OsComponent} from './technology/os/os.component';
import {BehaviorsComponent} from './behaviors/behaviors.component';
import {GeodataComponent} from './geodata/geodata.component';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatAutocompleteModule,
  MatButtonModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  NativeDateModule
} from '@angular/material';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {BehaviorOverviewComponent} from './behavior-overview/behavior-overview.component';
import {PageViewsComponent} from './behavior-overview/page-views/page-views.component';
import {BehaviorSummaryComponent} from './behavior-overview/behavior-summary/behavior-summary.component';
import {ChannelsComponent} from './channels/channels.component';
import {DirectComponent} from './channels/direct/direct.component';
import {ReferralsComponent} from './channels/referrals/referrals.component';
import {OrganicSearchComponent} from './channels/organic-search/organic-search.component';
import {SocialComponent} from './channels/social/social.component';
import {MatSortModule} from '@angular/material/sort';
import {LanguageComponent} from './geodata/language/language.component';
import {DemographicsComponent} from './demographics/demographics.component';
import {GenderComponent} from './demographics/gender/gender.component';
import {AgeComponent} from './demographics/age/age.component';


const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
    HighchartsChartModule,
    MatInputModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelectModule,
    MatSortModule,
    NativeDateModule,
  ],
  declarations: [
    AudienceComponent,
    TechnologyComponent,
    BrowserComponent,
    OsComponent,
    BehaviorsComponent,
    GeodataComponent,
    UserflowsComponent,
    BehaviorOverviewComponent,
    PageViewsComponent,
    BehaviorSummaryComponent,
    ChannelsComponent,
    DirectComponent,
    ReferralsComponent,
    OrganicSearchComponent,
    SocialComponent,
    LanguageComponent,
    DemographicsComponent,
    GenderComponent,
    AgeComponent

  ], providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}

  ]


})
export class GoogleAnalyticsModule {
}
