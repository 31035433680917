import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from './message.service';

@Component({
  selector: 'app-author-design',
  templateUrl: './author-design.component.html',
  styleUrls: ['./author-design.component.css']
})
export class AuthorDesignComponent implements OnInit {
  index: string; //number;
  topItemIndex: any;
  bottomItemIndex: any;
  firstName: string;
  lastName: string;
  allAuthors = [];
  @Input() data: any;

  constructor(private messageService: MessageService) { }

  ngOnInit() {
    if(this.data != undefined){
      this.index = this.data.index;
      this.topItemIndex = this.data.topItemIndex;
      this.bottomItemIndex = this.data.bottomItemIndex;
      this.firstName = this.data.firstName;
      this.lastName = this.data.lastName;
    }
  }

  sendMessageToParentComponent(type: string, index: number) {
    this.messageService.sendMessage(
      {
        type: type,
        index: this.index, //== 'remove'? index : index + 1,
        topItemIndex: this.topItemIndex,
        bottomItemIndex: this.bottomItemIndex,
      }
    );
  }

  updateAuthorDetails(source: string, event: any) {
    // You can access the value of the input field using event.target.value
    const enteredValue = event.target.value;
    console.log('Entered value:', enteredValue);
    if(source == 'last_name'){
      this.lastName = enteredValue;
    }
    else if(source == 'first_name'){
      this.firstName = enteredValue;
    }
    this.messageService.sendMessage(
      {
        type: 'update_record',
        index: this.index, //== 'remove'? index : index + 1,
        topItemIndex: this.topItemIndex,
        bottomItemIndex: this.bottomItemIndex,
        data: {
          id: this.index,
          firstName: this.firstName,
          lastName: this.lastName,
        }
      }
    );
  }

}
