import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import {MatDialog} from '@angular/material';

import {ApirequestService} from '../../../shared/services/apirequest.service';
import {ShareCollectionComponent} from '../share-collection.component';

@Component({
  selector: 'app-list-folder-measures',
  templateUrl: './list-folder-measures.component.html',
  styleUrls: ['./list-folder-measures.component.scss']
})
export class ListFolderMeasuresComponent implements OnInit {
  measures: Array<any>;
  id: string;
  folder_name: string;
  page = 1;
  total = 0;
  limit = 10;
  total_pages = 0;
  url_params;
  page_size = 0;

  constructor(
    private apirequestService: ApirequestService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'] || '';
      this.folder_name = params['name'] || '';
      this.page = params['page'] || '1';
    });
    this.getMyCollectionFolders();
  }

  goToPage(n: number): void {
    this.page = n;
    this.getMyCollectionFolders();
  }

  onNext(): void {
    this.page++;
    this.getMyCollectionFolders();
  }

  onPrev(): void {
    this.page--;
    this.getMyCollectionFolders();
  }

  shareMeasure(measure) {
    // pop up to add users
    const dialogRef = this.dialog.open(ShareCollectionComponent, {
      panelClass: 'customPanelClass',
      data: {
        measure: measure,
        type: 'measure',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getMyCollectionFolders();
    });
  }

  private getMyCollectionFolders() {
    this.url_params = {'page': this.page.toString()};
    const params = new HttpParams()
      .set('page', this.page.toString());
    const url = 'my_collection/measure_in_folder/' + this.id + '/';
    this.apirequestService.get(url, params)
      .subscribe(
        res => {
          this.total = res.count;
          this.page_size = res.page_size;
          this.page = res.current_page;
          this.total_pages = res.total_pages;
          this.measures = res.results;
          this.router.navigate([], {relativeTo: this.route, queryParams: this.url_params});
        },
        error => {
          console.log(error);
        });
  }


}
