import {Component, OnInit} from '@angular/core';

import {ApirequestService} from '../../shared/services/apirequest.service';
import {ResearcherDashboardModel} from '../../shared/models/researcher_dashboard';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  dashboard_details: ResearcherDashboardModel;

  constructor(
    private apirequestService: ApirequestService,
  ) {
  }

  ngOnInit() {
    const url = 'measures/dashboard/';
    this.apirequestService.get(url)
      .subscribe(
        data => {
          this.dashboard_details = data;
        },
        error => {
          console.log(error);
        });
  }

}
