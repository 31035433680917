import {Component, ElementRef, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import * as _ from 'lodash';
import {saveAs as importedSaveAs} from 'file-saver';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';

import {ApirequestService} from './../../../../../shared/services/apirequest.service';
import {GoogleAnalyticsService} from './../../../../../shared/services/google-analytics.service';
import {AuthenticationService} from './../../../../../shared/services/authentication.service';

import {Measures} from './../../../../../shared/models/measures';
import {GoogleScholar} from './../../../../../shared/models/googlescholar';

import {ShareCollectionComponent} from './../../../../my-collection/share-collection.component';
import {TheorytechniquestoolDialogService} from './../../../../../shared/theorytechniquestool-dialog/theorytechniquestool-dialog.service';
import {Utility} from './../../../../../shared/common/utility';

import {AlertService} from './../../../../../shared/services/alert.service';
import {CustomSnackBarComponent} from './../../../../../shared/common/custom-snack-bar/custom-snack-bar.component';
import {ConfirmationDialogService} from './../../../../../shared/confirmation-dialog/confirmation-dialog.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-citations-dialog',
  template: `
    <h1 mat-dialog-title class="text-capitalize">
      How to export
      <span class="text-capitalize">{{data.citation_name}} </span>references:</h1>
    <div mat-dialog-content>
      <ol>
        <li>
          {{citation_instruction}}
        </li>
        <li> Click the <b style="color: #0076ff">Export</b> button below.
          A file with the <b>{{data.extension}} </b>
          extension will be downloaded.
        </li>
        <li>
          Open Your favorite citation Software and import the file downloaded in 1) above.
        </li>
      </ol>
    </div>
    <div mat-dialog-action>
      <button class="btn btn-danger btn-sm" (click)="onNoClick()">Cancel</button>
      <button class="btn btn-primary btn-sm ml-2" (click)="downloadCitation(data.citation_type)">
        <i class="fa fa-circle-o-notch  fa-spin" *ngIf="loading"></i>
        Export
      </button>
    </div>
  `,
})
export class CitationsDialogComponent implements OnInit {
  citation_description: string;
  citation_instruction: string;
  loading = false;


  constructor(
    public dialogRef: MatDialogRef<CitationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private apirequestService: ApirequestService,
  ) {
  }

  ngOnInit() {

    this.citation_instruction = (this.data.citation_name === 'BibTex') ?
      'Ensure you have BibTex referencing software installed in your computer.' :
      'Ensure you have ' + this.data.citation_name + ' installed in your computer.';

  }


  downloadCitation(type) {
    this.loading = true;
    const url = 'measures/export_citations/' + this.data.measure_id + '/' + type + '/';
    this.apirequestService.getFile(url)
      .subscribe(
        response => {
          let extension = '.bib';
          if (type === 'bibtext') {
            extension = '.bib';
          } else if (type === 'endnote') {
            extension = '.xml';
          } else if (type === 'refworks' || type === 'zotero') {
            extension = '.enw';
          } else if (type === 'mendeley') {
            extension = '.irs';
          }
          importedSaveAs(response, this.data.measure_title + '_Citations' + extension);
          this.loading = false;
          this.dialogRef.close();
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}


@Component({
  selector: 'app-revised-dashboard-studydetail',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class StudyEvidenceDetailsComponent implements OnInit {
  measures: Measures;

  osfDetails = [];
  hasOsf = false;
  studies = [];
  identifieddata: any;
  measureddata: any;
  influenceddata: any;
  validateddata: any;
  relatedMeasures = [];
  loadingRelatedMeasures = true;
  creditDetails = [];
  proposeMechanismData = [];
  proposedMechanismClicked = false;
  identified = false;
  measured = false;
  influenced = false;
  validated = false;
  citeMeasure = false;
  loading_related_articles = true;
  loading_osf_details = true;
  loading_credit_details = true;
  loading_comments = true;
  theStars = [];
  load_reviewers = false;
  id: string;
  comments;
  isLoggedIn: boolean;
  page = 1;
  comments_total = 0;
  limit = 10;
  total_pages = 0;
  page_size = 0;
  survey_count = false;
  entries: string;
  toLoadGoogleScholar = false;
  googleScholarDetails: GoogleScholar;
  title: string;
  mechanisms_and_technics_count = 0;
  mechanisms_and_technics = [];
  measureTitle: string;
  parentMeasureTitle: string;
  userdetails: any;
  user_role: string;
  identifiedCitation: boolean;
  measuredDemographics: boolean;
  measuredCitation: boolean;
  identifiedDemographics: boolean;
  validatedCitation: boolean;
  validatedDemographics: boolean;
  influencedDemographics: boolean;
  influencedCitation: boolean;
  loadingMeasure: boolean = true;
  study: any;


  study_id: string;
  destination: string;
  can_edit = false;
  loaded_measure: string;
  commentsForm: FormGroup;
  loading_submit: boolean;
  show_review: boolean;
  user_id: string;
  action: string;
  new_update_id: string;

  @ViewChild('googleScholarCollapse', {read: ElementRef}) googleScholarCollapse: ElementRef;

  constructor(
    private apirequestService: ApirequestService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router,
    public dialog: MatDialog,
    public googleAnalyticsService: GoogleAnalyticsService,
    private theorytechniquestoolDialogService: TheorytechniquestoolDialogService,
    private elementRef: ElementRef,
    private utility: Utility,
    private formBuilder: FormBuilder,
    public alertService: AlertService,
    public snackBar: MatSnackBar,
    private confirmationDialogService: ConfirmationDialogService,
  ) {
  }

  ngOnInit() {
    this.loading_submit = false;
    this.user_id = JSON.parse(localStorage.getItem('auth.user'));
    this.createCommentsForm();
    this.route.params.subscribe(params => {
      this.id = params['id'] || '';
      this.action = params['action'] || undefined;
      this.userdetails = JSON.parse(localStorage.getItem('auth.user'));
      if (!_.isNull(this.userdetails)) {
        this.user_role = this.userdetails.role;
      }
    });
    this.loadData();
    this.getComments();
    this.utility.initSwiper(this.elementRef);
  }

  shareMeasure(measure) {
    // pop up to add users
    const dialogRef = this.dialog.open(ShareCollectionComponent, {
      panelClass: 'customPanelClass',
      data: {
        measure: this.measures,
        type: 'measure',
      },
    });
  }

  private createCommentsForm() {
    this.commentsForm = this.formBuilder.group({
      comments: ['', Validators.required],
    });
  }

  openCitationDialogue(type, ext, name) {
    this.dialog.open(CitationsDialogComponent, {
      data: {
        citation_type: type,
        measure_id: this.id,
        measure_title: this.measures.title,
        extension: ext,
        citation_name: name
      },
      position: {
        left: '20%'
      }
    });
  }

  // submitComment(e) {
  //   const data = {
  //     'study_id': this.id,
  //     'comment': e
  //   };
  //   const url = 'study_evidence/comment/';
  //   this.apirequestService.post(url, data)
  //     .subscribe(
  //       res => {
  //         this.isLoggedIn = false;
  //         this.getComments();
  //       },
  //       error => {
  //         console.log(error);
  //       });
  // }

  submitComment(e) {
    const data = {
      'study_id': this.id,
      'status': 'rejected',
      'comments': e,
    };
    const url = 'study_evidence/approve_reject_study/';
    this.apirequestService.post(url, data)
      .subscribe(
        res => {
          this.isLoggedIn = false;
          this.getComments();
          this.snackBar.openFromComponent(CustomSnackBarComponent, {
            data: {
              message: res.message,
              action: 'Success'
            },
            duration: 3 * 1000,
            panelClass: ['transparent-snack-bar']
          });
          this.getComments();
          // this.loading = false;
        },
        error => {
          console.log(error);
          // this.loading = false;
        });
  }

  downloadMeasure(title) {
    const data = {
      'measure_id': this.id,
    };
    const url = 'measures/download_count/';
    this.apirequestService.post(url, data)
      .subscribe(
        error => {
          console.log(error);
        });
    this.googleAnalyticsService.eventEmitter('download_measure_detail', 'measure', 'click', title, 1);
  }

  goToPage(n: number): void {
    this.page = n;
    this.getComments();
  }

  onNext(): void {
    this.page++;
    this.getComments();
  }

  onPrev(): void {
    this.page--;
    this.getComments();
  }

  private loadData() {
    let url = 'study_evidence/details/' + this.id + '/';
    if(this.action != undefined){
      url = `${url}?action=get-revision`;
    }
    else{
      url = `${url}?action=get-details`;
    }
    this.loadingMeasure = true;
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.loadingMeasure = false;
          if(this.action != undefined){
            this.new_update_id = res.id;
          }

          this.study = res;
          this.title = res.title;
          // this.measures = res;
          this.parentMeasureTitle = this.title;
          this.measureTitle = this.title;

          if (this.user_id['id'] === res.uploaded_by || this.user_role == 'Admin') {
            this.show_review = false;
          }
         
          if (this.user_id['role'] === 'Reviewing Scientist' || this.user_id['role'] === 'Admin') {
            this.show_review = true;
          } else {
            this.show_review = false;
          }

          
        },
        error => {
          console.log(error);
          this.loadingMeasure = false;
        });

    this.parentMeasureTitle = this.title;
    this.measureTitle = this.title;

  }


  private getComments() {
    const url = 'study_evidence/comments/' + this.id + '/';
    const params = new HttpParams()
      .set('page', this.page.toString());
    this.apirequestService.get(url, params)
      .subscribe(
        res => {
          this.comments = res;
          this.comments_total = res.count;
          this.page_size = res.page_size;
          this.page = res.current_page;
          this.total_pages = res.total_pages;
          this.loading_comments = false;
          this.entries = (this.comments_total > 1) ? 'Comments' : 'Comment';
        },
        error => {
          console.log(error);
        });
  }

  saveAndPublish(destination, action='APPROVE') {
    this.loading_submit = true;
    // this.submitData('Submit');
    this.loading_submit = false;
    // this.router.navigate(['/dashboard/measure/' + this.id]);
    this.confirmationDialogService.confirm('Confirm publishing study', 'Are you sure you want to publish this study?', 'Yes Publish')
      .then((confirmed) => {
        if (confirmed == true) {
          let data = {
            'study_id': this.id,
          };
          if(action == 'PUBLISH'){
            data['action'] = 'PUBLISH';
          }
          if(this.action != undefined){
            data['new_update_id'] = this.new_update_id;
          }
          else{
            data['new_update_id'] = '-';
          }
          const url = 'study_evidence/submit_for_approval/';
          this.loading_submit = true;
          this.apirequestService.post(url, data)
            // .pipe(first())
            .subscribe(
              data => {
                // this.id = data.id;
                this.snackBar.openFromComponent(CustomSnackBarComponent, {
                  data: {
                    message: data == 'Study successfully submitted for review'? 'Study successfully published': data,
                    action: 'Success'
                  },
                  duration: 3 * 1000,
                  panelClass: ['transparent-snack-bar']
                });
                this.loading_submit = false;
                // this.router.navigate(['/dashboard/measure/' + this.id]);
              },
              error => {
                this.alertService.error(error, '');
                this.loading_submit = false;
              });
        }
      });
    // this.router.navigate(['/dashboard/measure/' + this.id]);
  }


  loggedIn(type) {
    if (this.auth.isAuthenticated()) {
      this.isLoggedIn = true;
      if (type === 'collection') {
        this.router.navigate(['/dashboard/mycollection/' + this.id + '/'], {queryParams: {returnUrl: 'measuredetails/' + this.id}});
      } else if (type === 'share') {
        // this.shareMeasure(this.measures);
      }
    } else {
      if (type === 'comment' || type === 'share') {
        this.router.navigate(['/login'], {queryParams: {returnUrl: 'measuredetails/' + this.id}});
      } else {
        this.router.navigate(['/login'], {queryParams: {returnUrl: '/dashboard/mycollection/' + this.id}});
      }
    }
  }

  public openConfirmationDialog() {
    this.confirmationDialogService.confirm('Confirm Approval', 'Are you sure you want to approve this study?')
      .then((confirmed) => {
        if (confirmed == true) {
          const data = {
            'study_id': this.id,
            'status': 'approved',
            'comments': 'Approved',
          };
          const url = 'study_evidence/approve_reject_study/';
          this.apirequestService.post(url, data)
            .subscribe(
              res => {
                this.isLoggedIn = false;
                this.getComments();
                this.snackBar.openFromComponent(CustomSnackBarComponent, {
                  data: {
                    message: res.message,
                    action: 'Success'
                  },
                  duration: 3 * 1000,
                  panelClass: ['transparent-snack-bar']
                });
                // this.loading = false;
              },
              error => {
                console.log(error);
                // this.loading = false;
              });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
}
