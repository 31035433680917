import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import {saveAs as importedSaveAs} from 'file-saver';
import * as _ from 'lodash';

import {ApirequestService} from '../../shared/services/apirequest.service';
import {AlertService} from '../../shared/services/alert.service';

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.scss']
})
export class SurveysComponent implements OnInit {

  loading = false;
  surveys = [];
  page = 1;
  total = 0;
  limit = 10;
  total_pages = 0;
  page_size = 0;
  measure_id: string;
  measure_owner = false;
  measure;

  constructor(
    private apirequestService: ApirequestService,
    private route: ActivatedRoute,
    public alertService: AlertService,
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.measure_id = params['measure_id'] || '';
    });
    this.getSurveys();
    this.getMeasureDetails();
  }

  getSurveys() {
    this.loading = true;
    const url = 'surveys/surveys/';
    const params = new HttpParams()
      .set('page', this.page.toString());
    this.apirequestService.get(url + this.measure_id + '/', params)
      .subscribe(
        res => {
          this.total = res.count;
          this.page_size = res.page_size;
          this.surveys = res.results;
          this.total_pages = res.total_pages;
          this.loading = false;
        },
        error => {
          console.log(error);
        });
  }

  goToPage(n: number): void {
    this.page = n;
    this.getSurveys();
  }

  onNext(): void {
    this.page++;
    this.getSurveys();
  }

  onPrev(): void {
    this.page--;
    this.getSurveys();
  }

  getMeasureDetails() {
    const url = 'measures/details/' + this.measure_id + '/';
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.measure = res;
          this.measure_owner = _.isEqual(res.researcher.id, JSON.parse(localStorage['auth.user']).id);
        },
        error => {
          console.log(error);
        });
  }

  export(survey) {
    const url = 'surveys/export_responses/';
    const params = new HttpParams()
      .set('survey_id', survey.id);

    this.apirequestService.getFile(url, params)
      .subscribe(
        res => {
          importedSaveAs(res, survey.name + '.csv');
          this.alertService.success('Exporting survey responses for ' + survey.name);
        },
        error => {
          console.log(error);
        });
  }
}
