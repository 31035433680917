import {Component, OnDestroy, OnInit, ElementRef, Renderer2, ViewChild} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import * as _ from 'lodash';

import {ApirequestService} from '../../shared/services/apirequest.service';
import {ApirequestdetailService} from '../../shared/services/apirequestdetail.service';
import {GoogleAnalyticsService} from '../../shared/services/google-analytics.service';
import {debounceTime} from 'rxjs/operators';
// my-component.component.ts

// Define a custom class
class  IndividualCategory{
  id: number;
  name: string;
  constructor(id, name, ) {
    this.id = id;
    this.name = name;
  }
}

@Component({
  selector: 'app-sobc-resource-details',
  templateUrl: './measures.component.html',
  styleUrls: ['./measures.component.scss', 'font-custom.css'],
})
export class SobcResourceDetailsComponent implements OnDestroy, OnInit {
  // @ViewChild('myDiv') myDiv: ElementRef;
  @ViewChild('filters-selected', { read: ElementRef }) container: ElementRef;
  measures = [];
  resources = [];
  experiments = [];
  page: number;
  total = 0;
  total_pages = 0;
  thefilters = {};
  page_size = 10;
  sort_fields: any;
  search_data: any;
  route_stage: string;
  url_params;
  access_measure: boolean;
  loading = true;
  subscription: Subscription;

  collapse = 'closed';
  btncollapse = 'not_clicked';
  show = false;
  showText = false;
  isMainmeasuredetails = false;
  search_input: string;
  showMeasures = true;
  showExperiments = false;
  // Experiments pagination
  page_experiments: number;
  total_experiments = 0;
  total_pages_experiments = 0;
  thefilters_experiments = {};
  page_size_experiments = 10;
  loading_experiments = true;
  showFullMeasureHeader = false;
  resourcesFilters = [];
  filterCategories = [];
  searchedText = "";
  private searchTerms = new Subject<string>();
  private selectedResourcesFilter = new Subject<string>();
  dataValue: {};
  downloads = [];
  video = "";
  combinedArray = [];

  constructor(
    private apirequestService: ApirequestService,
    private apirequestdetailService: ApirequestdetailService,
    private route: ActivatedRoute,
    private router: Router,
    public googleAnalyticsService: GoogleAnalyticsService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {

  }
  ngOnDestroy(): void {
    // throw new Error('Method not implemented.');
  }


  toogelDisplay(item) {
    if (item == 'measures') {
      this.showMeasures = true;
      this.showExperiments = false;
    } else {
      this.showMeasures = false;
      this.showExperiments = true;
    }
  }

  ngOnInit() {

    
		let data = JSON.parse(localStorage.getItem('data'));
		this.dataValue = data;
		console.log(data);

		
		let dwns = [];
		let downloads_ = JSON.parse(localStorage.getItem('data')).downloads;
		let links_ = JSON.parse(localStorage.getItem('data')).links;
    const combinedArray = [...downloads_, ...links_];
    this.combinedArray = combinedArray;

    this.video = data.video;
    // if(vd != ''){
    //   this.video = vd;
    // }



  }
  getFileName = (details: string) => {
    let k = details.split("/");
		let fileName = k[k.length - 1];

    return fileName;
    // return details;
  }

  // sanitizeHtml = (htmlContent) => {
  //   return DOMPurify.sanitize(htmlContent);
  // };
  indexOf(details: string, filetype: string){
    if(details.indexOf(filetype) != -1){
      return true;
    }
    return false;

  }
}
