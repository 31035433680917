import Swiper, {Navigation} from 'swiper';
import {ElementRef, Injectable} from '@angular/core';

@Injectable()
export class Utility {
  public clearArray(array) {
    while (array.length) {
      array.pop();
    }
  }

  isElementLoaded = async selector => {
    while (document.querySelector(selector) === null) {
      await new Promise(resolve => requestAnimationFrame(resolve));
    }
    return document.querySelector(selector);
  }

  public initSwiper(elementRef: ElementRef) {
     this.isElementLoaded('.swiper').then((selector) => {
      let swiper = new Swiper(elementRef.nativeElement.querySelector('.swiper'), {
      modules: [Navigation],
      rewind: true,
      slidesPerView: 3,
      spaceBetween: 15,
      slidesPerGroup: 3,
      loop: true,
      loopFillGroupWithBlank: true,
      navigation: {
        nextEl: '.carousel-control-next',
        prevEl: '.carousel-control-prev',
      },
      breakpoints: {
        '@0.00': {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        '@0.75': {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        '@1.00': {
          slidesPerView: 3,
          spaceBetween: 15,
        },
        '@1.50': {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
    });
    });

  }
}
