import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard} from '../../shared/_guards/auth.guard';

import {AppLayoutComponent} from '../layout/app-layout/app-layout.component';
import {DashboardNavigationComponent} from '../layout/dashboard-navigation/dashboard-navigation.component';
import {CreateUserComponent, RegistrationRedirectComponent} from './create_user.component';
import {ListUsersComponent, PendingInstitutionApprovalComponent} from './list-users/list-users.component';
import {UserDetailsComponent} from './user_details/userdetails.component';
import {ConfirmEmailComponent, ResetPasswordComponent} from './reset-password/reset-password.component';
import {ManageAccountComponent, UpdateProfileComponent} from './manage-account/manage-account.component';


export const usersRoutes: Routes = [
  {
    path: 'users', component: AppLayoutComponent,
    children: [
      {path: 'register', component: CreateUserComponent},
      {path: 'verify_email', component: RegistrationRedirectComponent},
      {path: 'userdetails', component: UserDetailsComponent},
      {path: 'password_reset/:sign_up_token/:id', component: ResetPasswordComponent},
      {path: 'confirm_email', component: ConfirmEmailComponent},
      {path: 'update_profile/:sign_up_token/:id', component: UpdateProfileComponent},
    ],
  },
  {
    path: 'dashboard-users', component: DashboardNavigationComponent,
    children: [
      {path: 'manage_account', component: ManageAccountComponent},
      {path: 'manage_account/:id', component: ManageAccountComponent},
      {path: 'users', component: ListUsersComponent},
      {path: 'pending_institution_approval', component: PendingInstitutionApprovalComponent},
    ],
    canActivate: [AuthGuard]
  },
];


@NgModule({
  imports: [
    RouterModule.forChild(usersRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class UsersRouterModule {
}
