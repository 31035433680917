import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import * as _ from 'lodash';

import {ApirequestService} from '../../shared/services/apirequest.service';

@Component({
  selector: 'app-pending-review',
  templateUrl: './pending_review.component.html',
  styleUrls: ['./pending_review.component.scss']
})
export class PendingReviewComponent implements OnInit {
  loading = true;
  measures = [];
  page: number;
  total = 0;
  limit = 10;
  total_pages = 0;
  page_size = 0;
  accessbutton = {};
  userdetails: any;
  user_role: string;
  url_params;

  constructor(
    private apirequestService: ApirequestService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.page = params['page'] || '1';
    });
    this.userdetails = JSON.parse(localStorage.getItem('auth.user'));
    if (!_.isNull(this.userdetails)) {
      this.user_role = this.userdetails.role;
    }
    this.getDataByPage();
  }

  getDataByPage() {
    this.url_params = {
      'page': this.page.toString(),
    };
    const params = new HttpParams({
      fromObject: this.url_params
    });
    params.set('user_role', this.user_role);

    this.apirequestService.get('measures/pending_approval/', params)
      .subscribe(res => {
          this.page_size = res.page_size;
          this.total = res.count;
          this.measures = res.results;
          this.loading = false;
          this.total_pages = res.total_pages;
          this.router.navigate([], {relativeTo: this.route, queryParams: this.url_params});
        },
        error => {
          console.log(error);
        });
  }

  goToPage(n: number): void {
    this.page = n;
    this.getDataByPage();
  }

  onNext(): void {
    this.page++;
    this.getDataByPage();
  }

  onPrev(): void {
    this.page--;
    this.getDataByPage();
  }

}
