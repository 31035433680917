import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';

import {ApirequestService} from '../../../shared/services/apirequest.service';
import {AlertService} from '../../../shared/services/alert.service';
import {CustomSnackBarComponent} from '../../../shared/common/custom-snack-bar/custom-snack-bar.component';

@Component({
  selector: 'app-studies',
  templateUrl: './studies.component.html',
  styleUrls: ['./studies.component.scss']
})
export class StudiesComponent implements OnInit {
  @Input() id: string;
  @Output() viewDetails = new EventEmitter<any>();
  @Output() viewMaterials = new EventEmitter<any>();
  measureForm: FormGroup;
  submitted = false;
  loading = false;
  isUpdate = false;
  thereviewingScientists = [];
  supporting_document_file = 'Supporting Document File';
  can_edit = false;
  studiesResp: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apirequestService: ApirequestService,
    public snackBar: MatSnackBar,
    public alertService: AlertService,
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'] || '';
      if (this.id == '') {
        this.can_edit = true;
      }
      this.router.navigate(['/dashboard/measure/' + this.id]);
    });
    this.createForm();
    this.getReviewingScientists();
    this.getFormData();
  }

  getReviewingScientists() {
    const url = 'users/users/';
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.thereviewingScientists = res.results;
        },
        error => {
          console.log(error);
        });
  }

  initStudy(): FormGroup {
    return this.formBuilder.group({
      id: [''],
      stage: ['', Validators.required],
      project: ['', Validators.required],
      osf_link: ['', Validators.required],
      description: ['', Validators.required],
      demographics: this.formBuilder.array([this.initDemographic()]),
      reagion: ['', Validators.required],
      supporting_document: this.formBuilder.array([this.initSupportingDocument()]),
      scientists: [''],
      reviewing_scientists: [''],
      pmcid_pubmedid_or_citation: this.formBuilder.array([this.initPmcidPubmedidCitation()])
    });
  }

  addStudy(): void {
    const control = <FormArray>this.measureForm.get('studies');
    control.push(this.initStudy());
  }

  removeStudy(form, j) {
    const control = form.controls.studies;
    control.removeAt(j);
  }

  getstudiesData(form) {
    return form.controls.studies.controls;
  }

  setstudiesData(res) {
    const control = this.measureForm.get('studies') as FormArray;
    control.removeAt(0);
    let i = 0;
    res.studies.forEach((x) => {
      const reviewingscientistdata = [];
      const reviewingscientistvalue = x.reviewing_scientists;
      if (reviewingscientistvalue) {
        reviewingscientistvalue.forEach(function (element) {
          reviewingscientistdata.push(element.id);
        });
        x.reviewing_scientists = [reviewingscientistdata];
      }
      control.push(this.formBuilder.group({
        id: x.id,
        stage: x.stage,
        project: x.project,
        osf_link: x.osf_link,
        description: x.description,
        demographics: this.formBuilder.array([this.initDemographic()]),
        supporting_document: this.formBuilder.array([this.initSupportingDocument()]),
        scientists: x.reviewing_scientists,
        reviewing_scientists: x.reviewing_scientists,
        pmcid_pubmedid_or_citation: this.formBuilder.array([this.initPmcidPubmedidCitation()]),
        reagion: x.reagion,
      }));

      this.setPmcidPubmedidCitation(this.measureForm, i, res);
      this.setSupportingDocument(this.measureForm, i, res);
      this.setDemographic(this.measureForm, i, res);
      i++;
    });
  }

  initPmcidPubmedidCitation(): FormGroup {
    return this.formBuilder.group({
      citations: this.formBuilder.group({
        title: [''],
        authors: [''],
        doi: [''],
        type: [''],
      }),
      type: [''],
      link: [''],
    });
  }

  addPmcidPubmedidCitation(form, study, j) {
    const control = study.controls.pmcid_pubmedid_or_citation.controls;
    control.push(this.initPmcidPubmedidCitation());
  }

  getPmcidPubmedidCitation(form) {
    return form.controls.pmcid_pubmedid_or_citation.controls;
  }

  setPmcidPubmedidCitation(form, i, res) {
    const control = form.controls.studies.controls[i].get('pmcid_pubmedid_or_citation');
    control.removeAt(0);
    let thedata = [];
    thedata = res.studies[i].pmcid_pubmedid_or_citation;
    thedata.forEach(x => {
      control.push(this.formBuilder.group({
        citations: this.formBuilder.group({
          title: x.citations.title,
          authors: x.citations.authors,
          doi: x.citations.doi,
          type: x.citations.type,
        }),
        type: x.type,
        link: x.link,
      }));
    });
  }

  removePmcidPubmedidCitation(study, j) {
    const control = study.controls.pmcid_pubmedid_or_citation;
    control.removeAt(j);
  }

  initDemographic(): FormGroup {
    return this.formBuilder.group({
      demographic_text: [''],
    });
  }

  getDemographicData(study) {
    return study.controls.demographics.controls;
  }

  addDemographic(study) {
    const control = study.controls.demographics.controls;
    control.push(this.initDemographic());
  }

  setDemographic(form, i, res) {
    const control = form.controls.studies.controls[i].get('demographics');
    control.removeAt(0);
    let thedata = [];
    thedata = res.studies[i].demographics;
    thedata.forEach(x => {
      control.push(this.formBuilder.group({
        demographic_text: x.demographic_text,
      }));
    });
  }

  removeDemographic(study, j) {
    const control = study.controls.demographics;
    control.removeAt(j);
  }

  initSupportingDocument(): FormGroup {
    return this.formBuilder.group({
      link: ['', Validators.required],
      type: ['', Validators.required],
    });
  }

  getSupportingDocument(study) {
    return study.controls.supporting_document.controls;
  }

  addSupportingDocument(study) {
    const control = study.controls.supporting_document.controls;
    control.push(this.initSupportingDocument());
  }

  setSupportingDocument(form, i, res) {
    const control = form.controls.studies.controls[i].get('supporting_document');
    control.removeAt(0);
    let thedata = [];
    thedata = res.studies[i].supporting_document;
    thedata.forEach(x => {
      control.push(this.formBuilder.group({
        link: x.link,
        type: x.type,
      }));
    });
  }

  removeSupportingDocument(study, j) {
    const control = study.controls.supporting_document;
    control.removeAt(j, 1);
  }

  onUploadedFile(form, i, data) {
    form.controls.supporting_document.controls[i].controls.link.setValue(data.file_path);
  }

  goPrevious() {
    if (this.measureForm.dirty === true) {
      this.submitData();
    }
    // route to studies
    if (!_.isEmpty(this.id)) {
      this.viewDetails.emit(this.id);
    } else {
      return;
    }
  }

  goNext() {
    console.log(`this.measureForm.dirty=${this.measureForm.dirty}`);
    if (this.measureForm.dirty === true) {
      this.submitData();
    }
    // route to studies
    if (!_.isNil(this.id) || !_.isEmpty(this.id)) {
      this.viewMaterials.emit(this.id);
    } else {
      return;
    }
  }

  submitData() {
    this.submitted = true;

    if (_.isEmpty(this.measureForm.get('studies').value) || this.measureForm.invalid) {
      console.log(`_.isEmpty = ${_.isEmpty(this.measureForm.get('studies').value)}`);
      console.log(`this.measureForm.invalid = ${this.measureForm.invalid}`);
      console.log(`this.measureForm.get('studies').value=${JSON.stringify(this.measureForm.get('studies').value)}`);
      return;
    }

    this.loading = true;
    this.measureForm.get('id').setValue(this.id);
    const thestudies = this.measureForm.get('studies').value;
    const theForm = this.measureForm.value;
    const reviewingscientistdata = [];
    for (let i = 0; i < thestudies.length; i++) {
      const reviewingscientistvalue = thestudies[i].scientists;
      if (reviewingscientistvalue && typeof (reviewingscientistvalue) === 'object') {
        if (reviewingscientistvalue) {
          reviewingscientistvalue.forEach(function (element) {
            const obj = {user_id: element};
            reviewingscientistdata.push(obj);
          });
          thestudies[i].reviewing_scientists = reviewingscientistdata;
        }
      }
    }

    const url = 'measures/update/studies/';
    this.apirequestService.post(url, theForm)
      .pipe(first())
      .subscribe(
        data => {
          this.studiesResp = data.studies;
          this.id = data.id;
          this.snackBar.openFromComponent(CustomSnackBarComponent, {
            data: {
              message: 'Measure`s Studies saved',
              action: 'Success'
            },
            duration: 3 * 1000,
            panelClass: ['transparent-snack-bar']
          });
          this.loading = false;
        },
        error => {
          this.alertService.error(error, '');
          this.loading = false;
        });
  }

  private createForm() {
    this.measureForm = this.formBuilder.group({
      id: this.id,
      studiesid: '',
      studies: this.formBuilder.array([this.initStudy()]),
    });
  }

  private getFormData() {
    if (this.id) {
      this.isUpdate = true;
      const url = 'measures/details/studies/' + this.id + '/';
      var can_edit_ = false;
      this.apirequestService.get(url)
        .subscribe(
          res => {
            this.measureForm.patchValue(res, {onlySelf: true});
            if (res.studies.length > 0) {
              this.measureForm.get('studiesid').setValue('1');
            }
            this.setstudiesData(res);
            var user_ = JSON.parse(localStorage.getItem('auth.user'));
            if (res.researcher.id == user_['id'] || user_['role'] == 'Admin' || user_['role'] == 'System Admin') {
              can_edit_ = true;
            } else {
              res.co_owners.forEach(function (co_owner) {
                if (co_owner['id'] == user_['id']) {
                  can_edit_ = true;
                }
              });
            }

            if (can_edit_ == true) {
              this.can_edit = true;
            }
          },
          error => {
            console.log(error);
            // this.alertService.error('Error getting data ' + error.error.message);
          });
    }
  }

}
