import {Component, OnInit} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import * as _ from 'lodash';

import {ApirequestService} from '../../shared/services/apirequest.service';

@Component({
  selector: 'app-measures-statistics',
  templateUrl: './measures-statistics.component.html',
  styleUrls: ['./measures-statistics.component.scss']
})
export class MeasuresStatisticsComponent implements OnInit {

  measures = [];
  page = 1;
  total = 0;
  total_pages = 0;
  // page_size = 10;
  page_size = 150;
  search_data: any;
  search_input: string;
  loading: boolean;
  landing_page_sort = 'descending';
  isDesc = false;
  landing_arrow: string = 'arrow_downward';
  views_arrow: string = 'arrow_downward';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apirequestService: ApirequestService) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.page = params['page'] || '1';
      this.search_data = {'data': params['data'] || ''};
    });
    this.getMeasures();
  }

  deleteMeasure(measure) {
    console.log(measure);
  }

  getMeasures() {
    // const url = 'measures/search/';
    const url = 'measures/search/?measure_source=All&domains=All&behaviors=All&measurement_mode=All&validity_studies_uploaded=All&target_population=All&language=All&duration=All&database=All&page=1&page_size=10000000&data=&sort_by=popular';
    let url_params = {'page': this.page.toString(), 'page_size': this.page_size.toString()};
    _.merge(url_params, this.search_data);

    const params = new HttpParams({
      fromObject: url_params
    });

    this.apirequestService.get(url, params)
      .subscribe(
        res => {
          this.total = res.count;
          this.page_size = res.page_size;
          this.measures = res.results;
          this.total_pages = res.total_pages;
          this.router.navigate([], {relativeTo: this.route, queryParams: url_params});
        },
        error => {
          console.log(error);
        });
    this.search_input = this.search_data.data;
  }

  searchMeasure(search) {
    this.search_data = {'data': search};
    this.getMeasures();
  }

  goToPage(n: number): void {
    this.page = n;
    this.getMeasures();
  }

  onNext(): void {
    this.page++;
    this.getMeasures();
  }

  onPrev(): void {
    this.page--;
    this.getMeasures();
  }

  private sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    let direction = this.isDesc ? 'ascending' : 'descending';
      this.landing_page_sort = direction;
      if (direction == 'ascending') {
          if(property == 'downloads'){
            this.landing_arrow = 'arrow_upward';
            this.measures = this.measures.sort((a, b) => a.download_count - b.download_count);
          }
          else{
            this.views_arrow = 'arrow_upward';
            this.measures = this.measures.sort((a, b) => a.times_accessed - b.times_accessed);
          }
      } else {
          if(property == 'downloads'){
            this.landing_arrow = 'arrow_downward';
            this.measures = this.measures.sort((a, b) => b.download_count - a.download_count);
          }
          else{
            this.views_arrow = 'arrow_downward';
            this.measures = this.measures.sort((a, b) => b.times_accessed - a.times_accessed);
          }
      }
  }

}
