import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as _ from 'lodash';

import {TabsComponent} from '../../shared/common/tabs/tabs.component';
import {ApirequestService} from '../../shared/services/apirequest.service';


@Component({
  selector: 'app-measures-form',
  templateUrl: './measuresform.component.html',
  styleUrls: ['./measuresform.component.scss']
})
export class MeasuresformComponent implements OnInit {
  @ViewChild('detailsTab') detailsTabTemplate;
  @ViewChild('studiesTab') studiesTabTemplate;
  @ViewChild('materialsTab') materialsTabTemplate;
  @ViewChild(TabsComponent) tabsComponent;
  public childID: string;
  edit_measure_id: string;
  title = 'Create measure';
  comments = [];
  rejected: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apirequestService: ApirequestService,
  ) {
  }

  ngOnInit() {
    this.rejected = false;
    this.route.params.subscribe(
      (params): void => {
        this.childID = params['id'] || '';
        if (!_.isEmpty(this.childID) && this.childID !== 'undefined') {
          // get measure onViewMaterials
          this.title = 'Update measure';
        }
        this.edit_measure_id = params['id'];
        this.getMeasureComments(params['id']);
      });
  }

  getMeasureComments(id){
    const url = `measures/comments/${id}/?page=1`;
      this.apirequestService.get(url)
        // .pipe(first())
        .subscribe(
          data => {
            this.comments = data.results;
            if(this.comments.length > 0){
              if(this.comments[0].measure_rejected == true){
                this.rejected = true;
              }
              
            }
            // this.rejected = this.comments.length > 0? true: false;
            
            // this.loading = false;
            // this.viewStudies.emit(this.id);
          },
          error => {
            // this.alertService.error(error, '');
            // this.loading = false;
          });
  }

  onViewDetails(event) {
    this.childID = event;
    this.tabsComponent.selectTab(this.detailsTabTemplate);
  }

  onViewStudies(event) {
    this.childID = event;
    this.tabsComponent.selectTab(this.studiesTabTemplate);
  }

  onViewMaterials(event) {
    this.childID = event;
    this.tabsComponent.selectTab(this.materialsTabTemplate);
  }
}



