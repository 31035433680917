import {Component, HostListener, Input, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {environment} from '../../../../environments/environment';
import {AuthenticationService} from '../../../shared/services/authentication.service';

@Component({
  selector: 'app-resources-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})

export class AppResourcesHeaderComponent implements OnInit {
  @Input() _status: boolean;
  changeNav: boolean;
  is_authenticated: boolean;
  host_url: string;

  constructor(private route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private router: Router,
  ) {
  }
  // ngOnDestroy(): void {
  //   throw new Error('Method not implemented.');
  // }

  ngOnInit() {
    this.host_url = environment.host_url;
    this.is_authenticated = this.authenticationService.isAuthenticated();
  }
}
