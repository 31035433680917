// data.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private sharedDataSubject = new BehaviorSubject<string>('');

  sharedData = this.sharedDataSubject.asObservable();

  constructor() { }

  updateData(data: string) {
    this.sharedDataSubject.next(data);
  }
}
