import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard} from '../shared/_guards/auth.guard';

import {AppLayoutComponent} from './layout/app-layout/app-layout.component';
import {DashboardNavigationComponent} from './layout/dashboard-navigation/dashboard-navigation.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {PendingReviewComponent} from './pending_review/pending_review.component';
import {MymeasuresComponent} from './mymeasures/mymeasures.component';
import {HelpComponent} from './help/help.component';
import {LoginComponent} from './login/login.component';
import {MeasuresComponent} from './measures/measures.component';
import {MeasuresformComponent} from './measuresform/measuresform.component';
import {GemMeasuredetailsComponent} from './measuredetails/gemmeasuredetails/gemmeasuredetails.component';
import {MIDSSMeasuredetailsComponent} from './measuredetails/midssmeasuredetails/midssmeasuredetails.component';
import {MeasuredetailsComponent,} from './measuredetails/measuredetails.component';
import {MyCollectionComponent} from './my-collection/my-collection.component';
import {ListFolderMeasuresComponent} from './my-collection/list-folder-measures/list-folder-measures.component';
import {SurveysComponent} from './surveys/surveys.component';
import {SurveysCreateComponent} from './surveys/surveys-create/surveys-create.component';
import {SurveyAnswersComponent} from './surveys/surveys-answers/surveys-answers.component';
import {LogoutComponent} from './logout/logout.component';
import {MeasureApprovalComponent} from './measureapproval/measureapproval.component';
import {AllMeasuresComponent} from './allmeasures/allmeasures.component';
import {MeasuresStatisticsComponent} from './measures-statistics/measures-statistics.component';
import {ExperimentdetailsComponent,} from './experimentdetails/experimentdetails.component';
import {ExperimentsComponent} from './experiments/experiments.component';

// Google analytics routes
import {AudienceComponent} from './statistics/google-analytics/audience/audience.component';
import {TechnologyComponent} from './statistics/google-analytics/technology/technology.component';
import {BehaviorsComponent} from './statistics/google-analytics/behaviors/behaviors.component';
import {GeodataComponent} from './statistics/google-analytics/geodata/geodata.component';
import {UserflowsComponent} from './statistics/google-analytics/userflows/userflows.component';
import {BehaviorOverviewComponent} from './statistics/google-analytics/behavior-overview/behavior-overview.component';
import {ChannelsComponent} from './statistics/google-analytics/channels/channels.component';
import {DirectComponent} from './statistics/google-analytics/channels/direct/direct.component';
import {ReferralsComponent} from './statistics/google-analytics/channels/referrals/referrals.component';
import {SocialComponent} from './statistics/google-analytics/channels/social/social.component';
import {OrganicSearchComponent} from './statistics/google-analytics/channels/organic-search/organic-search.component';
import {AgeComponent} from './statistics/google-analytics/demographics/age/age.component';
import {GenderComponent} from './statistics/google-analytics/demographics/gender/gender.component';
import {LanguageComponent} from './statistics/google-analytics/geodata/language/language.component';
import {BulkMeasureUploadComponent} from './bulk-measure-upload/bulk-measure-upload.component';
import {ResourcesComponent} from './resources/resources.component';
import {SobcResourceDetailsComponent} from './sobc-resources-single/sobcresourcesingle.component';
import {SobcResourceDetails2Component} from './sobc-resources-single2/sobcresourcesingle2.component';
import {SobcResources2Component} from './sobc-resources2/sobcresources2.component';
import {ResourcesLayoutComponent} from './resources-layout/resources-layout.component';
import {SobcResourcesComponent} from './resources-layout/sobc-resources/sobcresources.component';
import {SobcSingleResourceDetailsComponent} from './resources-layout/sobc-single-resource-details/sobcresourcesingle2.component';
import {DashboardNavigationRevisedComponent} from './layout/dashboard-navigation-revised/dashboard-navigation.component';
import {GA4UsersComponent} from './statistics/google-analytics-ga4/users/users.component';
import { PagesComponent } from './statistics/google-analytics-ga4/pages/pages.component';
import {GA4TrafficChannelsComponent} from './statistics/google-analytics-ga4/traffic/channels/traffic-channels.component';
import {GA4TrafficCountriesComponent} from './statistics/google-analytics-ga4/traffic/countries/traffic-countries.component';
import {GA4TrafficLanguagesComponent} from './statistics/google-analytics-ga4/traffic/languages/traffic-languages.component';
import {GA4TrafficSourcesWebsitesComponent} from './statistics/google-analytics-ga4/traffic/sources-websites/traffic-sources-websites.component';
import {GA4TrafficSourcesLinksComponent} from './/statistics/google-analytics-ga4/traffic/sources-links/traffic-sources-links.component';

// Revised dashboard
import {DashboardRevisedComponent} from './dashboard-revised/dashboard/dashboard-revised.component';
import {RevisedDashboardMeasuresComponent} from './dashboard-revised/dashboard/measures/measures.component';
import {RevisedDashboardMeasureFormComponent} from './dashboard-revised/dashboard/measures/measure-form/measure-form.component';

import {RevisedDashboardStudyEvidenceComponent} from './dashboard-revised/dashboard/study-evidence/study-evidence.component';
import {RevisedDashboardStudyEvidenceFormComponent} from './dashboard-revised/dashboard/study-evidence/form/study-evidence.component';
import {RevisedDashboardMeasuredetailsComponent} from './dashboard-revised/dashboard/measures/details/details.component';
import {StudyEvidenceDetailsComponent} from './dashboard-revised/dashboard/study-evidence/details/details.component';
import {RevisedDashboardMyCollectionComponent} from './dashboard-revised/dashboard/my-collection/my-collection.component';
import {RevisedDashboardListFolderMeasuresComponent} from './dashboard-revised/dashboard/my-collection/list-folder-measures/list-folder-measures.component';
import {RevisedDashboardNotificationsComponent} from './dashboard-revised/dashboard/notifications/notifications.component';
import {RevisedDashboardUsersComponent} from './dashboard-revised/dashboard/users/users.component';
import {RevisedDashboardUserFormComponent} from './dashboard-revised/dashboard/users/form/user-form.component';
import {RevisedDashboardInstitutionsComponent} from './dashboard-revised/dashboard/institutions/institutions.component';
import {RevisedDashboardInstitutionFormComponent} from './dashboard-revised/dashboard/institutions/form/institution-form.component';
import { VisualizationTaxonomyComponent } from './../../app/pages/measuredetails/visualization-taxonomy/visualization-taxonomy.component';

const pagesRoutes: Routes = [
  {
    path: '', component: AppLayoutComponent,
    children: [
      {path: '', component: MeasuresComponent},
      // {path: 'resources1', component: SobcResourcesComponent},
      // {path: 'resource-details', component: SobcResourceDetailsComponent},
      {path: 'login', component: LoginComponent},
      {path: 'logout', component: LogoutComponent},
      {path: 'login:siguptoken', component: LoginComponent},
      {path: 'help', component: HelpComponent},
      {path: 'measuredetails', component: MeasuredetailsComponent},
      {path: 'measuredetails/:id', component: MeasuredetailsComponent},
      {path: 'gemmeasuredetails', component: GemMeasuredetailsComponent},
      {path: 'gemmeasuredetails/:id', component: GemMeasuredetailsComponent},
      {path: 'midssmeasuredetails', component: MIDSSMeasuredetailsComponent},
      {path: 'midssmeasuredetails/:id', component: MIDSSMeasuredetailsComponent},
      {path: 'experimentdetails/:id', component: ExperimentdetailsComponent},
      {path: 'vt', component: VisualizationTaxonomyComponent},


      // {path: 'resources', component: ResourcesComponent},
    ],
  },
  /////{path: 'resources', component: SobcResources2Component},
  // {path: 'resources', component: SobcResources2Component},
  ////// {path: 'resource-details', component: SobcResourceDetails2Component},
  {
    path: '', component: ResourcesLayoutComponent,
    children: [
      {path: 'resources', component: SobcResourcesComponent},
      {path: 'resource-details', component: SobcSingleResourceDetailsComponent},
    ]
  },

  {
    path: 'experiments', component: AppLayoutComponent,
    children: [
      {path: '', component: ExperimentsComponent},
    ]
  },
  // {path: 'resources', component: ResourcesComponent},
  {
    path: 'dashboard2', component: DashboardNavigationComponent,
    children: [
      {path: '', component: DashboardComponent},
      {path: 'pending_review', component: PendingReviewComponent},
      {path: 'allmeasures', component: AllMeasuresComponent},
      {path: 'mymeasures', component: MymeasuresComponent},
      {path: 'mymeasures/:tab', component: MymeasuresComponent},
      {path: 'measure', component: MeasuresformComponent},
      {path: 'bulk-measure-upload', component: BulkMeasureUploadComponent},
      {path: 'measure/:id', component: MeasuresformComponent},
      {path: 'mycollection', component: MyCollectionComponent},
      {path: 'mycollection/:id', component: MyCollectionComponent},
      {path: 'mycollection/:name/:id', component: ListFolderMeasuresComponent},
      {path: 'surveys/:measure_id', component: SurveysComponent},
      {path: 'survey/:measure_id', component: SurveysCreateComponent},
      {path: 'survey_form/:id', component: SurveyAnswersComponent},
      {path: 'review_measure/:id', component: MeasureApprovalComponent},
      {path: 'measures_statistics', component: MeasuresStatisticsComponent},
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard', component: DashboardNavigationRevisedComponent,
    children: [
      {path: '', component: DashboardRevisedComponent},
      {path: 'measures', component: RevisedDashboardMeasuresComponent},
      {path: 'measures/:id', component: RevisedDashboardMeasuresComponent},
      {path: 'measures/form/:id', component:  RevisedDashboardMeasureFormComponent},
      {path: 'measure/details/:id', component:  RevisedDashboardMeasuredetailsComponent},
      {path: 'measure-revision/details/:id/:action', component:  RevisedDashboardMeasuredetailsComponent},
      {path: 'study-evidence', component: RevisedDashboardStudyEvidenceComponent},
      {path: 'study-evidence/:id', component: RevisedDashboardStudyEvidenceFormComponent},
      {path: 'study-evidence/details/:id', component: StudyEvidenceDetailsComponent},
      {path: 'study-revision/details/:id/:action', component:  StudyEvidenceDetailsComponent},
      {path: 'my-collection', component: RevisedDashboardMyCollectionComponent},
      {path: 'mycollection/:id', component: RevisedDashboardMyCollectionComponent},
      {path: 'mycollection/:name/:id', component: RevisedDashboardListFolderMeasuresComponent},
      {path: 'notifications', component: RevisedDashboardNotificationsComponent},
      {path: 'users', component: RevisedDashboardUsersComponent},
      {path: 'users/:id', component: RevisedDashboardUsersComponent},
      {path: 'users/form/:id', component: RevisedDashboardUserFormComponent},
      {path: 'institutions', component: RevisedDashboardInstitutionsComponent},
      {path: 'institutions/form/:id', component: RevisedDashboardInstitutionFormComponent},
      
    ],
    canActivate: [AuthGuard]
  },

  // Google analytics statistics routes
  {
    path: 'statistics', component: DashboardNavigationComponent,
    children: [
      {path: 'audience', component: AudienceComponent},
      {path: 'technology', component: TechnologyComponent},
      {path: 'behaviors', component: BehaviorsComponent},
      {path: 'geodata', component: GeodataComponent},
      {path: 'geodata/language', component: LanguageComponent},
      {path: 'userflow', component: UserflowsComponent},
      {path: 'behavior-overview', component: BehaviorOverviewComponent},
      {path: 'traffic', component: ChannelsComponent},
      {path: 'traffic/direct', component: DirectComponent},
      {path: 'traffic/referral', component: ReferralsComponent},
      {path: 'traffic/social', component: SocialComponent},
      {path: 'traffic/organicsearch', component: OrganicSearchComponent},
      {path: 'age', component: AgeComponent},
      {path: 'gender', component: GenderComponent},

      // GA4
      
      {path: 'users', component: GA4UsersComponent},
      {path: 'pages', component: PagesComponent},
      {path: 'traffic-channels', component: GA4TrafficChannelsComponent},
      {path: 'traffic-countries', component: GA4TrafficCountriesComponent},
      {path: 'traffic-languages', component: GA4TrafficLanguagesComponent},
      {path: 'traffic-sources-websites', component: GA4TrafficSourcesWebsitesComponent},
      {path: 'traffic-sources-links', component: GA4TrafficSourcesLinksComponent},


    ],
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(pagesRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class PagesRoutingModule {
}
