import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';

import {ApirequestService} from '../../../shared/services/apirequest.service';
import {ApirequestdetailService} from '../../../shared/services/apirequestdetail.service';

@Component({
  selector: 'app-allmeasuresfilters',
  templateUrl: './measuresfilters.component.html',
  styleUrls: ['../measures.component.scss']
})
export class MeasuresfiltersComponent implements OnInit {
  @Input() pagefilters: any;

  domainsFiltersList = [];
  domainsFilters = [];
  typeFilters = [];
  durationFilters = [];
  targetPopulationFilters = [];
  stageFilters = [];
  measureSourceFilters = [];
  thefilter;
  btnclicked = false;
  url_measurefilters = 'measures/domains_list/';
  isType = false;
  isHypothesizedDomain = false;
  isStage = false;
  isAdvanced = false;
  isDuration = false;
  isTargetPopulation = false;
  isSource = false;
  showSource = false;
  showType = false;
  showHypothesizedDomain = false;
  showStage = false;
  showDuration = false;
  showTargetPopulation = false;


  constructor(
    private apirequestService: ApirequestService,
    private apirequestdetailService: ApirequestdetailService,
  ) {
  }

  ngOnInit() {
    this.apirequestService.get(this.url_measurefilters)
      .subscribe(
        res => {
          this.domainsFiltersList = res.results;
          _.each(this.domainsFiltersList, (value) => {
            if (this.pagefilters.domains.includes(value.id)) {
              this.domainsFilters.push(value.id);
            }
          });
        },
        error => {
          console.log(error);
        });


    _.each(['Self-report', 'Task', 'Observational'], (value) => {
      if (this.pagefilters.type.includes(value)) {
        this.typeFilters.push(value);
        this.showType = true;
      }
    });
    _.each(['SOBC', 'GEM', 'MIDSS', 'Others'], (value) => {
      if (this.pagefilters.measure_source.includes(value)) {
        this.measureSourceFilters.push(value);
        this.showSource = true;
      }
    });
    _.each(['Identified', 'Measured', 'Influenced', 'Validated', 'Not Validated'], (value) => {
      if (this.pagefilters.stage.includes(value)) {
        this.stageFilters.push(value);
        this.showStage = true;
      }
    });
    _.each(['0-5', '6-10', '11-15', '16-20', '21-30', '31', 'Not Specified'], (value) => {
      if (this.pagefilters.duration.includes(value)) {
        this.durationFilters.push(value);
        this.showDuration = true;
      }
    });
    _.each(['Adult', 'Child'], (value) => {
      if (this.pagefilters.target_population.includes(value)) {
        this.targetPopulationFilters.push(value);
        this.showTargetPopulation = true;
      }
    });

  }

  // _.remove(v, function(n){ return n!=8;});
  arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele !== value;
    });
  }

  toggleEditable(event, type, val) {
    if (type === 'domains') {
      if (event.target.checked === true) {
        this.domainsFilters.push(val);
      } else if (event.target.checked === false) {
        this.domainsFilters = this.arrayRemove(this.domainsFilters, val);
      }
    } else if (type === 'type') {
      if (event.target.checked === true) {
        this.typeFilters.push(val);
      } else if (event.target.checked === false) {
        this.typeFilters = this.arrayRemove(this.typeFilters, val);
      }
    } else if (type === 'duration') {
      if (event.target.checked === true) {
        this.durationFilters.push(val);
      } else if (event.target.checked === false) {
        this.durationFilters = this.arrayRemove(this.durationFilters, val);
      }
    } else if (type === 'target_population') {
      if (event.target.checked === true) {
        this.targetPopulationFilters.push(val);
      } else if (event.target.checked === false) {
        this.targetPopulationFilters = this.arrayRemove(this.targetPopulationFilters, val);
      }
    } else if (type === 'stage') {
      if (event.target.checked === true) {
        this.stageFilters.push(val);
      } else if (event.target.checked === false) {
        this.stageFilters = this.arrayRemove(this.stageFilters, val);
      }
    } else if (type === 'measure_source') {
      if (event.target.checked === true) {
        this.measureSourceFilters.push(val);
      } else if (event.target.checked === false) {
        this.measureSourceFilters = this.arrayRemove(this.measureSourceFilters, val);
      }
    }

    this.thefilter = {
      'domains': this.domainsFilters.join(',') || 'All',
      'type': this.typeFilters.join(',') || 'All',
      'duration': this.durationFilters.join(',') || 'All',
      'target_population': this.targetPopulationFilters.join(',') || 'All',
      'stage': this.stageFilters.join(',') || 'All',
      'measure_source': 'SOBC',
    };

    this.apirequestdetailService.setFilteredData(this.thefilter);
  }
}
