import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {SharedModule} from '../../shared/shared.module';

import {SurveysComponent} from './surveys.component';
import {SurveysCreateComponent} from './surveys-create/surveys-create.component';
import {SurveyAnswersComponent} from './surveys-answers/surveys-answers.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    SharedModule,
  ],
  declarations: [
    SurveysCreateComponent,
    SurveysComponent,
    SurveyAnswersComponent,
  ],
})
export class SurveysModule {
}
