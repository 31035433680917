import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';

import {SharedModule} from '../../shared/shared.module';

import {ExperimentsComponent} from './experiments.component';
import {ExperimentsfiltersComponent} from './experimentsfilters/experimentsfilters.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
  ],
  declarations: [
    ExperimentsComponent,
    ExperimentsfiltersComponent,
  ],
})
export class ExperimentsModule {
}
