import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {Field} from '../../models/field.interface';
import {FieldConfig} from '../../models/field-config.interface';

@Component({
  selector: 'app-form-button',
  styleUrls: ['form-button.component.scss'],
  template: `
    <div
      class="dynamic-field form-button"
      [formGroup]="group">
      <button class="btn btn-primary mt-3" type="submit" [disabled]="!group.valid || submitted">
        {{ config.label }}
      </button>
    </div>
  `,
})
export class FormButtonComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
  submitted: boolean;
}
