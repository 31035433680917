import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import * as _ from 'lodash';

import {ApirequestService} from '../../../shared/services/apirequest.service';
import {AlertService} from '../../../shared/services/alert.service';
import {DynamicFormComponent} from '../../../shared/common/dynamic-form/containers/dynamic-form/dynamic-form.component';

@Component({
  selector: 'app-surveys-answers',
  templateUrl: './surveys-answers.component.html',
  styleUrls: ['../surveys.component.scss']
})
export class SurveyAnswersComponent implements OnInit, AfterViewInit {
  @ViewChild(DynamicFormComponent) form: DynamicFormComponent;
  id: string;
  config = [];
  survey;
  has_survey = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apirequestService: ApirequestService,
    public alertService: AlertService,
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'] || '';
    });
    this.getSurveyQuestions();
  }

  getSurveyQuestions() {
    const url = 'surveys/questions/';
    const params = new HttpParams()
      .set('survey_id', this.id);
    this.apirequestService.get(url, params)
      .subscribe(
        res => {
          if (!_.isEmpty(res.results)) {
            res.results.forEach(x => {
              const data = x.questions;
              data.push({
                label: 'Submit',
                name: 'submit',
                type: 'button'
              });
              this.survey = x;
              this.config = x.questions;
              this.has_survey = true;
            });
          }
        },
        error => {
          console.log(error);
        });
  }

  ngAfterViewInit() {
    let previousValid = this.form.valid;
    this.form.changes.subscribe(() => {
      if (this.form.valid !== previousValid) {
        previousValid = this.form.valid;
        this.form.setDisabled('submit', !previousValid);
      }
    });
    this.form.setDisabled('submit', true);
  }

  submit(data: { [name: string]: any }) {
    let hashmaps = {};
    const answers = [];
    const m = [];
    _.mapKeys(data, function (value, key) {
      if (_.isArray(value)) {
        _.mapValues(value, function (v, k) {
          if (_.includes(v, true)) {
            m.push(k);
          }
        });
        value = m;
      }
      hashmaps = {'question_id': key, 'answer': value};
      answers.push(hashmaps);
    });
    const q_hashmaps = {'answers': answers};
    const url = 'surveys/submit_answers/';
    this.apirequestService.post(url, q_hashmaps)
      .subscribe(
        res => {
          this.alertService.success('Successfully submitted survey response');
        },
        error => {
          this.alertService.error(error, '');
        });
  }
}
