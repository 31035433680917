import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {Field} from '../../models/field.interface';
import {FieldConfig} from '../../models/field-config.interface';

@Component({
  selector: 'app-form-radio',
  styleUrls: ['form-radio.component.scss'],
  template: `
    <div
      class="dynamic-field form-radio"
      [formGroup]="group">
      <div>
          <label class="mt-2">{{config.label}}</label>
      </div>
      <div *ngFor="let option of config.options" class="form-check">
          <input type="radio" class="form-check-input" [formControlName]="config.name" [name]="config.name" [value]="option.name">
          <label class="form-check-label  mt-1">{{option.label}}</label>
      </div>
    <div>
  `
})
export class FormRadioComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
  submitted: boolean;
}
