import {Injectable} from '@angular/core';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {TheorytechniquestoolDialogComponent} from './theorytechniquestool-dialog.component';

@Injectable()
export class TheorytechniquestoolDialogService {

  constructor(private modalService: NgbModal) {
  }

  public confirm(
    title: string,
    message: Array<Object>,
    btnOkText: string = 'Yes Approve',
    btnCancelText: string = 'Cancel',
    dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(TheorytechniquestoolDialogComponent, {windowClass: 'myCustomModalClass'});
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

}

