import {Component, HostBinding, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import * as _ from 'lodash';
import {Router} from '@angular/router';

import {AuthenticationService} from '../../../shared/services/authentication.service';
import {ApirequestService} from '../../../shared/services/apirequest.service';
import {GlobalService} from '../../../shared/services/global.service';
import {CheckClickService} from '../../../shared/services/check_is_cliked.service';


@Component({
  selector: 'app-dashboard-navigation',
  templateUrl: './dashboard-navigation.component.html',
  styleUrls: ['./dashboard-navigation.component.scss'],
})
export class DashboardNavigationComponent implements OnInit {
  user_role: string;
  page_title: string;
  user_name: string;
  dashboarddata: any;
  userdetails: any;
  auth_token: any;
  user: any;
  u_role: string;
  @HostBinding('class.edit_measure_clicked')
  edit_measure_clicked = false;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(
    public _globalService: GlobalService,
    private breakpointObserver: BreakpointObserver,
    private authenticationService: AuthenticationService,
    private apirequestService: ApirequestService,
    private router: Router,
    private checkClickService: CheckClickService
  ) {
  }

  ngOnInit() {
    this.loadDropdown();
    let user_ = JSON.parse(localStorage.getItem('auth.user'));
    this.u_role = user_.role;
    try {
      this.userdetails = JSON.parse(localStorage.getItem('auth.user'));
      this.auth_token = JSON.parse(localStorage.getItem('auth.token'));

    } catch {
      console.log('Error');
    }

    if (!_.isNull(this.userdetails)) {
      this.user_name = this.userdetails.first_name + ' ' + this.userdetails.last_name;
      this.user_role = this.userdetails.role;
    }
    this.page_title = localStorage.getItem('page_title');
    if (_.isNull(this.page_title)) {
      this.page_title = '';
    }
    if (_.isNull(this.user_role)) {
      this.user_role = 'Researcher';
    }

    let url = 'measures/dashboard/';
    // if(this.router.url == '/statistics'){
    //     url = 'analytics/audience/?start_date=2022-01-01&end_date=2022-01-31'
    // }

    this.getDashBoardData(url);
  }

  getDashBoardData(url) {

    this.apirequestService.get(url)
      .subscribe(
        data => {
          this.dashboarddata = data;
        },
        error => {
          console.log(error);
        });
  }

  open_edit_measures() {
    this.checkClickService.change.subscribe(edit_measure_clicked => {
      this.edit_measure_clicked = edit_measure_clicked;
    });
  }


  loadDropdown() {
    //* Loop through all dropdown buttons to toggle between hiding and showing its dropdown content - This allows the user to have multiple dropdowns without any conflict */
    var dropdown = document.getElementsByClassName('dropdown-btn');
    var i;

    for (i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener('click', function () {
        console.log('CLICKED');
        this.classList.toggle('active');
        var dropdownContent = this.nextElementSibling;
        if (dropdownContent.style.display === 'block') {
          dropdownContent.style.display = 'none';
          console.log(`HIDE DROPDOWN:${dropdownContent.getAttribute('id')}`);
        } else {
          dropdownContent.style.display = 'block';
          console.log(`SHOW DROPDOWN:${dropdownContent.getAttribute('id')}`);
        }
      });
    }
  }
}
