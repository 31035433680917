import {Component, OnInit} from '@angular/core';
import {ApirequestService} from '../../../../../shared/services/apirequest.service';
import * as Highcharts from 'highcharts';
import {DatesService} from '../../../../../shared/services/dates.service';

@Component({
  selector: 'app-os',
  templateUrl: './os.component.html',
  styleUrls: ['./os.component.css'],

})
export class OsComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;
  updateFlag = false;
  start_date: string = '';
  end_date: string = '';
  loading: boolean;
  url: string;
  maxDate = new Date();
  chartOptions: Highcharts.Options = {
    title: {
      text: ''
    },
    colors: ['#0091d5'],
    chart: {
      type: 'column',
      width: 800

    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: 'category',
      labels: {
        padding: 0,

      }
    },
    legend: {
      itemStyle: {
        color: '#0091d5',
      },
      enabled: true,
      borderColor: '#0091d5',
      borderWidth: 2,
      backgroundColor: 'rgba(34, 102, 255, 0.05)',
      borderRadius: 10
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y}</b>'
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Users'
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        dataLabels: {
          enabled: true
        }
      }

    },
    series: [
      {
        type: 'column',
        name: 'Operating System',
        data: [],
      }

    ]
  };

  constructor(private apiService: ApirequestService, private dateService: DatesService) {
  }

  ngOnInit() {
     // let today = new Date();
     const dateString = "2023-02-01T12:30:45.678Z";
     let today = new Date(dateString);
     var lastMonth = new Date(today);
     lastMonth.setDate(lastMonth.getDate() - 30);
     this.start_date = lastMonth.toISOString().slice(0, 10);
     this.end_date = today.toISOString().slice(0, 10);
    this.getData(this.start_date, this.end_date);
  }

  filter_by_date() {
    this.loading = true;
    this.getData(this.start_date, this.end_date);
  }


  getData(startDate, endDate) {


    this.loading = true;
    if (startDate != '' && endDate != '') {
      this.start_date = this.dateService.formatDate(startDate);
      this.end_date = this.dateService.formatDate(endDate);
      this.url = 'analytics/os/?start_date=' + this.start_date + '&end_date=' + this.end_date;
    } else {
      this.start_date = this.dateService.getStartDate();
      this.end_date = this.dateService.getEndDate();
      this.url = 'analytics/os/';
    }

    this.apiService.get(this.url).subscribe(res => {
      this.loading = false;
      const entries = Object.entries(res);
      let dataSet = [];
      for (let i = 0; i < entries.length; i++) {
        dataSet.push({'name': entries[i][1]['operatingSystem'], y: Number(entries[i][1]['users'])});
      }
      this.chartOptions.series[0]['data'] = dataSet;
      this.updateFlag = true;

    }, error => {
      console.log('ERROR::' + error);
      this.loading = false;
    });

  }

}
