import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DynamicFormModule} from './dynamic-form//dynamic-form.module';
import {DynamicTabsDirective} from './tabs/dynamic-tabs.directive';
import {CustomSnackBarComponent} from './custom-snack-bar/custom-snack-bar.component';
import {AlertComponent} from './alert/alert.component';
import {UploadfileComponent} from './uploadfile/uploadfile.component';
import {PaginationComponent} from './pagination/pagination.component';
import {TabsComponent} from './tabs/tabs.component';
import {TabComponent} from './tabs/tab.component';
import {PromptDialogComponent} from './prompt-dialog/prompt-dialog.component';
import {Utility} from './utility';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DynamicFormModule,
    FormsModule,
  ],
  exports: [
    DynamicFormModule,
    CustomSnackBarComponent,
    PaginationComponent,
    UploadfileComponent,
    TabsComponent,
    TabComponent,
    AlertComponent,
  ],
  declarations: [
    PaginationComponent,
    UploadfileComponent,
    TabsComponent,
    TabComponent,
    AlertComponent,
    DynamicTabsDirective,
    CustomSnackBarComponent,
    PromptDialogComponent
  ],
  entryComponents: [
    CustomSnackBarComponent,
    PromptDialogComponent
  ],
  providers: [
    Utility
  ]
})
export class AppCommonModule {
}
