import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {SharedModule} from '../../shared/shared.module';

import {CreateUserComponent, RegistrationRedirectComponent} from './create_user.component';
import {ListUsersComponent, PendingInstitutionApprovalComponent} from './list-users/list-users.component';
import {UserDetailsComponent} from './user_details/userdetails.component';
import {ConfirmEmailComponent, ResetPasswordComponent} from './reset-password/reset-password.component';
import {InstitutionDialogComponent, ManageAccountComponent, UpdateProfileComponent} from './manage-account/manage-account.component';

import {UsersRouterModule} from './users.routing';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    SharedModule,
    UsersRouterModule,
  ],
  declarations: [
    CreateUserComponent,
    RegistrationRedirectComponent,
    ListUsersComponent,
    ManageAccountComponent,
    ResetPasswordComponent,
    ConfirmEmailComponent,
    UserDetailsComponent,
    PendingInstitutionApprovalComponent,
    InstitutionDialogComponent,
    UpdateProfileComponent
  ],
  entryComponents: [
    InstitutionDialogComponent,
  ],
})
export class UsersModule {
}
