import {Component, OnInit} from '@angular/core';

import {ApirequestService} from '../../../../../shared/services/apirequest.service';
import {ResearcherDashboardModel} from '../../../../../shared/models/researcher_dashboard';
import {StatisticsGA4UsersComponent} from '../audience/users.component';
import {CardComponent} from  './../../../card/card.component';
import {StatisticsGA4PagesComponent} from './../../google-analytics-ga4/pages/pages.component';
import {StatisticsGA4TrafficChannelsComponent} from './../../google-analytics-ga4/traffic/channels/traffic-channels.component';
import {StatisticsGA4TrafficCountriesComponent} from './../../google-analytics-ga4/traffic/countries/traffic-countries.component';
import {StatisticsGA4TrafficLanguagesComponent} from './../../google-analytics-ga4/traffic/languages/traffic-languages.component';
import {StatisticsGA4WebsiteComponent} from './../../google-analytics-ga4/traffic/websites/websites.component';
import {StatisticsGA4LinksComponent} from './../../google-analytics-ga4/traffic/links/links.component';

@Component({
  selector: 'app-stats-ga4-traffic-parent',
  templateUrl: './traffic.component.html',
  styleUrls: ['./traffic.component.scss']
})
export class StatsGATrafficComponent implements OnInit {
  dashboard_details: ResearcherDashboardModel;
  titlePersonal =  "Personal";
  tabs = [
    { label: 'Tab 1', content: 'Content for Tab 1' },
    { label: 'Tab 2', content: 'Content for Tab 2' },
    // Add more tab objects as needed
  ];
  gaUsersComponent = StatisticsGA4UsersComponent;
  gaPagesComponent = StatisticsGA4PagesComponent;
  gaTrafficChannelsComponent = StatisticsGA4TrafficChannelsComponent;
  gaTrafficCountriesComponent = StatisticsGA4TrafficCountriesComponent;
  gaTrafficLanguagesComponent = StatisticsGA4TrafficLanguagesComponent;
  gaStatisticsGA4WebsiteComponent = StatisticsGA4WebsiteComponent;
  gaStatisticsGA4LinksComponent = StatisticsGA4LinksComponent;

  constructor(
    private apirequestService: ApirequestService,
  ) {
  }

  ngOnInit() {
    const url = 'measures/dashboard/';
    this.apirequestService.get(url)
      .subscribe(
        data => {
          this.dashboard_details = {
            ...data,
            start_date: 'Start date',
            end_date: 'End date',
            values: {
              card1: {label: 'Drafts', value: data.my_draft_measures, show: true},
              card2: {label: 'Pending approval', value: data.my_measures_pending_approval, show: true},
              card3: {label: 'Published', value: data.my_published_measures, show: true},
              card4: {label: 'Published', value: '', show: false},
            }
          };

        },
        error => {
          console.log(error);
        });
  }

}
