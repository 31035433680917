import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import * as _ from 'lodash';

import {ApirequestService} from '../../shared/services/apirequest.service';
import {ApirequestdetailService} from '../../shared/services/apirequestdetail.service';
import {GoogleAnalyticsService} from '../../shared/services/google-analytics.service';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-measures',
  templateUrl: './measures.component.html',
  styleUrls: ['./measures.component.scss'],
})
export class MeasuresComponent implements OnDestroy, OnInit {
  measures = [];
  experiments = [];
  page: number;
  total = 0;
  total_pages = 0;
  thefilters = {};
  page_size = 10;
  sort_fields: any;
  search_data: any;
  route_stage: string;
  url_params;
  access_measure: boolean;
  loading = true;
  subscription: Subscription;

  collapse = 'closed';
  btncollapse = 'not_clicked';
  show = false;
  showText = false;
  isMainmeasuredetails = false;
  search_input: string;
  showMeasures = true;
  showExperiments = false;
  // Experiments pagination
  page_experiments: number;
  total_experiments = 0;
  total_pages_experiments = 0;
  thefilters_experiments = {};
  page_size_experiments = 10;
  loading_experiments = true;
  showFullMeasureHeader = false;
  private searchTerms = new Subject<string>();

  constructor(
    private apirequestService: ApirequestService,
    private apirequestdetailService: ApirequestdetailService,
    private route: ActivatedRoute,
    private router: Router,
    public googleAnalyticsService: GoogleAnalyticsService,
  ) {
    this.subscription = this.apirequestdetailService.getFilteredData()
      .subscribe(
        data => {
          console.log(`this.thefilters=${JSON.stringify(data)}`);
          this.thefilters = data;
          this.page = 1;
          this.page_experiments = 1;
          this.getDataByPage();
        },
        error => {
          console.log(error);
        });
  }


  toogelDisplay(item) {
    if (item == 'measures') {
      this.showMeasures = true;
      this.showExperiments = false;
    } else {
      this.showMeasures = false;
      this.showExperiments = true;
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.page = params['page'] || '1';
      this.thefilters = {
        'measure_source': params['measure_source'] || 'All',
        'domains': params['domains'] || 'All',
        'behaviors': params['behaviors'] || 'All',
        'measurement_mode': params['measurement_mode'] || 'All',
        'validity_studies_uploaded': params['validity_studies_uploaded'] || 'All',
        'target_population': params['target_population'] || 'All',
        'language': params['language'] || 'All',
        'duration': params['duration'] || 'All',
        'database': params['database'] || 'All',
        'specific_links': params['specific_links'] || 'All',
      };
      this.search_data = {'data': params['data'] || ''};
      this.sort_fields = {'sort_by': params['sort_by'] || 'popular'};
      if (params['experiments']) {
        // Disable experiments for now
        // this.getExperimentsDataByPage();
        // this.toogelDisplay('experiments');
      } else {
        this.getDataByPage();
        // Disable experiments for now
        // this.getExperimentsDataByPage();
      }
    });
    this.getDataByPage();
    // Disable experiments for now
    // this.getExperimentsDataByPage();

    // this.getDataByPage();
    this.searchTerms.pipe(
      debounceTime(500)
    ).subscribe(searchTerm => {
      this.search_data = {'data': searchTerm};
      this.getDataByPage('measures', 'keyup');
    });
  }

  /* Get experiments data */
  getExperimentsDataByPage() {
    this.url_params = this.thefilters;
    _.merge(this.url_params, {'page': this.page.toString(), 'page_size': this.page_size});
    _.merge(this.url_params, this.search_data);
    _.merge(this.url_params, this.sort_fields);

    const params = new HttpParams({
      fromObject: this.url_params
    });
    this.apirequestService.getExperiments('experiments', params)
      .subscribe(
        res => {
          this.total_experiments = res.total;
          this.experiments = res.results;
          this.page_size_experiments = res.per_page;
          this.page_experiments = res.current_page;
          this.total_pages_experiments = res.pages;
          this.loading_experiments = false;
          this.router.navigate([], {relativeTo: this.route, queryParams: this.url_params});
        },
        error => {
          console.log(error);
        });
    // this.search_input = this.search_data.data;
  }

  /* End of get experiments data */

  getDataByPage(type = 'measures', source= 'pagination') {
    if(source == "keyup" && this.search_data.data !== ""){
      this.page = 1;
    }
    this.url_params = this.thefilters;
    _.merge(this.url_params, {'page': this.page.toString(), 'page_size': this.page_size});
    _.merge(this.url_params, this.search_data);
    _.merge(this.url_params, this.sort_fields);

    const params = new HttpParams({
      fromObject: this.url_params
    });
    if (type == 'measures') {
      this.apirequestService.get('measures/search/', params)
        .subscribe(
          res => {
            this.total = res.count;
            this.measures = res.results;
            this.page_size = res.page_size;
            this.page = res.current_page;
            this.total_pages = res.total_pages;
            this.loading = false;
            this.router.navigate([], {relativeTo: this.route, queryParams: this.url_params});
          },
          error => {
            console.log(error);
          });
    } else {


      //   this.url_params = this.thefilters;
      // _.merge(this.url_params, { 'page': this.page.toString()});

      const params_ = new HttpParams({
        fromObject: {'page': this.page_experiments.toString()}
      });

      // this.url_params = this.thefilters;
      // _.merge(this.url_params, { 'page_experiments': this.page.toString(), 'page_size_experiments': this.page_size_experiments });
      this.url_params = this.thefilters;
      _.merge(this.url_params, {
        'page_experiments': this.page.toString(),
        'page_size_experiments': this.page_size_experiments,
        'total_pages_experiments': this.total_pages_experiments
      });

      this.apirequestService.getExperiments('experiments', params_)
        .subscribe(
          res => {
            this.total_experiments = res.count;
            this.experiments = res.results;
            this.page_size_experiments = res.per_page;
            this.page_experiments = res.current_page;
            this.total_pages_experiments = res.pages;
            this.loading_experiments = false;
            this.router.navigate([], {relativeTo: this.route, queryParams: this.url_params});
          },
          error => {
            console.log(error);
          });
    }


    // this.search_input = this.search_data.data;
  }

  loadPageSize(n: number): void {
    this.page = 1;
    this.page_size = n;
    this.getDataByPage();
  }


  loadExperimentsPageSize(n: number): void {
    this.page_experiments = 1;
    this.page_size_experiments = n;
    this.getDataByPage('experiments');
  }


  goToPage(n: number): void {
    this.page = n;
    this.getDataByPage();
  }

  onNext(): void {
    this.page++;
    this.getDataByPage();
  }

  onPrev(): void {
    this.page--;
    this.getDataByPage();
  }

  goToPageExperiments(n: number): void {
    this.page_experiments = n;
    this.getDataByPage('experiments');
  }

  onNextExperiment(): void {
    this.page_experiments++;
    this.getDataByPage('experiments');
  }

  onPrevExperiment(): void {
    this.page_experiments--;
    this.getDataByPage('experiments');
  }

  toggleCollapse() {
    this.show = !this.show;
    this.btncollapse = this.btncollapse === 'clicked' ? 'not_clicked' : 'clicked';
    this.collapse = this.collapse === 'closed' ? 'open' : 'closed';
  }

  sortMeasures(sort) {
    this.sort_fields = {'sort_by': sort};
    this.getDataByPage();
  }

  searchMeasure(search) {
    this.searchTerms.next(search);
  }

  toggleShowHeader(show: boolean): void {
    this.showFullMeasureHeader = !show;
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  downloadMeasure(id, title) {
    const data = {
      'measure_id': id,
    };
    const url = 'measures/download_count/';
    this.apirequestService.post(url, data)
      .subscribe(
        error => {
          console.log(error);
        });

    this.googleAnalyticsService.eventEmitter('download_measure_detail', 'measure', 'click', title, 1);
  }


}
