import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {ApirequestService} from '../../../shared/services/apirequest.service';
import {AlertService} from '../../../shared/services/alert.service';
import * as _ from 'lodash';
import {CustomSnackBarComponent} from '../../../shared/common/custom-snack-bar/custom-snack-bar.component';

export interface DialogData {
  institution: string;
  user: string;
}

@Component({
  selector: 'app-institution-dialog',
  templateUrl: 'institution-dialog.html',
})
export class InstitutionDialogComponent implements OnInit {
  modalForm: FormGroup;
  institutions = [];
  loading = false;
  url_institution = 'users/institution/create/';
  url_request_institution_change = 'users/request-institution-change/';

  constructor(
    public dialogRef: MatDialogRef<InstitutionDialogComponent>,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private apirequestService: ApirequestService,
    @Inject(MAT_DIALOG_DATA) public institution_data: DialogData,
  ) {
  }

  ngOnInit() {
    this.initDiaologForm();
    this.getInstitutionList();
  }

  onSubmit() {
    console.log(`onSubmit1 CALLED`);
    const data = {
      'user_id': this.institution_data.user,
      'old_institution_id': this.institution_data.institution,
      'new_institution_id': this.modalForm.get('institution').value
    };
    this.apirequestService.post(this.url_request_institution_change, data)
      .subscribe(
        res => {
          this.alertService.success('Successful');
        },
        error => {
          this.alertService.error(error, error.error);
          this.loading = false;
        });

    this.dialogRef.close(this.modalForm.value);
  }

  private getInstitutionList() {
    this.apirequestService.get(this.url_institution)
      .subscribe(
        res => {
          this.institutions = res.results;
        },
        error => {
          console.log(error);
        });
  }

  private initDiaologForm() {
    this.modalForm = this.formBuilder.group({
      institution: [''],
    });
  }
}

@Component({
  selector: 'app-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.scss']
})
export class ManageAccountComponent implements OnInit {
  manageAccountForm: FormGroup;
  modalForm: FormGroup;
  loading = false;
  submitted = false;
  error: string;
  id: string;
  thedomains: string[];
  userdetails: any;
  user_role: string;
  institution_id: string;
  institutions = [];
  url = 'users/update/';
  url_institution = 'users/institution/create/';
  url_measurefilters = 'measures/domains_list/';
  url_approve_institution = 'users/approve_institution_change/';

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apirequestService: ApirequestService,
    public alertService: AlertService,
    public snackBar: MatSnackBar,
  ) {
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.manageAccountForm.controls;
  }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.id = params['id'];
        this.userdetails = JSON.parse(localStorage.getItem('auth.user'));
        this.user_role = this.userdetails.role;
        if (_.isNil(params['id'])) {
          if (!_.isNull(this.userdetails)) {
            this.id = this.userdetails.id;
          }
        }
      },
      error => {
        console.log(error);
      });
    this.createForm();
    this.getFormData();
    this.getDomainsList();
    this.getInstitutionList();
    this.initModalForm();
  }

  initDomain(): FormGroup {
    return this.formBuilder.group({
      id: ['', Validators.required],
      name: '',
    });
  }

  addDomain(): void {
    const control = <FormArray>this.manageAccountForm.get('domains');
    control.push(this.initDomain());
  }

  removeDomain(j) {
    const control = <FormArray>this.manageAccountForm.get('domains');
    control.removeAt(j);
  }

  getdomainsData(form) {
    return form.controls.domains.controls;
  }

  setdomainsData(res) {
    const control = <FormArray>this.manageAccountForm.controls.domains;
    control.removeAt(0);
    res.domains.forEach(x => {
      control.push(this.formBuilder.group({
        id: x.id,
        name: x.name
      }));
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(InstitutionDialogComponent, {
      width: '250px',
      data: {institution: this.institution_id, user: this.id}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.alertService.success('Institution change requested');
    });
  }

  approveInstitution() {
    this.apirequestService.get(this.url_approve_institution + this.id + '/')
      .subscribe(
        res => {
          console.log('approved');
        },
        error => {
          console.log(error);
        });
  }

  onSubmit() {
    console.log(`onSubmit2 CALLED`);
    this.submitted = true;
    // stop here if form is invalid
    if (this.manageAccountForm.invalid) {
      return;
    }
    this.loading = true;

    this.apirequestService.post(this.url + this.id + '/', this.manageAccountForm.value)
      .subscribe(
        data => {
          // update user object in localStorage
          this.loading = false;
          this.snackBar.openFromComponent(CustomSnackBarComponent, {
            data: {
              message: 'User successfully updated.',
              action: 'Success'
            },
            duration: 3 * 1000,
            panelClass: ['transparent-snack-bar']
          });
          // this.router.navigate(['/dashboard/manage_account/' + data.id]);
        },
        error => {
          this.alertService.error(error, error.error);
          this.loading = false;
        });
  }

  private createForm() {
    this.manageAccountForm = this.formBuilder.group({
      id: this.id,
      email: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      domains: this.formBuilder.array([this.initDomain()]),
      role: [''],
      institution: [''],
      is_active: [''],
      is_admin: [''],
      is_reviewer: [''],
    });
  }

  private initModalForm() {
    this.modalForm = this.formBuilder.group({
      institution: [''],
    });
  }

  private getFormData() {
    if (this.id) {
      this.apirequestService.get(this.url + this.id + '/')
        .subscribe(
          res => {
            this.manageAccountForm.patchValue(res, {onlySelf: true});
            this.institution_id = res.institution.id;
            this.manageAccountForm.get('institution').setValue(res.institution.name);
            this.setdomainsData(res);
          },
          error => {
            console.log(error);
          });
    }
  }

  private getInstitutionList() {
    this.apirequestService.get(this.url_institution)
      .subscribe(
        res => {
          this.institutions = res.results;
        },
        error => {
          console.log(error);
        });
  }

  private getDomainsList() {
    this.apirequestService.get(this.url_measurefilters)
      .subscribe(
        res => {
          this.thedomains = res.results;
        },
        error => {
          console.log(error);
        });
  }
}


@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./manage-account.component.scss']
})
export class UpdateProfileComponent implements OnInit {
  profileForm: FormGroup;
  modalForm: FormGroup;
  loading = false;
  submitted = false;
  error: string;
  id: string;
  sign_up_token: string;
  url = 'users/update/';
  url_update = 'users/update_profile/';
  url_get = 'users/user-details/';
  url_verify_registration = 'users/verify_registration/';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apirequestService: ApirequestService,
    public alertService: AlertService,) {
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.profileForm.controls;
  }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.id = params['id'];
        this.sign_up_token = params['sign_up_token'];
      },
      error => {
        console.log(error);
      });
    this.createForm();
    this.getFormData();
  }

  onSubmit() {
  console.log(`onSubmit3 CALLED`);
    this.submitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.profileForm.invalid) {
      return;
    }
    // this.loading = true;
    // Verify registration
    this.apirequestService.get(this.url_verify_registration + this.sign_up_token + '/')
      .subscribe(
        data => {
          // Update profile
          this.apirequestService.post(this.url_update, this.profileForm.value)
            .subscribe(
              res => {
                localStorage.removeItem('email');
                this.router.navigate(['/dashboard']);
              },
              error => {
                this.alertService.error(error, error.error);
                this.loading = false;
              });
        },
        error => {
          this.alertService.error(error, error.error);
          this.loading = false;
        });
  }

  private createForm() {
    this.profileForm = this.formBuilder.group({
      id: [''],
      email: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
    });
  }

  private getFormData() {
    if (this.id) {
      this.apirequestService.get(this.url_get + this.id + '/')
        .subscribe(
          res => {
            this.profileForm.patchValue(res, {onlySelf: true});
          },
          error => {
            console.log(error);
          });
    }
  }
}

