import {Component, OnInit} from '@angular/core';
import {KeyValue} from '@angular/common';
import * as moment from 'moment/moment';

import {ApirequestService} from '../../../../../shared/services/apirequest.service';
import {FileService} from 'src/app/shared/services/file.service';

@Component({
  selector: 'app-stats-ga4-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class StatisticsGA4PagesComponent implements OnInit {
  dashboard_details: any[];//{};// GoogleStatsAudienceModel;
  start_date: string = '';
  end_date: string = '';
  loading: boolean;
  exportloading: boolean;
  landing_page_sort = 'descending';
  second_page_sort = 'descending';
  exit_page_sort = 'descending';
  isDesc = false;
  column: string = '';
  landing_arrow: string = 'arrow_downward';
  second_arrow: string = 'arrow_downward';
  exit_arrow: string = 'arrow_downward';
  search: string = '';
  topHeaders: string[] = ['FirstPage', 'SecondPage', 'ExitPage'];
  displayedColumns: string[] = ['FirstPageKey', 'FirstPageUsers', 'SecondPageKey', 'SecondPageUsers', 'ExitPageKey', 'ExitPageUsers'];
  pageTitle = 'Pages';

  constructor(
    private apirequestService: ApirequestService,
    private fileService: FileService
  ) {
  }

  ngOnInit() {
    // let today = new Date();
    // const dateString = "2023-02-01T12:30:45.678Z";
    
    const currentDate = new Date();
    const dateString = currentDate.toISOString();

    let today = new Date(dateString);
    var lastMonth = new Date(today);
    lastMonth.setDate(lastMonth.getDate() - 30);
    this.start_date = lastMonth.toISOString().slice(0, 10);
    this.end_date = today.toISOString().slice(0, 10);

    this.getData(this.start_date, this.end_date, this.landing_page_sort, this.second_page_sort, this.exit_page_sort);
  }

  filter_stats() {
    this.loading = true;
    this.start_date = (<HTMLInputElement>document.getElementById('start_date')).value;
    this.end_date = (<HTMLInputElement>document.getElementById('end_date')).value;

    this.getData(this.start_date, this.end_date, this.landing_page_sort, this.second_page_sort, this.exit_page_sort);
  }

  filter_by_date() {
    this.loading = true;
    this.getData(this.start_date, this.end_date, this.landing_page_sort, this.second_page_sort, this.exit_page_sort);
  }

  getData(start_date, end_date, landing_page_sort, second_page_sort, exit_page_sort) {
    this.loading = true;

    var url = '';
    console.log(start_date + '::::' + end_date);

    if (start_date != '' && end_date != '') {
      var formatedstartDate = moment(start_date).format('YYYY-MM-DD');
      var formatedendDate = moment(end_date).format('YYYY-MM-DD');

    } else {
      let today = new Date();
      var lastMonth = new Date(today);
      lastMonth.setDate(lastMonth.getDate() - 30);

      var formatedstartDate = lastMonth.toISOString().slice(0, 10);
      var formatedendDate = today.toISOString().slice(0, 10);
    }
    this.start_date = formatedstartDate;
    this.end_date = formatedendDate;
    //?start_date=2022-01-01&end_date=2022-01-31&sort_landing_page_by=descending&sort_second_page_by=descending&sort_exit_by_page_by=descending
    url = 'analytics/pages/?start_date=' + formatedstartDate + '&end_date=' + formatedendDate;
    this.apirequestService.get(url)
      .subscribe(
        data => {
          this.dashboard_details = data;
          this.loading = false;


        },
        error => {
          console.log('ERROR::' + error);
          this.loading = false;
        });
  }

  // Order by ascending property value
  valueAscOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    // return a.value.localeCompare(b.value);
    return a.value > b.value ? 1 : 0;
  };

  // Order by ascending property value
  valueDescOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    // return b.value.localeCompare(a.value);
    return b.value > a.value ? 1 : 0;
  };


  get_session_duration(secondsSessionDuration) {
    let unix_timestamp = parseFloat(secondsSessionDuration);
    // Create a new JavaScript Date object based on the timestamp
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    var date = new Date(unix_timestamp * 1000);
    // Hours part from the timestamp
    var hours = date.getHours();
    // Minutes part from the timestamp
    var minutes = '0' + date.getMinutes();
    // Seconds part from the timestamp
    var seconds = '0' + date.getSeconds();

    // Will display time in 10:30:23 format
    var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

    return formattedTime;
  }

  onSearchClick() {
    this.getData(this.start_date, this.end_date, this.landing_page_sort, this.second_page_sort, this.exit_page_sort);

  }

  searchRecords(event: any) {
    this.search = event.target.value;
    this.getData(this.start_date, this.end_date, this.landing_page_sort, this.second_page_sort, this.exit_page_sort);
  }

  exportData() {
    var pageSize = 10000;
    var url = url = 'analytics/userflows/?start_date=' + this.start_date + '&end_date=' + this.end_date + '&sort_landing_page_by=' + this.landing_page_sort + '&sort_second_page_by=' + this.second_page_sort + '&sort_exit_by_page_by=' + this.exit_page_sort + '&searchTerm=' + this.search + '&export=True';
    this.exportloading = true;
    this.apirequestService.get(url)
      .subscribe(
        data => {
          this.exportloading = false;
          this.fileService.downloadFile(data.file_url);
        },
        error => {
          console.log(error);
          this.exportloading = false;

        });
  }

  private onCompare(_left: KeyValue<any, any>, _right: KeyValue<any, any>): number {
    return -1;
  }

  private sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 'ascending' : 'descending';
    if (this.column == 'landing_page') {
      this.landing_page_sort = direction;
      if (direction == 'ascending') {
        this.landing_arrow = 'arrow_upward';
        this.dashboard_details = this.dashboard_details.sort((a, b) => a.total_users - b.total_users);
      } else {
        this.landing_arrow = 'arrow_downward';
        this.dashboard_details = this.dashboard_details.sort((a, b) => b.total_users - a.total_users);
      }
    } else if (this.column == 'second_page') {
      this.second_page_sort = direction;
      if (direction == 'ascending') {
        this.second_arrow = 'arrow_upward';
      } else {
        this.second_arrow = 'arrow_downward';
      }
    } else if (this.column == 'exit_page') {
      this.exit_page_sort = direction;
      if (direction == 'ascending') {
        this.exit_arrow = 'arrow_upward';
      } else {
        this.exit_arrow = 'arrow_downward';
      }
    }
    

    // this.getData(this.start_date, this.end_date, this.landing_page_sort, this.second_page_sort, this.exit_page_sort);

  };
}



