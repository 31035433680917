let choices = {
MEASUREMENT_MODE: [],
DURATION: [],
MEASURE_SOURCE: []

};

choices.MEASUREMENT_MODE = [
    {name: 'Observational', order: 1},
    {name: 'Physiological', order: 2},
    {name: 'Self-report', order: 3},
    {name: 'Task', order: 3},
    {name: 'Other', order: 4},
    {name: 'Not specified', order: 5},
];


choices.DURATION = [
    {name: '0-5 minutes', order: 1},
    {name: '6-10 minutes', order: 2},
    {name: '11-15 minutes', order: 3},
    {name: '16-20 minutes', order: 3},
    {name: '20+ minutes', order: 4},
    {name: 'Not specified', order: 5},
];


choices.MEASURE_SOURCE = [
    {name: 'Science of Behavior Change (SOBC)', order: 1},
    {name: 'Group Evaluated Measures (GEM)', order: 2},
    {name: 'Stress Measurement Network (SMN)', order: 3},
    {name: 'Emotional Well-Being (EWB)', order: 3},
    {name: 'Roybal', order: 4},
    {name: 'User upload', order: 5},
    {name: 'Other', order: 6},
];


// module.exports = choices;
export default choices;