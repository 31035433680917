import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material';


@Component({
  selector: 'app-custom-snack-bar',
  templateUrl: './custom-snack-bar.component.html',
  styleUrls: ['./custom-snack-bar.component.scss']
})
export class CustomSnackBarComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<CustomSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,) {
  }

  cssClass() {
    // return css class based on action
    switch (this.data.action) {
      case 'Success':
        return 'alert alert-success';
      case 'Error':
        return 'alert alert-danger';
      case 'Info':
        return 'alert alert-info';
      case 'Warning':
        return 'alert alert-warning';
    }
  }

}
