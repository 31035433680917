import {Component, OnDestroy, OnInit, ElementRef, Renderer2, ViewChild, Inject} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import * as _ from 'lodash';

import {ApirequestService} from '../../shared/services/apirequest.service';
import {ApirequestdetailService} from '../../shared/services/apirequestdetail.service';
import {GoogleAnalyticsService} from '../../shared/services/google-analytics.service';
import {debounceTime} from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
// import * as customScript from './js.js';
import { DynamicScriptLoaderService } from '../../shared/services/DynamicScriptLoaderService';
import * as DOMPurify from 'dompurify';

// Define a custom class
class  IndividualCategory{
  id: number;
  name: string;
  constructor(id, name, ) {
    this.id = id;
    this.name = name;
  }
}

@Component({
  selector: 'app-sobc-resource-details2',
  templateUrl: './single-resource.component.html',
  styleUrls: ['./measures.component.scss', 'font-custom.css'],
})
export class SobcResourceDetails2Component implements OnDestroy, OnInit {
  // @ViewChild('myDiv') myDiv: ElementRef;
  @ViewChild('filters-selected', { read: ElementRef }) container: ElementRef;
  measures = [];
  resources = [];
  experiments = [];
  page: number;
  total = 0;
  total_pages = 0;
  thefilters = {};
  page_size = 10;
  sort_fields: any;
  search_data: any;
  route_stage: string;
  url_params;
  access_measure: boolean;
  loading = true;
  subscription: Subscription;

  collapse = 'closed';
  btncollapse = 'not_clicked';
  show = false;
  showText = false;
  isMainmeasuredetails = false;
  search_input: string;
  showMeasures = true;
  showExperiments = false;
  // Experiments pagination
  page_experiments: number;
  total_experiments = 0;
  total_pages_experiments = 0;
  thefilters_experiments = {};
  page_size_experiments = 10;
  loading_experiments = true;
  showFullMeasureHeader = false;
  resourcesFilters = [];
  filterCategories = [];
  searchedText = "";
  private searchTerms = new Subject<string>();
  private selectedResourcesFilter = new Subject<string>();
  dataValue: {};
  downloads = [];
  video = "";
  combinedArray = [];

  constructor(
    private apirequestService: ApirequestService,
    private apirequestdetailService: ApirequestdetailService,
    private route: ActivatedRoute,
    private router: Router,
    public googleAnalyticsService: GoogleAnalyticsService,
    private renderer: Renderer2,
    private el: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    private dynamicScriptLoader: DynamicScriptLoaderService
  ) {

  }
  ngOnDestroy(): void {
    // throw new Error('Method not implemented.');
  }

  // ngOnChanges(){
  //   this.loadScripts();
  // }
  // ngDoCheck(){
  //   this.loadScripts();
  // }
  // ngAfterViewChecked(){
  //   this.loadScripts();
  // }


  toogelDisplay(item) {
    if (item == 'measures') {
      this.showMeasures = true;
      this.showExperiments = false;
    } else {
      this.showMeasures = false;
      this.showExperiments = true;
    }
  }

  ngOnInit() {

    ///////////////////////////
    this.route.queryParams.subscribe(async queryParams => {
      // Access the query parameter value
      
      try {
        ////
        let post_title = queryParams['post_title'];
        let post_name = queryParams['post_name'];
        let post_summary = queryParams['post_summary'];
        let post_image_url = queryParams['post_image_url'];
        let post_category = queryParams['post_category'];
        let resources = queryParams['resources'];
        let downloads = queryParams['downloads'];
        let links = queryParams['links'];
        let videos = queryParams['videos'];

        ////
        let searchParams = queryParams['d'];
        if(searchParams == undefined){
          let data =  {
            post_title: decodeURIComponent(post_title),
            post_name: decodeURIComponent(post_name),
            post_summary: decodeURIComponent(post_summary),
            post_image_url: decodeURIComponent(post_image_url),
            post_category: decodeURIComponent(post_category),
            resources: decodeURIComponent(resources),
            downloads: [decodeURIComponent(downloads)],
            links: [decodeURIComponent(links)],
            video: [decodeURIComponent(videos)]
          };
          searchParams = JSON.stringify(data);
          
        }
        // let iv = queryParams['iv'];
        if (searchParams) {
          const parsedData = decodeURIComponent(searchParams);
          if(searchParams != undefined){
            // const decodedString = decodeURIComponent(searchParams);
            
          // const decryptedText = await this.decryptString('2486bd01-b806-42e2-b296-20122288b4b2', decodedString, iv);
          // const parsedData = JSON.parse(decodedString);
          // const parsedData = JSON.parse(searchParams);

          /////////////////////////////////////////////////////
          // localStorage.setItem('data', decodedString);
          /////////////////////////////////////////////////////
          }
          else{
            /////////////////////////////////////////////////////
            // localStorage.setItem('data', searchParams);
            /////////////////////////////////////////////////////
          }
        // const parsedData = JSON.parse(decryptedText);
        // localStorage.setItem('data', decodedString);
        // setJsonData(parsedData);
        // setData(parsedData);
        /////////////////////////////////////////////////////
        // let data = JSON.parse(localStorage.getItem('data'));
        /////////////////////////////////////////////////////
        let data = JSON.parse(parsedData);
        this.dataValue = data;
        console.log(data);

        
        let dwns = [];
        // let downloads_ = JSON.parse(localStorage.getItem('data')).downloads;
        // let links_ = JSON.parse(localStorage.getItem('data')).links;
        let downloads_ = data.downloads;
        let links_ = data.links;
        const combinedArray = [...downloads_, ...links_];
        this.combinedArray = combinedArray;

        this.video = data.video;
        this.loadExternalStylesheet('../../../assets/js/css.css');
        this.loadScripts();
          
            } else {
            console.error('No JSON parameter found in the URL.');
            }
          } catch (error) {
            console.error('Error parsing JSON data:', error);
          }
        });
    






    //////////////////////////

    
		// let data = JSON.parse(localStorage.getItem('data'));
		// this.dataValue = data;
		// console.log(data);

		
		// let dwns = [];
		// let downloads_ = JSON.parse(localStorage.getItem('data')).downloads;
		// let links_ = JSON.parse(localStorage.getItem('data')).links;
    // const combinedArray = [...downloads_, ...links_];
    // this.combinedArray = combinedArray;

    // this.video = data.video;
    // this.loadExternalStylesheet('../../../assets/js/css.css');
    // this.loadScripts();

  }
  // private loadScript(scriptUrl: string) {
  //   const script = this.renderer.createElement('script');
  //   script.type = 'text/javascript';
  //   script.src = scriptUrl;
  //   this.renderer.appendChild(this.document.body, script);
  // }
  // private loadScript(src: string) {
  //   const script = this.renderer.createElement('script');
  //   script.type = 'text/javascript';
  //   script.src = src;
  //   script.async = true;
  //   this.renderer.appendChild(this.document.body, script);
  // }

  // public loadScript(url: string) {
  //   return new Promise<void>((resolve, reject) => {
  //     const script = document.createElement('script');
  //     script.type = 'text/javascript';
  //     script.src = url;
  //     script.onload = () => {
  //       resolve();
  //     };
  //     script.onerror = () => {
  //       reject(new Error(`Script load error for ${url}`));
  //     };
  //     document.head.appendChild(script);
  //   });
  // }
  private loadScripts() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    // this.dynamicScriptLoader.load('chartjs','random-num').then(data => {
    //   // Script Loaded Successfully
    // }).catch(error => console.log(error));
    // this.dynamicScriptLoader.load('js').then(data => {
    this.dynamicScriptLoader.load('jsresources').then(data => {
      // Script Loaded Successfully
    }).catch(error => console.log(error));
  }

  private loadExternalStylesheet(url: string): void {
    const linkElement = this.renderer.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.type = 'text/css';
    linkElement.id="applicationStylesheet";
    linkElement.href = url;
    this.renderer.appendChild(this.document.head, linkElement);
  }
  
  
  getFileName = (details: string) => {
    let k = details.split("/");
		let fileName = k[k.length - 1];

    return fileName;
    // return details;
  }

  // sanitizeHtml = (htmlContent) => {
  //   return DOMPurify.sanitize(htmlContent);
  // };
  indexOf(details: string, filetype: string){
    if(details.indexOf('.pdf') != -1 || details.indexOf('.docx') != -1 || details.indexOf('.doc') != -1){
      return true;
    }
    return false;
  }
  getLinkName(link){
    // let link2 = link.split('/');
    // let linkLen = link2.length;
    // return link2[linkLen-1];
    // let link2 = link.split('.');
    // let linkLen = link2.length;
    // return link2[linkLen-1];
    return this.getDomainFromLink(link);
  }

  getDomainFromLink(link) {
    const url = new URL(link);
    return url.hostname;
  }

  // Function to decrypt a string
async decryptString(key, ciphertext, iv) {
  const decryptedData = await crypto.subtle.decrypt(
    { name: 'AES-GCM', iv },
    key,
    ciphertext
  );

  const decoder = new TextDecoder();
  const decryptedText = decoder.decode(decryptedData);

  return decryptedText;
}
sanitizeHtml = (htmlContent) => {
  return DOMPurify.sanitize(htmlContent);
};
}
