import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';

import {AuthenticationService} from '../../shared/services/authentication.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  @Output() _logStatus = new EventEmitter<boolean>();
  is_authenticated: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    const auth_token = JSON.parse(localStorage.getItem('auth.token'));
    this.authenticationService.logout(auth_token);
    this.is_authenticated = false;
    this._logStatus.emit(this.is_authenticated);
    this.router.navigate(['/login']);
  }

}
