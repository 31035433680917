import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {ApirequestService} from '../../../../../shared/services/apirequest.service';
import {MatTableDataSource} from '@angular/material';
import * as moment from 'moment/moment';
import {MatSort, Sort} from '@angular/material/sort';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {DatesService} from '../../../../../shared/services/dates.service';
import {FileService} from '../../../../../shared/services/file.service';

export interface Referrer {
  fullReferrer: string,
  users: number,
  newUsers: number,
  sessions: number,
  bounceRate: number,
  avgSessionDuration: number,
  pageviewsPerSession: number
}


@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['../channels.component.css']
})


export class ReferralsComponent implements AfterViewInit {


  topHeaders: string[] = ['fullReferrer', 'acquisition', 'behavior'];
  displayedColumns: string[] = ['users', 'newUsers', 'sessions', 'bounceRate', 'avgSessionDuration', 'pageviewsPerSession'];
  alldisplayedColumns: string[] = ['fullReferrer', 'users', 'newUsers', 'sessions', 'bounceRate', 'avgSessionDuration', 'pageviewsPerSession'];
  dataSource: MatTableDataSource<any>;
  dataCount = 0;
  totalPages = 0;
  pageSize = 10;
  pageToken = 0;
  rowCount = 0;
  loading: boolean;
  start_date: string = '';
  end_date: string = '';
  url: string;
  maxDate = new Date();
  sortOrder: string = 'DESCENDING';
  column: string = 'users';
  pageTokens = new Map;
  pageIndex = 0;
  search: string = '';
  @ViewChild(MatSort) sort: MatSort;

  constructor(private apirequestService: ApirequestService, private datesService: DatesService, private _liveAnnouncer: LiveAnnouncer, private fileService: FileService) {
  }

  ngAfterViewInit() {
     // let today = new Date();
     const dateString = "2023-02-01T12:30:45.678Z";
     let today = new Date(dateString);
     var lastMonth = new Date(today);
     lastMonth.setDate(lastMonth.getDate() - 30);
     this.start_date = lastMonth.toISOString().slice(0, 10);
     this.end_date = today.toISOString().slice(0, 10);
    this.dataSource = new MatTableDataSource();
    // this.dataSource.sort = this.sort;
    this.pageTokens.set(0, this.pageToken);
    this.getData(this.start_date, this.end_date, this.sortOrder, this.pageSize, this.column);
  }

  filter_by_date() {
    this.loading = true;
    this.getData(this.start_date, this.end_date, this.sortOrder, this.pageSize, this.column);
  }

  getData(start_date, end_date, sortOrder, pageSize, column) {
    if (this.pageTokens.has(this.pageIndex)) {
      this.pageToken = this.pageTokens.get(this.pageIndex);
    }
    if (start_date != '' && end_date != '') {
      this.start_date = this.datesService.formatDate(start_date);
      this.end_date = this.datesService.formatDate(end_date);
      this.url = 'analytics/channels/referrals/?pageToken=' + this.pageToken + '&start_date=' + this.start_date + '&end_date=' + this.end_date + '&pageSize=' + pageSize + '&sortOrder=' + sortOrder + '&column=' + column + '&searchTerm=' + this.search;

    } else {
      this.start_date = this.datesService.getStartDate();
      this.end_date = this.datesService.getEndDate();
      this.url = 'analytics/channels/referrals/?pageToken=' + this.pageToken;
    }
    this.loading = true;


    this.apirequestService.get(this.url)
      .subscribe(
        data => {
          this.loading = false;
          let alldata = [];
          const entries = Object.entries(data);
          this.pageToken = entries[0][1]['pageToken'];
          this.rowCount = entries[0][1]['rowCount'];
          this.dataCount = this.rowCount;

          for (let i = 1; i < entries.length; i++) {
            var avgsession = entries[i][1]['avgSessionDuration'];
            entries[i][1]['avgSessionDuration'] = moment().startOf('day')
              .seconds(avgsession)
              .format('H:mm:ss');
            alldata.push(entries[i][1]);
          }
          this.dataSource.data = alldata;
          this.totalPages = this.dataCount / this.pageSize;
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
        });
  }

  pageChanged(data: any) {
    this.dataSource.data = [];
    this.pageSize = data.pageSize;
    this.pageIndex = data.pageIndex;
    this.getData(this.start_date, this.end_date, this.sortOrder, this.pageSize, this.column);
    this.pageTokens.set(data.pageIndex, this.pageToken);
  }

  announceSortChange(sortState: Sort) {
    this.pageTokens.clear();
    if (sortState.direction == 'asc') {
      this.sortOrder = this.sortOrder == 'ASCENDING' ? 'DESCENDING' : 'ASCENDING';

    } else if (sortState.direction == 'desc') {
      this.sortOrder = this.sortOrder == 'DESCENDING' ? 'ASCENDING' : 'DESCENDING';
    } else {
      this.sortOrder = this.sortOrder == 'DESCENDING' ? 'ASCENDING' : 'DESCENDING';
    }
    this.column = sortState.active;
    this.pageTokens.set(0, 0);
    this.getData(this.start_date, this.end_date, this.sortOrder, this.pageSize, this.column);

  }

  onSearchClick() {
    this.getData(this.start_date, this.end_date, this.sortOrder, this.pageSize, this.column);

  }

  searchRecords(event: any) {
    this.search = event.target.value;
    this.getData(this.start_date, this.end_date, this.sortOrder, this.pageSize, this.column);

  }

  exportData() {
    var pageSize = 10000;
    this.url = 'analytics/channels/referrals/?pageToken=' + this.pageToken + '&start_date=' + this.start_date + '&end_date=' + this.end_date + '&pageSize=' + pageSize + '&sortOrder=' + this.sortOrder + '&column=' + this.column + '&searchTerm=' + this.search + '&export=True';
    this.loading = true;
    this.apirequestService.get(this.url)
      .subscribe(
        data => {
          this.loading = false;
          this.fileService.downloadFile(data.file_url);
        },
        error => {
          console.log(error);
          this.loading = false;

        });
  }
}
