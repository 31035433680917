import { Component, OnInit, ViewChild, ElementRef, Renderer2, Input } from '@angular/core';
import * as d3 from 'd3';
import { ApirequestService } from '../../../shared/services/apirequest.service';
import { DiagramSharedService } from './diagram-shared.service';


interface Node extends d3.SimulationNodeDatum {
  id: string;
  fx?: number;
  fy?: number;
  radius?: number;
  ca_link?: string;
  order?: number;
}

interface Link extends d3.SimulationLinkDatum<Node> {
  source: string | Node;
  target: string | Node;
}

@Component({
  selector: 'app-network-diagram',
  templateUrl: './network-diagram.component.html',
  styleUrls: ['./network-diagram.component.scss']
})
export class NetworkDiagramComponent implements OnInit {
  // measure_title = 'Stop Signal Task';
  // measure_ca_id: string = 'trm_56674133b666c';
  concepts = [];
  img1: string = '../../../../assets/images//cognitive_atlas/Group_760.png';
  img2: string = '../../../../assets/images//cognitive_atlas/Group_760@2x.png';
  img_url: string;
  allNodes: any[];
  links: any[];
  selectedNode: string;
  @Input() measure_ca_id: string;
  @Input() measure_title: string;
  loading: boolean;

  // @ViewChild('networkDiagram') networkDiagram: ElementRef;

  constructor(
    private apirequestService: ApirequestService,
    private renderer: Renderer2, private diagramSharedService: DiagramSharedService,) { }

    moveTestToFirstPosition(arr, val) {
      // Find the index of the object with name "Test"
      const testIndex = arr.findIndex(item => item.id === val);
    
      // If there's no such object, return the array as is
      if (testIndex === -1) {
        return arr;
      }
    
      // Remove the object from its current position
      const [testObject] = arr.splice(testIndex, 1);
    
      // Insert the object into the second position
      arr.splice(0, 0, testObject);
    
      return arr;
    }

  ngOnInit() {
    // this.measure_title == 'Stop Signal Task';
    this.loading = true;
    this.diagramSharedService.data$.subscribe(data => {
      if(data != undefined){
        // console.log(`data=${JSON.stringify(data)}`);
        if(data.source == 'diagram'){
          this.selectedNode = data.selectedNode;
          this.diagramSharedService.sendData({
            concepts: this.concepts,
            selectedNode: this.selectedNode,
            allNodes: this.allNodes,
            links: this.links
          });
        }
      }
    });

    this.img_url = `${this.img1} 1x, ${this.img2} 2x`;
    this.allNodes = [];
    this.links = [];
    const url = 'visualization-taxonomy/get-concepts/' + this.measure_ca_id + '/';
    this.apirequestService.get(url).subscribe(
      res => {
        this.concepts = res;
        // this.createNetworkDiagram(undefined);
          //   if(selectedNodeName != undefined){
  //     this.selectedNode = selectedNodeName;
  //   }
  //   this.allNodes = [];
          const mainNodeRadius = 90;
          const nodeRadius = 50;
          const nodes = [];
          
          const randomIndex = Math.floor(Math.random() * this.concepts.length);

          let i = 0;
          nodes.push({ id: this.measure_title, radius: mainNodeRadius, order: 0 });
          

          this.concepts.forEach(currConcept => {
            // const is_selected = (this.measure_title === 'Stop Signal Task' && currConcept.name === 'proactive interference') ||
            //                     (i === randomIndex && this.selectedNode === undefined);
            //  if(this.measure_title === 'Stop Signal Task' && currConcept.name === 'proactive interference'){
            //   this.selectedNode = currConcept.name;
            // }
            // else if(i === randomIndex && this.selectedNode === undefined){
            //   this.selectedNode = currConcept.name;
            // }
            let is_selected = false;
            let order = this.allNodes.length;
            
            if(this.measure_title == 'Stop Signal Task'){
              if(currConcept.name == 'Proactive Control'){
                is_selected = true;
                this.selectedNode = currConcept.name;
                if(this.selectedNode == undefined){
                  this.selectedNode = currConcept.name;
                  order = 0;
                }
              }
            }
            else{
              if(i == randomIndex){
                is_selected = true;
                this.selectedNode = currConcept.name;
                if(this.selectedNode == undefined){
                  this.selectedNode = currConcept.name;
                  order = 0;
                }
              }
            }
            // nodes.push({ id: currConcept.name, radius: nodeRadius, ca_link: currConcept.ca_link, is_selected });
            // links.push({ source: this.measure_title, target: currConcept.name });
            this.allNodes.push({ id: currConcept.name, name: currConcept.name, radius: nodeRadius, ca_link: currConcept.ca_link, is_selected, order: order });
            ///////////////////////////////////////////////
            this.links.push({ source: this.measure_title, target: currConcept.name });


            //////////////////////////////////////////////
            // this.allNodes = this.allNodes;
            i++;
          });

          if(this.measure_title == 'Stop Signal Task'){
            this.allNodes = this.moveTestToFirstPosition(this.allNodes, 'Proactive Control');
            this.selectedNode = 'Proactive Control';
          }

          this.allNodes.forEach(currNode => {
            const currIndex = this.allNodes.findIndex(node => node.name === currNode.name);
            currNode.order = currIndex;
            // if(this.measure_title != 'Stop Signal Task' && currNode.order == 0){
            if(this.measure_title != 'Stop Signal Task' && currNode.order == 0){
              this.allNodes.forEach(node => node.is_selected = false);
              // currNode.is_selected = true;
              const nodeWithOrderZero = this.allNodes.find(node => node.order === 0);
              if (nodeWithOrderZero) {
                nodeWithOrderZero.is_selected = true;  // Select the node with order 0
              }
              this.allNodes = this.moveTestToFirstPosition(this.allNodes, currNode.name);
              this.selectedNode = currNode.name;
            }
          });

          // this.allNodes =  this.allNodes.reverse();
          console.log(`this.allNodes=${JSON.stringify(this.allNodes)}`);
          

          

          this.diagramSharedService.sendData({
            concepts: this.concepts,
            selectedNode: this.selectedNode,
            allNodes: this.allNodes,
            links: this.links,
            source: 'buttons',
          });

          this.loading = false;

      },
      error => {
        console.log(error);
        this.loading = false;
      }
    );

    
  }

  // arrangeNodes(data: { nodes: Node[], links: Link[] }) {
  //   // Sort nodes by order
  //   data.nodes.sort((a, b) => a.order - b.order);

  //   // Calculate positions
  //   const width = 800;
  //   const height = 600;
  //   const radius = 200;
  //   const angleStep = (2 * Math.PI) / (data.nodes.length - 1);

  //   data.nodes.forEach((node, index) => {
  //     if (node.id !== this.measure_title) {
  //       const angle = angleStep * (index - 1);
  //       node.fx = width / 2 + radius * Math.cos(angle);
  //       node.fy = height / 2 + radius * Math.sin(angle);
  //     } else {
  //       node.fx = width / 2;
  //       node.fy = height / 2;
  //     }
  //   });
  //   return data;
  // }

  arrangeNodes(data: { nodes: Node[], links: Link[] }) {
    // Sort nodes by order
    data.nodes.sort((a, b) => a.order - b.order);
  
    // Calculate positions
    const width = 800;
    const height = 600;
    const radius = 200;
    const angleStep = (2 * Math.PI) / (data.nodes.length - 1);
  
    let workingMemoryNode = null;
  
    data.nodes.forEach((node, index) => {
      if (node.id !== this.measure_title) {
        const angle = angleStep * (index - 1);
        node.fx = width / 2 + radius * Math.cos(angle);
        node.fy = height / 2 + radius * Math.sin(angle);
      } else {
        node.fx = width / 2;
        node.fy = height / 2;
      }
  
      // Store the working memory node position
      if (node.id === 'Working Memory') {
        workingMemoryNode = node;
      }
    });
  
    // Set the position of the "Proactive Interference" node to the same as the "Working Memory" node
    const proactiveInterferenceNode = data.nodes.find(node => node.id === 'Proactive Interference');
    // if (proactiveInterferenceNode && workingMemoryNode) {
    //   proactiveInterferenceNode.fx = workingMemoryNode.fx;
    //   proactiveInterferenceNode.fy = workingMemoryNode.fy;
    // }
    if (proactiveInterferenceNode) {
      proactiveInterferenceNode.fx = workingMemoryNode.fx;
      proactiveInterferenceNode.fy = workingMemoryNode.fy;
    }
  
    return data;
  }
  

  setRectWidth(text) {
    const len = text.length;
    if (len <= 10) {
      return (len * 13);
    } else if (len >= 18 && len < 23) {
      // return (len * 7.5);
      return (len * 8);
    }
    else if (len >= 18 && len >= 23) {
      // return (len * 7.5);
      return (len * 7.5);
    }
    // return (len * 10);
    return (len * 10);
  }

  setSelectedNode(nodeId) {
    console.log(`nodeId=${nodeId}`);
    this.selectedNode = nodeId;
    this.diagramSharedService.sendData({
      concepts: this.concepts,
      selectedNode: this.selectedNode,
      allNodes: this.allNodes,
      links: this.links
    });
    // this.createNetworkDiagram(nodeId);
  }
}
