import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import * as _ from 'lodash';

import {FieldConfig} from '../../models/field-config.interface';


@Component({
  exportAs: 'dynamicForm',
  selector: 'app-dynamic-form',
  styleUrls: ['dynamic-form.component.scss'],
  template: `
  <form
    class="dynamic-form"
    [formGroup]="form"
    (submit)="handleSubmit($event)">
    <ng-container
      *ngFor="let field of config;"
      dynamicField
      [config]="field"
      [group]="form"
      [submitted]= submitted>
    </ng-container>
  </form>
  `
})

export class DynamicFormComponent implements OnChanges, OnInit {
  @Input()
  config: FieldConfig[] = [];

  @Output()
  submit: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;
  submitted = false;

  constructor(private fb: FormBuilder) {
  }

  get controls() {
    return this.config.filter(({type}) => type !== 'button');
  }

  get changes() {
    return this.form.valueChanges;
  }

  get valid() {
    return this.form.valid;
  }

  get value() {
    return this.form.value;
  }

  ngOnInit() {
    this.form = this.createGroup();
  }

  ngOnChanges() {
    if (this.form) {
      const controls = Object.keys(this.form.controls);
      const configControls = this.controls.map((item) => item.name);

      controls
        .filter((control) => !configControls.includes(control))
        .forEach((control) => this.form.removeControl(control));

      configControls
        .filter((control) => !controls.includes(control))
        .forEach((name) => {
          const config = this.config.find((control) => control.name === name);
          this.form.addControl(name, this.createControl(config));
        });

    }
  }

  createGroup() {
    const group = this.fb.group({});
    this.controls.forEach(control => {
      group.addControl(control.name, this.createControl(control));
    });
    return group;
  }

  createControl(config) {
    config.validation = {required: true};
    const {disabled, validation, value} = config;
    let fArray;
    if (config.type === 'checkbox') {
      this.form.setControl(config.name, new FormArray([], this.minSelectedCheckboxes(1)));
      const options = config.options;
      options.map((o, i) => {
        const group = this.fb.group({});
        i++;
        const control = new FormControl(i === 0); // if first item set to true, else false
        group.addControl(o.name, control);
        fArray = (this.form.controls[config.name] as FormArray).push(group);
      });
      return fArray;
    } else {
      return this.fb.control({disabled, value}, Validators.required);
    }
  }

  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => {
          const value = _.values(control.value);
          return value[0];
        })
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);
      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : {required: true};
    };
    return validator;
  }

  handleSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.valid) {
      this.submit.emit(this.value);
      this.submitted = true;
    }
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  setDisabled(name: string, disable: boolean) {
    if (this.form.controls[name]) {
      const method = disable ? 'disable' : 'enable';
      this.form.controls[name][method]();
      return;
    }

    this.config = this.config.map((item) => {
      if (item.name === name) {
        item.disabled = disable;
      }
      return item;
    });
  }

  setValue(name: string, value: any, config: FieldConfig) {
    this.form.controls[name].setValue(value, {emitEvent: true});
  }
}
