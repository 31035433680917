import {Component, ElementRef, Inject, OnInit, ViewChild, EventEmitter, Input, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import * as _ from 'lodash';
import {saveAs as importedSaveAs} from 'file-saver';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';

import {ApirequestService} from '../../shared/services/apirequest.service';
import {Measures} from '../../shared/models/measures';
import {GoogleScholar} from '../../shared/models/googlescholar';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {ShareCollectionComponent} from '../my-collection/share-collection.component';
import {CustomSnackBarComponent} from '../../shared/common/custom-snack-bar/custom-snack-bar.component';
import {ConfirmationDialogService} from '../../shared/confirmation-dialog/confirmation-dialog.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../shared/services/alert.service';


export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-send-for-review',
  templateUrl: 'send-for-review.html',
})
export class SendForReviewComponent implements OnInit {
  @Input() id: string;
  commentsForm: FormGroup;
  comments: string;
  measure_id: string;
  destination: string;
  user_role: string;
  userdetails: any;
  can_edit = false;
  loaded_measure: string;

  constructor(
    public dialogRef: MatDialogRef<SendForReviewComponent>,
    private formBuilder: FormBuilder,
    private apirequestService: ApirequestService,
    public alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private activeRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.createCommentsForm();
    this.measure_id = this.id;
    console.log('this.measure_id=' + this.measure_id);

    // this.route.params.subscribe(params => {
    //   var id_ = params['id'] || '';
    //   if(id_ == ''){
    //       this.can_edit = true;
    //     }
    // });
    this.can_edit = true;


    this.measure_id = this.dialogRef.componentInstance.data['measure_id'];
    this.destination = this.dialogRef.componentInstance.data['destination'];
    this.userdetails = JSON.parse(localStorage.getItem('auth.user'));
    if (!_.isNull(this.userdetails)) {
      this.user_role = this.userdetails.role;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private createCommentsForm() {
    this.commentsForm = this.formBuilder.group({
      comments: ['', Validators.required],
    });
  }

  private sendForReview(user_role, action: string = 'APPROVE') {
    let senddata = {
      'destination': this.destination,
      'measure_id': this.measure_id,
      'comments': this.commentsForm.get('comments').value
    };
    if(action == 'APPROVE'){
      senddata['action'] = 'PUBLISH';
    }
    // 'action': 'PUBLISH'
    console.log(`senddata=${JSON.stringify(senddata)}`);
    const url_approval = 'measures/approve_reject_measure/';
    const url_review = 'measures/admin_send_to_user/';
    if (this.destination === 'Approve') {
      this.apirequestService.post(url_approval, senddata).subscribe(
        data => {
          this.alertService.success(data.message);
        },
        error => {
          console.log(error);
        });
    } else {
      this.apirequestService.post(url_review, senddata).subscribe(
        data => {
        },
        error => {
          console.log(error);
        });
    }
    this.dialogRef.close();
  }

  private saveAndPublish(user_role, action: string = 'APPROVE') {
    let senddata = {
      'destination': this.destination,
      'measure_id': this.measure_id,
      'comments': this.commentsForm.get('comments').value,
      'action': 'PUBLISH'
    };
    const url_approval = 'measures/approve_reject_measure/';
    const url_review = 'measures/admin_send_to_user/';
    if (this.destination === 'Approve') {
      this.apirequestService.post(url_approval, senddata).subscribe(
        data => {
          this.alertService.success(data.message);
        },
        error => {
          console.log(error);
        });
    } else {
      senddata['publish'] = true;
      this.apirequestService.post(url_review, senddata).subscribe(
        data => {
        },
        error => {
          console.log(error);
        });
    }
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-citations-dialog',
  template: `
    <h1 mat-dialog-title class="text-capitalize">
      How to export
      <span class="text-capitalize">{{data.citation_name}} </span>references:</h1>
    <div mat-dialog-content>
      <ol>
        <li>
          {{citation_instruction}}
        </li>
        <li> Click the <b style="color: #0076ff">Export</b> button below.
          A file with the <b>{{data.extension}} </b>
          extension will be downloaded.
        </li>
        <li>
          Open Your favorite citation Software and import the file downloaded in 1) above.
        </li>
      </ol>
    </div>
    <div mat-dialog-action>
      <button class="btn btn-danger btn-sm" (click)="onNoClick()">Cancel</button>
      <button class="btn btn-primary btn-sm ml-2" (click)="downloadCitation(data.citation_type)">
        <i class="fa fa-circle-o-notch  fa-spin" *ngIf="loading"></i>
        Export
      </button>
    </div>
  `,
})
export class CitationsDialogComponent implements OnInit {
  citation_description: string;
  citation_instruction: string;
  loading = false;
  user_role: string;

  constructor(
    public dialogRef: MatDialogRef<CitationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private apirequestService: ApirequestService,
  ) {
  }

  ngOnInit() {
    this.citation_instruction = (this.data.citation_name === 'BibTex') ?
      'Ensure you have BibTex referencing software installed in your computer.' :
      'Ensure you have ' + this.data.citation_name + ' installed in your computer.';
      let user_ = JSON.parse(localStorage.getItem('auth.user'));
      this.user_role = user_.role;
      
  }

  downloadCitation(type) {
    this.loading = true;
    const url = 'measures/export_citations/' + this.data.measure_id + '/' + type + '/';
    this.apirequestService.getFile(url)
      .subscribe(
        response => {
          let extension = '.bib';
          if (type === 'bibtext') {
            extension = '.bib';
          } else if (type === 'endnote') {
            extension = '.xml';
          } else if (type === 'refworks' || type === 'zotero') {
            extension = '.enw';
          } else if (type === 'mendeley') {
            extension = '.irs';
          }
          importedSaveAs(response, this.data.measure_title + '_Citations' + extension);
          this.loading = false;
          this.dialogRef.close();
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}


@Component({
  selector: 'app-measureapproval',
  templateUrl: './measuredetails.component.html',
  styleUrls: ['./measuredetails.component.scss']
})
export class MeasureApprovalComponent implements OnInit {
  measures: Measures;
  osfDetails = [];
  hasOsf = false;
  studies = [];
  identifieddata;
  measureddata;
  influenceddata;
  validateddata;
  relatedMeasures = [];
  creditDetails = [];
  identified = false;
  measured = false;
  influenced = false;
  validated = false;
  loading_related_articles = true;
  loading_osf_details = true;
  loading_credit_details = true;
  loading_comments = true;
  theStars = [];
  load_reviewers = false;
  id: string;
  comments;
  isLoggedIn: boolean;
  page = 1;
  total = 0;
  limit = 10;
  total_pages = 0;
  page_size = 0;
  survey_count = true;
  entries: string;
  toLoadGoogleScholar = false;
  googleScholarDetails: GoogleScholar;
  user_id: string;
  show_review: boolean;
  show_edit: boolean;
  user_role: string;
  loading_submit: boolean;

  @ViewChild('googleScholarCollapse', {read: ElementRef}) googleScholarCollapse: ElementRef;

  constructor(
    private apirequestService: ApirequestService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private confirmationDialogService: ConfirmationDialogService,
    public alertService: AlertService,
  ) {
  }

  ngOnInit() {
    this.loading_submit = false;
    let user_ = JSON.parse(localStorage.getItem('auth.user'));
    this.user_role = user_.role;
    this.route.params.subscribe(params => {
      this.id = params['id'] || '';
    });
    this.loadData();
    this.getMeasureDetailsOSF();
    this.getRelatedMeasures();
    this.getCreditDetails();
    this.getComments();
    this.getSurveys();
  }

  shareMeasure(measure) {
    // pop up to add users
    const dialogRef = this.dialog.open(ShareCollectionComponent, {
      panelClass: 'customPanelClass',
      data: {
        measure: this.measures,
        type: 'measure',
      },
    });
  }

  openCitationDialogue(type, ext, name) {
    this.dialog.open(CitationsDialogComponent, {
      data: {
        citation_type: type,
        measure_id: this.id,
        measure_title: this.measures.title,
        extension: ext,
        citation_name: name
      },
      position: {
        left: '20%'
      }
    });
  }

  loggedIn(type) {
    if (this.auth.isAuthenticated()) {
      this.isLoggedIn = true;
      if (type === 'collection') {
        this.router.navigate(['/dashboard/mycollection/' + this.id + '/'], {queryParams: {returnUrl: 'measuredetails/' + this.id}});
      } else if (type === 'share') {
        this.shareMeasure(this.measures);
      }
    } else {
      if (type === 'comment' || type === 'share') {
        this.router.navigate(['/login'], {queryParams: {returnUrl: 'measuredetails/' + this.id}});
      } else {
        this.router.navigate(['/login'], {queryParams: {returnUrl: '/dashboard/mycollection/' + this.id}});
      }
    }
  }

  public openConfirmationDialog() {
    this.confirmationDialogService.confirm('Confirm Approval', 'Are you sure you want to approve this measure?')
      .then((confirmed) => {
        if (confirmed == true) {
          const data = {
            'measure_id': this.id,
            'status': 'approved',
            'comments': 'Approved',
          };
          const url = 'measures/approve_reject_measure/';
          this.apirequestService.post(url, data)
            .subscribe(
              res => {
                this.isLoggedIn = false;
                this.getComments();
                this.snackBar.openFromComponent(CustomSnackBarComponent, {
                  data: {
                    message: res.message,
                    action: 'Success'
                  },
                  duration: 3 * 1000,
                  panelClass: ['transparent-snack-bar']
                });
                // this.loading = false;
              },
              error => {
                console.log(error);
                // this.loading = false;
              });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  approveMeasure() {
    const data = {
      'measure_id': this.id,
      'status': 'approved',
      'comments': 'Approved',
    };
    const url = 'measures/approve_reject_measure/';
    this.apirequestService.post(url, data)
      .subscribe(
        res => {
          this.isLoggedIn = false;
          this.getComments();
          this.snackBar.openFromComponent(CustomSnackBarComponent, {
            data: {
              message: res.message,
              action: 'Success'
            },
            duration: 3 * 1000,
            panelClass: ['transparent-snack-bar']
          });
          // this.loading = false;
        },
        error => {
          console.log(error);
          // this.loading = false;
        });
  }

  submitComment(e) {
    const data = {
      'measure_id': this.id,
      'status': 'rejected',
      'comments': e,
    };
    const url = 'measures/approve_reject_measure/';
    this.apirequestService.post(url, data)
      .subscribe(
        res => {
          this.isLoggedIn = false;
          this.getComments();
          this.snackBar.openFromComponent(CustomSnackBarComponent, {
            data: {
              message: res.message,
              action: 'Success'
            },
            duration: 3 * 1000,
            panelClass: ['transparent-snack-bar']
          });
          // this.loading = false;
        },
        error => {
          console.log(error);
          // this.loading = false;
        });
  }

  googleScholarContainerClicked(event) {
    if (_.isEqual(this.googleScholarCollapse.nativeElement.className, 'collapse')) {
      this.getMeasureDetailsGoogleScholar();
    }
  }

  goToPage(n: number): void {
    this.page = n;
    this.getComments();
  }

  onNext(): void {
    this.page++;
    this.getComments();
  }

  onPrev(): void {
    this.page--;
    this.getComments();
  }

  private loadData() {
    this.user_id = JSON.parse(localStorage.getItem('auth.user'));
    const url = 'measures/pending_review_details/' + this.id + '/';
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.measures = res;
          if (res.stage === 'Identified') {
            this.identified = true;
            this.measured = false;
            this.influenced = false;
            this.validated = false;
          } else if (res.stage === 'Measured') {
            this.identified = true;
            this.measured = true;
            this.influenced = false;
            this.validated = false;
          } else if (res.stage === 'Influenced') {
            this.identified = true;
            this.measured = true;
            this.influenced = true;
            this.validated = false;
          } else {
            this.identified = true;
            this.measured = true;
            this.influenced = true;
            this.validated = true;
          }

          if (this.user_id['id'] === res.researcher.id || this.user_role == 'Admin') {
            this.show_review = false;
            this.show_edit = true;
          }
          // if() {
            if (this.user_id['role'] === 'Reviewing Scientist' || this.user_id['role'] === 'Admin') {
            // if (this.user_id['role'] === 'Reviewing Scientist' || this.user_id['role'] != 'Admin') {
              this.show_review = true;
            } else {
              this.show_review = false;
            }
          // }


          this.studies = res.studies;
          for (let i = 0, len = this.studies.length; i < len; i++) {
            const studiesdata = this.studies[i];

            if (studiesdata.stage === 'Identified') {
              this.identifieddata = studiesdata;
            } else if (studiesdata.stage === 'Measured') {
              this.measureddata = studiesdata;
            } else if (studiesdata.stage === 'Influenced') {
              this.influenceddata = studiesdata;
            } else if (studiesdata.stage === 'Validated') {
              this.validateddata = studiesdata;
            }
          }
        },
        error => {
          console.log(error);
        });
  }

  private getMeasureDetailsOSF() {
    const url = 'measures/osf_details/' + this.id + '/';
    // GET OSF Details
    this.apirequestService.get(url)
      .subscribe(
        res => {
          if (_.isUndefined(res.message) || res.message === '') {
            this.hasOsf = true;
          }
          this.loading_osf_details = false;
          this.osfDetails = res;
        },
        error => {
          console.log(error);
        });
  }

  private getMeasureDetailsGoogleScholar() {
    const url = 'measures/related_google_scholar_articles/' + this.id + '/';
    // GET GOOGLE SCHOLAR Details
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.googleScholarDetails = res;
        },
        error => {
          console.log(error);
        });
  }

  private getRelatedMeasures() {
    const url = 'measures/related_measures/' + this.id + '/';
    // Get Related measures
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.relatedMeasures = res.results;
        },
        error => {
          console.log(error);
        });
  }

  private getCreditDetails() {
    const url = 'measures/credits_details/' + this.id + '/';
    this.apirequestService.get(url)
      .subscribe(
        res => {
          this.creditDetails = res;
          this.loading_credit_details = false;
          const data = [];
          for (let u = 0; parseInt(res.stars, 10) > u; u++) {
            data.push(u);
          }
          if (res.reviewers.length > 0) {
            this.load_reviewers = true;
          }
          this.theStars = data;
        },
        error => {
          console.log(error);
        });
  }

  private getSurveys() {
    const url = 'surveys/surveys/' + this.id + '/';
    this.apirequestService.get(url)
      .subscribe(
        res => {
          if (_.isEmpty(res.results)) {
            this.survey_count = false;
          }
        },
        error => {
          console.log(error);
        });
  }

  private getComments() {
    const url = 'measures/comments/' + this.id + '/';
    const params = new HttpParams()
      .set('page', this.page.toString());
    this.apirequestService.get(url, params)
      .subscribe(
        res => {
          this.comments = res;
          this.total = res.count;
          this.page_size = res.page_size;
          this.page = res.current_page;
          this.total_pages = res.total_pages;
          this.loading_comments = false;
          this.entries = (this.total > 1) ? 'Comments' : 'Comment';
        },
        error => {
          console.log(error);
        });
  }

  saveAndPublish(destination, action='APPROVE') {
    this.loading_submit = true;
    // this.submitData('Submit');
    this.loading_submit = false;
    // this.router.navigate(['/dashboard/measure/' + this.id]);
    this.confirmationDialogService.confirm('Confirm publishing measure', 'Are you sure you want to publish this measure?', 'Yes Publish')
      .then((confirmed) => {
        if (confirmed == true) {
          let data = {
            'measure_id': this.id,
          };
          if(action == 'PUBLISH'){
            data['action'] = 'PUBLISH';
          }
          const url = 'measures/submit_for_approval/';
          this.loading_submit = true;
          this.apirequestService.post(url, data)
            // .pipe(first())
            .subscribe(
              data => {
                // this.id = data.id;
                this.snackBar.openFromComponent(CustomSnackBarComponent, {
                  data: {
                    message: data == 'Measure successfully submitted for review'? 'Measure successfully published': data,
                    action: 'Success'
                  },
                  duration: 3 * 1000,
                  panelClass: ['transparent-snack-bar']
                });
                this.loading_submit = false;
                // this.router.navigate(['/dashboard/measure/' + this.id]);
              },
              error => {
                this.alertService.error(error, '');
                this.loading_submit = false;
              });
        }
      });
    // this.router.navigate(['/dashboard/measure/' + this.id]);
  }
}
