import {Component, OnInit} from '@angular/core';
import {ApirequestService} from '../../../../shared/services/apirequest.service';
import {MatTableDataSource} from '@angular/material';
import * as moment from 'moment/moment';
import {Router} from '@angular/router';
import {FileService} from 'src/app/shared/services/file.service';

@Component({
  selector: 'app-channels',
  templateUrl: './channels.component.html',
  styleUrls: ['./channels.component.css']
})
export class ChannelsComponent implements OnInit {


  loading: boolean;
  start_date: string = '';
  end_date: string = '';
  url: string;
  topHeaders: string[] = ['channelGrouping', 'acquisition', 'behavior'];
  displayedColumns: string[] = ['users', 'newUsers', 'sessions', 'bounceRate', 'avgSessionDuration', 'pageviewsPerSession'];
  alldisplayedColumns: string[] = ['channelGrouping', 'users', 'newUsers', 'sessions', 'bounceRate', 'avgSessionDuration', 'pageviewsPerSession'];
  dataSource: MatTableDataSource<any>;

  constructor(
    private apirequestService: ApirequestService,
    private router: Router,
    private fileService: FileService
  ) {
  }

  ngOnInit() {
     // let today = new Date();
     const dateString = "2023-02-01T12:30:45.678Z";
     let today = new Date(dateString);
     var lastMonth = new Date(today);
     lastMonth.setDate(lastMonth.getDate() - 30);
     this.start_date = lastMonth.toISOString().slice(0, 10);
     this.end_date = today.toISOString().slice(0, 10);
    this.dataSource = new MatTableDataSource();
    this.getData(this.start_date, this.end_date);
  }

  filter_by_date() {
    this.loading = true;
    this.getData(this.start_date, this.end_date);
  }

  getData(start_date, end_date) {

    if (start_date != '' && end_date != '') {
      var formatedstartDate = moment(start_date).format('YYYY-MM-DD');
      var formatedendDate = moment(end_date).format('YYYY-MM-DD');
      this.url = 'analytics/channels/?start_date=' + formatedstartDate + '&end_date=' + formatedendDate;
    } else {
      this.start_date = moment(moment().subtract(30, 'days')).format('YYYY-MM-DD');
      this.end_date = moment(moment().subtract(1, 'days')).format('YYYY-MM-DD');

      this.url = 'analytics/channels/';
    }
    this.loading = true;
    this.apirequestService.get(this.url)
      .subscribe(
        data => {
          this.dataSource.data = data;
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
        });
  }

  navigateTo(link: any) {
    console.log(link.toLowerCase().replace(/\s/g, ''));
    this.router.navigate(['/statistics/traffic/' + link.toLowerCase().replace(/\s/g, '')]);
  }

  exportData() {
    var pageSize = 10000;
    this.url = 'analytics/channels/?start_date=' + this.start_date + '&end_date=' + this.end_date + '&export=True';
    this.loading = true;
    this.apirequestService.get(this.url)
      .subscribe(
        data => {
          this.loading = false;
          this.fileService.downloadFile(data.file_url);
        },
        error => {
          console.log(error);
          this.loading = false;

        });
  }

}

